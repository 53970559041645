import { TableView } from "@components/TableView";
import { ModelTwilioNumber } from "@sportsgravyengineering/sg-api-react-sdk";
import { GridColDef } from "@mui/x-data-grid";
import { getTwilioNumbers } from "@services/Network";

export const TwilioPhoneNumber = () => {
  const ACCOUNTS_COLUMNS: GridColDef<ModelTwilioNumber>[] = [
    {
      headerName: "Twillio Phone Number",
      field: "number",
      minWidth: 175,
      flex: 1,
      sortable: true
    },
    {
      headerName: "Assigned To",
      field: "name",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => {
        if (row.usedBy) {
          return (
            row.usedBy.person?.firstName + " " + row.usedBy.person?.lastName
          );
        } else {
          return "-";
        }
      }
    },
    {
      headerName: "Department",
      field: "department",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => {
        if (row.department) {
          return row.department.name;
        } else {
          return "-";
        }
      }
    },
    {
      headerName: "Location",
      field: "province",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => {
        return row.country + ", " + row.province;
      }
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => {
        if (row.usedById || row.departmentId) return "In Use";
        else return "Not In Use";
      }
    }
  ];

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "In Use",
        value: "IN_USE"
      },
      {
        label: "Not In Use",
        value: "NOT_IN_USE"
      }
    ]
  };

  return (
    <>
      <TableView
        title="Twillio Phone Number"
        useGet={getTwilioNumbers}
        columns={ACCOUNTS_COLUMNS}
        getRowId={(row) => row.number}
        filterConfig={filterConfig}
        defaultSort={[{ field: "name", sort: "asc" }]}
        isDeleteDisabled={() => true}
        hasActionColumn={false}
        rowSelection={false}
      />
    </>
  );
};
