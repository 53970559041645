import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Image,
  Text,
  Font
} from "@react-pdf/renderer";
import SGLogo from "@assets/images/sg-logo.png";
import { formatCurrency } from "@utils/formatCurrency";
import { capitalize } from "@utils/capitalize";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "../../fonts/Inter400.ttf",
      fontWeight: 400
    },
    {
      src: "../../fonts/Inter500.ttf",
      fontWeight: 500
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "32px 24px",
    display: "flex",
    height: "459px",
    width: "360px",
    letterSpacing: 0.5
  },
  view: {
    display: "flex",
    flexDirection: "column"
  },
  seperator: {
    marginTop: "10px",
    width: "100%",
    borderBottomWidth: "1px",
    borderBottomColor: "#E5E5E5",
    borderBottomStyle: "solid"
  },
  titleText: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#000"
  },
  bodyText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#000"
  },
  linkText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#007AFF"
  },
  flexbox: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  footer: {
    position: "absolute",
    textAlign: "center",
    color: "#666666",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    bottom: 30,
    fontSize: "10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: "5px"
  }
});

export const InvoiceReceipt = ({
  hasHeader,
  hasFooter,
  receiptDetails
}: {
  hasHeader: boolean;
  hasFooter?: boolean;
  receiptDetails: {
    amount: number;
    to: string;
    invoiceNumber: string;
    invoiceAmount: number;
    status: "PAID" | "UNPAID" | "FAILED";
    paymentMethod: string;
    authorizationId: string;
    from: {
      name: string;
      email: string;
      address: string;
      phone: string;
    };
  };
}) => {
  return (
    <Document>
      <Page size="B6" style={styles.page}>
        <View style={styles.view}>
          {hasHeader && (
            <>
              <Image src={SGLogo} style={{ width: "150px", height: "32px" }} />
              <View style={styles.seperator} />
            </>
          )}
        </View>
        <View
          style={{
            marginTop: hasHeader ? "24px" : 0,
            display: "flex",
            gap: "8px"
          }}
        >
          <Text style={styles.bodyText}>Payment receipt</Text>
          <Text style={styles.titleText}>
            You paid {formatCurrency(receiptDetails.amount)}
          </Text>
          <Text style={styles.bodyText}>to {receiptDetails.to}</Text>
        </View>
        <View style={styles.seperator} />

        <View style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
          <View style={styles.flexbox}>
            <Text style={styles.bodyText}>Invoice</Text>
            <Text style={styles.bodyText}>{receiptDetails.invoiceNumber}</Text>
          </View>
          <View style={styles.flexbox}>
            <Text style={styles.bodyText}>Invoice Amount</Text>
            <Text
              style={
                (styles.bodyText, { letterSpacing: 0.8, fontSize: "12px" })
              }
            >
              {formatCurrency(receiptDetails.invoiceAmount)}
            </Text>
          </View>
          <View style={styles.flexbox}>
            <Text style={styles.bodyText}>Total</Text>
            <Text
              style={
                (styles.bodyText, { letterSpacing: 0.8, fontSize: "12px" })
              }
            >
              {formatCurrency(receiptDetails.amount)}
            </Text>
          </View>
        </View>
        <View style={styles.seperator} />
        <View style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
          <View style={styles.flexbox}>
            <Text style={styles.bodyText}>Status</Text>
            <Text style={styles.bodyText}>
              {capitalize(receiptDetails.status)}
            </Text>
          </View>
          <View style={styles.flexbox}>
            <Text style={styles.bodyText}>Payment Method</Text>
            <Text style={styles.bodyText}>{receiptDetails.paymentMethod}</Text>
          </View>
          <View style={styles.flexbox}>
            <Text style={styles.bodyText}>Authorization ID</Text>
            <Text style={styles.bodyText}>
              {receiptDetails.authorizationId}
            </Text>
          </View>
        </View>

        <View
          style={[
            styles.flexbox,
            { flexDirection: "column", marginTop: "16px", gap: "6px" }
          ]}
        >
          <Text style={styles.bodyText}>Thank you</Text>
          <Text style={styles.bodyText}>{receiptDetails.from.name}</Text>
          <Text style={styles.bodyText}>{receiptDetails.from.phone}</Text>
          <Text style={styles.linkText}>{receiptDetails.from.email}</Text>
          <Text style={styles.linkText}>{receiptDetails.from.address}</Text>
        </View>

        {hasFooter && (
          <View style={styles.footer}>
            <Text>Powered By</Text>
            <Image
              src={SGLogo}
              style={{
                width: "80px",
                height: "15px"
              }}
            />
          </View>
        )}
      </Page>
    </Document>
  );
};
