import parsePhoneNumber, {
  parsePhoneNumberFromString
} from "libphonenumber-js";

export const formatPhoneWithHypens = (phone: string | undefined) => {
  if (!phone) return "";
  return phone.replace(/^(\+1)?(\d{3})(\d{3})(\d{4})/, "$2-$3-$4");
};

export const formatPhoneWithoutCountryCode = (phone: string | undefined) => {
  if (!phone) return "";
  return phone.replace(/^(\+1)?/, "");
};

export const formatPhone = (phone: string | undefined) => {
  if (!phone) return "";
  if (phone.indexOf("*") !== -1) return phone;

  const parsedPhone = parsePhoneNumber(phone);

  return parsedPhone?.formatNational();
};

export const formatPhoneWithCountryCode = (phone: string | undefined) => {
  if (!phone) return "";
  return `+${parsePhoneNumberFromString(phone)
    ?.countryCallingCode} ${formatPhone(phone)}`;
};
