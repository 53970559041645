import { PauseCircle, PlayCircle } from "@mui/icons-material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export const Timer = ({ onStop }: { onStop: (timeLogged: string) => void }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [timeValue, setTimeValue] = useState(0);
  const startTimeRef = useRef<number | null>(null);
  const accumulatedTimeRef = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);

  const updateTimer = () => {
    if (startTimeRef.current !== null) {
      const elapsed = performance.now() - startTimeRef.current;
      setTimeValue(accumulatedTimeRef.current + elapsed);
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    }
  };

  useEffect(() => {
    if (isRunning) {
      startTimeRef.current = performance.now();
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    } else {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
      if (startTimeRef.current !== null) {
        accumulatedTimeRef.current += performance.now() - startTimeRef.current;
        startTimeRef.current = null;
      }
    }
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isRunning]);

  return (
    <Grid item container direction="row" spacing="1px" alignItems="center">
      <Grid item xs={5.2}>
        <Button
          variant="outlined"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px 20px",
            borderRadius: "15px 0 15px 0",
            border: `1px solid ${isTimerVisible ? "#E82C2C" : "#2B337A"}`,
            color: isTimerVisible ? "#E82C2C" : "#2B337A",
            textTransform: "none",
            marginBottom: "8px"
          }}
          onClick={() => {
            if (isTimerVisible) {
              const hours = Math.floor((timeValue / 3_600_000) % 24);
              const minutes =
                Math.floor((timeValue / 60_000) % 60) + 60 * hours;
              const seconds = Math.floor((timeValue / 1_000) % 60);
              let timeLogged = "";
              if (minutes) {
                timeLogged = minutes + "m ";
              }
              if (seconds) {
                timeLogged += seconds + "s";
              } else {
                timeLogged = "0s";
              }
              setTimeValue(0);
              accumulatedTimeRef.current = 0;
              onStop(timeLogged);
            }
            setIsRunning(!isTimerVisible);
            setIsTimerVisible(!isTimerVisible);
          }}
        >
          {isTimerVisible ? "Stop Timer" : "Start Timer"}
        </Button>
      </Grid>
      {isTimerVisible && timeValue !== 0 && (
        <Grid
          item
          container
          alignSelf="center"
          direction="row"
          spacing="1px"
          xs={6.5}
        >
          <Grid item xs={7.5} alignSelf="center">
            <Typography
              variant="h4"
              style={{
                fontSize: "25px",
                fontWeight: 600,
                lineHeight: "38.73px",
                color: "#2B337A"
              }}
            >
              {("0" + Math.floor((timeValue / 3_600_000) % 24)).slice(-2)}:
              {("0" + Math.floor((timeValue / 60_000) % 60)).slice(-2)}:
              {("0" + Math.floor((timeValue / 1_000) % 60)).slice(-2)}
            </Typography>
          </Grid>
          <Grid item alignSelf="center">
            <IconButton
              onClick={() => {
                setIsRunning(!isRunning);
              }}
            >
              {isRunning ? (
                <PauseCircle fontSize="large" />
              ) : (
                <PlayCircle fontSize="large" />
              )}
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
