import { Popover, styled } from "@mui/material";
export const MoreOption = styled("div")`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f2f4f6;
  }
`;
export const MediaContextMenu = ({
  id,
  open,
  anchorEl,
  handleClose,
  items
}: {
  id;
  open;
  anchorEl;
  handleClose;
  items;
}) => {
  return (
    <Popover
      style={{ marginLeft: "25px", zIndex: 100001 }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      disableScrollLock={true}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      slotProps={{
        paper: {
          style: {
            borderRadius: "8px"
          }
        }
      }}
    >
      {items}
    </Popover>
  );
};
