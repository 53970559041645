/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, styled, Typography } from "@mui/material";
import {
  AccountCallerDetails,
  CallEmailTemplateContext
} from "@templates/CallEmailTemplate";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CallIcon from "@mui/icons-material/Call";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import MicNoneRoundedIcon from "@mui/icons-material/MicNoneRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { FormInput } from "@components/FormInput";
import { useForm } from "react-hook-form";
import { Button } from "@components/Button";
import { CallContactDetails } from "./CallContactDetails";
import dayjs from "dayjs";
import { updateCRMActivity } from "@services/Network";
import { hasPermission } from "@services/Casbin";

const StyledBox = styled("div")`
  position: absolute;
  bottom: 0;
  right: 32px;
  width: 450px;
  border-radius: 8px 0;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 24px 0px #00000029;
  background-color: #ffffff;
  z-index: 10000;
  max-height: 600px;
  overflow: auto;
  border-radius: 8px 8px 0 0;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

const StyledHeader = styled("div")`
  background-color: #1abc9c;
  padding: 12px 24px;
  border-radius: 8px 8px 0 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const StyledForm = styled("div")`
  padding: 0 24px 24px 24px;

  button {
    margin-top: 10px;
    width: 100%;
  }
`;

const CallActionButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const CallActionButton = styled("div")`
  height: 68px;
  width: 68px;
  background-color: #e82c2c;
  padding: 18px;
  border-radius: 99px;
`;

const IconStyle = {
  height: "32px",
  width: "32px",
  color: "#fff"
};

export const MergeCall = ({ account }: { account: AccountCallerDetails }) => {
  const { control } = useForm({
    mode: "onBlur",
    defaultValues: { feedback: "" }
  });
  const { callInstance, disconnectCall, setCallInstance } = useContext(
    CallEmailTemplateContext
  );
  const [expanded, setExpanded] = useState(true);
  const [feedback, setFeedback] = useState("");
  const [isMuted, setIsMuted] = useState(true);
  const [feedbackPermission, setFeedbackPermission] = useState(false);
  const mutation = updateCRMActivity();

  const toggleMute = () => {
    setIsMuted(!isMuted);
    callInstance?.mute(!isMuted);
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const feedback = await checkPermission("crm.manager-feedback", "ON");
      setFeedbackPermission(feedback);
    };
    fetchPermissions();
  }, []);
  return (
    <>
      <StyledBox>
        <StyledHeader>
          <StyledIconButton
            onClick={() => {
              setCallInstance(undefined);
              disconnectCall(true);
            }}
          >
            <CloseRoundedIcon
              style={{ height: "24px", width: "24px", color: "#fff" }}
            />
          </StyledIconButton>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <CallIcon style={{ color: "#ffffff" }} />
            <span>
              Live{" "}
              {account?.callDirection == "inbound" ? "Incoming" : "Outgoing"}{" "}
              Call
            </span>
          </div>
          <StyledIconButton>
            {!expanded ? (
              <ExpandLessOutlinedIcon
                onClick={() => setExpanded(true)}
                style={{
                  height: "24px",
                  width: "24px",
                  color: "#fff",
                  cursor: "pointer"
                }}
              />
            ) : (
              <ExpandMoreOutlinedIcon
                onClick={() => setExpanded(false)}
                style={{
                  height: "30px",
                  width: "30px",
                  color: "#fff",
                  cursor: "pointer"
                }}
              />
            )}
          </StyledIconButton>
        </StyledHeader>
        <div
          style={{
            display: expanded ? "flex" : "none",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "24px 40px",
            gap: "16px"
          }}
        >
          <CallContactDetails account={account} showTimer={true} />
        </div>
        <StyledForm style={{ display: expanded ? "block" : "none" }}>
          <div>
            <Typography style={{ color: "#666", fontSize: "14px" }}>
              Call Participants
            </Typography>
            <div
              style={{
                marginTop: "8px",
                backgroundColor: "#F3F4F7",
                borderRadius: "4px",
                padding: "8px",
                display: "flex",
                gap: "6px"
              }}
            >
              <Typography
                style={{ color: "#0F0F0F", fontSize: "16px", fontWeight: 500 }}
              >
                {account?.call?.participants?.[0].name}
              </Typography>
              <Typography style={{ color: "#666", fontSize: "16px" }}>
                {account?.call?.participants?.[0].role}
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "40px",
              marginTop: "40px",
              marginBottom: "24px"
            }}
          >
            <CallActionButtonContainer onClick={toggleMute}>
              <CallActionButton
                style={{
                  backgroundColor: isMuted ? "#2B337A" : "#F3F4F7"
                }}
              >
                {isMuted ? (
                  <MicNoneRoundedIcon style={IconStyle} />
                ) : (
                  <MicOffOutlinedIcon style={{ ...IconStyle, color: "#000" }} />
                )}
              </CallActionButton>
              <span style={{ color: "#666666", fontSize: "14px" }}>
                {isMuted ? "Unmute" : "Mute"}
              </span>
            </CallActionButtonContainer>
            <CallActionButtonContainer
              onClick={() => {
                setCallInstance(undefined);
                disconnectCall(true);
              }}
            >
              <CallActionButton>
                <LogoutRoundedIcon style={IconStyle} />
              </CallActionButton>
              <span style={{ color: "#666666", fontSize: "14px" }}>Leave</span>
            </CallActionButtonContainer>
          </div>
          {feedbackPermission && (
            <>
              <FormInput
                control={control}
                type="text"
                name="feedback"
                label="Manager Feedback"
                rows={4}
                multiline
                onChange={(e) => {
                  setFeedback(
                    (e as ChangeEvent<HTMLInputElement>).target.value
                  );
                }}
              />
              <Button
                variant="admin-primary"
                disabled={feedback == "" || mutation.isLoading}
                isLoading={mutation.isLoading}
                onClick={() => {
                  if (account?.activityId)
                    mutation.mutate({
                      activityId: account?.activityId,
                      data: {
                        accountId: account.accountId,
                        type: "CALL",
                        relatesTo: "ACCOUNT",
                        status: "COMPLETED",
                        date: new Date(),
                        timezone: dayjs.tz.guess(),
                        managerFeedback: feedback
                      }
                    });
                }}
              >
                Save
              </Button>
            </>
          )}
        </StyledForm>
      </StyledBox>
    </>
  );
};
