import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Typography,
  styled
} from "@mui/material";
import { LEAD_SOURCES, LEAD_STATUS } from "@utils/constants";

import { Controller, UseFormReturn } from "react-hook-form";
import { SearchInput } from "@components/SearchInput";
import { useEffect, useState } from "react";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { CloseIcon } from "@components/Icons";
import { QuickAddContactForm } from "../components/QuickAddContactForm";
import { AccountDetailsForm } from "../components/AccountDetailsForm";
import { ContactDetailsSubForm } from "../components/ContactDetailsSubForm";
import {
  ContactResponse,
  ModelAccount,
  useAdminUserCrmGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { Loader } from "@components/crud/Loader";
import { QuickAddAccount } from "../accounts/components/QuickAddAccount";
import { LoadScript } from "@react-google-maps/api";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { useParams } from "react-router-dom";

const libraries = ["places"] as "places"[];

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 1000,
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

export const LeadDetailsForm = ({
  disabled,
  form,
  leadOwner,
  setLeadOwner,
  selectedAccount,
  setSelectedAccount,
  selectedContact,
  setSelectedContact,
  contacts,
  setContacts,
  setQualifiedLead,
  isMQL,
  mode,
  account
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  leadOwner;
  setLeadOwner;
  selectedAccount;
  setSelectedAccount;
  selectedContact;
  setSelectedContact;
  contacts: {
    label: string;
    value: string;
    isPrimary: boolean;
    details: ContactResponse;
  }[];
  setContacts;
  setQualifiedLead?: (isQualified: boolean) => void;
  isMQL?: boolean;
  mode?: "EDIT" | "VIEW";
  account?: ModelAccount;
}) => {
  const { control, setValue, getValues } = form;
  const [leadOwnerOptions, setLeadOwnerOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [leadOwnerInputValue, setLeadOwnerInputValue] = useState<string>("");
  const { leadId } = useParams();
  const { data: leadOwnerOptionsData, isLoading: isLoadingLeadOwnerOptions } =
    useAdminUserCrmGet({
      type: "lead_owner"
    });

  useEffect(() => {
    if (leadOwnerOptionsData?.data) {
      setLeadOwnerOptions(
        leadOwnerOptionsData.data.map((per) => ({
          label: formatFullName(per),
          value: per.personId!
        }))
      );
    }
  }, [leadOwnerOptionsData]);
  useEffect(() => {
    if (leadOwner)
      setLeadOwnerInputValue(
        leadOwnerOptions.find((ow) => ow.value === leadOwner)?.label || ""
      );
  }, [leadOwner, leadOwnerOptions]);

  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showQuickAddAccount, setShowQuickAddAccount] = useState(false);

  useEffect(() => {
    if (selectedContact) {
      const contact = contacts.find((c) => c.value === selectedContact);
      if (contact) {
        setValue(
          "contact",
          {
            name: formatFullName(contact.details),
            personalEmail: contact.details.email,
            homeAddress: contact.details.location,
            phoneType: "MOB",
            phone: contact.details.phone,
            whatsappNumber: contact.details.whatsappNumber,
            workEmail: contact.workEmail,
            workPhone: contact.workPhone,
            jobTitle: contact.jobTitle
          },
          {
            shouldDirty: true,
            shouldValidate: true
          }
        );
      }
    } else if (!isMQL) {
      setValue(
        "contact",
        {
          name: "",
          personalEmail: "",
          homeAddress: "",
          phoneType: "MOB",
          phone: "",
          whatsappNumber: ""
        },
        {
          shouldDirty: true,
          shouldValidate: true
        }
      );
    }
  }, [selectedContact, contacts]);
  return (
    <Loader isLoading={isLoadingLeadOwnerOptions}>
      <Grid
        data-testid="lead-detail-form"
        container
        direction="column"
        spacing="25px"
      >
        <Modal
          open={showAddContactModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MainContainer>
            <Header>
              <HeaderText>{`Quick Add Contact for ${
                getValues().account?.name || "Account"
              }`}</HeaderText>
              <IconButton
                onClick={() => {
                  setShowAddContactModal(false);
                  //setRefreshKey(refreshKey + 1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Header>

            {selectedAccount && (
              <QuickAddContactForm
                onClose={() => {
                  setShowAddContactModal(false);
                }}
                onSave={(resp) => {
                  const options = contacts;
                  setContacts([
                    ...options,
                    {
                      label: `${resp.firstName} ${resp.lastName}`,
                      value: resp.contactId,
                      isPrimary: !!resp.accounts?.[0]?.isPrimary,
                      details: resp,
                      jobTitle: resp.accounts?.[0]?.jobTitle || "",
                      workEmail: resp.accounts?.[0].workEmail || "",
                      workPhone: resp.accounts?.[0]?.workPhone || ""
                    }
                  ]);
                  setSelectedContact(resp.contactId);
                  setShowAddContactModal(false);
                }}
                accountId={selectedAccount}
                accountName={getValues("account.name")}
              />
            )}
          </MainContainer>
        </Modal>

        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6}>
            <FormInput
              name="name"
              control={control}
              rules={{ required: "Name is required" }}
              label="Name"
              type="text"
              required={true}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name="source"
              control={control}
              label="Source"
              options={LEAD_SOURCES}
              disabled={disabled || isMQL}
              required
              rules={{ required: "Source is required" }}
            />
          </Grid>
          {isMQL && (
            <Grid item xs={12}>
              <FormInput
                name="referralUrl"
                control={control}
                label="Referral URL"
                type="text"
                disabled
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormSelect
              name="status"
              control={control}
              label="Status"
              options={LEAD_STATUS}
              disabled={disabled}
              required
              onChange={(e) => {
                if (setQualifiedLead)
                  setQualifiedLead(
                    e.target.value === "CONTACTED_QUALIFIED_LEAD"
                  );
              }}
              rules={{ required: "Status is required" }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="leadOwner"
              control={control}
              rules={{ required: "Lead Owner is required" }}
              render={({ fieldState, field }) => (
                <FormInputContainer>
                  <StyledFormLabel required>
                    <Typography display="inline" variant="formLabel">
                      {"Lead Owner"}
                    </Typography>
                  </StyledFormLabel>
                  <Autocomplete
                    disabled={disabled}
                    options={leadOwnerOptions || []}
                    inputValue={leadOwnerInputValue}
                    onBlur={field.onBlur}
                    disableClearable={false}
                    clearOnBlur={true}
                    renderInput={(params) => {
                      return (
                        <SearchInput
                          data-testid="SEARCH_LOCATION_INPUT"
                          {...params}
                          {...fieldState}
                          value={leadOwnerInputValue}
                          onChange={(e) => {
                            setValue("leadOwner", undefined, {
                              shouldValidate: true
                            });
                            setLeadOwnerInputValue(e.target.value);
                          }}
                          clearable
                          onClearClick={() => {
                            setLeadOwnerInputValue("");
                            setLeadOwner(undefined);
                            setValue("leadOwner", undefined, {
                              shouldValidate: true
                            });
                          }}
                        />
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <MenuItem
                          data-testId={`Lead_owner_OPTION_${option.value}`}
                          key={option.value}
                          id={option.value}
                          selected={
                            leadOwner
                              ? option.value === leadOwner
                              : option.value === undefined
                          }
                          onClick={() => {
                            setLeadOwnerInputValue(option.label);
                            setLeadOwner(option.value);

                            setValue("leadOwner", option.value, {
                              shouldValidate: true
                            });
                          }}
                        >
                          <>{option.label}</>
                        </MenuItem>
                      );
                    }}
                  />
                </FormInputContainer>
              )}
            />
          </Grid>
        </Grid>
        <LoadScript
          key="address"
          googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
          libraries={libraries}
          loadingElement={<LoadingSpinner />}
        >
          <AccountDetailsForm
            disabled={mode === "EDIT" ? !!selectedAccount : disabled}
            shouldEnable={isMQL && mode === "EDIT" && !selectedAccount}
            form={form}
            setSelectedAccount={setSelectedAccount}
            setIsNewAccountSelected={setShowQuickAddAccount}
            relatesTo="LEAD"
            relatesToId={leadId}
            account={account?.accountId}
          />
          <ContactDetailsSubForm
            disabled={mode === "EDIT" ? !!selectedAccount : disabled}
            shouldEnable={isMQL && mode === "EDIT" && !selectedAccount}
            mode={mode}
            form={form}
            contactOptions={contacts}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            setShowAddContactModal={setShowAddContactModal}
            selectedAccount={selectedAccount}
            account={account}
            relatesTo="LEAD"
            relatesToId={leadId as string}
          />
          {showQuickAddAccount && (
            <QuickAddAccount
              addLoadScript={false}
              onClose={() => {
                setShowQuickAddAccount(false);
              }}
              addNewAccountOption={false}
              onSave={(resp) => {
                setValue("account", {
                  name: resp.name,
                  parent: resp.parent?.name,
                  category: resp.category,
                  type: resp.type,
                  officeAddress: resp.officeAddress,
                  email: resp.email,
                  website: resp.website,
                  sportsOffered: [],
                  noOfAthletes: "",
                  aeOwner: ""
                });
                setSelectedAccount(resp.accountId);
                if (resp?.contacts && resp?.contacts.length > 0)
                  setSelectedContact(resp?.contacts?.[0]?.contactId);
                setShowQuickAddAccount(false);
              }}
            />
          )}
        </LoadScript>
        {isMQL && (
          <Grid item xs={12}>
            <FormInput
              multiline
              name="interestInSG"
              disabled
              control={control}
              rows={3}
              label="Why are you interested in SportsGravy?"
              type="text"
            />
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};
