import { Fragment } from "react";
import { Typography, styled } from "@mui/material";
import {
  CallMadeLoggedIcon,
  CallUpdatedIcon,
  EmailSentLoggedIcon,
  EmailUpdatedIcon,
  MeetingLoggedIcon,
  MeetingUpdatedIcon,
  TaskLoggedIcon,
  TaskUpdatedIcon
} from "@components/Icons";
import { useMemo } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from "@mui/lab/TimelineOppositeContent";
import formatFullName from "@utils/formatFullName";
import { useNavigate } from "react-router-dom";

const dateFormatActivity = (iso) => {
  const date = new Date(iso);
  const formattedDate = date.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short"
  });

  return formattedDate;
};

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: inline-block;
`;

const StyledTimeLineTime = styled(TimelineOppositeContent)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  padding-left: 0;
  padding-top: 15px;,
  min-width: 80px !important;
  flex: 0 !important; 
  min-width: 80px;
`;

const StyledTimelineContent = styled(TimelineContent)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  padding-left: 24px;
  padding-top: 8px;
`;

const StyledTimelineDot = styled(TimelineDot)`
  margin: 0;
  background-color: #f7f7f7;
  padding: 12px;
  border: 1px solid #e5e5e5;
  box-shadow: none;
`;

const StyledTimelineDateBox = styled(TimelineDot)`
  height: 34px;
  padding: 8px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  background-color: #f7f7f7;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid #e5e5e5;
  width: 150px;
  color: #000000;
  justify-content: center;
  margin: 0;
  position: relative;
  left: -32px;
  box-shadow: none;
`;

const StyledTimelineConnector = styled(TimelineConnector)`
  height: 48px;
  background-color: #e5e5e5;
`;

const ColumnFlexBox = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const IconStyle = {
  color: "#666666",
  height: "24px",
  width: "24px"
};

const dateFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC"
  });
};

const timeFormat = (isoString: string): string => {
  const date = new Date(isoString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });
};

const ACTIVITY_TYPES = [
  {
    label: "Call",
    value: "CALL",
    type: "LOGGED",
    icon: <CallMadeLoggedIcon style={IconStyle} />
  },
  {
    label: "Call",
    value: "CALL",
    type: "CREATED",
    icon: <CallMadeLoggedIcon style={IconStyle} />
  },
  {
    label: "Call",
    value: "CALL",
    type: "UPDATE",
    icon: <CallUpdatedIcon style={IconStyle} />
  },
  {
    label: "Call",
    value: "CALL",
    type: "MANAGER_FEEDBACK",
    icon: <CallUpdatedIcon style={IconStyle} />
  },
  {
    label: "Email",
    value: "EMAIL",
    type: "LOGGED",
    icon: <EmailSentLoggedIcon style={IconStyle} />
  },
  {
    label: "Email",
    value: "EMAIL",
    type: "CREATED",
    icon: <EmailSentLoggedIcon style={IconStyle} />
  },
  {
    label: "Email",
    value: "EMAIL",
    type: "UPDATE",
    icon: <EmailUpdatedIcon style={IconStyle} />
  },
  {
    label: "Email",
    value: "EMAIL",
    type: "MANAGER_FEEDBACK",
    icon: <EmailUpdatedIcon style={IconStyle} />
  },
  {
    label: "Meeting",
    value: "MEETING",
    type: "CREATED",
    icon: <MeetingLoggedIcon style={IconStyle} />
  },
  {
    label: "Meeting",
    value: "MEETING",
    type: "LOGGED",
    icon: <MeetingLoggedIcon style={IconStyle} />
  },
  {
    label: "Meeting",
    value: "MEETING",
    type: "UPDATE",
    icon: <MeetingUpdatedIcon style={IconStyle} />
  },
  {
    label: "Meeting",
    value: "MEETING",
    type: "MANAGER_FEEDBACK",
    icon: <MeetingUpdatedIcon style={IconStyle} />
  },
  {
    label: "Task",
    value: "TASK",
    type: "LOGGED",
    icon: <TaskLoggedIcon style={IconStyle} />
  },
  {
    label: "Task",
    value: "TASK",
    type: "UPDATE",
    icon: <TaskUpdatedIcon style={IconStyle} />
  },
  {
    label: "Task",
    value: "TASK",
    type: "MANAGER_FEEDACK",
    icon: <TaskUpdatedIcon style={IconStyle} />
  }
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ActivityDetails = ({ details }: { details: any }) => {
  const navigate = useNavigate();
  const activityContent = (activity) => {
    if (activity.activity.type === "TASK") {
      let linkText = "",
        remainingText = "";
      switch (activity.type) {
        case "LOGGED":
          linkText = `logged a Task`;
          remainingText = ` on ${dateFormatActivity(activity.date)}`;
          break;
        case "UPDATE":
          linkText = "updated a Task";
          remainingText = ` activity created on ${dateFormatActivity(
            activity.activity.createdAt
          )}`;
          break;
        case "MANAGER_FEEDBACK":
          linkText = "added Manager Feedback";
          remainingText = ` to a Task created by ${formatFullName(
            activity.activity.createdBy
          )} on ${dateFormatActivity(activity.date)}`;
      }
      return (
        <ColumnFlexBox>
          <div style={{ display: "inline" }}>
            <span>{`${formatFullName(activity.updatedBy?.person)} `}</span>
            <StyledLink
              onClick={() =>
                navigate(`/crm/activities/${activity.activity.activityId}`)
              }
            >
              {linkText}
            </StyledLink>
            <span>{remainingText}</span>
          </div>
        </ColumnFlexBox>
      );
    }
    if (activity.activity.type === "CALL" && activity.activity.call) {
      let linkText = "",
        remainingText = "";
      const callType = activity.activity.call.type;
      const caller = callType === "inbound" ? "" : "";
      const receiver = "";
      switch (activity.type) {
        case "LOGGED":
          linkText = `logged an ${
            callType === "inbound" ? "Incoming Call" : "Outgoing Call"
          }`;
          remainingText = ` ${
            callType === "inbound" ? "from" : "to"
          } ${activity.activity.externalParticipants
            ?.map((p) => formatFullName(p))
            .join(",")} on ${dateFormatActivity(activity.date)}`;
          break;
        case "CREATED":
          linkText = `${
            callType === "inbound"
              ? "received an Incoming Call"
              : "made an Outgoing Call"
          }`;
          remainingText = ` ${
            callType === "inbound" ? "from" : "to"
          } ${activity.activity.externalParticipants
            ?.map((p) => formatFullName(p))
            .join(",")} on ${dateFormatActivity(activity.date)}`;
          break;
        case "UPDATE":
          linkText = "updated a Call";
          remainingText = ` activity for an ${
            callType === "inbound" ? "Outgoing Call to" : "Incoming Call from"
          }  ${activity.activity.externalParticipants
            ?.map((p) => formatFullName(p))
            .join(",")} on ${dateFormatActivity(activity.date)}`;
          break;
        case "MANAGER_FEEDBACK":
          linkText = "added Manager Feedback";
          remainingText = ` for an ${
            callType === "inbound" ? "Outgoing Call" : "Incoming Call"
          } ${caller} ${
            callType === "inbound" ? "received from" : "made to"
          } ${receiver} ${activity.activity.externalParticipants
            ?.map((p) => formatFullName(p))
            .join(",")} on ${dateFormatActivity(activity.date)}`;
      }
      return (
        <ColumnFlexBox>
          <div style={{ display: "inline" }}>
            <span>{`${formatFullName(activity.updatedBy?.person)} `}</span>
            <StyledLink
              onClick={() =>
                navigate(`/crm/activities/${activity.activity.activityId}`)
              }
            >
              {linkText}
            </StyledLink>
            <span>{remainingText}</span>
          </div>
        </ColumnFlexBox>
      );
    }
    if (activity.activity.type === "EMAIL" && activity.activity.email) {
      let linkText = "",
        remainingText = "";
      const sentTo = [
        ...activity.activity.email.to,
        ...activity.activity.email.cc,
        ...activity.activity.email.bcc
      ];
      switch (activity.type) {
        case "LOGGED":
          linkText = `logged an Email`;
          remainingText = ` sent to ${sentTo
            .map((t) => t.name)
            .join(", ")} on ${dateFormatActivity(activity.date)}`;
          break;
        case "CREATED":
          linkText = `sent an Email`;
          remainingText = ` to ${sentTo
            .map((t) => t.name)
            .join(", ")} on ${dateFormatActivity(activity.date)}`;
          break;
        case "UPDATE":
          linkText = "updated an Email";
          remainingText = ` activity sent to ${sentTo
            .map((t) => t.name)
            .join(", ")} on ${dateFormatActivity(activity.date)}`;
          break;
        case "MANAGER_FEEDBACK":
          linkText = "added Manager Feedback";
          remainingText = ` to an email ${
            activity.activity.email?.from?.name || ""
          } sent to ${sentTo
            .map((t) => t.name)
            .join(", ")} on ${dateFormatActivity(activity.date)}`;
      }
      return (
        <ColumnFlexBox>
          <div style={{ display: "inline" }}>
            <span>{`${formatFullName(activity.updatedBy?.person)} `}</span>
            <StyledLink
              onClick={() =>
                navigate(`/crm/activities/${activity.activity.activityId}`)
              }
            >
              {linkText}
            </StyledLink>
            <span>{remainingText}</span>
          </div>
        </ColumnFlexBox>
      );
    }
    if (activity.activity.type === "MEETING") {
      const participants = [
        ...(activity.activity.internalParicipants?.map((p) =>
          formatFullName(p?.person)
        ) || []),
        ...(activity.activity.externalParticipants?.map((p) =>
          formatFullName(p)
        ) || [])
      ];
      let linkText = "",
        remainingText = "";
      switch (activity.type) {
        case "CREATED":
          linkText = `logged a Meeting`;
          remainingText = ` with ${participants.join(
            ", "
          )} on ${dateFormatActivity(activity.date)}`;
          break;
        case "LOGGED":
          linkText = `logged a Meeting`;
          remainingText = ` with ${participants.join(
            ", "
          )} on ${dateFormatActivity(activity.date)}`;
          break;
        case "UPDATE":
          linkText = "updated a Meeting";
          remainingText = ` activity happened between ${participants.join(
            ", "
          )} on ${dateFormatActivity(activity.date)}`;
          break;
        case "MANAGER_FEEDBACK":
          linkText = "added Manager Feedback";
          remainingText = ` to a Meeting happened between ${participants.join(
            ", "
          )} on ${dateFormatActivity(activity.date)}`;
      }
      return (
        <ColumnFlexBox>
          <div style={{ display: "inline" }}>
            <span>{`${formatFullName(activity.updatedBy?.person)} `}</span>
            <StyledLink
              onClick={() =>
                navigate(`/crm/activities/${activity.activity.activityId}`)
              }
            >
              {linkText}
            </StyledLink>
            <span>{remainingText}</span>
          </div>
        </ColumnFlexBox>
      );
    }
  };

  const formattedActivities = useMemo(() => {
    return details.reduce((acc, activity) => {
      const date = activity.date.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(activity);
      return acc;
    }, {});
  }, [details]);

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2
        },
        padding: "24px 0 0 0"
      }}
    >
      {Object.keys(formattedActivities).map((date, index) => (
        <Fragment key={date}>
          <TimelineItem key={index}>
            <TimelineOppositeContent
              style={{ flex: 0 }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
              <StyledTimelineDateBox>{dateFormat(date)}</StyledTimelineDateBox>
              <StyledTimelineConnector />
            </TimelineSeparator>
            <StyledTimelineContent></StyledTimelineContent>
          </TimelineItem>
          {formattedActivities[date].map((activity, index) => (
            <TimelineItem key={index}>
              <StyledTimeLineTime>
                {timeFormat(activity.date)}
              </StyledTimeLineTime>
              <TimelineSeparator>
                <StyledTimelineDot>
                  {ACTIVITY_TYPES.find(
                    (type) =>
                      type.value === activity.activity?.type &&
                      type.type === activity.type
                  )?.icon || null}
                </StyledTimelineDot>
                <StyledTimelineConnector />
              </TimelineSeparator>
              <StyledTimelineContent>
                {activityContent(activity)}
              </StyledTimelineContent>
            </TimelineItem>
          ))}
        </Fragment>
      ))}
    </Timeline>
  );
};
