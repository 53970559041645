import { Loader } from "@components/crud/Loader";
import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  styled,
  Typography
} from "@mui/material";
import {
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers-pro";
import { FormSelect } from "@components/FormSelect";
import { Toolbar } from "@components/crud/Toolbar";
import { Edit } from "@mui/icons-material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";

const StyledGrid = styled(Grid)`
  padding: 0 24px;
  .toolbar {
    padding: 24px 0 0 0;
  }
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  padding: 1.5rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
  padding-bottom: 16px;
`;

const StyledFormLabel = styled(FormLabel)`
  & .MuiFormLabel-asterisk {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

export const MarketingSetting = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [permission, setPermission] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { control, reset, getValues, setValue } = useForm({
    mode: "onBlur"
  });

  const { data: settings, isLoading: isLoading } = useConfigGet();

  const { mutate: save, isLoading: isSaving } = useConfigPut();

  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });

  const onSave = () => {
    const data = [
      {
        key: "marketing.start-time-pre-break",
        value: getValues("marketing.start-time-pre-break")
      },
      {
        key: "marketing.end-time-pre-break",
        value: getValues("marketing.end-time-pre-break")
      },
      {
        key: "marketing.start-time-post-break",
        value: getValues("marketing.start-time-post-break")
      },
      {
        key: "marketing.end-time-post-break",
        value: getValues("marketing.end-time-post-break")
      },
      {
        key: "marketing.time-zone",
        value: getValues("marketing.time-zone")
      },
      {
        key: "marketing.demo-duration",
        value: getValues("marketing.demo-duration")
      }
    ];
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    if (settings?.data) {
      const formData = {};
      settings.data
        .filter(
          //@ts-ignore
          (s) => s.key?.includes("marketing.")
        )
        .map((item) => {
          if (item.key) {
            if (item.key.includes("break")) {
              const timeString = item.value as string;
              if (timeString) {
                const [hours, minutes] = timeString.split(":").map(Number);
                const date = new Date();
                date.setHours(hours, minutes, 0, 0);
                formData[item.key.replace(/\./g, "_")] = date;
              }
            } else {
              formData[item.key.replace(/\./g, "_")] = item.value;
            }
          }
        });
      reset(formData);
    }
  }, [settings]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setPermission(edit);
    };
    fetchPermissions();
  }, []);

  const TimePicker = ({ name, label }: { name: string; label: string }) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: `${label} is required`
        }}
        render={({ fieldState, field }) => (
          <div data-testid={name}>
            <StyledFormLabel required>
              <Typography display="inline" variant="formLabel">
                {label}
              </Typography>
            </StyledFormLabel>
            <MuiTimePicker
              disabled={disabled}
              ampm={false}
              onChange={(date) => {
                field.onChange(date);
              }}
              onClose={() => {
                const date = getValues(name);
                const time = `${date
                  ?.getHours()
                  .toString()
                  .padStart(2, "0")}:${date
                  ?.getMinutes()
                  .toString()
                  .padStart(2, "0")}`;
                setValue(name.replace(/_/g, "."), time);
              }}
              value={new Date(field.value as string)}
              ampmInClock={true}
              sx={{ width: "100%", marginTop: "10px" }}
              views={["hours", "minutes"]}
              format="HH:mm"
            />
            <FormHelperText error={!!fieldState.error}>
              {fieldState.error?.message}
            </FormHelperText>
          </div>
        )}
      />
    );
  };

  return (
    <Loader isLoading={isLoading}>
      <StyledGrid item container direction="column" spacing="15px">
        {permission && disabled && (
          <Toolbar
            title=""
            addBtnClick={() => setDisabled(false)}
            addBtnLabel="Edit"
            addBtnIcon={<Edit />}
          />
        )}
        <StyledContainer>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <StyledTypography>
                Sports organization sign up demos
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid container columnSpacing="24px" sx={{ marginTop: "16px" }}>
            <Grid item xs={3}>
              <TimePicker
                label="Start Time (Before Break)"
                name="marketing_start-time-pre-break"
              />
            </Grid>
            <Grid item xs={3}>
              <TimePicker
                label="End Time (Before Break)"
                name="marketing_end-time-pre-break"
              />
            </Grid>
            <Grid item xs={3}>
              <TimePicker
                label="Start Time (After Break)"
                name="marketing_start-time-post-break"
              />
            </Grid>
            <Grid item xs={3}>
              <TimePicker
                label="End Time (After Break)"
                name="marketing_end-time-post-break"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing="24px" sx={{ marginTop: "32px" }}>
            <Grid item xs={6} data-testid="marketing_time-zone">
              <FormSelect
                disabled={disabled}
                options={timeZoneOptions}
                control={control}
                label="Time Zone"
                name="marketing_time-zone"
                required
                rules={{
                  required: "Time Zone is required"
                }}
                onChange={(e) => {
                  setValue("marketing.time-zone", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6} data-testid="marketing_demo-duration">
              <FormSelect
                disabled={disabled}
                control={control}
                label="Duration"
                name="marketing_demo-duration"
                required
                options={[
                  {
                    label: "30 minutes",
                    value: 30
                  },
                  {
                    label: "45 minutes",
                    value: 45
                  },
                  {
                    label: "1 hour",
                    value: 60
                  }
                ]}
                rules={{
                  required: "Duration is required"
                }}
                onChange={(e) => {
                  setValue("marketing.demo-duration", parseInt(e.target.value));
                }}
              />
            </Grid>
          </Grid>
        </StyledContainer>
      </StyledGrid>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => setDisabled(true)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {!disabled && (
        <Footer
          saveBtnClick={onSave}
          isLoading={isSaving}
          isDisabled={isSaving}
          saveBtnLabel="Save"
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
    </Loader>
  );
};
