import { Loader } from "@components/crud/Loader";
import { FormInput } from "@components/FormInput";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Add } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled
} from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import { profileAtom } from "@recoil/auth";
import { createCRMActivity } from "@services/Network";
import { useContext, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useRecoilValue } from "recoil";
import colors from "theme/colors";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { enqueueSnackbar } from "notistack";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import CallIcon from "@mui/icons-material/Call";
import {
  ContactResponse,
  ModelAccount,
  ModelActivityRelatesTo
} from "@sportsgravyengineering/sg-api-react-sdk";
import EmailIcon from "@mui/icons-material/Email";
import { ToolTip } from "@components/ToolTip";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autocompleteService = { current: null } as any;

const StyledSelect = styled(Select)((props) => ({
  height: 35,
  color: props.color,
  minWidth: "150px",
  borderBottom: "1px solid #E5E5E5",
  fieldset: {
    "&.MuiOutlinedInput-notchedOutline": {
      // @ts-ignore
      borderColor: "rgba(0, 0, 0, 0.26)"
    }
  }
}));

export const ContactDetailsSubForm = ({
  disabled,
  form,
  contactOptions,
  selectedContact,
  setSelectedContact,
  setShowAddContactModal,
  selectedAccount,
  mode,
  shouldEnable,
  account,
  relatesTo,
  relatesToId
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  contactOptions: {
    label: string;
    value: string;
    isPrimary: boolean;
    details: ContactResponse;

    jobTitle: string;
    workEmail: string;
    workPhone: string;
  }[];
  setShowAddContactModal;
  selectedContact;
  setSelectedContact;
  selectedAccount;
  mode?: "EDIT" | "VIEW" | "ADD";
  shouldEnable?: boolean;
  account?: ModelAccount;
  relatesTo: ModelActivityRelatesTo;
  relatesToId: string;
}) => {
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const mutation = createCRMActivity();
  const {
    setCallerDetails,
    connectToCall,
    setCallAccepted,
    callInstance,
    setEmailTo
  } = useContext(CallEmailTemplateContext);
  const [addressSelected, setAddressSelected] = useState(true);
  const { control, setValue, trigger } = form;
  const [open, setOpen] = useState(false);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;

    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    let address: string = "";

    if (address1 && !address1.includes("undefined")) address = `${address1}, `;
    if (city?.long_name && city?.long_name != "undefined")
      address += `${city?.long_name}, `;
    if (state?.short_name && state?.short_name != "undefined")
      address += `${state?.short_name}, `;
    if (zip?.long_name && zip?.long_name != "undefined")
      address += `${zip?.long_name}, `;
    if (country?.short_name && country?.short_name != "undefined")
      address += `${country?.short_name}`;
    setValue("contact.homeAddress", address);
  };

  useEffect(() => {
    if (trigger) {
      if (addressSelected) {
        trigger("contact.homeAddress");
      }
    }
  }, [addressSelected]);
  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item container xs={12} direction="column">
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "10%",
                lineHeight: "14.52px",
                opacity: "50%"
              }}
            >
              CONTACT DETAILS
            </Typography>
          </Grid>
          {selectedAccount && (
            <Grid item>
              <StyledSelect
                disabled={!selectedAccount || (disabled && mode !== "EDIT")}
                value={selectedContact}
                onChange={(event) => {
                  if (event.target.value)
                    setSelectedContact(event.target.value);
                }}
                inputProps={{ shrink: "true" }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
              >
                {contactOptions.map((item) => {
                  return (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      style={{
                        borderBottom: "1px solid #E5E5E5"
                      }}
                    >
                      {`${item.label} ${
                        item.isPrimary ? "- Primary Contact" : ""
                      }`}
                    </MenuItem>
                  );
                })}

                <Button
                  startIcon={<Add />}
                  style={{
                    color: colors.primary.main,
                    textTransform: "capitalize"
                  }}
                  onClick={() => {
                    setShowAddContactModal(true);
                  }}
                >
                  Add Contact
                </Button>
              </StyledSelect>
            </Grid>
          )}
        </Grid>

        <HeaderUnderLine width="100%" />
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <FormInput
            name="contact.name"
            label="Contact Name"
            control={control}
            type="text"
            disabled={!shouldEnable}
            required
            rules={{
              required: { value: true, message: "Contact Name is required" }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="contact.jobTitle"
            label="Job Title"
            control={control}
            type="text"
            disabled={!shouldEnable}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="25px">
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="contact.workPhone"
            type="tel"
            label="Work Phone"
            disabled={!shouldEnable}
            InputProps={{
              ...(disabled && {
                endAdornment: (
                  <Loader align="flex-end" isLoading={mutation.isLoading}>
                    <IconButton
                      disabled={
                        !selectedContact ||
                        contactOptions.length === 0 ||
                        !contactOptions.find(
                          (contact) => contact.value === selectedContact
                        )?.details.phone
                      }
                      onClick={async () => {
                        const contact = contactOptions.find(
                          (contact) => contact.value === selectedContact
                        );
                        if (callInstance) {
                          enqueueSnackbar("You are already on a call!", {
                            variant: "error"
                          });
                          return;
                        }
                        mutation.mutate(
                          {
                            data: {
                              accountId: account?.accountId,
                              type: "CALL",
                              relatesTo: relatesTo || "ACCOUNT",
                              ...(relatesTo === "OPPORTUNITY" &&
                                relatesToId && {
                                  opportunityId: relatesToId
                                }),
                              ...(relatesTo === "LEAD" &&
                                relatesToId && {
                                  leadId: relatesToId
                                }),
                              date: new Date(),
                              timezone: dayjs.tz.guess(),
                              direction: "outbound",
                              status: "LIVE",
                              outcome: "CONNECTED_WITH_CONTACT",
                              internalParticipants: [user?.userId as string],
                              externalParticipants: [
                                {
                                  phone: contact?.workPhone || "",
                                  contactId: contact?.value
                                }
                              ]
                            }
                          },
                          {
                            onSuccess: (data) => {
                              setCallerDetails({
                                activityId: data?.data?.activity?.activityId,
                                relatesTo: relatesTo || "ACCOUNT",
                                ...(relatesTo === "OPPORTUNITY" &&
                                  relatesToId && {
                                    opportunityId: relatesToId
                                  }),
                                ...(relatesTo === "LEAD" &&
                                  relatesToId && {
                                    leadId: relatesToId
                                  }),
                                accountId: account?.accountId,
                                accountName: account?.name,
                                callDirection: "outbound",
                                location: account?.officeAddress,
                                phone: contact?.details.phone,
                                phoneType: "WORK",
                                isConnected: account?.accountId ? true : false,
                                contact: {
                                  contactName: contact?.label,
                                  contactPhone: contact?.details.phone,
                                  isPrimary: contact?.isPrimary
                                }
                              });
                              connectToCall(
                                contact?.details.phone as string,
                                data?.data?.activity?.activityId
                              );
                              setCallAccepted(true);
                            }
                          }
                        );
                      }}
                    >
                      <ToolTip title="Click to Call">
                        <CallIcon style={{ color: "#007AFF" }} />
                      </ToolTip>
                    </IconButton>
                  </Loader>
                )
              })
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInput
            name="contact.workEmail"
            label="Work Email"
            control={control}
            type="text"
            disabled={!shouldEnable}
            InputProps={{
              ...(disabled && {
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      const contact = contactOptions.find(
                        (contact) => contact.value === selectedContact
                      );
                      setEmailTo({
                        to: contact?.workEmail || "",
                        name: contact?.label || "",
                        contactId: contact?.value,
                        accountId: account?.accountId,
                        relatesTo: relatesTo || "ACCOUNT",
                        opportunityId:
                          relatesTo === "OPPORTUNITY" ? relatesToId : undefined,
                        leadId: relatesTo === "LEAD" ? relatesToId : undefined,
                        accountName: account?.name
                      });
                    }}
                  >
                    <EmailIcon style={{ color: "#007AFF" }} />
                  </IconButton>
                )
              })
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        {disabled || !shouldEnable ? (
          <FormInput
            name="contact.homeAddress"
            label="Home Address"
            control={control}
            type="text"
            disabled
          />
        ) : (
          <Grid xs={12}>
            <Autocomplete
              key={"homeAddress"}
              className="address-autocomplete"
              onLoad={(autocomplete) => {
                autocompleteService.current = autocomplete;
              }}
              onPlaceChanged={() => {
                if (autocompleteService.current) {
                  const place = autocompleteService.current.getPlace();
                  selectAddressSuggestion(place);
                  setAddressSelected(true);
                }
              }}
            >
              <FormInput
                name="contact.homeAddress"
                control={control}
                rules={{
                  validate: () => {
                    return !addressSelected
                      ? "The address entered is invalid, please make a recommended selection"
                      : undefined;
                  }
                }}
                label="Home Address"
                type="text"
                onChange={() => setAddressSelected(false)}
              />
            </Autocomplete>
          </Grid>
        )}
      </Grid>

      <Grid item container direction="row" spacing="25px">
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="contact.phone"
            type="tel"
            label="Personal Phone"
            disabled={!shouldEnable}
            InputProps={{
              ...(disabled && {
                endAdornment: (
                  <Loader align="flex-end" isLoading={mutation.isLoading}>
                    <IconButton
                      disabled={
                        !selectedContact ||
                        contactOptions.length === 0 ||
                        !contactOptions.find(
                          (contact) => contact.value === selectedContact
                        )?.details.phone
                      }
                      onClick={async () => {
                        const contact = contactOptions.find(
                          (contact) => contact.value === selectedContact
                        );
                        if (callInstance) {
                          enqueueSnackbar("You are already on a call!", {
                            variant: "error"
                          });
                          return;
                        }
                        mutation.mutate(
                          {
                            data: {
                              accountId: account?.accountId,
                              type: "CALL",
                              relatesTo: relatesTo || "ACCOUNT",
                              ...(relatesTo === "OPPORTUNITY" &&
                                relatesToId && {
                                  opportunityId: relatesToId
                                }),
                              ...(relatesTo === "LEAD" &&
                                relatesToId && {
                                  leadId: relatesToId
                                }),
                              date: new Date(),
                              timezone: dayjs.tz.guess(),
                              direction: "outbound",
                              status: "LIVE",
                              outcome: "CONNECTED_WITH_CONTACT",
                              internalParticipants: [user?.userId as string],
                              externalParticipants: [
                                {
                                  phone: contact?.details.phone || "",
                                  contactId: contact?.value
                                }
                              ]
                            }
                          },
                          {
                            onSuccess: (data) => {
                              setCallerDetails({
                                activityId: data?.data?.activity?.activityId,
                                relatesTo: relatesTo || "ACCOUNT",
                                ...(relatesTo === "OPPORTUNITY" &&
                                  relatesToId && {
                                    opportunityId: relatesToId
                                  }),
                                ...(relatesTo === "LEAD" &&
                                  relatesToId && {
                                    leadId: relatesToId
                                  }),
                                accountId: account?.accountId,
                                accountName: account?.name,
                                phoneType: "PERSONAL",
                                callDirection: "outbound",
                                location: account?.officeAddress,
                                phone: contact?.details.phone,
                                isConnected: account?.accountId ? true : false,
                                contact: {
                                  contactName: contact?.label,
                                  contactPhone: contact?.details.phone,
                                  isPrimary: contact?.isPrimary
                                }
                              });
                              connectToCall(
                                contact?.details.phone as string,
                                data?.data?.activity?.activityId
                              );
                              setCallAccepted(true);
                            }
                          }
                        );
                      }}
                    >
                      <ToolTip title="Click to Call">
                        <CallIcon style={{ color: "#007AFF" }} />
                      </ToolTip>
                    </IconButton>
                  </Loader>
                )
              })
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInput
            name="contact.personalEmail"
            label="Personal Email"
            control={control}
            type="text"
            disabled={!shouldEnable}
            InputProps={{
              ...(disabled && {
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      const contact = contactOptions.find(
                        (contact) => contact.value === selectedContact
                      );
                      setEmailTo({
                        to: contact?.details?.email || "",
                        name: contact?.label || "",
                        accountId: account?.accountId,
                        relatesTo: relatesTo || "ACCOUNT",
                        opportunityId:
                          relatesTo === "OPPORTUNITY" ? relatesToId : undefined,
                        leadId: relatesTo === "LEAD" ? relatesToId : undefined,
                        accountName: account?.name
                      });
                    }}
                  >
                    <EmailIcon style={{ color: "#007AFF" }} />
                  </IconButton>
                )
              })
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormInput
          control={control}
          name="contact.whatsappNumber"
          type="tel"
          label="Whatsapp Number"
          disabled={!shouldEnable}
        />
      </Grid>
    </Grid>
  );
};
