import React, { useState, useEffect } from "react";
import { AccountDetailsForm } from "./components/AccountDetailsForm";
import { useForm } from "react-hook-form";
import {
  ModelAccount,
  ModelCountry,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { RelatedLink } from "../components/ReleatedLinks";

export const AccountView = ({
  accountDetails,
  organizationId
}: {
  accountDetails?: ModelAccount;
  organizationId?: string;
}) => {
  const { control, setValue, reset } = useForm({
    mode: "onBlur"
  });

  const { data: countries } = useLookupCountryGet({
    query: {
      staleTime: Infinity
    }
  });
  const [isBillingSame, setIsBillingSame] = useState(false);
  const [isShippingSame, setIsShippingSame] = useState(false);
  const [country, setCountry] = useState<ModelCountry | undefined>(undefined);

  const selectAddressSuggestion = (
    place,
    addressType: "officeAddress" | "billingAddress" | "shippingAddress"
  ) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;

    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    let address: string = "";

    if (address1 && !address1.includes("undefined")) address = `${address1}, `;
    if (city?.long_name && city?.long_name != "undefined")
      address += `${city?.long_name}, `;
    if (state?.short_name && state?.short_name != "undefined")
      address += `${state?.short_name}, `;
    if (zip?.long_name && zip?.long_name != "undefined")
      address += `${zip?.long_name}, `;
    if (country?.short_name && country?.short_name != "undefined")
      address += `${country?.short_name}`;
    setValue(addressType, address);
    if (
      countries &&
      countries.data &&
      countries.data.find((c) => c.countryId == country?.short_name)
    ) {
      setCountry(
        countries.data.find((c) => c.countryId == country?.short_name)
      );
    }
  };

  const [socialMediaLinks, setSocialMediaLinks] = useState<RelatedLink[]>([]);
  const [parentId, setParentId] = useState<string | null>(null);

  useEffect(() => {
    if (accountDetails) {
      const account = accountDetails;
      const billingSame = account.officeAddress === account.billingAddress;
      const shippingSame =
        account.shippingAddress === account.officeAddress ||
        account.shippingAddress === account.billingAddress;
      const shippingSameAs = shippingSame
        ? account.shippingAddress === account.officeAddress
          ? "OFFICE"
          : "BILLING"
        : "OFFICE";
      const links =
        account.links?.reduce((acc, link, idx) => {
          const key = `url${idx}`;
          acc[key] = link.link;
          if (link.altName) acc[`urlOtherName${idx}`] = link.altName;
          return acc;
        }, {}) || {};
      reset({
        name: account.name,
        type: account.type,
        category: account.category,
        parentId: account.parentId,
        parent: account.parent?.name,

        officeAddress: account.officeAddress,
        billingAddress: account.billingAddress,
        shippingAddress: account.shippingAddress,
        phoneType: account.phoneType,
        phone: account.phone,
        fax: account.fax,
        email: account.email,
        website: account.website,
        ...(!organizationId
          ? {
              sportsOffered: account.sports?.map((s) => s.sportId) || [],
              noOfAtheletes: account.numberOfAthletes,
              bdrOwner: account.bdrOwner?.userId,
              aeOwner: account.aeOwner?.userId,
              csmOwner: account.csmOwner?.userId
            }
          : {
              owner: account.owner?.userId
            }),
        notes: account.notes,
        isBillingSameAs: billingSame,
        isShippingSameAs: shippingSame,
        shippingSameAs: shippingSameAs,
        ...links
      });
      setIsBillingSame(billingSame);
      setIsShippingSame(shippingSame);
      setCountry(countries?.data.find((c) => c.countryId == "US"));
      setSocialMediaLinks(
        account.links?.map((l) => ({
          url: l.link!,
          otherName: l.altName || "",
          name: l.type!
        })) || []
      );
      setParentId(account.parentId || null);
    }
  }, [accountDetails, countries?.data]);

  return (
    <AccountDetailsForm
      control={control}
      isBillingSame={isBillingSame}
      isShippingSame={isShippingSame}
      setIsBillingSame={setIsBillingSame}
      setIsShippingSame={setIsShippingSame}
      selectAddressSuggestion={selectAddressSuggestion}
      country={country}
      relatedLinks={socialMediaLinks}
      setRelatedLinks={setSocialMediaLinks}
      parentId={parentId}
      setParentId={setParentId}
      mode="view"
    />
  );
};
