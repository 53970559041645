import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getAdvertisers } from "@services/Network";
import { useSponsorshipLevelGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatCurrency } from "@utils/formatCurrency";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const dateFormat = (date) => {
  const formatedDate = new Date(date);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //@ts-ignore
  return formatedDate.toLocaleDateString("en-US", options);
};

const LIST_COLUMNS = [
  {
    headerName: "Business Name",
    field: "businessName",
    minWidth: 175,
    flex: 1
  },
  {
    headerName: "Business Category",
    field: "businessCategory",
    minWidth: 200,
    flex: 1,
    valueGetter: ({ row }) => row.businessCategory.label
  },
  {
    headerName: "Sponsorship Level",
    field: "sponsorship",
    minWidth: 200,
    flex: 1,
    valueGetter: ({ row }) => row.sponsorship.name
  },
  {
    headerName: "Amount",
    field: "amount",
    minWidth: 120,
    flex: 1,
    valueGetter: ({ row }) => formatCurrency(row.sponsorship.amount)
  },
  {
    headerName: "Is Active",
    field: "status",
    minWidth: 100,
    flex: 1,
    valueFormatter: (params) => {
      if (params.value == "INACTIVE") {
        return "No";
      }
      return `Yes`;
    }
  },
  {
    headerName: "No of Ads",
    field: "noOfAds",
    minWidth: 120,
    flex: 1,
    valueGetter: ({ row }) => row.advertisements.length
  },
  {
    headerName: "Created on",
    field: "createdAt",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => dateFormat(row.createdAt)
  },
  {
    headerName: "Renews By",
    field: "renewsBy",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => dateFormat(row.createdAt)
  }
];

export const Advertisers = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false
  });
  const [hasMerchant, setHasMerchant] = useState(true);
  const onAdd = () => {
    if (!!organizationId && !org?.merchantId) {
      setHasMerchant(false);
      return;
    }
    navigate("/advertisers/create");
  };
  const onEdit = (advertiser) =>
    navigate(`/advertisers/${advertiser.advertiserId}/edit`);
  const onView = (advertiser) =>
    navigate(`/advertisers/${advertiser.advertiserId}`);
  const { data: sponsorshipLevels } = useSponsorshipLevelGet({
    organizationId: organizationId!
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("live-streaming.advertisers", "ADD");
      const edit = await checkPermission("live-streaming.advertisers", "EDIT");
      const permission = {
        create,
        edit
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);

  useEffect(() => {
    if (!!organizationId && !org?.merchantId) {
      setHasMerchant(false);
    }
  }, [org]);

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      ...(sponsorshipLevels?.data?.sponsorshipLevels.map(
        (sponsorshipLevel) => ({
          label: `${sponsorshipLevel.name} Sponsorships`,
          value: sponsorshipLevel.sponsorshipId
        })
      ) || []),
      {
        label: "Active Sponsorships",
        value: "ACTIVE"
      },
      {
        label: "Inactive Sponsorships",
        value: "INACTIVE"
      }
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Advertisers"
          useGet={getAdvertisers}
          columns={LIST_COLUMNS}
          getRowId={(row) => row.advertiserId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onEdit={permissions?.edit ? onEdit : undefined}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          onView={onView}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
        />
        <ConfirmationDialog
          open={!hasMerchant}
          title="Merchant Account Required"
          body="To promote Advertisers within your live streams, deploy automated invoicing and accept payments please setup your Merchant Account."
          onConfirm={() => navigate("/merchant-account")}
          onCancel={() => setHasMerchant(true)}
          cancelBtnText="Cancel"
          confirmBtnText="Add Merchant Account"
        />
      </Loader>
    </>
  );
};
