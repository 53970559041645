import React, { useEffect, useState } from "react";
import { Container } from "@components/crud/Container";
import { MerchantView } from "./MerchantView";
import { MerchantAdd } from "./MerchantAdd";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useNavigate } from "react-router-dom";

export const Merchant = () => {
  const [orgHasMerchant, setOrgHasMerchant] = useState(false);
  const orgId = useRecoilValue(organizationAtom);
  const orgs = useRecoilValue(organizationsAtom);
  const currOrg = orgs.find((org) => org.organizationId === orgId);
  const navigate = useNavigate();
  useEffect(() => {
    if (!orgId) navigate("/not-found");
  }, [orgId]);
  useEffect(() => {
    if (currOrg?.merchantId) setOrgHasMerchant(true);
  }, [currOrg, orgs]);

  return (
    <Container>{orgHasMerchant ? <MerchantView /> : <MerchantAdd />}</Container>
  );
};
