import React, { useEffect, useMemo, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { styled } from "@mui/material";

window.Quill = Quill;

const BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

const StyledReactQuill = styled(ReactQuill)`
  .ql-container.ql-snow {
    border: none;
  }
  .ql-editor {
    padding: 0;
  }
`;

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

Quill.register("modules/imageResize", ImageResize);

export const EmailViewEditor = ({ style, html }: { style?; html?: string }) => {
  const [value, setValue] = useState("");

  const modules = useMemo(() => {
    return {
      toolbar: false,
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
      }
    };
  }, []);
  useEffect(() => {
    if (html) {
      setValue(html);
    }
  }, [html]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let quillObj: ReactQuill | null;
  return (
    <StyledReactQuill
      ref={(el) => {
        quillObj = el;
      }}
      value={value}
      readOnly
      style={style ? style : {}}
      modules={modules}
    ></StyledReactQuill>
  );
};
