import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import React, { SyntheticEvent, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Form } from "@components/crud/Form";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  useAdminCrmOpportunityApprovalApprovalIdPost,
  useAdminCrmOpportunityOpportunityIdGet,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { Tier } from "@pages/settings/CRMSetting";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { OpportunityDetailsForm } from "../opportunities/OpportunityDetailsForm";
import { Button } from "@components/Button";
import { ApprovalHistoryDetails } from "./ApprovalHistoryDetails";
import { OpportunityApprovalRejectModal } from "./OpportunityApprovalRejectModal";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { OrderApproval } from "@pages/settings/CRMApprovalSettings";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import { getCountryFromAddress } from "@utils/getCountryFromAddress";

export const OpportunityApprovalView = () => {
  const navigate = useNavigate();
  const { approvalId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });
  const { reset } = form;

  const [opportunityOwner, setOpportunityOwner] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
    }[]
  >([]);
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [rejectionNotes, setRejectionNotes] = useState("");
  const [canApprove, setCanApprove] = useState(false);
  const { data: settings, isLoading: isLoadingConfig } = useConfigGet();
  const { data: opportunitiesData, isLoading: isLoading } =
    useAdminCrmOpportunityOpportunityIdGet(approvalId!);

  const profile = useRecoilValue(profileAtom);
  const checkCanApprove = () => {
    if (settings?.data && opportunitiesData?.data) {
      const jobId = profile?.sportsgravyUser?.jobTitle?.jobtitleId;
      const amount =
        opportunitiesData?.data?.approvals?.[0]?.amount || String(Infinity);
      const numericAmount = Number(amount);

      const orderApprovalsLimits = (settings.data.find(
        (item) => item.key === "crm.order.approvals-required"
      )?.value || []) as OrderApproval[];

      const minApprovalAmount = orderApprovalsLimits.reduce((min, current) => {
        const currentAmount = parseFloat(
          current.amount?.replace(/,/g, "") || "10000000000"
        );
        return currentAmount < min ? currentAmount : min;
      }, Infinity);

      if (Number(minApprovalAmount) < numericAmount) {
        const index = orderApprovalsLimits.findIndex(
          (limit) => limit.job === jobId
        );
        if (index === -1) {
          setCanApprove(false);
        }

        // Get the next limit (or infinity if it's the last one)
        const nextLimit = orderApprovalsLimits[index + 1]
          ? Number(orderApprovalsLimits[index + 1].amount)
          : Infinity;

        // Check if the amount is within the allowed range for the job
        setCanApprove(numericAmount > 0 && numericAmount < nextLimit);
      } else if (opportunitiesData.data.discountedPricePerAthlete) {
        const priceApprovalsData = (settings.data.find(
          (item) =>
            item.key === "crm.opportunity.pricing-tier-approval-required"
        )?.value || []) as OrderApproval[];

        const index = priceApprovalsData.findIndex(
          (limit) => limit.job === jobId
        );
        if (index === -1) {
          setCanApprove(false);
        }
        const actualCost = getCostPerAthlete(
          opportunitiesData.data.numberOfAthletes!,
          pricingTier.filter(
            (p) =>
              p.countryId ===
              (getCountryFromAddress(
                opportunitiesData.data.account!.officeAddress!
              ) || "US")
          )
        ) as number;
        const discountOffered =
          ((actualCost - opportunitiesData.data.discountedPricePerAthlete) /
            actualCost) *
          100;
        const nextLimit = priceApprovalsData[index + 1]
          ? Number(priceApprovalsData[index + 1].percentage)
          : 100;
        setCanApprove(discountOffered > 0 && discountOffered < nextLimit);
      }
    }
  };

  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
      checkCanApprove();
    }
  }, [settings]);

  useEffect(() => {
    if (opportunitiesData?.data) {
      const opportunity = opportunitiesData.data;
      reset({
        createdBy: formatFullName(opportunity?.createdBy?.person),
        createdAt: new Date(opportunity.createdAt!),
        type: opportunity.type,
        name: opportunity.name,
        noOfAthletes: opportunity.numberOfAthletes,
        stage: opportunity.stage,
        costOfAthletesPeryear: `$ ${
          opportunity.discountedPricePerAthlete
            ? opportunity.discountedPricePerAthlete
            : getCostPerAthlete(
                opportunity.numberOfAthletes!,
                pricingTier.filter(
                  (p) =>
                    p.countryId ===
                    (getCountryFromAddress(
                      opportunity.account!.officeAddress!
                    ) || "US")
                )
              )
        } Per Athlete, Per Registration`,
        amount: opportunity.amount,
        closeDate: new Date(opportunity.closeDate!),
        opportunityOwner: opportunity.owner?.personId,
        account: {
          name: opportunity.account?.name || "",
          parent: opportunity.account?.parent?.name || "",
          category: opportunity.account?.category,
          type: opportunity.account?.type,
          officeAddress: opportunity.account?.officeAddress,
          email: opportunity.account?.email,
          website: opportunity.account?.website,
          sportsOffered:
            opportunity.account?.sports?.map((s) => s.sportId) || [],
          noOfAthletes: opportunity.account?.numberOfAthletes,
          aeOwner: opportunity.account?.aeOwner?.person
            ? formatFullName(opportunity.account?.aeOwner?.person)
            : ""
        },
        contact: {
          firstName: opportunity.contact?.firstName,
          lastName: opportunity.contact?.lastName,
          personalEmail: opportunity.contact?.email,
          homeAddress: opportunity.contact?.location,
          phoneType: "MOB",
          phone: opportunity.contact?.phone,
          whatsappNumber: opportunity.contact?.whatsappNumber
        }
      });
      setSelectedAccount(opportunity.accountId || "");
      setOpportunityOwner(opportunity.ownerId || "");
      checkCanApprove();
    }
  }, [opportunitiesData, pricingTier]);

  const { mutate: save, isLoading: isSaving } =
    useAdminCrmOpportunityApprovalApprovalIdPost();
  const onSave = () => {
    if (opportunitiesData?.data?.approvals?.[0]?.approvalId) {
      save(
        {
          approvalId: opportunitiesData.data.approvals[0].approvalId,
          data: {
            status: showRejectModal ? "REJECTED" : "APPROVED",
            notes: rejectionNotes
          }
        },
        {
          onSuccess: (resp) => {
            enqueueSnackbar("Opportunity Created successfully!", {
              variant: "success"
            });
            navigate(
              `/crm/opportunities/${resp.data.opportunityId}?tab=Approval History`
            );
          },
          onError: () => {
            enqueueSnackbar("Failed to Create Opportunity!", {
              variant: "error"
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && opportunitiesData?.data) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              details: c.contact as ContactResponse,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || ""
            }))
          );
          if (opportunitiesData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(opportunitiesData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, opportunitiesData]);

  const [tab, setTab] = useState(
    searchParams.get("tab") || "Opportunity Details"
  );
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
    setSearchParams({ tab: value as string });
  };
  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, [searchParams]);
  const getTabs = () => {
    return ["Opportunity Details", "Approval History"];
  };
  return (
    <Container>
      <Loader isLoading={isLoading || isLoadingConfig}>
        <Toolbar
          title="View Opportunity Approval"
          backBtnClick={() => {
            navigate("/crm/opportunity-approvals");
          }}
          additionalBtns={
            canApprove
              ? [
                  {
                    button: (
                      <Button
                        variant="admin-success"
                        onClick={() => {
                          setOpenConfirmationModal(true);
                        }}
                        isLoading={isSaving}
                      >
                        Approve
                      </Button>
                    )
                  },
                  {
                    button: (
                      <Button
                        variant="admin-error"
                        onClick={() => {
                          setShowRejectModal(true);
                        }}
                        isLoading={isSaving}
                      >
                        Reject
                      </Button>
                    )
                  }
                ]
              : []
          }
          tabs={{
            tabs: getTabs(),
            onTabChange: onTabChange,
            activeTab: tab
          }}
        />
        {opportunitiesData?.data && (
          <>
            {tab === "Opportunity Details" && (
              <Form>
                <OpportunityDetailsForm
                  form={form}
                  opportunityOwner={opportunityOwner}
                  setOpportunityOwner={setOpportunityOwner}
                  disabled
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                  contacts={contacts}
                  setContacts={setContacts}
                />
              </Form>
            )}
            {tab === "Approval History" && (
              <Form
                style={{
                  paddingTop: "5xpx"
                }}
              >
                <ApprovalHistoryDetails
                  details={opportunitiesData?.data?.approvals}
                />
              </Form>
            )}
          </>
        )}
      </Loader>
      <OpportunityApprovalRejectModal
        open={showRejectModal}
        handleClose={() => {
          setShowRejectModal(false);
        }}
        handleReject={() => {
          setOpenConfirmationModal(true);
        }}
        rejectionNotes={rejectionNotes}
        setRejectionNotes={setRejectionNotes}
        opportunityOwner={formatFullName(
          opportunitiesData?.data?.owner?.person
        )}
        accountName={opportunitiesData?.data?.account?.name}
      />
      <ConfirmationDialog
        title={showRejectModal ? "Reject Opportunity" : "Approve Opportunity"}
        body={`Are you sure you want to ${
          showRejectModal ? "reject" : "approve"
        } this Opportunity for this Organization? This change cannot be undone.`}
        open={openConfirmationModal}
        close={() => setOpenConfirmationModal(false)}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => {
          onSave();
          setOpenConfirmationModal(false);
        }}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant={showRejectModal ? "admin-error" : "admin-success"}
        icon={showRejectModal ? "error" : "success"}
      />
    </Container>
  );
};
