import { Checkbox, Grid } from "@mui/material";
import { ModelSeason } from "@sportsgravyengineering/sg-api-react-sdk";

export const FilterSeasonOption = ({
  season,
  onCheck,
  selectedSeasons
}: {
  season: ModelSeason;
  onCheck: (checked: boolean) => void;
  selectedSeasons: string[];
}) => {
  return (
    <Grid container direction="row" spacing="15px" alignItems="center">
      <Grid item>
        <Checkbox
          checked={!!selectedSeasons.find((x) => x === season.seasonId)}
          onChange={(e) => {
            onCheck(e.target.checked);
          }}
        />
      </Grid>
      <Grid item>{season.name}</Grid>
      <Grid
        item
        style={{
          color: "#64748B"
        }}
      >
        {season.sport?.name}
      </Grid>
      {season.startDate && season.endDate && (
        <Grid
          item
          style={{
            color: "#64748B"
          }}
        >{`(${new Date(season.startDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
        })} - ${new Date(season.endDate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
        })})`}</Grid>
      )}
    </Grid>
  );
};
