import { TableView } from "@components/TableView";
import { getKeyData } from "@services/Network";
import {
  ModelAccount,
  useLookupCountryCountryIdGet,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useMemo, useState } from "react";
import { Loader } from "@components/crud/Loader";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

export const KeyData = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [countryFilter, setCountryFilter] = useState<string | undefined>(
    undefined
  );
  const [, setStateFilter] = useState<string | undefined>(undefined);

  const { data: countries, isLoading: countriesLoading } =
    useLookupCountryGet();

  const { data: states } = useLookupCountryCountryIdGet(
    countryFilter as string
  );
  const countriesOptions = useMemo(() => {
    if (countries?.data)
      return countries?.data.map((country) => ({
        label: country.name as string,
        value: country.countryId as string
      }));
    return [];
  }, [countries?.data]);

  const statesOptions = useMemo(() => {
    if (states?.data && states?.data?.subdivisions)
      return states?.data?.subdivisions.map((state) => ({
        label: state.name as string,
        value:
          state.subdivisionId && state.subdivisionId?.split("-").length > 1
            ? state.subdivisionId?.split("-")[1]
            : (state.subdivisionId?.split("-")[0] as string)
      }));
    return [];
  }, [states?.data]);

  const onEdit = (keyData) =>
    navigate(`/crm/key-data/${keyData.accountId}/edit`);

  const onView = (keyData) => navigate(`/crm/accounts/${keyData.accountId}`);

  const ACCOUNTS_COLUMNS: GridColDef<ModelAccount>[] = [
    {
      headerName: "Account",
      field: "account",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.name
    },
    {
      headerName: "Address",
      field: "address",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography style={{ padding: "15px 0", fontSize: "12px" }}>
            {params.row.officeAddress}
          </Typography>
        );
      }
    },
    {
      headerName: "Org Sales Rating",
      field: "salesRating",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.orgSalesRating
    },
    {
      headerName: "No. of Athletes",
      field: "noOfAthletes",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.numberOfAthletes
    }
  ];

  const filterConfig = {
    field: "country",
    label: "Country",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: countriesOptions
  };

  const secondaryFilterConfig = {
    field: "state",
    label: "State",
    placeholderOption: {
      label: "All",
      value: "All"
    },
    options: statesOptions
  };

  return (
    <Loader isLoading={countriesLoading}>
      <TableView
        title="Key Data for Accounts"
        useGet={getKeyData}
        columns={ACCOUNTS_COLUMNS}
        getRowId={(row) => row.accountId}
        filterConfig={filterConfig}
        secondaryFilterConfig={
          countryFilter ? secondaryFilterConfig : undefined
        }
        onFilterChange={(filter) =>
          filter != "all"
            ? setCountryFilter(filter)
            : setCountryFilter(undefined)
        }
        hideSecondaryFilter={!countryFilter}
        hideSecondaryLabelContainer={!countryFilter}
        onSecondaryFilterChange={(filter) =>
          filter != "all" ? setStateFilter(filter) : setStateFilter(undefined)
        }
        defaultSort={[{ field: "name", sort: "asc" }]}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
        onEdit={onEdit}
        onView={onView}
        getRowHeight={() => "auto"}
      />
    </Loader>
  );
};
