import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  RoleType,
  useAdminRoleGet,
  useAdminUserUserIdGet,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { UserDetailsForm } from "./UserDetailsForm";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import convertUTCDateToLocalDate from "@utils/convertUtcToLocal";
import { RelatedUsersView } from "./RelatedUsersView";
import { calculateAge } from "@utils/calculateAge";
import { capitalize } from "@utils/capitalize";
import { UserTeamsView } from "./UserTeamsView";
import { UserTrainingProgramsView } from "./UserTrainingProgramsView";
import { UserAvailability } from "./UserAvailability";
import dayjs, { Dayjs } from "dayjs";
import { convertTimeToDate } from "@utils/convertStringAndTime";

export const UserView = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [organizationId] = useRecoilState(organizationAtom);
  const [fullDays, setFullDays] = useState<Dayjs[]>([]);
  const [halfDays, setHalfDays] = useState<Dayjs[]>([]);

  const [tab, setTab] = useState("User Details");

  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };

  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      email: "",
      country: "",
      dialCode: "",
      province: "",
      locality: "",
      address1: "",
      address2: "",
      postalCode: "",
      phone: "",
      birthedAt: "",
      guardians: [],
      children: [],
      roles: [],
      isBanned: false,
      isUnderAge: false,
      bannedRoles: [],
      bannedRolesSG: []
    }
  });

  const { watch, setValue, trigger, reset, getValues } = form;
  const { country } = watch();

  const { data: countriesResponse, isLoading: isFetchingCountries } =
    useLookupCountryGet({
      query: {
        staleTime: Infinity
      }
    });
  const countries = useMemo(() => countriesResponse?.data, [countriesResponse]);

  const organizationOption = organizationId
    ? {
        organizationId: organizationId
      }
    : {};

  const {
    data: userResponse,
    isLoading: isFetchingUser,
    error: error
  } = useAdminUserUserIdGet(userId as string, organizationOption);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const user = useMemo(() => userResponse?.data, [userResponse]);

  useEffect(() => {
    if (isFetchingCountries || isFetchingUser) return;

    const dialCode = countries?.find(
      (country) => country?.countryId === user?.country
    )?.dialCode;

    const ageOfConsent = countries?.find(
      (country) => country?.countryId === user?.addressPrimary?.country
    )?.ageMajority;

    const rolesSelected =
      user?.user?.roles?.reduce((acc, role) => {
        const key = role.roleId?.replace(".", "_") + "_SELECTED";
        acc[key] = true;
        return acc;
      }, {}) || {};

    const rolesInvited =
      user?.invites
        ?.filter((inv) => inv.invitedFor?.length || 0 > 0)
        ?.reduce((acc, inv) => {
          inv!.invitedFor!.map((role) => {
            const key = role.roleId?.replace(".", "_") + "_SELECTED";
            acc[key] = true;
          });

          return acc;
        }, {}) || {};

    const getBannedRolesSelected = (orgRoles) => {
      const bannedRoles = orgRoles.filter(
        (role) => role.isBanned === true && !role.role.parentId
      );
      const bannedRolesValues = bannedRoles.map((role) => role.role.alias);
      return bannedRolesValues;
    };

    const bannedAliases = user?.user?.roleAliasBans?.map(
      (alias) => alias.alias
    );
    const bannedRolesAliases = !organizationId
      ? bannedAliases
      : user?.user?.roles
      ? getBannedRolesSelected(
          user.user.roles.filter(
            (role) =>
              role.organizationId &&
              role.organizationId === organizationId &&
              role.role?.type === "ORGANIZATION"
          )
        )
      : [];

    const defaultValues = {
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      suffix: user?.suffix,
      email: user?.email,
      country: user?.addressPrimary?.country,
      dialCode,
      province: user?.addressPrimary?.province,
      locality: user?.addressPrimary?.locality,
      address1: user?.addressPrimary?.lines?.[0],
      address2: user?.addressPrimary?.lines?.[1],
      postalCode: user?.addressPrimary?.postalCode,
      phone: user?.phone,
      guardians: user?.guardians,
      birthedAt: user?.birthedAt
        ? convertUTCDateToLocalDate(new Date(user?.birthedAt))
        : "",
      isUnderAge: user?.birthedAt
        ? calculateAge(user.birthedAt) < (ageOfConsent ? ageOfConsent : 18)
        : false,
      roles: user?.user?.roles,
      isBanned: user?.user
        ? user.user.status === "ACCOUNT_LOCKED" || bannedRolesAliases.length > 0
        : false,
      bannedRoles: bannedRolesAliases,
      bannedRolesSG: bannedAliases?.length === 3 ? ["ALL"] : bannedAliases,
      photoUrl: user?.avatar?.baseUrl
        ? user?.avatar?.baseUrl + user?.avatar?.path
        : undefined,
      sportsgravyUser: user?.sportsgravyUser
        ? {
            ...user.sportsgravyUser,
            startDate: user?.sportsgravyUser.startDate
              ? convertUTCDateToLocalDate(
                  new Date(user?.sportsgravyUser.startDate)
                )
              : "",
            startTime: user?.sportsgravyUser.startTime
              ? convertTimeToDate(user?.sportsgravyUser.startTime)
              : "",
            endTime: user?.sportsgravyUser.endTime
              ? convertTimeToDate(user?.sportsgravyUser.endTime)
              : ""
          }
        : undefined,
      subscriptionPlan: !organizationId
        ? {
            planId: user?.user?.activeSubscription?.plan?.planId,
            name: user?.user?.activeSubscription?.plan?.name,
            ownerName: user?.user?.activeSubscription?.sharedFrom?.user?.person
              ? user?.user?.activeSubscription?.sharedFrom?.user?.person
                  ?.firstName +
                " " +
                user?.user?.activeSubscription?.sharedFrom?.user?.person
                  ?.lastName
              : "You",
            ownerId: user?.user?.activeSubscription?.sharedFrom?.user?.personId
          }
        : undefined,
      sportsGravyUserAvgHoursPerWeek: user?.sportsgravyUser
        ? user.sportsgravyUser.avgHoursPerMonth
          ? user.sportsgravyUser.avgHoursPerMonth / 4
          : undefined
        : undefined,
      ...rolesSelected,
      ...rolesInvited
    };
    console.log("IMAZ___", defaultValues);
    reset(defaultValues, {
      keepDirtyValues: false
    });
    if (user.sportsgravyUser?.timeoff) {
      const fullDays = user.sportsgravyUser.timeoff
        .filter((day) => day.isFullDay)
        .map((day) => dayjs(day.date));
      const halfDays = user.sportsgravyUser.timeoff
        .filter((day) => !day.isFullDay)
        .map((day) => dayjs(day.date));
      setFullDays(fullDays);
      setHalfDays(halfDays);
    }
  }, [countries, user]);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find((c) => c.types.includes("locality"));
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    setValue("address1", address1);
    if (subpremise) setValue("address2", subpremise?.long_name);
    setValue("country", country?.short_name);
    setValue("province", state?.short_name);
    setValue("locality", city?.long_name);
    setValue("postalCode", zip?.long_name);
    trigger("address1");
    trigger("country");
    trigger("province");
    trigger("locality");
    trigger("postalCode");
  };

  const onBackClick = () => {
    navigate("/users");
  };

  const getTeams = () => {
    return user?.user?.roles?.filter((team) => team?.teamId);
  };
  const getTrainingPrograms = () => {
    return user?.user?.roles?.filter((training) => training?.trainingProgramId);
  };
  const getTabs = (isSGUser: boolean) => {
    let tabs = ["User Details"];
    if (user?.guardians?.length || user?.children?.length)
      tabs = [...tabs, "Related Users"];
    if (isSGUser) tabs = [...tabs, "Availability"];
    if (organizationId) {
      const teams = getTeams();
      const trainingPrograms = getTrainingPrograms();
      if (teams?.length) tabs = [...tabs, "Teams"];
      if (trainingPrograms?.length) tabs = [...tabs, "Training Programs"];
      return tabs;
    } else return tabs;
  };
  const getBadgeCounts = () => {
    let badges = [0];
    if (user?.guardians?.length) badges = [...badges, user.guardians?.length];
    else if (user?.children?.length) badges = [...badges, user.children.length];
    if (organizationId) {
      const teamsSize = getTeams()?.length;
      const traingPrograms = getTrainingPrograms()?.length;
      if (teamsSize) badges = [...badges, teamsSize];
      if (traingPrograms) badges = [...badges, traingPrograms];
      return badges;
    } else return badges;
  };

  const { data: roleOptionsRequest, isLoading: isRoleOptionsLoading } =
    useAdminRoleGet({
      organizationId,
      type: organizationId ? RoleType.ORGANIZATION : undefined,
      pageSize: "100",
      includeChildren: true
    });

  const roleOptions = () => {
    if (!roleOptionsRequest?.data?.roles) {
      return [];
    }

    if (!organizationId) {
      const SGRoles = roleOptionsRequest.data.roles.filter(
        (role) => role.type === "SYSTEM"
      );
      return SGRoles.map((role) => {
        return {
          label: role.name as string,
          value: role.roleId as string,
          children: role.children,
          role: role
        };
      });
    } else {
      const FSORoles = roleOptionsRequest.data.roles.filter(
        (role) => role.type === "ORGANIZATION"
      );
      return FSORoles.map((role) => {
        return {
          label:
            (role.name as string) + (role.type === "SYSTEM" ? " (System)" : ""),
          value: role.roleId as string,
          children: role.children
            ? role.children.filter((child) => child.type === "ORGANIZATION")
            : [],
          role: role
        };
      });
    }
  };

  const getSGBannedRolesOptions = () => {
    return [
      { label: "Admin", value: "ADMIN" },
      { label: "Coach", value: "COACH" },
      { label: "Manager", value: "MANAGER" },
      { label: "All Roles", value: "ALL" }
    ];
  };

  const getBannedolesFSOOptions = () => {
    const allOptions = roleOptions();
    if (
      allOptions.length &&
      user &&
      allOptions.length === getValues().bannedRoles.length
    )
      setValue("bannedRoles", ["ALL"]);
    const options = allOptions.map((role) => {
      return {
        label: capitalize(role?.role?.alias ? role.role.alias : role.label),
        value: role.role.alias
      };
    });
    return [...options, { label: "All Roles", value: "ALL" }];
  };

  const hideSGUserFields = () => {
    return (
      !(
        user?.status === "PRIMARY" || user?.user?.status === "ACCOUNT_LOCKED"
      ) ||
      !!organizationId ||
      !user.sportsgravyUser ||
      !user?.user
    );
  };

  return (
    <Container>
      <Toolbar
        title={"View User"}
        editBtnClick={() => navigate(`/users/${userId}/edit`)}
        backBtnClick={onBackClick}
        tabs={{
          tabs: getTabs(!hideSGUserFields()),
          onTabChange: onTabChange,
          activeTab: tab
        }}
        badgeCounts={getBadgeCounts()}
      />
      <Form>
        <Loader
          isLoading={
            isFetchingCountries || isFetchingUser || isRoleOptionsLoading
          }
        >
          <Grid container spacing={3}>
            {tab === "User Details" && (
              <Grid xs={12}>
                <UserDetailsForm
                  disabled={true}
                  isEditing={false}
                  form={form}
                  country={country}
                  selectAddressSuggestion={selectAddressSuggestion}
                  countries={countries}
                  roles={user?.user?.roles ? user.user.roles : []}
                  isFetchingCountries={isFetchingCountries}
                  organizationId={organizationId}
                  hideSGUserFields={hideSGUserFields()}
                  hideBanPortion={
                    !(
                      user?.status === "PRIMARY" ||
                      user?.user?.status === "ACCOUNT_LOCKED"
                    )
                  }
                  roleOptions={roleOptions()}
                  isUserBanned={getValues().isBanned}
                  isUnderAge={
                    user?.birthedAt ? calculateAge(user.birthedAt) < 18 : false
                  }
                  bannedRolesOptions={
                    !organizationId
                      ? getSGBannedRolesOptions()
                      : getBannedolesFSOOptions()
                  }
                />
              </Grid>
            )}
            {tab === "Related Users" && (
              <Grid xs={12}>
                <RelatedUsersView
                  countries={countries}
                  guardians={user?.guardians ? user?.guardians : []}
                  relatedChildren={user?.children ? user.children : []}
                  viewDetailsTab={() => {
                    setTab("User Details");
                  }}
                />
              </Grid>
            )}
            {tab === "Teams" && (
              <Grid xs={12}>
                <UserTeamsView teams={getTeams()} />
              </Grid>
            )}
            {tab === "Training Programs" && (
              <Grid xs={12}>
                <UserTrainingProgramsView
                  trainingPrograms={getTrainingPrograms()}
                />
              </Grid>
            )}
            {tab === "Availability" && (
              <UserAvailability
                disabled={true}
                halfDays={halfDays}
                fullDays={fullDays}
              />
            )}
          </Grid>
        </Loader>
      </Form>
    </Container>
  );
};
