/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import {
  Accordion,
  AccordionSummary,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { AccordionDetails } from "@components/crud/Accordian";
import { Edit } from "@mui/icons-material";
import { ReplyIcon } from "@components/Icons";
import { Button } from "@components/Button";
import { EmailReply } from "./EmailReply";
import { useNavigate } from "react-router-dom";
import { ToolTip } from "@components/ToolTip";
import { EmailViewEditor } from "@components/EmailViewEditor";
import { extractTextFromDelta } from "@utils/convertDeltaToText";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import { EmailAttachment } from "./EmailAtachment";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { AttachmentMediaViewer } from "./AttachmentMediaViewer";
const IconStyle = {
  height: "20px",
  width: "20px",
  color: "#666666",
  fill: "#666666"
};

const FlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledIconButton = styled(IconButton)`
  padding: 5px;
  svg path {
    fill: #666666;
  }
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #fff !important;
  padding: 12px 0 !important;

  .MuiAccordionSummary-content {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-bottom: 24px !important;
`;

const OneLineText = styled("div")`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HiddenEmailCountBox = styled("div")`
  width: 32px;
  height: 32px;
  border: 1px solid #e5e5e5;
  padding: 6px;
  text-align: center;
  position: relative;
  top: -10px;
  left: 32px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;

  &:hover .icon {
    display: block;
  }
  &:hover .content {
    display: none;
  }

  .icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledReplyIcon = styled(ReplyIcon)`
  height: 20px;
  width: 20px;
  color: #666666;
  path {
    fill: #666666;
  }
`;

const StyledButtonGrid = styled(Grid)`
  .MuiButton-startIcon {
    height: 15px;
  }
  .MuiTypography-root {
    font-weight: 600;
    fontsize: 14px;
    color: #666;
  }
`;

const dateFormat = (date) => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

const renderContentWithLineBreaks = (content) => {
  return content.split("\n").map((line, index, array) => (
    <span key={index}>
      {line}
      {index < array.length - 1 && <br />}
    </span>
  ));
};

export const EmailActivityView = ({
  email,
  onStar,
  setRefreshKey,
  organizationId
}: {
  email: any;
  onStar: (mailId: string, isStar: boolean) => void;
  setRefreshKey: () => void;
  organizationId?: string;
}) => {
  const navigate = useNavigate();
  const userEmail = useRecoilValue(profileAtom)?.email || "";
  const [expandedEmails, setExpandedEmails] = useState<string[]>([]);
  const [showMedia, setShowMedia] = useState<ModelMedia | undefined>();
  const [isExpanded, setExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [reply, setReply] = useState<
    | {
        email: any;
        replyType: "SINGLE" | "ALL";
      }
    | undefined
  >(undefined);
  const [singleEmailReply, setSingleEmailReply] = useState<any | undefined>(
    undefined
  );

  const toggleEmailExpand = (mailId: string) => {
    setExpandedEmails((prevExpandedEmails) => {
      if (prevExpandedEmails.includes(mailId)) {
        return prevExpandedEmails.filter((id) => id !== mailId);
      } else {
        return [...prevExpandedEmails, mailId];
      }
    });
  };

  const toggleAllEmail = (all: boolean) => {
    if (all) {
      setExpandedEmails(email?.emails.map((mail) => mail.mailId));
    } else {
      setExpandedEmails([email?.emails[email?.emails.length - 1].mailId]);
    }
    setExpanded(all);
  };

  useEffect(() => {
    if (email?.emails && email.emails.length > 0) {
      setExpandedEmails([email.emails[email.emails.length - 1].mailId]);
    }
  }, [email]);

  const onCloseReply = () => {
    setReply(undefined);
    setSingleEmailReply(undefined);
  };

  const renderEmails = () => {
    if (!email?.emails || email.emails.length === 0) {
      return (
        <Typography variant="body1" color="textSecondary">
          No emails available.
        </Typography>
      );
    }

    if (email.emails.length > 3 && !showAll) {
      const firstEmail = email.emails[0];
      const lastTwoEmails = email.emails.slice(-2);
      const hiddenEmailCount = email.emails.length - 3;

      return (
        <>
          {renderEmail(firstEmail, 0)}
          <HiddenEmailCountBox onClick={() => setShowAll(true)}>
            <div className="content">{hiddenEmailCount}</div>
            <UnfoldMoreIcon className="icon" />
          </HiddenEmailCountBox>
          {lastTwoEmails.map((mail, index) =>
            renderEmail(mail, email.emails.length - 2 + index, true)
          )}
        </>
      );
    }

    return email.emails.map((mail: any, index: number) =>
      renderEmail(mail, index)
    );
  };

  const renderEmail = (mail: any, index: number, lastTwo?: boolean) => {
    const isCurrentExpanded = expandedEmails.includes(mail?.mailId as string);
    const toList = mail?.to.map((c) => ({
      ...c,
      name: c.email === userEmail ? "Me" : c.name
    }));
    const ccList =
      mail?.cc.map((c) => ({
        ...c,
        name: c.email === userEmail ? "Me" : c.name
      })) || [];
    const bccList =
      mail?.bcc.map((c) => ({
        ...c,
        name: c.email === userEmail ? "Me" : c.name
      })) || [];
    return (
      <StyledAccordion
        key={index}
        disableGutters
        onChange={() => toggleEmailExpand(mail?.mailId)}
        expanded={isCurrentExpanded}
        style={{
          ...(lastTwo && { position: "relative", top: "-20px" })
        }}
      >
        <StyledAccordionSummary>
          <FlexBox>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                {mail?.from?.email !== userEmail ? mail?.from?.name : "Me"}
              </Typography>
              {isCurrentExpanded && (
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#666666"
                  }}
                >
                  {mail?.from?.email}
                </Typography>
              )}
            </div>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <span
                style={{ height: "17px", color: "#666666", fontSize: "13px" }}
              >
                {dateFormat(mail?.createdAt)}
              </span>
              <span>
                <StyledIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onStar(mail.mailId, !mail.isFavourite);
                    mail.isFavourite = !mail.isFavourite;
                  }}
                >
                  {mail?.isFavourite ? (
                    <StarRateRoundedIcon style={IconStyle} />
                  ) : (
                    <StarOutlineRoundedIcon style={IconStyle} />
                  )}
                </StyledIconButton>
              </span>
              {index === 0 && (
                <span>
                  <StyledIconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("edit");
                    }}
                  >
                    <Edit style={IconStyle} />
                  </StyledIconButton>
                </span>
              )}
              {index !== 0 && isCurrentExpanded && (
                <span style={{ height: "25px" }}>
                  <StyledIconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSingleEmailReply(mail);
                    }}
                  >
                    <ReplyIcon style={IconStyle} />
                  </StyledIconButton>
                </span>
              )}
            </div>
          </FlexBox>
          {!isCurrentExpanded && (
            <>
              <OneLineText style={{ color: "#666666" }}>{`${
                typeof mail?.body === "string"
                  ? mail?.body
                  : extractTextFromDelta(mail?.body)
              }`}</OneLineText>
              {mail?.attachments && mail.attachments.length > 0 && (
                <Grid
                  container
                  direction="row"
                  spacing="10px"
                  marginTop="10px"
                  alignItems="center"
                >
                  {(mail.attachments.length > 4
                    ? mail.attachments.slice(0, 4)
                    : mail.attachments
                  ).map((attachment) => {
                    return (
                      <Grid key={attachment.mediaId}>
                        <EmailAttachment
                          attachment={attachment}
                          onClick={() => {
                            setShowMedia({ ...attachment });
                          }}
                        />
                      </Grid>
                    );
                  })}
                  {mail.attachments.length > 4 && (
                    <Grid
                      style={{
                        borderRadius: "100px",
                        padding: "6px 12px",
                        border: "1px solid #CBD5E1",
                        display: "flex",
                        width: "36px",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        height: "36px",
                        cursor: "pointer"
                      }}
                    >
                      +{mail.attachments.length - 4}
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
          {isCurrentExpanded && (
            <ToolTip
              placement="right"
              componentsProps={{
                tooltip: {
                  style: {
                    width: "350px"
                  }
                }
              }}
              title={
                <div>
                  {toList.map((t, idx) => (
                    <div key={t.email}>
                      {`${idx === 0 ? "To: " : ""}`}
                      {`${t.name} `}
                      <span style={{ color: "#666666" }}>{`${t.email}${
                        idx !== toList.length - 1 ? "," : ""
                      }`}</span>
                    </div>
                  ))}
                  {ccList.map((t, idx) => (
                    <div key={t.email}>
                      {`${idx === 0 ? "CC: " : ""}`}
                      {`${t.name} `}

                      <span style={{ color: "#666666" }}>{`${t.email}${
                        idx !== ccList.length - 1 ? "," : ""
                      }`}</span>
                    </div>
                  ))}
                  {bccList.map((t, idx) => (
                    <div key={t.email}>
                      {`${idx === 0 ? "BCC: " : ""}`}
                      {`${t.name} `}

                      <span style={{ color: "#666666" }}>{`${t.email}${
                        idx !== bccList.length - 1 ? "," : ""
                      }`}</span>
                    </div>
                  ))}
                </div>
              }
            >
              <OneLineText
                style={{
                  color: "#666666",
                  display: "inline-block"
                }}
              >
                To:{" "}
                {[...toList, ...ccList, ...bccList]
                  .map((to) => to.name)
                  .join(", ")}
              </OneLineText>
            </ToolTip>
          )}
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {typeof mail?.body === "string" ? (
            renderContentWithLineBreaks(mail?.body)
          ) : (
            <>
              <EmailViewEditor html={mail.body} />
              {mail?.attachments && mail.attachments.length > 0 && (
                <Grid container direction="row" spacing="10px" marginTop="10px">
                  {mail?.attachments?.map((attachment) => {
                    return (
                      <Grid key={attachment.mediaId}>
                        <EmailAttachment
                          attachment={attachment}
                          onClick={() => {
                            setShowMedia({ ...attachment });
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </>
          )}
          {singleEmailReply && singleEmailReply.mailId === mail.mailId && (
            <div style={{ paddingTop: "24px" }}>
              <EmailReply
                replyOfId={email?.emails[0].mailId}
                email={singleEmailReply}
                replyType="SINGLE"
                onClose={onCloseReply}
                onSuccessSent={() => {
                  setRefreshKey();
                }}
                userEmail={userEmail}
              />
            </div>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    );
  };

  const uniqueFromEmails = [
    ...new Set(email?.emails?.map((email) => email.from.email))
  ];
  const uniqueEmails = [
    ...new Set([
      ...uniqueFromEmails,
      ...(email?.emails
        ? email.emails.flatMap((email) => email.to.map((email) => email.email))
        : []),
      ...(email?.emails
        ? email.emails.flatMap((email) => email.cc.map((email) => email.email))
        : []),
      ...(email?.emails
        ? email.emails.flatMap((email) => email.bcc.map((email) => email.email))
        : [])
    ])
  ];
  return (
    <>
      <FlexBox>
        <div>
          <Typography style={{ fontSize: "24px", fontWeight: 500 }}>
            {email?.subject || "No Subject"}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => toggleAllEmail(!isExpanded)}>
            {isExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          </IconButton>
        </div>
      </FlexBox>

      <Grid xs={12}>{renderEmails()}</Grid>
      {!organizationId && !reply && !singleEmailReply && (
        <StyledButtonGrid container gap="24px">
          <Grid xs={1} marginTop="24px">
            <Button
              variant="admin-secondary"
              startIcon={<StyledReplyIcon />}
              onClick={() =>
                setReply({ email: email?.emails[0], replyType: "SINGLE" })
              }
            >
              <Typography>Reply</Typography>
            </Button>
          </Grid>

          {uniqueEmails.length > 1 && (
            <Grid xs={2} marginTop="24px">
              <Button
                variant="admin-secondary"
                startIcon={<StyledReplyIcon />}
                onClick={() =>
                  setReply({ email: email?.emails, replyType: "ALL" })
                }
              >
                <Typography>Reply All</Typography>
              </Button>
            </Grid>
          )}
        </StyledButtonGrid>
      )}
      {reply && (
        <EmailReply
          replyOfId={email?.emails[0].mailId}
          email={reply.email}
          replyType={reply.replyType}
          onClose={onCloseReply}
          onSuccessSent={() => {
            setRefreshKey();
          }}
          userEmail={userEmail}
        />
      )}
      {!!showMedia && (
        <AttachmentMediaViewer
          media={showMedia}
          onClose={() => {
            setShowMedia(undefined);
          }}
        />
      )}
    </>
  );
};
