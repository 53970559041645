import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import { FormSwitch } from "@components/FormSwitch";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { ToolTip } from "@components/ToolTip";
import { MoreVert, Pause, PlayArrow } from "@mui/icons-material";
import { IconButton, Popover, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { organizationAtom, profileAtom } from "@recoil/auth";
import {
  ModelMedia,
  useAdminLiveStreamStreamIdClipGet,
  useAdminLiveStreamStreamIdClipsDelete,
  useAdminLiveStreamStreamIdClipsDownload,
  useMediaLibraryPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { AddToAlbum } from "./AddToAlbum";
import { LivestreamClipEdit } from "./LivestreamClipEdit";
import { PostToFeed } from "@components/PostToFeed";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { capitalize } from "@utils/capitalize";
import { Loader } from "@components/crud/Loader";
import { ShareModal } from "@components/ShareModal";
import { enqueueSnackbar } from "notistack";

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

const StyledClipOption = styled(Grid)`
  border-bottom: 2px solid #e5e5e5;
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  .MuiBox-root {
    width: fit-content;
  }
`;

const MoreOption = styled("div")`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f2f4f6;
  }
`;

const convertSecondsToMMSS = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const LivestreamClipList = ({
  streamDetails,
  handleClipViewClick,
  setCurrentClipPlaying,
  handlePlayPause,
  currentClipPlaying,
  isPlaying,
  mainLsPlaying,
  currentPlayingEnded
}: {
  handleClipViewClick: () => void;
  handlePlayPause: () => void;
  setCurrentClipPlaying: (clip: ModelMedia) => void;
  isPlaying: boolean;
  mainLsPlaying: boolean;
  currentClipPlaying: ModelMedia | undefined;
  streamDetails: {
    sportId: string;
    streamId: string;
    teamProgramId: string | undefined;
    duration: number;
  };
  currentPlayingEnded: boolean;
}) => {
  const user = useRecoilValue(profileAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const { control, getValues, reset, setValue } = useFormContext();
  const [permissions, setPermissions] = useState({
    edit: false,
    delete: false
  });
  const [clipsSelected, setClipsSelected] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [albumId, setAlbumId] = useState<"ALL" | string | undefined>("ALL");
  const [tags, setTags] = useState<string[] | undefined>(undefined);
  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const [openClipEdit, setOpenClipEdit] = useState(false);
  const [openPostToFeed, setOpenPostToFeed] = useState(false);
  const [openSaveToLibrary, setOpenSaveToLibrary] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [clipPlaying, setClipPlaying] = useState<string | undefined>(undefined);
  const [autoplay, setAutoplay] = useState(true);
  const [moreOptionSelectedClip, setMoreOptionSelectedClip] = useState<
    ModelMedia | undefined | "SELECTED"
  >(undefined);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const query = useMemo(() => {
    const buildQuery = {} as {
      albumId: string;
      tags: string[];
    };

    if (albumId && albumId !== "ALL") buildQuery.albumId = albumId;
    if (tags) buildQuery.tags = tags;

    return buildQuery;
  }, [albumId, tags]);

  const { data: clipResources, isLoading: clipResourceLoading } =
    useAdminLiveStreamStreamIdClipGet(streamDetails?.streamId, {
      resources: true
    });

  const { mutate: downloadClips } = useAdminLiveStreamStreamIdClipsDownload();

  const {
    data: clips,
    isLoading: clipsLoading,
    refetch: clipsListRefetch
  } = useAdminLiveStreamStreamIdClipGet(streamDetails?.streamId, query);

  const { mutate: saveToLibrary, isLoading: isSavingToMyLibrary } =
    useMediaLibraryPost();
  const { mutate: deleteClips, isLoading: isDeleting } =
    useAdminLiveStreamStreamIdClipsDelete();

  const handleSaveToLibrary = () => {
    saveToLibrary(
      {
        data:
          moreOptionSelectedClip === "SELECTED"
            ? clipsSelected
            : [moreOptionSelectedClip?.mediaId as string]
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Clip saved to library", { variant: "success" });
          setOpenSaveToLibrary(false);
        },
        onError: () => {
          enqueueSnackbar("Failed to save clip to library", {
            variant: "error"
          });
          setOpenSaveToLibrary(false);
        }
      }
    );
  };

  const albumOptions = useMemo(() => {
    if (clipResources && clipResources.data.albums) {
      const options = clipResources.data.albums.map((album) => ({
        label: album.name!,
        value: album.albumId!
      }));
      return [{ label: "Albums", value: "ALL" }, ...options];
    }
    return [{ label: "Albums", value: "ALL" }];
  }, [clipResources]);

  const tagOptions = useMemo(() => {
    if (clipResources && clipResources.data.tags) {
      return clipResources.data.tags.map((tag) => ({
        label: tag,
        value: tag
      }));
    }
    return [];
  }, [clipResources]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const roles = user?.roles?.filter(
      (role) => role.organizationId === organizationId
    );
    if (moreOptionSelectedClip === "SELECTED") {
      setPermissions({
        edit: false,
        delete: clips?.data.clips?.every(
          (clip) => clip.createdById === user?.userId
        ) as boolean
      });
      return;
    }
    const fetchPermissions = async () => {
      const edit =
        moreOptionSelectedClip?.createdById === user?.userId ||
        (roles?.some((role) => role.role?.alias === "ADMIN") as boolean);
      const del =
        moreOptionSelectedClip?.createdById === user?.userId ||
        (roles?.some((role) => role.role?.alias === "ADMIN") as boolean);
      setPermissions({
        edit,
        delete: del
      });
    };
    fetchPermissions();
  }, [moreOptionSelectedClip]);

  useEffect(() => {
    if (!mainLsPlaying && currentClipPlaying)
      setClipPlaying(currentClipPlaying.mediaId);
    setValue("autoplay", autoplay);
  }, []);

  useEffect(() => {
    if (
      autoplay &&
      currentPlayingEnded &&
      currentClipPlaying &&
      clips?.data?.clips
    ) {
      const lsClips = clips.data.clips;
      const currentIndex = clips?.data?.clips?.findIndex(
        (clip) => clip.mediaId === currentClipPlaying.mediaId
      );
      const nextIndex = currentIndex + 1;
      setValue("title", lsClips[nextIndex]?.metadata?.title);
      setTimeout(() => {
        setCurrentClipPlaying(lsClips[nextIndex] || lsClips[0]);
        setClipPlaying(lsClips[nextIndex]?.mediaId || lsClips[0].mediaId);
      }, 1000);
    }
  }, [currentPlayingEnded, autoplay]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      reset({
        ...getValues(),
        ...clips?.data?.clips?.reduce((acc, clip) => {
          acc[`select_${clip.mediaId}`] = true;
          return acc;
        })
      });
      setClipsSelected(
        clips?.data?.clips?.map((clip) => clip.mediaId!) as string[]
      );
    } else {
      reset(
        clips?.data.clips?.reduce((acc, clip) => {
          acc[`select_${clip.mediaId}`] = false;
          return acc;
        }, {}),
        { keepDefaultValues: false }
      );
      setClipsSelected([]);
    }
  };

  const moreOptionsClick = (event, clip: "SELECTED" | ModelMedia) => {
    setAnchorEl(event.currentTarget);
    setMoreOptionSelectedClip(clip);
  };

  const onConfirmDelete = () => {
    deleteClips(
      {
        streamId: streamDetails.streamId,
        params: {
          clipIds:
            moreOptionSelectedClip === "SELECTED"
              ? clipsSelected
              : [moreOptionSelectedClip?.mediaId as string]
        }
      },
      {
        onSuccess: () => {
          clipsListRefetch();
          enqueueSnackbar("Clip deleted successfully", { variant: "success" });
          setOpenDelete(false);
        },
        onError: () => {
          enqueueSnackbar("Failed to delete clip", { variant: "error" });
          setOpenDelete(false);
        }
      }
    );
  };

  const downloadHandler = () => {
    enqueueSnackbar("Download will start shortly", {
      variant: "success"
    });
    downloadClips(
      {
        streamId: streamDetails.streamId,
        data: {
          mediaIds:
            moreOptionSelectedClip === "SELECTED"
              ? clipsSelected
              : [moreOptionSelectedClip?.mediaId as string]
        }
      },
      {
        onSuccess: (data) => {
          if (data.data.urls)
            data.data.urls.forEach((u, index) => {
              const clip = clips?.data.clips?.find((clip) =>
                moreOptionSelectedClip === "SELECTED"
                  ? clip.mediaId === moreOptionSelectedClip
                  : clip.mediaId === moreOptionSelectedClip?.mediaId
              );
              const a = document.createElement("a");
              a.target = "_blank";
              a.href = u;
              a.download = `${
                clip?.metadata?.title || `Clip ${index + 1}`
              }.mp4`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            });
        },
        onError: () => {
          enqueueSnackbar("Failed to download clip", { variant: "error" });
        }
      }
    );
  };

  return (
    <div
      style={{
        minHeight: "415px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {!openClipEdit && (
        <Grid container spacing={2}>
          <Grid xs={12} sx={{ padding: "12px 16px" }}>
            <FormSelect
              options={albumOptions}
              name="album"
              onChange={(e) => setAlbumId(e.target.value)}
              value={albumId}
            />
          </Grid>
          <Grid xs={12} sx={{ padding: "12px 16px" }}>
            <FormMultiSelect
              options={tagOptions}
              name="tags"
              placeholder="Tags"
              control={control}
              onChange={(e) => setTags(e.target.value)}
              onRemove={(value, allOptions) => {
                setTags(allOptions);
              }}
            />
          </Grid>
          <Loader isLoading={clipsLoading || clipResourceLoading}>
            <Grid
              xs={12}
              sx={{ borderBottom: "2px solid #E5E5E5", padding: "12px 16px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  style={{
                    color: "#666666",
                    fontWeight: 600,
                    fontSize: "14px"
                  }}
                >
                  {clips?.data.clips && clips?.data.clips.length} Clips
                </Typography>
                {clips?.data.clips && clips?.data.clips.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px"
                    }}
                  >
                    <StyledFormLabel>Autoplay</StyledFormLabel>
                    <FormSwitch
                      name="autoplay"
                      control={control}
                      showLabels={false}
                      onChange={(e) => setAutoplay(e.target.checked)}
                    />
                  </div>
                )}
              </div>
            </Grid>

            {clips?.data.clips && clips?.data.clips.length > 0 && (
              <StyledClipOption
                xs={12}
                sx={{
                  padding: "12px 16px",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "8px"
                  }}
                >
                  <FormCheckbox
                    name={`select_ALL`}
                    control={control}
                    onChange={handleSelectAll}
                  />
                  <StyledFormLabel sx={{ marginBottom: 0 }}>
                    Select All
                  </StyledFormLabel>
                </div>
                {clipsSelected.length > 1 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <div>{clipsSelected.length} items selected</div>
                    <IconButton
                      onClick={(e) => moreOptionsClick(e, "SELECTED")}
                    >
                      <MoreVert htmlColor="#000" />
                    </IconButton>
                  </div>
                )}
              </StyledClipOption>
            )}
          </Loader>
          <div
            style={{
              maxHeight: "calc(100vh - 585px)",
              overflowY: "auto",
              width: "100%"
            }}
          >
            {clips?.data?.clips?.map((clip) => (
              <StyledClipOption
                key={clip.mediaId}
                xs={12}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    clipPlaying === clip.mediaId && !mainLsPlaying
                      ? "#F1F5FF"
                      : "transparent"
                }}
              >
                <FormCheckbox
                  name={`select_${clip.mediaId}`}
                  control={control}
                  value={clipsSelected.includes(clip?.mediaId as string)}
                  onChange={(e) => {
                    if (
                      e.target.checked &&
                      !clipsSelected.includes(clip?.mediaId as string)
                    )
                      setClipsSelected([
                        ...clipsSelected,
                        clip?.mediaId as string
                      ]);
                    else
                      setClipsSelected(
                        clipsSelected.filter((id) => id !== clip.mediaId)
                      );
                  }}
                />
                <div
                  onClick={() => {
                    setValue("title", clip.metadata?.title);
                    setClipPlaying(clip.mediaId);
                    setCurrentClipPlaying(clip);
                    handleClipViewClick();
                  }}
                  style={{ width: "100%" }}
                >
                  <ToolTip
                    title={
                      <div>
                        <Typography style={{ fontWeight: 600 }}>
                          {clip.metadata?.title}
                        </Typography>
                        <Typography>{clip.tags?.join(", ")}</Typography>
                      </div>
                    }
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [20, -10]
                          }
                        }
                      ]
                    }}
                  >
                    <TwoLineText>{clip.metadata?.title}</TwoLineText>
                  </ToolTip>
                  <Typography>{capitalize(clip?.tags?.[0] || "")}</Typography>
                </div>
                <div style={{ width: "40px", height: "40px" }}>
                  {clipPlaying === clip.mediaId && !mainLsPlaying && (
                    <>
                      {isPlaying ? (
                        <IconButton onClick={handlePlayPause}>
                          <Pause htmlColor="#007AFF" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={handlePlayPause}>
                          <PlayArrow htmlColor="#007AFF" />
                        </IconButton>
                      )}
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                  }}
                >
                  <Typography>
                    {convertSecondsToMMSS(clip.metadata?.duration || 0)}
                  </Typography>
                  {clipsSelected.length < 2 && (
                    <IconButton onClick={(e) => moreOptionsClick(e, clip)}>
                      <MoreVert htmlColor="#000" />
                    </IconButton>
                  )}
                </div>
              </StyledClipOption>
            ))}
          </div>
        </Grid>
      )}
      {openClipEdit && (
        <LivestreamClipEdit
          refetch={clipsListRefetch}
          clip={moreOptionSelectedClip as ModelMedia}
          onClose={() => {
            setOpenClipEdit(false);
            setMoreOptionSelectedClip(undefined);
          }}
        />
      )}
      <Popover
        style={{ marginLeft: "25px" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: "8px"
            }
          }
        }}
      >
        <MoreOption
          onClick={() => {
            handleClose();
            setOpenAlbumModal(true);
          }}
        >
          Add to Album
        </MoreOption>
        <MoreOption
          onClick={() => {
            handleClose();
            setOpenPostToFeed(true);
          }}
        >
          Post to Feed
        </MoreOption>
        <MoreOption
          onClick={() => {
            handleClose();
            setOpenSaveToLibrary(true);
          }}
        >
          Save to My Library
        </MoreOption>
        <MoreOption
          onClick={() => {
            handleClose();
            downloadHandler();
          }}
        >
          Download
        </MoreOption>
        <MoreOption
          onClick={() => {
            handleClose();
            setOpenShare(true);
          }}
        >
          Share Outside of SportsGravy
        </MoreOption>
        {clipsSelected.length < 2 && permissions.edit && (
          <MoreOption
            onClick={() => {
              handleClose();
              setOpenClipEdit(true);
            }}
          >
            Edit Clip
          </MoreOption>
        )}
        {permissions.delete && (
          <MoreOption
            style={{ color: "#E82C2C" }}
            onClick={() => {
              handleClose();
              setOpenDelete(true);
            }}
          >
            Delete {clipsSelected.length > 1 ? "Clips" : "Clip"}
          </MoreOption>
        )}
      </Popover>

      {openAlbumModal && (
        <AddToAlbum
          type="ADD_TO_ALBUM"
          onClose={() => {
            setOpenAlbumModal(false);
            setMoreOptionSelectedClip(undefined);
          }}
          mediaIds={
            moreOptionSelectedClip === "SELECTED"
              ? clipsSelected
              : [moreOptionSelectedClip?.mediaId as string]
          }
        />
      )}

      {openShare && (
        <ShareModal
          onClose={() => setOpenShare(false)}
          mediaIds={
            moreOptionSelectedClip === "SELECTED"
              ? clipsSelected
              : [moreOptionSelectedClip?.mediaId as string]
          }
        />
      )}

      {openPostToFeed && clipsSelected.length <= 10 && (
        <PostToFeed
          onClose={() => {
            setOpenPostToFeed(false);
            setMoreOptionSelectedClip(undefined);
          }}
          media={
            moreOptionSelectedClip === "SELECTED"
              ? clipsSelected.map((id) => ({
                  mediaId: id,
                  mimeType: "video"
                }))
              : [
                  {
                    mediaId: moreOptionSelectedClip?.mediaId,
                    mimeType: "video"
                  }
                ]
          }
          sportId={streamDetails.sportId}
        />
      )}

      <ConfirmationDialog
        open={openPostToFeed && clipsSelected.length > 10}
        title="Photo/Video Limit"
        body="You have selected too many items. You may only select up to 10 items to include in a single post."
        onConfirm={() => setOpenPostToFeed(false)}
        confirmBtnText="Okay"
        onCancel={() => setOpenPostToFeed(false)}
        hideCancelBtn
      />
      <ConfirmationDialog
        open={openSaveToLibrary}
        title="Confirm"
        body={`These ${
          moreOptionSelectedClip === "SELECTED" ? "items" : "item"
        } will be saved to your SportsGravy account’s My Library.`}
        onConfirm={handleSaveToLibrary}
        isConfirming={isSavingToMyLibrary}
        confirmBtnText="Okay"
        onCancel={() => setOpenSaveToLibrary(false)}
      />
      <ConfirmationDialog
        open={openDelete}
        title={`Delete ${
          moreOptionSelectedClip === "SELECTED" ? "Clips" : "Clip"
        }`}
        body={`Are you sure you want to delete this ${
          moreOptionSelectedClip === "SELECTED" ? "clips" : "clip"
        }?`}
        onConfirm={onConfirmDelete}
        isConfirming={isDeleting}
        onCancel={() => setOpenDelete(false)}
        icon="error"
      />
    </div>
  );
};
