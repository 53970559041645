import {
  Autocomplete,
  Button,
  FormLabel,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { useEffect, useState } from "react";
import { SearchInput } from "../../../components/SearchInput";
import {
  getOpportunities,
  getOrgOpportunities
} from "../../../services/Network";
import { Add } from "@mui/icons-material";
import { ModelOpportunity } from "@sportsgravyengineering/sg-api-react-sdk";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledAccountOption = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));

const OpportunityOption = ({
  opportunity,
  onClick,
  ...props
}: {
  opportunity: ModelOpportunity;
  onClick: () => void;
}) => {
  const { name } = opportunity;
  return (
    <StyledAccountOption
      container
      direction="column"
      padding="10px 16px 10px 16px"
      {...props}
      onClick={onClick}
    >
      <Grid item>
        <Typography
          style={{
            color: "#000",
            fontSize: "13px",
            fontWeight: 500
          }}
        >
          {name}
        </Typography>
      </Grid>
    </StyledAccountOption>
  );
};

const AddOpportunityOption = ({ onClick }: { onClick: () => void }) => {
  return (
    <Grid
      container
      padding="10px 16px 10px 16px"
      style={{ backgroundColor: "#E5E5E5" }}
      xs={12}
      direction="column"
      data-testid="ADD_NEW_PERSON_OPTION"
    >
      <Grid item>
        <Typography
          style={{
            color: "#0F0F0F",
            fontWeight: "600",
            fontSize: "13px"
          }}
        >
          Not finding what you're looking for?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          style={{
            color: "#007AFF",
            textTransform: "capitalize",
            fontSize: "14px"
          }}
          onClick={onClick}
          startIcon={<Add />}
        >
          Add New Opportunity
        </Button>
      </Grid>
    </Grid>
  );
};

export const SearchAddOpportunity = ({
  opportunitySelected,
  showAddOpportunityOption,
  required,
  label,
  disabled,
  searchValue,
  onClear,
  organizationId
}: {
  opportunitySelected: (
    selectedOpportunity,
    opportunitySelected,
    newOpportunitySelected
  ) => void;
  showAddOpportunityOption?: boolean;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  searchValue?: string;
  onClear?: () => void;
  organizationId?: string;
}) => {
  const [opportunitySearch, setOpportunitySearch] = useState("");
  useEffect(() => {
    if (searchValue) setOpportunitySearch(searchValue);
  }, [searchValue]);
  const [addOpportunityMenuOpen, setAddOpportunityMenuOpen] = useState(false);
  const defaultOpportunity: ModelOpportunity = {
    name: ""
  };
  const onAddOpportunitySearchChange = (event) => {
    setOpportunitySearch(event.target.value);
    if (!event.target.value && onClear) onClear();
  };
  const { data: addOpportunityResults, isFetching: isLoading } = organizationId
    ? getOrgOpportunities(
        {
          textSearch: opportunitySearch,
          organizationId: organizationId
        },
        { staleTime: Infinity, enabled: !!opportunitySearch?.length }
      )
    : getOpportunities(
        {
          textSearch: opportunitySearch
        },
        { staleTime: Infinity, enabled: !!opportunitySearch?.length }
      );
  const addOpportunityOptions = [...addOpportunityResults];
  if (showAddOpportunityOption) addOpportunityOptions.push(null);

  return (
    <Grid item container direction="row" spacing="24px">
      <Grid item xs={12}>
        <StyledFormLabel required={required}>
          <Typography display="inline" variant="formLabel">
            {label || "Opportunity Name"}
          </Typography>
        </StyledFormLabel>
        <Autocomplete
          open={addOpportunityMenuOpen}
          clearOnBlur={false}
          freeSolo
          disabled={disabled}
          onOpen={() => setAddOpportunityMenuOpen(true)}
          onClose={() => setAddOpportunityMenuOpen(false)}
          // value={secondaryContact}
          getOptionLabel={(option) => (option ? option.name : "")}
          isOptionEqualToValue={(option, value) =>
            option?.opportunityId === value?.opportunityId
          }
          inputValue={opportunitySearch}
          renderInput={(params) => (
            <SearchInput
              data-testid="ADD_OPP_SEARCH_PERSON_INPUT"
              {...params}
              value={opportunitySearch}
              onChange={onAddOpportunitySearchChange}
            />
          )}
          filterOptions={(options) =>
            addOpportunityMenuOpen && !isLoading ? options : []
          }
          renderOption={(props, option: ModelOpportunity, state) => {
            if (
              state.index === addOpportunityOptions.length - 1 &&
              showAddOpportunityOption
            ) {
              return (
                <AddOpportunityOption
                  key={state.index}
                  onClick={() => {
                    opportunitySelected(defaultOpportunity, false, true);
                    setAddOpportunityMenuOpen(false);
                  }}
                />
              );
            }
            return (
              <OpportunityOption
                key={option?.opportunityId}
                opportunity={option}
                onClick={() => {
                  opportunitySelected(
                    {
                      opportunityId: option?.opportunityId,
                      name: option?.name,

                      accountId: option?.accountId
                    },
                    true,
                    false
                  );
                  setOpportunitySearch(option!.name!);
                  setAddOpportunityMenuOpen(false);
                }}
              />
            );
          }}
          options={addOpportunityOptions || []}
          loading={isLoading}
          loadingText="Loading..."
        />
      </Grid>
    </Grid>
  );
};
