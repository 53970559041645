import { FormInput } from "@components/FormInput";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Autocomplete, TextField, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import {
  AdminLiveStreamStreamIdClipClipIdPutBodyMetadata,
  ModelMedia,
  useAdminLiveStreamStreamIdClipClipIdPut,
  useAdminLiveStreamStreamIdClipTagGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";

export const LivestreamClipEdit = ({
  clip,
  onClose,
  refetch
}: {
  clip: ModelMedia;
  onClose: () => void;
  refetch: () => void;
}) => {
  const [tags, setTags] = useState<
    { label: string; value: string; type?: string }[]
  >([]);
  const {
    control,
    setValue,
    formState: { isValid },
    reset,
    getValues
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    reset({
      title: clip.metadata?.title,
      tags: clip.tags?.map((tag) => ({ label: tag, value: tag })) || []
    });
    setTags(clip.tags?.map((tag) => ({ label: tag, value: tag })) || []);
  }, [clip]);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { data: presetTags, isLoading: presetTagsLoading } =
    useAdminLiveStreamStreamIdClipTagGet(clip.clipOfId!);

  const { mutate: edit, isLoading: isSaving } =
    useAdminLiveStreamStreamIdClipClipIdPut();

  const tagsOptions = useMemo(() => {
    return [
      ...(presetTags?.data?.presetTags || []).map((tag) => ({
        label: tag.name,
        value: tag.tagId,
        type: "Pre-set Tags"
      })),
      ...(presetTags?.data?.memberTags || []).map((tag) => ({
        label: tag.name,
        value: tag.tagId,
        type: "Team Members"
      })),
      ...(presetTags?.data?.personGroupTags || []).map((tag) => ({
        label: tag.name,
        value: tag.tagId,
        type: "Program Person Groups"
      }))
    ];
  }, [presetTags]);

  const saveHandler = () => {
    const values = getValues();
    const title = values.title;
    const tags = values.tags.map((tag) => tag.value);
    edit(
      {
        clipId: clip.mediaId!,
        streamId: clip.clipOfId!,
        data: {
          tags: tags,
          metadata: {
            ...(clip.metadata as AdminLiveStreamStreamIdClipClipIdPutBodyMetadata),
            title: title
          }
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Clip updated successfully", {
            variant: "success"
          });
          refetch();
          onClose();
        },
        onError: () => {
          enqueueSnackbar("Failed to update clip", { variant: "error" });
        }
      }
    );
  };
  return (
    <Loader isLoading={presetTagsLoading}>
      <div
        style={{
          minHeight: "415px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography style={{ fontSize: "18px", fontWeight: 700 }}>
              Edit Clip
            </Typography>
          </Grid>
          <Grid xs={12}>
            <FormInput
              control={control}
              type="text"
              name="title"
              label="Title"
              required
              rules={{
                required: "Title is required"
              }}
            />
          </Grid>

          <Grid xs={12}>
            <StyledFormLabel>
              <Typography display="inline" variant="formLabel">
                Tags
              </Typography>
            </StyledFormLabel>
            <Autocomplete
              freeSolo
              disableClearable={true}
              multiple
              groupBy={(option) => option.type}
              options={tagsOptions}
              filterSelectedOptions
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
              value={tags}
              noOptionsText="No tags found"
              loadingText="Loading..."
              loading
              onChange={(e, value) => {
                setTags(
                  value.map((v) => v) as {
                    label: string;
                    value: string;
                    type?: string;
                  }[]
                );
                setValue(
                  "tags",
                  value.map((v) => v)
                );
              }}
            />
          </Grid>

          <Grid
            xs={12}
            container
            gap="8px"
            marginTop="16px"
            justifyContent="flex-end"
          >
            <Button
              variant="admin-secondary"
              onClick={() => setOpenCancelDialog(true)}
            >
              Cancel
            </Button>
            <Button
              variant="admin-primary"
              disabled={!isValid || isSaving}
              isLoading={isSaving}
              onClick={saveHandler}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => {
            reset({}, { keepErrors: false });
            onClose();
          }}
        />
      </div>
    </Loader>
  );
};
