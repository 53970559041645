import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  AdminJobTitlePostPutRequest,
  ModelDepartment,
  ModelMedia,
  mediaGet,
  useAdminDepartmentGet,
  useAdminJobTitileTitleIdGet,
  useAdminJobTitleTitleIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Footer } from "@components/crud/Footer";
import { enqueueSnackbar } from "notistack";
import { base64ImageToFile } from "@utils/file";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { JobTitleForm } from "./jobTitleForm";

const containsUrl = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  return urlRegex.test(str);
};

export const JobTitleEdit = () => {
  const navigate = useNavigate();
  const { jobTitleId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const {
    reset,
    formState: { isValid, errors },
    getValues
  } = form;

  const { data: departmentsData, isLoading: isLoadingDepartments } =
    useAdminDepartmentGet();

  const { data: data, isLoading: isLoading } = useAdminJobTitileTitleIdGet(
    jobTitleId!
  );

  const [departments, setDepartments] = React.useState<ModelDepartment[]>([]);

  const { mutate: save, isLoading: isSaving } = useAdminJobTitleTitleIdPut();
  const [isUploadingImage, setUploadingImage] = useState(false);
  const onSave = async () => {
    const description = getValues().description;
    if (getValues().hasMedia) {
      setUploadingImage(true);
      await Promise.all(
        description?.map(async (op, idx) => {
          if (op.insert && Object.keys(op.insert).includes("image")) {
            if (!containsUrl(op.insert.image)) {
              const file = base64ImageToFile(
                op.insert.image,
                getValues().headline + "_" + idx
              );
              const filesPromises = await Promise.all(
                //@ts-ignore
                [file].map((file) => {
                  if (file instanceof File) {
                    const promise = uploadMediaUsingPresignedUrl(file);
                    return promise;
                  } else {
                    return (file as ModelMedia).mediaId!;
                  }
                })
              );

              if (filesPromises) {
                const response = await mediaGet({
                  mediaIds: [filesPromises[0]]
                });
                if (
                  response &&
                  response.data.media &&
                  response.data.media.length
                ) {
                  const media = response.data.media[0];
                  if (media.media && media.media.baseUrl && media.media.path) {
                    let path = media.media.path;
                    if (media.media.state === "DRAFT") {
                      path = path.replace("original/", "");
                    }
                    const urlPath = media.media.baseUrl + path;
                    description[idx].insert.image = urlPath;
                  }
                }
              }
            }
          }
        })
      );

      setUploadingImage(false);
    }
    const values = {
      name: getValues().name,
      departmentId: getValues().departmentId,
      isActive: getValues().isActive,
      description: description,
      abbreviation: getValues().abbreviation,
      supportsCalls: getValues().supportsCalls,
      supportsCallCenterCalls: getValues().supportsCallCenterCalls,
      canSetDemo: getValues().canSetDemo,
      canSellAccount: getValues().canSellAccount,
      canSupportAccount: getValues().canSupportAccount,
      canConductOnboardingDemo: getValues().canConductOnboardingDemo,
      maxAccountsDemo:
        canSetDemo && getValues().maxAccountsDemo
          ? parseInt(getValues().maxAccountsDemo)
          : null,
      maxAccountsSupport:
        canSupportAccount && getValues().maxAccountsSupport
          ? parseInt(getValues().maxAccountsSupport)
          : null,
      maxOpenOppSell:
        canSellAccount && getValues().maxOpenOppSell
          ? parseInt(getValues().maxOpenOppSell)
          : null,
      orgRatingDemo: canSetDemo ? getValues().orgRatingDemo || [] : [],
      orgRatingSell: canSellAccount ? getValues().orgRatingSell || [] : [],
      orgRatingSupport: canSupportAccount
        ? getValues().orgRatingSupport || []
        : [],
      athletesThresholdDemo:
        canSetDemo && getValues().athletesThresholdDemo
          ? parseInt(getValues().athletesThresholdDemo)
          : null,
      athletesThresholdSell:
        canSellAccount && getValues().athletesThresholdSell
          ? parseInt(getValues().athletesThresholdSell)
          : null,
      athletesThresholdSupport:
        canSupportAccount && getValues().athletesThresholdSupport
          ? parseInt(getValues().athletesThresholdSupport)
          : null
    } as AdminJobTitlePostPutRequest;
    save(
      {
        data: values,
        jobTitleId: jobTitleId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Job edited successfully!", {
            variant: "success"
          });

          navigate(`/job-titles/${jobTitleId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit Job!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [canSetDemo, setCanSetDemo] = useState<boolean>(false);
  const [canSellAccount, setCanSellAccount] = useState(false);
  const [canSupportAccount, setCanSupportAccount] = useState(false);
  React.useEffect(() => {
    if (data?.data) {
      const jobTitle = data.data;
      reset({
        name: jobTitle.name,
        departmentId: jobTitle.departmentId,
        description: jobTitle.description,
        isActive: jobTitle.isActive,
        abbreviation: jobTitle.abbreviation,
        supportsCalls: jobTitle.supportsCalls,
        canSetDemo: jobTitle.canSetDemo,
        canSellAccount: jobTitle.canSellAccount,
        canSupportAccount: jobTitle.canSupportAccount,
        supportsCallCenterCalls: jobTitle.supportsCallCenterCalls,
        maxAccountsDemo: jobTitle.maxAccountsDemo,
        maxAccountsSupport: jobTitle.maxAccountsSupport,
        maxOpenOppSell: jobTitle.maxOpenOppSell,
        orgRatingDemo: jobTitle.orgRatingDemo || [],
        orgRatingSell: jobTitle.orgRatingSell || [],
        orgRatingSupport: jobTitle.orgRatingSupport || [],
        athletesThresholdDemo: jobTitle.athletesThresholdDemo,
        athletesThresholdSell: jobTitle.athletesThresholdSell,
        athletesThresholdSupport: jobTitle.athletesThresholdSupport,
        canConductOnboardingDemo: jobTitle?.canConductOnboardingDemo
      });
      setCanSetDemo(jobTitle.canSetDemo as boolean);
      setCanSellAccount(jobTitle.canSellAccount as boolean);
      setCanSupportAccount(jobTitle.canSupportAccount as boolean);
    }
  }, [data]);
  React.useEffect(() => {
    if (departmentsData?.data) {
      setDepartments(departmentsData.data.departments!);
    }
  }, [departmentsData]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      return true;
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      return true;
    } else {
      return false;
    }
  };

  console.log(errors, isValid);

  return (
    <Container>
      <Toolbar title="Edit Job" />
      <Loader isLoading={isLoading}>
        <JobTitleForm
          form={form}
          departments={departments}
          isLoadingDepartments={isLoadingDepartments}
          isActiveReadOnly={
            data?.data?.sportsgravyUsers
              ? data.data.sportsgravyUsers.length > 0
              : false
          }
          canSetDemo={canSetDemo}
          canSellAccount={canSellAccount}
          canSupportAccount={canSupportAccount}
          setCanSellAccount={setCanSellAccount}
          setCanSetDemo={setCanSetDemo}
          setCanSupportAccount={setCanSupportAccount}
        />
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={
          !isValid ||
          !validateDescriptionArray(getValues().description) ||
          isSaving ||
          isUploadingImage
        }
        isLoading={isLoading || isSaving || isUploadingImage}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/job-titles")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
