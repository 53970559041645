import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { getCountries, getSports } from "@services/Network";
import { ModelSport } from "@sportsgravyengineering/sg-api-react-sdk";
import { format, parseISO } from "date-fns";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { IconButton, Modal, styled } from "@mui/material";
import { CloseIcon } from "@components/Icons";
import { Form } from "@components/crud/Form";
import { FormSelect } from "@components/FormSelect";
import { Footer } from "@components/crud/Footer";

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 600,
  height: 60,
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

const LIST_COLUMNS: GridColDef<ModelSport>[] = [
  {
    headerName: "Icon",
    field: "icon",
    minWidth: 40,
    renderCell: (params) => {
      return (
        params.row.icon && (
          <img
            src={
              (params.row.icon?.baseUrl || "") + (params.row.icon?.path || "")
            }
            width={24}
            height={24}
          />
        )
      );
    },
    sortable: false
  },
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Participating Countries",
    field: "countries",
    minWidth: 190,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("country.name", 1)
  },
  {
    headerName: "Created Date",
    field: "createdAt",
    value: "date",
    minWidth: 115,
    flex: 1,
    valueGetter: ({ value }) => format(parseISO(value), "MMM d, yyyy")
  }
];

export const Sports = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);
  const [filter, setFilter] = React.useState<string>("All");
  const [openCountrySelectModal, setOpenCountrySelectModal] =
    React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(undefined);

  const { data: countries, isLoading: isCountriesLoading } = getCountries({
    staleTime: Infinity
  });

  const options = useMemo(() => {
    if (countries)
      return countries.map((country) => ({
        label: country.name,
        value: country.countryId
      }));
    return [];
  }, [countries]);

  const filterConfig = useMemo(() => {
    if (countries) {
      return {
        field: "country",
        placeholderOption: { label: "All Countries", value: "All" },
        options: [
          {
            label: "Filter by Country",
            children: options
          }
        ]
      };
    }
  }, [countries]);

  const LIST_COUNTRY_COLUMNS = [
    {
      headerName: "Icon",
      field: "icon",
      minWidth: 40,
      renderCell: (params) => {
        return (
          <img
            src={
              (params.row.icon?.baseUrl || "") + (params.row.icon?.path || "")
            }
            width={24}
            height={24}
          />
        );
      }
    },
    { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
    {
      headerName: "Alternate Name",
      field: "alternateName",
      minWidth: 190,
      flex: 1,

      valueGetter: ({ row }) => {
        return row?.countries?.find((country) => country.countryId === filter)
          ?.name;
      }
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
      valueGetter: ({ row }) => {
        return row?.countries?.find((country) => country.countryId === filter)
          ?.country?.name;
      }
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      width: 115,
      flex: 1,
      value: "date",
      valueGetter: ({ value }) => format(parseISO(value), "MMM d, yyyy")
    }
  ];

  const onAdd = () => {
    navigate("/sports/create");
  };

  const onEdit = (sport) => {
    navigate(`/sports/${sport.id}/edit`);
  };

  const onView = (sport) => {
    navigate(`/sports/${sport.id}`);
  };

  const onSortButtonClick = () => {
    if (filter !== "All") navigate(`/sports/country/${filter}`);
    else {
      setSelectedCountry(undefined);
      setOpenCountrySelectModal(true);
    }
  };

  return (
    <Loader isLoading={isCountriesLoading}>
      <TableView
        title="Sports"
        useGet={getSports}
        columns={
          filter && filter !== "All" ? LIST_COUNTRY_COLUMNS : LIST_COLUMNS
        }
        isDeleteDisabled={() => true}
        filterConfig={filterConfig}
        // defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        refreshKey={refreshKey}
        onFilterChange={(value: string) => setFilter(value)}
        hideSortButton={false}
        onSortButtonClick={onSortButtonClick}
      />
      {openCountrySelectModal && (
        <Modal
          open={openCountrySelectModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MainContainer>
            <Header>
              <HeaderText>Select Sport</HeaderText>
              <IconButton
                onClick={() => {
                  setOpenCountrySelectModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Header>
            <Form>
              <FormSelect
                options={[{ label: "", value: "" }, ...options]}
                name="sport"
                onChange={(e) => setSelectedCountry(e.target.value)}
              />
            </Form>
            <Footer
              cancelBtnLabel="Cancel"
              saveBtnLabel="Continue"
              isDisabled={!selectedCountry}
              cancelBtnClick={() => {
                setSelectedCountry(undefined);
                setOpenCountrySelectModal(false);
              }}
              saveBtnClick={() => {
                selectedCountry && setFilter(selectedCountry);
                setOpenCountrySelectModal(false);
                navigate(`/sports/country/${selectedCountry}`);
              }}
            />
          </MainContainer>
        </Modal>
      )}
    </Loader>
  );
};
