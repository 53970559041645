import SportsGravyLogoIcon from "@assets/icons/sportsgravy.svg";
import SportsGravyLogo from "@assets/images/sg-logo-bw.svg";
import { FormSelect } from "@components/FormSelect";
import {
  CannedIcon,
  DashboardIcon,
  GameConceptIcon,
  GameSystemIcon,
  ImportTmpIcon,
  LevelIcon,
  OrganizationIcon,
  PositionIcon,
  RoleIcon,
  SeasonsIcon,
  SettingIcon,
  SkillIcon,
  SportIcon,
  SubRoleIcon,
  UserIcon,
  TeamsIcon,
  TrainingProgramsIcon,
  FeedIcon,
  ReportIcon,
  ImportIcon,
  LiveStreamIcon,
  SponsorshipLevelIcon,
  SportLocationIcon,
  CalendarIcon,
  SupportIcon,
  QaIcon,
  TestcaseIcon,
  GanttChartIcon,
  LeadsIcon,
  AccountIcon,
  KeyDataForAccountIcon,
  ContactsIcon,
  OpportunitiesIcon,
  OrdersIcon,
  ActivitiesIcon,
  AdvertiserInvoiceIcon
} from "@components/Icons";
import { NotificationDrawer } from "@components/NotificationDrawer";
import { SearchInput } from "@components/SearchInput";
import {
  EdgeSidebar,
  Header,
  Root,
  Scheme,
  SidebarContent
} from "@mui-treasury/layout";
import { AccountCircleOutlined, Logout } from "@mui/icons-material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  accessTokenAtom,
  organizationsAtom,
  organizationAtom,
  profileAtom,
  previousPathAtom
} from "@recoil/auth";
import { redirectLinkAtom } from "@recoil/signup";
import { canAccessDashboard, hasPermission } from "@services/Casbin";
import {
  getUnreadNotificationCount,
  updateCallAvailibiltyStatus
} from "@services/Network";
import {
  OrganizationType,
  useAdminCalendarGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import React, { useEffect, useState } from "react";
import {
  Location,
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
const StyledAppBar = styled(AppBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.palette.white.main};
  border: solid ${(props) => props.theme.palette.grey[200]};
  border-width: 0px 0px 1px 0px;
  box-shadow: unset;
  height: 60px;
  box-shadow: 0 1.5px 2px 0 ${(props) => props.theme.palette.grey[200]};
  padding: 10px 14px;
`;

// const StyledIconButton = styled(IconButton)(() => ({
//   marginLeft: "32px",
//   fontSize: "12px",
//   width: "40pxx",
//   display: "flex",
//   justifyContent: "space-between",

//   "& .create-btn-text": {
//     fontSize: "14px",
//     whiteSpace: "nowrap",
//   },

//   " .MuiPaperRoot.MuiList": {
//     width: "3000px",
//   },
// }));

const SelectContainer = styled(Box)(
  ({ theme }) => `
  width: 100%;
  height: 48px;
  margin-right: 10px;

  ${theme.breakpoints.up("lg")} {
    width: 482px;
    margin-right: 20px;
  }
  @media (max-width: 1200px) {
   width: 100%;
  }
`
);

const NavbarItemsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifySelf: "flex-end"
}));

const NotificationContainer = styled(Box)(() => ({
  marginRight: "24px",
  display: "flex",
  alignItems: "center"
}));

const StyledSidebarContent = styled(SidebarContent)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderLeft: `10px solid #E82C2C`,
  color: theme.palette.white.main
}));

const SideBarContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 18px 2px 18px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const Logo = styled("img")`
  cursor: pointer;
  width: 205px;
  display: block;
`;

const LogoIcon = styled("img")`
  cursor: pointer;
  width: 36px;
`;

const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 60px);
  margin-left: 271px;
  flex: 1;
  background-color: ${(props) => props.theme.palette.white.main};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &.collapsed {
    margin-left: 68px;
    width: calc(100vw - 68px);
  }
`;

const StyledListCategory = styled("div")`
  color: #cacaca;
  text-transform: uppercase;
`;

const StyledList = styled(List)`
  margin-bottom: 96px;
`;
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  margin: 0,
  padding: ".2rem .25rem .4rem .5rem",
  borderRadius: ".3rem",
  "&:hover": {
    backgroundColor: "#FFFFFF1A",
    color: theme.palette.white.main
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#8A91BD",
    color: theme.palette.white.main
  },
  "&.collapsed": {
    padding: ".25rem .25rem",
    margin: ".25rem 0"
  },
  "& .MuiTypography-root": {
    fontWeight: 600,
    color: theme.palette.white.main,
    textTransform: "capitalize"
  }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "0",
  margin: ".5rem 0",

  "&.main-item": {
    marginTop: "1rem",
    fontWeight: 600,

    "& .MuiTypography-root": {
      fontWeight: 600
    }
  },

  "&.sub-item": {
    "&.collapsed": {
      visibility: "hidden",
      display: "none"
    },

    "& .MuiTypography-root": {
      fontWeight: 600,
      color: theme.palette.white.main
    }
  }
}));

const StyledListItemIcon = styled(ListItemIcon)`
  width: 16px;
  height: 17px;
  padding: 0;
  margin-right: 0.75rem;
  min-width: 0;
  color: transparent;

  &.collapsed {
    width: 24px;
    height: 24px;
    margin-right: 0;
  }
`;

const SidebarFooter = styled("div")`
  justify-self: flex-end;
  position: absolute;
  bottom: 0;
  height: 96px;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-top: 1px solid #ffffff33;
  width: calc(100% - 10px);
  margin-left: -18px;
  padding: 25px 19px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    color: "#0D9488",
    backgroundColor: "#0D9488"
  }
}));

const NavMainItem = ({
  collapsed,
  icon,
  text,
  variant
}: {
  collapsed?: boolean;
  icon?: React.ReactNode;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variant?: any;
}) => {
  if (collapsed) {
    return (
      <Divider
        sx={{
          width: "80%",
          margin: "0 auto"
        }}
      />
    );
  }
  return (
    <StyledListItem className="main-item">
      {!!icon && (
        <StyledListItemIcon
          aria-label="menu"
          className={`${collapsed ? "collapsed" : ""}`}
          color={variant}
        >
          {icon}
        </StyledListItemIcon>
      )}
      <ListItemText>
        <Typography variant={variant}>{text}</Typography>
      </ListItemText>
    </StyledListItem>
  );
};

const NavSubItem = ({
  collapsed,
  icon,
  text,
  href,
  navigate,
  location,
  testid
}: {
  collapsed?: boolean;
  icon?: React.ReactNode;
  text: string;
  href: string;
  navigate: NavigateFunction;
  location: Location;
  testid?: string;
}) => (
  <StyledListItemButton
    data-testid={testid}
    selected={location?.pathname?.startsWith(href)}
    onClick={() => navigate?.(href)}
    className={`sub-item${collapsed ? " collapsed" : ""}`}
  >
    {!!icon && (
      <StyledListItemIcon
        aria-label="menu"
        className={`${collapsed ? "collapsed" : ""}`}
      >
        {icon}
      </StyledListItemIcon>
    )}
    {!collapsed && (
      <ListItemText
        sx={{
          padding: "4px 0 0 8px"
        }}
      >
        <Typography variant="body1">{text}</Typography>
      </ListItemText>
    )}
  </StyledListItemButton>
);

const scheme = {
  header: {
    config: {
      xs: { position: "sticky", height: 60 },
      md: { position: "sticky", height: 60 }
    }
  },
  leftEdgeSidebar: {
    config: {
      xs: {
        variant: "permanent",
        width: 272,
        collapsible: true,
        collapsedWidth: 68
      }
    },
    autoCollapse: "sm"
  }
} as Scheme;

const adminMenu = [
  {
    text: "General",
    items: [
      {
        text: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
        permissionId: "general.dashboard",
        action: "VIEW"
      },
      {
        text: "Organizations",
        href: "/organizations",
        icon: <OrganizationIcon />,
        permissionId: "general.organization",
        action: "EDIT"
      }
    ]
  },
  {
    text: "CRM",
    items: [
      {
        text: "Leads",
        href: "/crm/leads",
        icon: <LeadsIcon />,
        permissionId: "crm.leads",
        action: "VIEW"
      },
      {
        text: "Opportunities",
        href: "/crm/opportunities",
        icon: <OpportunitiesIcon />,
        permissionId: "crm.opportunities",
        action: "VIEW"
      },
      {
        text: "Opportunities Approvals",
        href: "/crm/opportunity-approvals",
        icon: <OpportunitiesIcon />,
        permissionId: "crm.opportunities",
        action: "VIEW"
      },
      {
        text: "Orders",
        href: "/crm/orders",
        icon: <OrdersIcon />,
        permissionId: "crm.orders",
        action: "VIEW"
      },
      {
        text: "Accounts",
        href: "/crm/accounts",
        icon: <AccountIcon />,
        permissionId: "crm.accounts",
        action: "VIEW"
      },
      {
        text: "Contacts",
        href: "/crm/contacts",
        icon: <ContactsIcon />,
        permissionId: "crm.contacts",
        action: "VIEW"
      },
      {
        text: "Activities",
        href: "/crm/activities",
        icon: <ActivitiesIcon />,
        permissionId: "crm.activities",
        action: "VIEW"
      },
      {
        text: "Key Data for Accounts",
        href: "/crm/key-data",
        icon: <KeyDataForAccountIcon />,
        permissionId: "crm.accounts",
        action: "VIEW"
      },
      {
        text: "Twillio Phone Numbers",
        href: "/crm/twilio-phone-number",
        icon: <OrdersIcon />,
        permissionId: "crm.accounts",
        action: "VIEW"
      }
    ]
  },
  {
    text: "Live Streaming",
    items: [
      {
        text: "Live Streams",
        href: "/live-stream",
        icon: <LiveStreamIcon />,
        permissionId: ["organization.post"],
        action: "ON"
      },
      {
        text: "Advertisers",
        href: "/advertisers",
        icon: <SponsorshipLevelIcon />,
        permissionId: ["live-streaming.advertisers"],
        action: "EDIT"
      },
      {
        text: "Sponsorship Levels",
        href: "/sponsorship-levels",
        icon: <SponsorshipLevelIcon />,
        permissionId: ["live-streaming.sponsors"],
        action: "EDIT"
      }
    ]
  },
  {
    text: "Technology Department",
    items: [
      {
        text: "Epics",
        href: "/epics",
        icon: <TestcaseIcon />,
        permissionId: "tech.epics",
        action: "VIEW"
      },
      {
        text: "Features",
        href: "/features",
        icon: <TestcaseIcon />,
        permissionId: "tech.features",
        action: "VIEW"
      },
      {
        text: "Test Cases",
        href: "/testcases",
        icon: <TestcaseIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "Gantt Chart",
        href: "/gantt-chart",
        icon: <GanttChartIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "Releases",
        href: "/releases",
        icon: <QaIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "QA Features",
        href: "/qa-features",
        icon: <QaIcon />,
        permissionId: "tech.features",
        action: "VIEW"
      },
      {
        text: "QA Test Cases",
        href: "/qa-testcases",
        icon: <QaIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "Builds",
        href: "/builds",
        icon: <QaIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "QA Builds",
        href: "/qa-builds",
        icon: <QaIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      }
    ]
  },
  {
    text: "Human Resources Department",
    items: [
      {
        text: "Departments",
        href: "/departments",
        icon: <OrganizationIcon />,
        permissionId: "hr.departments",
        action: "EDIT"
      },
      {
        text: "Jobs",
        href: "/job-titles",
        icon: <OrganizationIcon />,
        permissionId: "hr.job-titles",
        action: "EDIT"
      },
      {
        text: "Company Directory",
        href: "/directory",
        icon: <OrganizationIcon />,
        permissionId: "tech.test-cases",
        action: "EDIT"
      },
      {
        text: "Email Templates",
        href: "/email-templates",
        icon: <OrganizationIcon />,
        permissionId: "tech.test-cases",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Feed Management",
    items: [
      {
        text: "Feed",
        href: "/feed",
        icon: <FeedIcon />,
        permissionId: "organization.post",
        action: "ON"
      },
      {
        text: "Reports",
        href: "/reports",
        icon: <ReportIcon />,
        permissionId: "organization.post",
        action: "ON"
      }
    ]
  },
  {
    text: "User Management",
    items: [
      {
        text: "Users",
        href: "/users",
        icon: <UserIcon />,
        permissionId: "user.users",
        action: "EDIT"
      },
      {
        text: "Roles",
        href: "/roles",
        icon: <RoleIcon />,
        permissionId: "user.roles",
        action: "EDIT"
      },
      {
        text: "Sub Roles",
        href: "/subroles",
        icon: <SubRoleIcon />,
        permissionId: "user.sub-roles",
        action: "EDIT"
      },
      {
        text: "Import Template Providers",
        href: "/import-template-providers",
        icon: <ImportTmpIcon />,
        permissionId: "user.import-providers",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Support",
    items: [
      {
        text: "Help Categories",
        href: "/help-category",
        icon: <SupportIcon />,
        permissionId: "support.categories",
        action: "EDIT"
      },
      {
        text: "Help Articles",
        href: "/help-articles",
        icon: <SupportIcon />,
        permissionId: "support.articles",
        action: "EDIT"
      },
      {
        text: "FAQ’s",
        href: "/faqs",
        icon: <SupportIcon />,
        permissionId: "support.faqs",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Setup",
    items: [
      {
        text: "Sports",
        href: "/sports",
        icon: <SportIcon />,
        permissionId: "admin.sports",
        action: "EDIT"
      },
      {
        text: "Positions",
        href: "/positions",
        icon: <PositionIcon />,
        permissionId: "admin.positions",
        action: "EDIT"
      },
      {
        text: "Levels",
        href: "/levels",
        icon: <LevelIcon />,
        permissionId: "admin.levels",
        action: "EDIT"
      },
      {
        text: "Skills",
        href: "/skills",
        icon: <SkillIcon />,
        permissionId: "admin.skills",
        action: "EDIT"
      },
      {
        text: "Game Concepts",
        href: "/game-concepts",
        icon: <GameConceptIcon />,
        permissionId: "admin.game-concepts",
        action: "EDIT"
      },
      {
        text: "Game Systems",
        href: "/game-systems",
        icon: <GameSystemIcon />,
        permissionId: "admin.game-systems",
        action: "EDIT"
      },
      {
        text: "Settings",
        href: "/settings",
        icon: <SettingIcon />,
        permissionId: "admin.settings",
        action: "EDIT"
      }
    ]
  }
];

const orgMenu = [
  {
    text: "General",
    items: [
      {
        text: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
        permissionId: "general.dashboard",
        action: "VIEW"
      },
      {
        text: "Calendar",
        href: "/calendar",
        icon: <CalendarIcon />,
        permissionId: "general.calendar",
        action: "VIEW"
      },
      {
        text: "Photos & Videos",
        href: "/photos-videos",
        icon: <CalendarIcon />,
        permissionId: "general.calendar",
        action: "VIEW"
      },
      {
        text: "Organization Details",
        href: "/organizations",
        icon: <OrganizationIcon />,
        permissionId: "organization.organization",
        action: "EDIT"
      },
      {
        text: "Seasons",
        href: "/seasons",
        icon: <SeasonsIcon />,
        permissionId: "general.seasons",
        action: "EDIT"
      },
      {
        text: "Teams",
        href: "/teams",
        icon: <TeamsIcon />,
        permissionId: "team.teams",
        action: "EDIT"
      },
      {
        text: "Training Programs",
        href: "/training-programs",
        icon: <TrainingProgramsIcon />,
        permissionId: "training-programs.training-programs",
        action: "EDIT"
      }
    ]
  },
  {
    text: "CRM",
    items: [
      {
        text: "Opportunities",
        href: "/crm/opportunities ",
        icon: <OpportunitiesIcon />,
        permissionId: "crm.opportunities",
        action: "VIEW"
      },
      {
        text: "Accounts",
        href: "/crm/accounts",
        icon: <AccountIcon />,
        permissionId: "crm.accounts",
        action: "VIEW"
      },
      {
        text: "Contacts",
        href: "/crm/contacts",
        icon: <ContactsIcon />,
        permissionId: "crm.contacts",
        action: "VIEW"
      },
      {
        text: "Activities",
        href: "/crm/activities",
        icon: <ActivitiesIcon />,
        permissionId: "crm.activities",
        action: "VIEW"
      }
    ]
  },
  {
    text: "Live Streaming",
    items: [
      {
        text: "Live Streams",
        href: "/live-stream",
        icon: <LiveStreamIcon />,
        permissionId: [
          "organization.post",
          "team.all-social",
          "training-programs.all-social"
        ],
        action: "ON"
      },
      {
        text: "Clip Preset Tags",
        href: "/preset-tags",
        icon: <LiveStreamIcon />,
        permissionId: "live-streaming.clip-tags",
        action: "EDIT"
      },
      {
        text: "Advertiser Invoices",
        href: "/advertiser-invoices",
        icon: <AdvertiserInvoiceIcon />,
        permissionId: "live-streaming.advertisers",
        action: "EDIT"
      },
      {
        text: "Advertisers",
        href: "/advertisers",
        icon: <SponsorshipLevelIcon />,
        permissionId: "live-streaming.advertisers",
        action: "EDIT"
      },
      {
        text: "Sponsorship Levels",
        href: "/sponsorship-levels",
        icon: <SponsorshipLevelIcon />,
        permissionId: "live-streaming.sponsors",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Feed Management",
    items: [
      {
        text: "Feed",
        href: "/feed",
        icon: <FeedIcon />,
        permissionId: "organization.post",
        action: "ON"
      },
      {
        text: "Canned Messages",
        href: "/canned-messages",
        icon: <CannedIcon />,
        permissionId: "feed.canned-messaged",
        action: "EDIT"
      },
      {
        text: "Reports",
        href: "/reports",
        icon: <ReportIcon />,
        permissionId: "organization.post",
        action: "ON"
      }
    ]
  },
  {
    text: "User Management",
    items: [
      {
        text: "Users",
        href: "/users",
        icon: <UserIcon />,
        permissionId: "user.users",
        action: "EDIT"
      },
      {
        text: "Upload Players / Athletes",
        href: "/import-players",
        permissionId: "user.upload",
        icon: <ImportIcon />,
        action: "EDIT"
      },
      {
        text: "Roles",
        href: "/roles",
        icon: <RoleIcon />,
        permissionId: "user.roles",
        action: "EDIT"
      },
      {
        text: "Sub Roles",
        href: "/subroles",
        icon: <SubRoleIcon />,
        permissionId: "user.sub-roles",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Setup",
    items: [
      {
        text: "Merchant Account",
        href: "/merchant-account",
        icon: <SportLocationIcon />,
        permissionId: "general.calendar",
        action: "EDIT"
      },
      {
        text: "Sports Locations",
        href: "/sport-locations",
        icon: <SportLocationIcon />,
        permissionId: "general.calendar",
        action: "EDIT"
      },
      {
        text: "Levels",
        href: "/levels",
        icon: <LevelIcon />,
        permissionId: "admin.levels",
        action: "EDIT"
      },
      {
        text: "Skills",
        href: "/skills",
        icon: <SkillIcon />,
        permissionId: "admin.skills",
        action: "EDIT"
      },
      {
        text: "Game Concepts",
        href: "/game-concepts",
        icon: <GameConceptIcon />,
        permissionId: "admin.game-concepts",
        action: "EDIT"
      },
      {
        text: "Game Systems",
        href: "/game-systems",
        icon: <GameSystemIcon />,
        permissionId: "admin.game-systems",
        action: "EDIT"
      },
      {
        text: "Settings",
        href: "/settings",
        icon: <SettingIcon />,
        permissionId: "admin.settings",
        action: "EDIT"
      }
    ]
  }
];
const StyledLink = styled("a")`
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  font-size:13px

  padding: 10px 0;
  &:visited {
    color: #ffffff;
  }
  &:hover {
    color:  rgba(255, 255, 255, 0.7);
  }
  &:active {
    color: #ffffff;
  }
`;

export const AuthenticatedTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = useRecoilValue(accessTokenAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [organization, setOrganization] = useRecoilState(organizationAtom);
  const [redirectLink, setRedirectLink] = useRecoilState(redirectLinkAtom);
  const setPreviousPath = useSetRecoilState(previousPathAtom);
  const [menu, setMenu] = useState<typeof adminMenu | typeof orgMenu>([]);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [availableForCalls, setAvailableForCalls] = useState<boolean>(false);
  const [hasCallPermission, setHasCallPermissions] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    if (redirectLink) {
      navigate(redirectLink);
      setRedirectLink(undefined);
    }

    if (!accessToken) {
      setRedirectLink(location.pathname + location.search);
      updateCallAvailibiltyStatus("false");
      navigate("/", {
        state: { hasTimedOut: true }
      });
    }
  }, [accessToken]);
  useEffect(() => {
    canAccessDashboard()
      .then((hasAccess) => {
        if (!hasAccess) {
          return navigate("/unauthorized");
        }
      })
      .catch(() => {
        navigate("/");
      });
  }, [accessToken, navigate]);

  useEffect(() => {
    if (profile?.roles) {
      const superAdminRole = profile.roles.find(
        (role) => role.roleId === "system.admin"
      );
      setIsSuperAdmin(!!superAdminRole);
    }
  }, [profile]);

  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location.pathname, setPreviousPath]);

  useEffect(() => {
    (async () => {
      if (!organization) {
        // return setMenu(adminMenu);
        const menu: typeof adminMenu = [];
        for (const menuGroup of adminMenu) {
          const filteredItems: typeof menuGroup.items = [];
          for (const item of menuGroup.items) {
            const can = await hasPermission(
              "SYSTEM",
              "*",
              item.permissionId as string,
              item.action as string
            );

            if (can) {
              filteredItems.push(item);
            }
          }
          if (filteredItems.length > 0) {
            menu.push({
              ...menuGroup,
              items: filteredItems
            });
          }
        }
        return setMenu(menu);
      }

      const modelOrg = organizations.find(
        (org) => org.organizationId === organization
      );
      if (!modelOrg) {
        return;
      }
      // filter orgMenu based on permissionId and action
      const menu: typeof orgMenu = [];
      for (const menuGroup of orgMenu) {
        const filteredItems: typeof menuGroup.items = [];
        for (const item of menuGroup.items) {
          if (
            (item.permissionId === "admin.seasons" ||
              item.permissionId === "team.teams") &&
            modelOrg?.offering === "TRAINING_PROGRAMS"
          )
            continue;
          if (
            item.permissionId === "training-programs.training-programs" &&
            modelOrg?.offering === "TEAMS"
          )
            continue;
          if (
            (item.permissionId === "admin.skills" ||
              item.permissionId === "admin.game-concepts" ||
              item.permissionId === "admin.game-systems") &&
            !(
              [
                OrganizationType.NATIONAL_GOVERNING_BODY,
                OrganizationType.STATE_GOVERNING_BODY
              ] as OrganizationType[]
            ).includes(modelOrg.type!)
          )
            continue;
          if (isSuperAdmin) {
            filteredItems.push(item);
            continue;
          }
          if (Array.isArray(item.permissionId)) {
            const can = await Promise.all(
              item.permissionId.map((permissionId) =>
                hasPermission(
                  "ORGANIZATION",
                  organization!,
                  permissionId as string,
                  item.action as string
                )
              )
            );
            if (can.some((c) => c)) {
              filteredItems.push(item);
            }
          } else {
            const can = await hasPermission(
              "ORGANIZATION",
              organization!,
              item.permissionId as string,
              item.action as string
            );
            if (can) {
              filteredItems.push(item);
            }
          }
        }
        if (filteredItems.length > 0) {
          menu.push({
            ...menuGroup,
            items: filteredItems
          });
        }
      }

      setMenu(menu);
    })();
  }, [organization]);

  useEffect(() => {
    setAvailableForCalls(
      profile?.sportsgravyUser?.isAvailableForCalls as boolean
    );
    if (
      !organization &&
      (profile?.sportsgravyUser?.sgNumber ||
        profile?.sportsgravyUser?.jobTitle?.supportsCallCenterCalls)
    )
      setHasCallPermissions(true);
  }, [profile, organization]);

  const startDate = new Date();
  startDate.setUTCDate(1);
  startDate.setUTCHours(0, 0, 0, 0);
  startDate.setUTCMonth(startDate.getUTCMonth() - 4);

  const endDate = new Date();
  endDate.setUTCHours(23, 59, 59, 0);
  endDate.setUTCMonth(endDate.getUTCMonth() + 4);
  useAdminCalendarGet(
    {
      organizationId: organization!,
      startDate: encodeURI(startDate.toISOString()),
      endDate: encodeURI(endDate.toISOString())
    },
    {
      query: {
        queryKey: ["CalendarEvents", organization],
        enabled: !!organization
      },
      staleTime: 15 * 60 * 1000
    }
  );

  const unreadCountGet = getUnreadNotificationCount(
    {
      ...(organization && { organizationId: organization })
    },
    {}
  );

  const { unreadCount } = unreadCountGet;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAvatar = () => {
    setAnchorEl(null);
  };
  const handleProfileClick = () => {
    navigate("/profile");
    handleCloseAvatar();
  };
  const handleSignOutClick = () => {
    updateCallAvailibiltyStatus("false");
    localStorage.clear();
    navigate("/");
    handleCloseAvatar();
  };
  const getRole = () => {
    const priorityOrder = {
      ADMIN: 1,
      COACH: 2,
      MANAGER: 3,
      PARENT: 4,
      PLAYER: 5
    };
    if (profile?.roles) {
      const roles = profile.roles
        ?.filter((role) =>
          !organization
            ? role.organization === null && role.role?.type === "SYSTEM"
            : role.organizationId === organization
        )
        .map((role) => ({
          name: role.role?.name,
          alias: role.role?.alias,
          priority: role.role?.alias ? priorityOrder[role.role.alias] : 0
        }));
      if (roles.length > 0) {
        const highestPrioirtyRole = roles.reduce((min, current) => {
          return current.priority < min.priority ? current : min;
        }, roles[0]);
        return highestPrioirtyRole.name;
      }

      // this is sportsgravy admin then put Super Admin
      return "Super Admin";
    }
  };

  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    useState(false);
  const toggleNotificationDrawer = (isOpen) => {
    setIsNotificationDrawerOpen(isOpen);
    if (!isOpen) {
      unreadCountGet.refetch();
    }
  };

  const [orgSelectorInputValue, setOrgSelectorInputValue] =
    useState<string>("");

  useEffect(() => {
    if (orgSelectorInputValue === "") {
      const org = organizations?.find((o) =>
        organization
          ? o.organizationId === organization
          : o.organizationId === undefined
      );
      setOrgSelectorInputValue(org?.name || "");
    }
  }, [organization]);
  return (
    <Root scheme={scheme}>
      {({ state }) => (
        <>
          <Header sx={{ paddingRight: "0px !important" }}>
            <StyledAppBar position="static">
              <NavbarItemsContainer>
                <SelectContainer data-testid="organization-select">
                  {organizations && (
                    <Autocomplete
                      clearOnBlur={false}
                      options={organizations.map((org) => ({
                        value: org.organizationId,
                        label: org.name as string
                      }))}
                      inputValue={orgSelectorInputValue}
                      renderInput={(params) => {
                        return (
                          <SearchInput
                            data-testid="SEARCH_ORGANIZATION_INPUT"
                            {...params}
                            value={orgSelectorInputValue}
                            onChange={(e) => {
                              setOrgSelectorInputValue(e.target.value);
                            }}
                            onBlur={() => {
                              const org = organizations.find((org) =>
                                organization
                                  ? org.organizationId === organization
                                  : org.organizationId === undefined
                              );
                              setOrgSelectorInputValue(org?.name || "");
                            }}
                            placeholder="Search for Organizations"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password" // disable autocomplete and autofill
                            }}
                          />
                        );
                      }}
                      renderOption={(
                        props,
                        option: { value: string | undefined; label: string }
                      ) => {
                        return (
                          <MenuItem
                            data-testId={`ORG_OPTION_${option.value}`}
                            key={option.value}
                            id={option.value}
                            selected={
                              organization
                                ? option.value === organization
                                : option.value === undefined
                            }
                            disabled={
                              organization
                                ? option.value === organization
                                : option.value === undefined
                            }
                            onClick={() => {
                              setOrgSelectorInputValue(option.label);
                              if (option.label === "SportsGravy LLC") {
                                setOrganization(undefined);
                              } else {
                                setOrganization(option.value);
                                setRecoil(organizationAtom, option.value);
                              }
                              navigate("/dashboard");
                            }}
                          >
                            <ListItemText>{option.label}</ListItemText>
                          </MenuItem>
                        );
                      }}
                    />
                  )}
                </SelectContainer>
                {hasCallPermission && (
                  <SelectContainer style={{ width: "160px" }}>
                    <FormSelect
                      name=""
                      options={[
                        { label: "Available for calls", value: "true" },
                        { label: "Not available for calls", value: "false" }
                      ]}
                      value={availableForCalls ? "true" : "false"}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          sportsgravyUser: {
                            ...profile?.sportsgravyUser,
                            isAvailableForCalls: e.target.value === "true"
                          }
                        });
                        setAvailableForCalls(e.target.value === "true");
                        updateCallAvailibiltyStatus(e.target.value);
                      }}
                      sx={{ height: "50px" }}
                    />
                  </SelectContainer>
                )}

                <NotificationContainer data-testid="notification">
                  <IconButton
                    onClick={() => {
                      toggleNotificationDrawer(true);
                    }}
                  >
                    <StyledBadge
                      badgeContent={
                        <Typography
                          style={{ color: "white", fontSize: "13px" }}
                        >
                          {unreadCount}
                        </Typography>
                      }
                    >
                      <NotificationsNoneIcon className="notification-bell" />
                    </StyledBadge>
                  </IconButton>
                </NotificationContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end"
                  }}
                >
                  <Typography variant="formLabel">
                    {formatFullName(profile?.person)}
                  </Typography>
                  <Typography style={{ color: "#8D8D8D", fontSize: "12px" }}>
                    {getRole()}
                  </Typography>
                </div>
                <IconButton
                  data-testid="menu-avatar"
                  onClick={handleClickAvatar}
                >
                  <Avatar
                    alt="Avatar"
                    src={
                      profile?.person?.avatarId
                        ? profile?.person?.avatar?.baseUrl +
                          profile?.person?.avatar?.path
                        : ""
                    }
                  />
                </IconButton>
              </NavbarItemsContainer>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                disableScrollLock={true}
                open={open}
                onClose={handleCloseAvatar}
                onClick={handleCloseAvatar}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                <MenuItem onClick={handleProfileClick}>
                  <ListItemIcon>
                    <AccountCircleOutlined
                      fontSize="medium"
                      htmlColor="#64748B"
                    />
                  </ListItemIcon>
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleSignOutClick}>
                  <ListItemIcon>
                    <Logout fontSize="medium" htmlColor="#64748B" />
                  </ListItemIcon>
                  Sign Out
                </MenuItem>
              </Menu>
              {isNotificationDrawerOpen && (
                <NotificationDrawer
                  isNotificationDrawerOpen={isNotificationDrawerOpen}
                  toggleNotificationDrawer={toggleNotificationDrawer}
                />
              )}
            </StyledAppBar>
          </Header>
          <EdgeSidebar anchor="left">
            <StyledSidebarContent>
              <SideBarContentContainer data-testid="sidemenu">
                {state?.leftEdgeSidebar?.collapsed ? (
                  <LogoIcon src={SportsGravyLogoIcon} alt="SG" />
                ) : (
                  <Logo src={SportsGravyLogo} alt="SportsGravy" />
                )}
                <StyledList>
                  {menu.map((item) => (
                    <StyledListCategory key={item.text} data-testid={item.text}>
                      <NavMainItem
                        key={item.text}
                        collapsed={state?.leftEdgeSidebar?.collapsed}
                        text={item.text}
                        variant="navigationMenuHeader"
                      />
                      {item.items.map((subItem, i) => (
                        <NavSubItem
                          testid={subItem.permissionId}
                          key={`${item.text}-${i}`}
                          collapsed={state?.leftEdgeSidebar?.collapsed}
                          icon={subItem.icon}
                          text={subItem.text}
                          href={subItem.href}
                          navigate={navigate}
                          location={location}
                        />
                      ))}
                    </StyledListCategory>
                  ))}
                </StyledList>
                {!state?.leftEdgeSidebar?.collapsed && (
                  <SidebarFooter>
                    <Typography
                      style={{
                        color: "#fff",
                        fontSize: "13px",
                        fontWeight: 500
                      }}
                    >
                      &copy; SportsGravy {new Date().getFullYear()}
                    </Typography>
                    <Typography variant="body2" color="white">
                      <StyledLink
                        href={`${
                          import.meta.env.VITE_APP_WEBSITE_URL
                        }/about-us.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About Us
                      </StyledLink>
                      <p style={{ display: "inline" }}> | </p>
                      <StyledLink
                        href={`${
                          import.meta.env.VITE_APP_WEBSITE_URL
                        }/help.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help
                      </StyledLink>
                      <p style={{ display: "inline" }}> | </p>
                      <StyledLink
                        href={`${
                          import.meta.env.VITE_APP_WEBSITE_URL
                        }/contact.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact Us
                      </StyledLink>
                    </Typography>
                  </SidebarFooter>
                )}
              </SideBarContentContainer>
            </StyledSidebarContent>
          </EdgeSidebar>
          <ContentContainer
            className={state?.leftEdgeSidebar?.collapsed ? "collapsed" : ""}
          >
            <Outlet />
          </ContentContainer>
        </>
      )}
    </Root>
  );
};
