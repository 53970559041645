import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Box, IconButton, Modal } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import MoreVert from "@mui/icons-material/MoreVert";
import { FullscreenExit } from "@mui/icons-material";
import {
  MediaContextMenu,
  MoreOption
} from "../../components/MediaContextMenu";
import "./styles/videoPlayer.css";
import { PostToFeed } from "@components/PostToFeed";
import { ShareModal } from "@components/ShareModal";
import { enqueueSnackbar } from "notistack";

interface FullscreenMediaViewerProps {
  media: ModelMedia;
  currentIndex: number;
  isLastIndex?: boolean;
  onClose: () => void;
  onNavigate: (direction: "prev" | "next") => void;
}

export const FullscreenMediaViewer = ({
  media,
  currentIndex,
  onClose,
  onNavigate,
  isLastIndex
}: FullscreenMediaViewerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Handler for more options click
  const moreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handler to close the menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openPostToFeed, setOpenPostToFeed] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName; // You can change the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      enqueueSnackbar("Error downloading the file");
    }
  };
  return (
    <Modal
      style={{
        zIndex: 100000,
        width: "100%",
        height: "100%",
        backgroundColor: "black"
      }}
      open={true}
    >
      <>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            position: "relative"
          }}
        >
          {/* Media Display */}
          {media.type === "IMAGE" ? (
            <img
              src={media.baseUrl! + media.path!}
              alt="Fullscreen Image"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : media.type === "VIDEO" ? (
            <div
              className="video-player-wrapper"
              style={{ width: "100%", height: "100%" }}
            >
              <ReactPlayer
                playing={true}
                url={media.baseUrl! + media.path!}
                width="100%"
                height="100%"
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: "nofullscreen nodownload noplaybackrate",
                      disablePictureInPicture: true
                    }
                  }
                }}
              />
            </div>
          ) : null}

          {/* Close Button */}
          <IconButton
            onClick={onClose}
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              color: "white",
              height: "50px",
              width: "50px",
              zIndex: 1000
            }}
          >
            <FullscreenExit htmlColor="white" fontSize="large" />
          </IconButton>

          {/* Left Navigation Button */}
          {currentIndex > 0 && (
            <IconButton
              onClick={() => onNavigate("prev")}
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)"
              }}
            >
              <ArrowBackIosNewIcon htmlColor="rgba(255,255,255,0.75)" />
            </IconButton>
          )}

          {/* Right Navigation Button */}
          {!isLastIndex && (
            <IconButton
              onClick={() => onNavigate("next")}
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)"
              }}
            >
              <ArrowForwardIosIcon
                htmlColor="rgba(255,255,255,0.75)"
                fontSize="large"
              />
            </IconButton>
          )}
          <IconButton
            onClick={moreOptionsClick}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              height: "50px",
              width: "50px",
              zIndex: 1000
            }}
          >
            <MoreVert htmlColor="white" />
          </IconButton>
        </Box>
        <MediaContextMenu
          id={id}
          open={open}
          handleClose={handleClose}
          anchorEl={anchorEl}
          items={[
            <MoreOption
              key="1"
              onClick={() => {
                handleClose();
                setOpenPostToFeed(true);
              }}
            >
              Post to Feed
            </MoreOption>,
            <MoreOption
              key="2"
              onClick={() => {
                downloadFile(media.baseUrl! + media.path!, media.mediaId!);
              }}
            >
              Download
            </MoreOption>,
            <MoreOption
              key="3"
              onClick={() => {
                handleClose();
                setOpenShare(true);
              }}
            >
              Share Outside of SportsGravy
            </MoreOption>
          ]}
        />
        {openPostToFeed && (
          <PostToFeed
            onClose={() => {
              setOpenPostToFeed(false);
            }}
            media={[media]}
          />
        )}
        {openShare && (
          <ShareModal
            onClose={() => setOpenShare(false)}
            mediaIds={[media.mediaId!]}
          />
        )}
      </>
    </Modal>
  );
};
