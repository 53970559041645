import { TableViewInfinite } from "@components/TableViewInfinite";
import { Loader } from "@components/crud/Loader";
import { styled, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getEpics } from "@services/Network";
import { EpicGetResponse } from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const LIST_COLUMNS: GridColDef<EpicGetResponse>[] = [
  {
    headerName: "Epic Name",
    field: "name",
    minWidth: 100,
    flex: 1,
    valueGetter: ({ row }) => row.name
  },
  {
    headerName: "Description",
    field: "description",
    minWidth: 500,
    flex: 1,
    renderCell: ({ row }) => (
      <div style={{ padding: "20px 0" }}>
        <TwoLineText>{row.description}</TwoLineText>
      </div>
    )
  },
  {
    headerName: "Platforms",
    field: "platforms",
    minWidth: 100,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row.platforms?.join(", ")
  },
  {
    headerName: "% Done",
    field: "done",
    sortable: false,
    minWidth: 100,
    flex: 1,
    valueGetter: ({ row }) => row.donePercentage
  }
];

export const Epics = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });

  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.epics", "ADD");
      const edit = await checkPermission("tech.epics", "EDIT");
      const del = await checkPermission("tech.epics", "VIEW");
      const permission = {
        create,
        edit,
        view: del
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const onAdd = () => {
    navigate(`/epics/create`);
  };

  const onEdit = (epic) => {
    navigate(`/epics/${epic.epicId}/edit`);
  };

  const onView = (epic) => {
    navigate(`/epics/${epic.epicId}`);
  };

  const platformConfig = {
    field: "platform",
    defaultValue: "MOB",
    label: "Platform",
    placeHolderOption: { label: "Mobile", value: "MOB" },
    options: [
      {
        label: "MOB",
        value: "MOB"
      },
      {
        label: "WEB",
        value: "WEB"
      },
      {
        label: "API",
        value: "API"
      }
    ]
  };

  const isEditEnabled = () => {
    return permissions.edit;
  };
  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableViewInfinite
          title="Epics"
          useGet={getEpics}
          columns={LIST_COLUMNS}
          getRowId={(row) => row.epicId}
          hideFooter
          hidePlatformFilter={false}
          filterConfig={platformConfig}
          addBtnText="Add Epic"
          defaultSort={[{ field: "name", sort: "asc" }]}
          onEdit={isEditEnabled() ? onEdit : undefined}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          onView={permissions?.view ? onView : undefined}
          getRowHeight={() => "auto"}
        />
      </Loader>
    </>
  );
};
