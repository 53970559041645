import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Image,
  Text,
  Font
} from "@react-pdf/renderer";
import SGLogo from "@assets/images/sg-logo.png";
import { formatCurrency } from "@utils/formatCurrency";
import { capitalize } from "@utils/capitalize";
import { AdvertiserInvoiceSchemaStatus } from "@sportsgravyengineering/sg-api-react-sdk";

export type InvoicePDFDetailsProps = {
  invoiceNumber: string;
  orderDate: string | Date;
  paymentDate: string | Date | undefined | null;
  paymentMode: string;
  terms: string;
  from: { name: string; address: string };
  paymentStatus: AdvertiserInvoiceSchemaStatus;
  billTo: {
    to: string;
    name: string;
    phone: string;
    email: string;
  };
  items: {
    name: string;
    quantity: number;
    desciption: InvoiceDetailsDescription[];
    amount: number;
  }[];
};

type InvoiceDetailsDescription = {
  name: string;
  isBold?: boolean;
  subDescription?: {
    name: string;
    isBold?: boolean;
  }[];
};

const formatDate = (
  iosDateString: string | Date | undefined | null
): string => {
  if (!iosDateString) return "";
  const date = new Date(iosDateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "../../fonts/Inter400.ttf",
      fontWeight: 400
    },
    {
      src: "../../fonts/Inter500.ttf",
      fontWeight: 500
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: 40,
    display: "flex",
    height: "100vh",
    letterSpacing: 0.5
  },
  view: {
    display: "flex",
    flexDirection: "column"
  },
  seperator: {
    marginTop: "22px",
    width: "100%",
    borderBottomWidth: "3px",
    borderBottomColor: "#E5E5E5",
    borderBottomStyle: "solid"
  },
  fromDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  label: {
    fontSize: 16,
    fontWeight: 500
  },
  value: {
    color: "#666666",
    fontSize: "10px",
    width: "50%",
    lineHeight: 1.6,
    fontWeight: 400
  },
  billTo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  invoiceDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: "-25px"
  },
  invoiceDetails: {
    display: "flex",
    flexDirection: "row",
    gap: 25,
    justifyContent: "space-between"
  },
  invoiceDetailsTitle: {
    fontWeight: 500,
    fontSize: "10px"
  },
  invoiceDetailsValue: {
    color: "#666666",
    fontWeight: 400,
    fontSize: "10px"
  },
  table: {
    width: "100%"
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    border: "1px solid #B3B3B3",
    backgroundColor: "#F3F4F7"
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    border: "1px solid #B3B3B3",
    borderTop: "none"
  },
  tableCell: {
    padding: 8,
    fontSize: "10px",
    fontWeight: 400
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row"
  },
  tableTotalContainer: {
    border: "1px solid #B3B3B3",
    borderTop: "none"
  },
  tableTotalCell: {
    padding: 8
  },
  footer: {
    position: "absolute",
    textAlign: "center",
    fontSize: "12px",
    color: "#666666",
    bottom: 40,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto"
  }
});

export const InvoicePDF = ({
  hasHeader,
  hasFooter,
  invoiceDetails
}: {
  hasHeader: boolean;
  hasFooter?: boolean;
  invoiceDetails: InvoicePDFDetailsProps;
}) => {
  const renderDescription = (desc: InvoiceDetailsDescription[]) => {
    return (
      <>
        {desc.map((item, i) => (
          <View
            key={i}
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: item.isBold ? 500 : 400
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Text>&nbsp;&nbsp;• &nbsp; </Text>
              <Text>{item.name}</Text>
            </View>
            {item.subDescription && (
              <>
                {item.subDescription.map((subItem, j) => (
                  <View
                    key={j}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontWeight: subItem.isBold ? 500 : 400
                    }}
                  >
                    <Text>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;{" "}
                    </Text>
                    <Text>{subItem.name}</Text>
                  </View>
                ))}
              </>
            )}
          </View>
        ))}
      </>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          {hasHeader && (
            <>
              <Image src={SGLogo} style={{ width: "210px", height: "35px" }} />
              <View style={styles.seperator} />
            </>
          )}
          <View style={[styles.fromDetails, { marginTop: "22px" }]}>
            <View>
              <Text style={styles.label}>{invoiceDetails.from.name}</Text>
            </View>
            <View>
              <Text style={styles.label}>Invoice</Text>
            </View>
          </View>

          <View style={[styles.fromDetails, { marginTop: "10px" }]}>
            <View>
              <Text style={styles.value}>{invoiceDetails.from.address}</Text>
            </View>
            <View>
              <Text style={styles.label}>
                Payment Status:
                <Text
                  style={[
                    styles.label,
                    {
                      color:
                        invoiceDetails.paymentStatus === "PAID"
                          ? "#1ABC9C"
                          : "#E82C2C"
                    }
                  ]}
                >
                  &nbsp; {capitalize(invoiceDetails.paymentStatus)}
                </Text>
              </Text>
            </View>
          </View>

          <View style={[styles.fromDetails, { marginTop: "22px" }]}>
            <View>
              <Text style={{ fontSize: "10px", fontWeight: 500 }}>Bill To</Text>
            </View>
          </View>

          <View style={[styles.billTo, { marginTop: "10px" }]}>
            <View>
              <Text style={styles.value}>{invoiceDetails.billTo.to}</Text>
              <Text style={styles.value}>{invoiceDetails.billTo.name}</Text>
              <Text style={styles.value}>{invoiceDetails.billTo.phone}</Text>
              <Text style={styles.value}>{invoiceDetails.billTo.email}</Text>
            </View>
            <View>
              <View style={styles.invoiceDetailsContainer}>
                <View style={styles.invoiceDetails}>
                  <Text style={styles.invoiceDetailsTitle}>Invoice No.</Text>
                  <Text style={styles.invoiceDetailsValue}>
                    {invoiceDetails.invoiceNumber}
                  </Text>
                </View>
                <View style={styles.invoiceDetails}>
                  <Text style={styles.invoiceDetailsTitle}>Order Date</Text>
                  <Text style={styles.invoiceDetailsValue}>
                    {formatDate(invoiceDetails.orderDate)}
                  </Text>
                </View>
                <View style={styles.invoiceDetails}>
                  <Text style={styles.invoiceDetailsTitle}>Paid Date</Text>
                  <Text style={styles.invoiceDetailsValue}>
                    {formatDate(invoiceDetails.paymentDate)}
                  </Text>
                </View>
                <View style={styles.invoiceDetails}>
                  <Text style={styles.invoiceDetailsTitle}>Payment Mode</Text>
                  <Text
                    style={[
                      styles.invoiceDetailsValue,
                      {
                        minWidth: !invoiceDetails.paymentMode ? "75px" : "0px"
                      }
                    ]}
                  >
                    {invoiceDetails.paymentMode}
                  </Text>
                </View>
                <View style={styles.invoiceDetails}>
                  <Text style={styles.invoiceDetailsTitle}>Terms</Text>
                  <Text style={styles.invoiceDetailsValue}>
                    {invoiceDetails.terms}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 32 }}>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <View
                  style={[
                    styles.tableCell,
                    { width: "10%", textAlign: "center" }
                  ]}
                >
                  <Text>QTY</Text>
                </View>
                <View style={[styles.tableCell, { width: "70%" }]}>
                  <Text>Description</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    {
                      width: "20%",
                      textAlign: "right",
                      marginRight: "20px"
                    }
                  ]}
                >
                  <Text>Amount</Text>
                </View>
              </View>
              {invoiceDetails.items.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                  <View
                    style={[
                      styles.tableCell,
                      { width: "10%", textAlign: "center" }
                    ]}
                  >
                    <Text>{item.quantity}</Text>
                  </View>
                  <View
                    style={[
                      styles.tableCell,
                      { width: "70%", lineHeight: 1.5 }
                    ]}
                  >
                    <Text>{item.name}</Text>
                    {renderDescription(item.desciption)}
                  </View>
                  <View
                    style={[
                      styles.tableCell,
                      {
                        width: "20%",
                        textAlign: "right",
                        marginRight: "10px",
                        letterSpacing: 0.8
                      }
                    ]}
                  >
                    <Text>{formatCurrency(item.amount)}</Text>
                  </View>
                </View>
              ))}
              <View style={styles.tableFooter}>
                <View
                  style={[
                    styles.tableCell,
                    { width: "10%", textAlign: "center" }
                  ]}
                ></View>
                <View
                  style={[
                    styles.tableCell,
                    {
                      width: "70%",
                      textAlign: "right"
                    }
                  ]}
                >
                  <Text style={[styles.tableTotalCell, { width: "330px" }]}>
                    Sub Total
                  </Text>
                  <Text style={[styles.tableTotalCell, { width: "330px" }]}>
                    Tax (12%)
                  </Text>
                  <Text
                    style={[
                      styles.tableTotalCell,
                      { width: "310px", fontWeight: 500 }
                    ]}
                  >
                    Total
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    styles.tableTotalContainer,
                    { width: "20%" }
                  ]}
                >
                  <View>
                    <Text
                      style={[
                        styles.tableTotalCell,
                        { textAlign: "right", letterSpacing: 0.8 }
                      ]}
                    >
                      {formatCurrency(
                        invoiceDetails.items.reduce(
                          (total, i) => total + i.amount,
                          0
                        )
                      )}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={[
                        styles.tableTotalCell,
                        { textAlign: "right", letterSpacing: 0.8 }
                      ]}
                    >
                      {formatCurrency(
                        Number(
                          (
                            invoiceDetails.items.reduce(
                              (total, i) => total + i.amount,
                              0
                            ) * 0.12
                          ).toFixed(2)
                        )
                      )}
                    </Text>
                  </View>
                  <View
                    style={[
                      {
                        borderTop: "1px solid #B3B3B3",
                        right: 8,
                        width: "102px",
                        letterSpacing: 0.8
                      }
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableTotalCell,
                        {
                          textAlign: "right",
                          marginRight: "8px",
                          fontWeight: 500
                        }
                      ]}
                    >
                      {formatCurrency(
                        Number(
                          (
                            invoiceDetails.items.reduce(
                              (total, i) => total + i.amount,
                              0
                            ) +
                            invoiceDetails.items.reduce(
                              (total, i) => total + i.amount,
                              0
                            ) *
                              0.12
                          ).toFixed(2)
                        )
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.footer, { bottom: hasFooter ? 70 : 40 }]}>
          <Text>THANK FOR YOUR BUISNESS!</Text>
        </View>
        {hasFooter && (
          <View
            style={[
              styles.footer,
              {
                bottom: 30,
                fontSize: "10px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                gap: "5px"
              }
            ]}
          >
            <Text>Powered By</Text>
            <Image
              src={SGLogo}
              style={{
                width: "80px",
                height: "15px"
              }}
            />
          </View>
        )}
      </Page>
    </Document>
  );
};
