import React from "react";
import { Container } from "@components/crud/Container";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { FormSelect } from "@components/FormSelect";
import { FormInput } from "@components/FormInput";
import { CRM_ORDER_BANK_ACH_TYPES } from "@utils/constants";

export const AddBankAccount = ({ disabled }: { disabled: boolean }) => {
  const { control } = useFormContext();
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="method"
            label="Bank Account Type"
            options={CRM_ORDER_BANK_ACH_TYPES}
            required
            rules={{
              required: "Boarding Status is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}></Grid>
        <Grid xs={6}>
          <FormInput
            type="text"
            control={control}
            name="routing"
            label="Bank Routing Number"
            required
            rules={{
              required: "Bank Routing Number is required"
            }}
            TextProps={{
              inputProps: {
                maxLength: 9
              }
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="text"
            control={control}
            name="number"
            label="Bank Account Number"
            required
            rules={{
              required: "Bank Account Number is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}></Grid>
      </Grid>
    </Container>
  );
};
