import { Button } from "@components/Button";
import { InvoiceReceipt } from "@components/InvoiceReceipt";
import { styled, Typography } from "@mui/material";
import ReactPDF, { usePDF } from "@react-pdf/renderer";
import { AdvertiserInvoiceSchemaPaymentType } from "@sportsgravyengineering/sg-api-react-sdk";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";
import { formatCurrency } from "@utils/formatCurrency";
import React from "react";

const StyledDiv = styled("div")`
  box-shadow: 0px 0px 12px 0px #0000001f;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 100%;
  max-width: 650px;
  padding: 40px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  border-top: 8px solid #1abc9c;
`;

const HeaderText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const TitleText = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  color: #000;
  line-height: 22px;
`;

const DescriptionText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 20px;
`;

const FlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

export type PaymentConfirmationDialogProps = {
  organization: {
    name: string;
    phone: string;
    address: string;
    email: string;
  };
  to: string;
  paymentDetails: {
    type: AdvertiserInvoiceSchemaPaymentType;
    number: string;
    transactionId: string;
  };
  dueDate: string | Date | undefined;
  amount: number;
  invoiceId: string;
};

const formatDate = (isoDate: string | Date | undefined): string => {
  if (!isoDate) return "";
  const date = new Date(isoDate);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric"
  });
};

const PaymentConfirmation = ({
  confirmationDetails,
  invoiceInstance
}: {
  confirmationDetails: PaymentConfirmationDialogProps;
  invoiceInstance: ReactPDF.UsePDFInstance;
}) => {
  if (!confirmationDetails) return null;
  const [receiptInstance] = usePDF({
    document: (
      <InvoiceReceipt
        hasHeader={false}
        hasFooter={true}
        receiptDetails={{
          amount: confirmationDetails.amount,
          invoiceAmount: confirmationDetails.amount,
          invoiceNumber: confirmationDetails.invoiceId,
          to: confirmationDetails.to,
          status: "PAID",
          paymentMethod:
            `${CRM_ORDER_PAYMENT_TYPES.find(
              (c) => c.value === confirmationDetails.paymentDetails.type
            )?.label} - ${confirmationDetails.paymentDetails.number}` || "",
          authorizationId:
            confirmationDetails.paymentDetails.transactionId.slice(-10),
          from: {
            name: confirmationDetails.organization.name,
            email: confirmationDetails.organization.email,
            address: confirmationDetails.organization.address,
            phone: confirmationDetails.organization.phone
          }
        }}
      />
    )
  });
  return (
    <>
      <StyledDiv>
        <FlexBox style={{ justifyContent: "center" }}>
          <HeaderText style={{ color: "#1abc9c" }}>Success!</HeaderText>
        </FlexBox>
        <FlexBox
          style={{ marginTop: "32px", flexDirection: "column", gap: "16px" }}
        >
          <FlexBox>
            <DescriptionText>Merchant</DescriptionText>
            <TitleText style={{ textAlign: "end" }}>
              {confirmationDetails.organization.name}
            </TitleText>
          </FlexBox>
          <FlexBox>
            <DescriptionText>Payment Method</DescriptionText>
            <TitleText style={{ textAlign: "end" }}>
              {
                CRM_ORDER_PAYMENT_TYPES.find(
                  (c) => c.value === confirmationDetails.paymentDetails.type
                )?.label
              }
              {" - "}
              {confirmationDetails.paymentDetails.number}
            </TitleText>
          </FlexBox>
          <FlexBox>
            <DescriptionText>Transaction ID</DescriptionText>
            <TitleText>
              {confirmationDetails.paymentDetails.transactionId.slice(-10)}
            </TitleText>
          </FlexBox>
          <FlexBox>
            <DescriptionText>Due Date</DescriptionText>
            <TitleText>{formatDate(confirmationDetails.dueDate)}</TitleText>
          </FlexBox>
          <FlexBox>
            <DescriptionText>Invoice Amount</DescriptionText>
            <TitleText>{formatCurrency(confirmationDetails.amount)}</TitleText>
          </FlexBox>
          <FlexBox>
            <DescriptionText>Total</DescriptionText>
            <TitleText>{formatCurrency(confirmationDetails.amount)}</TitleText>
          </FlexBox>
          <FlexBox>
            <DescriptionText>Status</DescriptionText>
            <TitleText>Paid</TitleText>
          </FlexBox>
        </FlexBox>
        <FlexBox
          style={{ marginTop: "32px", flexDirection: "column", gap: "12px" }}
        >
          <Button
            variant="admin-primary"
            onClick={() =>
              downloadPDFFromLink(
                receiptInstance.url,
                `${confirmationDetails.invoiceId}-receipt.pdf`
              )
            }
          >
            Download Receipt
          </Button>
          <Button
            variant="admin-secondary"
            onClick={() =>
              downloadPDFFromLink(
                invoiceInstance.url,
                `${confirmationDetails.invoiceId}.pdf`
              )
            }
          >
            Download Invoice
          </Button>
        </FlexBox>
        <FlexBox style={{ justifyContent: "center" }}>
          <Typography style={{ fontSize: "12px", color: "#666" }}>
            &copy; {new Date().getFullYear()}. SportsGravy. All rights reserved.
          </Typography>
        </FlexBox>
      </StyledDiv>
    </>
  );
};

export default PaymentConfirmation;
