import React from "react";
import { Activity } from "../activities/Activity";
import { ModelAccount } from "@sportsgravyengineering/sg-api-react-sdk";

export const AccountActivity = ({
  activities,
  onClickAdd,
  account
}: {
  activities;
  account: ModelAccount;
  onClickAdd: () => void;
}) => {
  return (
    <Activity
      activities={activities}
      onClickAdd={onClickAdd}
      hideRelatedTo
      hideFooter
      account={account}
      useOfflineTable
    />
  );
};
