import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { getEmailTemplates } from "@services/Network";
import { ModelEmailTemplate } from "@sportsgravyengineering/sg-api-react-sdk";

import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS: GridColDef<ModelEmailTemplate>[] = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 150,
    sortable: false,
    flex: 1
  },
  {
    headerName: "Department",
    field: "department",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row.department?.name
  },
  {
    headerName: "Job Title",
    field: "jobTitle",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row?.jobTitle?.name
  },
  {
    headerName: "Subject",
    field: "subject",
    minWidth: 200,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row.subject
  },
  {
    headerName: "Active",
    field: "isActive",
    minWidth: 120,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => (row.isActive ? "Yes" : "No")
  }
];

export const EmailTemplates = () => {
  const navigate = useNavigate();

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/email-templates/create");
  const onEdit = (row) => navigate(`/email-templates/${row.templateId}/edit`);
  const onView = (row) => navigate(`/email-templates/${row.templateId}`);

  return (
    <>
      <TableView
        title="Email Templates"
        useGet={getEmailTemplates}
        columns={LIST_COLUMNS}
        hideLabelContainer={true}
        hideFilter={true}
        searchable={false}
        getRowId={(row) => row.templateId}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
