/* eslint-disable @typescript-eslint/no-explicit-any */
import { Loader } from "@components/crud/Loader";
import { Column, DataGridTable } from "@components/DataGridTable";
import { Grid, Typography, Divider, styled, Chip } from "@mui/material";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";
import {
  adminMetricPneFeatureCostOverviewFeatureIdGet,
  ObjectAny
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useQuery } from "@tanstack/react-query";
import { JIRA_CARD_STATUSES } from "@utils/constants";
import { useEffect, useMemo, useState } from "react";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { formatCurrency } from "@utils/formatCurrency";

const dateFormat = (isoString: string): string => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric"
  });
};

const convertToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours === 0 && minutes === 0) {
    return "";
  }
  const formattedHours = hours > 0 ? `${hours}h` : "";
  const formattedMinutes = minutes > 0 ? ` ${minutes}m` : "";
  const formattedTime = `${formattedHours}${formattedMinutes}`;
  return formattedTime.trim();
};

const StyledGrid = styled(Grid)`
  .pinned-body,
  .body {
    background-color: #fff !important;
    border: none !important;
    border-right: 1px solid #e5e5e5 !important;
  }
  .pinned-header,
  .header {
    border: none !important;
    border-right: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }
  .pinned-header:nth-child(4),
  .pinned-body:nth-child(4) {
    border-right: 2px solid #000 !important;
  }
  tbody td {
    border-bottom: none !important;
  }
  .total {
    background-color: #f3f4f7 !important;
  }
`;

const StyledTotalValue = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
`;

const StyledLink = styled("a")`
  color: #007aff;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 0;
  &:visited {
    color: #007aff;
  }
  &:hover {
    color: #004494;
  }
  &:active {
    color: #007aff;
  }
`;

export const FeatureCostBreakdown = ({
  platform,
  resourceId,
  featureId,
  cardType
}: {
  platform: "WEB" | "API" | "DEV" | "AND" | "IOS" | "ALL";
  resourceId: string;
  featureId: string;
  cardType: "STORY" | "BUG" | "TASK" | "ALL";
}) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [totals, setTotals] = useState<Record<string, number>>({
    originalEstimate: 0,
    timeSpent: 0,
    estimatedCost: 0,
    actualCost: 0
  });

  const query = useMemo(() => {
    const buildQuery = {} as {
      accountId?: string | undefined;
      platform: "WEB" | "API" | "DEV" | "AND" | "IOS";
      dtStart?: string | undefined;
      dtEnd?: string | undefined;
      type?: "STORY" | "BUG" | "TASK" | "ALL";
    };

    if (resourceId === "ALL") {
      delete buildQuery.accountId;
    } else {
      buildQuery.accountId = resourceId;
    }

    if (platform != "ALL") {
      buildQuery.platform = platform;
    }

    if (cardType === "ALL") {
      delete buildQuery.type;
    } else {
      buildQuery.type = cardType;
    }

    if (selectedTimeRange[0] && selectedTimeRange[1]) {
      buildQuery.dtStart = selectedTimeRange[0];
      buildQuery.dtEnd = selectedTimeRange[1];
    }
    return buildQuery;
  }, [resourceId, platform, selectedTimeRange, cardType]);

  const { data: fcb, isLoading: isLoading } = useQuery(
    [
      "adminMetricPneFeatureCostOverview",
      platform,
      resourceId,
      featureId,
      selectedTimeRange,
      cardType
    ],
    () => adminMetricPneFeatureCostOverviewFeatureIdGet(featureId, query),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60,
      refetchOnWindowFocus: true
    }
  );

  const renderChip = (value) => {
    return (
      <>
        <Chip
          style={{
            fontSize: "14px",
            padding: "4px 8px",
            fontWeight: 600
          }}
          label={
            JIRA_CARD_STATUSES.find((status) => status.value === value)?.label
          }
          sx={{
            background:
              value === "DONE"
                ? "#BBF7D0"
                : value === "REVIEW" || value == "QA"
                ? "#ECF4FC"
                : value == "IN_PROGRESS" || value == "TODAYS_PLAN"
                ? "#FBF1BC"
                : value == "ON_HOLD"
                ? "#FFEEEE"
                : "#E5E5E5",
            color:
              value === "DONE"
                ? "#166534"
                : value === "REVIEW" || value == "QA"
                ? "#093B72"
                : value == "IN_PROGRESS" || value == "TODAYS_PLAN"
                ? "#7B5B08"
                : value == "ON_HOLD"
                ? "#A11212"
                : "#666666"
          }}
        />
      </>
    );
  };

  const COLUMNS: Column[] = [
    {
      field: "key",
      headerName: "Card #",
      width: 100,
      renderCell: (params) => {
        return (
          <StyledLink
            href={`https://sportsgravy.atlassian.net/browse/${params.key}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.key}
          </StyledLink>
        );
      }
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      renderCell: (params) => {
        if (params.type === "STORY") {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "2px",
                  background: "#1ABC9C",
                  marginRight: "8px"
                }}
              ></span>
              <Typography>Story</Typography>
            </div>
          );
        }
        if (params.type === "BUG") {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "99px",
                  background: "#E82C2C",
                  marginRight: "8px"
                }}
              ></span>
              <Typography>Bug</Typography>
            </div>
          );
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "2px",
                background: "#4bade8",
                marginRight: "8px"
              }}
            ></span>
            <Typography>Task</Typography>
          </div>
        );
      }
    },
    {
      headerName: "Use Case / Description",
      field: "title",
      width: 250,
      align: "left",
      renderCell: (params) => {
        return (
          <StyledLink
            href={`https://sportsgravy.atlassian.net/browse/${params.key}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.summary}
          </StyledLink>
        );
      }
    },
    {
      headerName: "Assignee",
      field: "assignee.person.firstName",
      sortable: true,
      width: 140,
      renderCell: (params) => {
        if (!params.assignee || !params.assignee.person)
          return <Typography>-</Typography>;
        return (
          <Typography>
            {params.assignee.person.firstName} {params.assignee.person.lastName}
          </Typography>
        );
      }
    },
    {
      headerName: "Sprint",
      field: "sprint",
      width: 140,
      renderCell: (params) => {
        if (!params.sprint) return <Typography>-</Typography>;
        return <Typography>{params.sprint?.name}</Typography>;
      }
    },
    {
      headerName: "Est Start Date",
      field: "estimateStartDate",
      width: 140,
      renderCell: (params) => {
        if (!params.originalStartDate) return <Typography>-</Typography>;
        return <Typography>{dateFormat(params.originalStartDate)}</Typography>;
      }
    },
    {
      headerName: "Est Due Date",
      field: "estimateDueDate",
      width: 140,
      renderCell: (params) => {
        if (!params.originalDueDate) return <Typography>-</Typography>;
        return <Typography>{dateFormat(params.originalDueDate)}</Typography>;
      }
    },
    {
      headerName: "Act Start Date",
      field: "actualStartDate",
      width: 140,
      renderCell: (params) => {
        if (!params.actualStartDate) return <Typography>-</Typography>;
        return <Typography>{dateFormat(params.actualStartDate)}</Typography>;
      }
    },
    {
      headerName: "Act End Date",
      field: "actualDueDate",
      width: 150,
      sortable: true,
      renderCell: (params) => {
        if (!params.actualDueDate) return <Typography>-</Typography>;
        return <Typography>{dateFormat(params.actualDueDate)}</Typography>;
      }
    },
    {
      headerName: "Est Time",
      field: "estimate",
      width: 140,
      renderCell: (params) => {
        if (!params.originalEstimate) return <Typography>-</Typography>;
        return (
          <Typography>{convertToTime(params.originalEstimate)}</Typography>
        );
      }
    },
    {
      headerName: "Act Time ",
      field: "timespent",
      width: 140,
      renderCell: (params) => {
        return <Typography>{convertToTime(params.timeSpent)}</Typography>;
      }
    },
    {
      headerName: "Status",
      field: "status",
      width: 140,
      sortable: true,
      renderCell: (params) => {
        return renderChip(params.status);
      }
    },
    {
      headerName: "Est Cost",
      field: "estimateCost",
      width: 140,
      renderCell: (params) => {
        return (
          <Typography>
            {formatCurrency(params.estimatedCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Iterations",
      field: "iterations",
      width: 140,
      sortable: true,
      renderCell: (params) => {
        return <Typography>{params.iterations}</Typography>;
      }
    },
    {
      headerName: "Act Cost",
      field: "actualCost",
      width: 140,
      renderCell: (params) => {
        const { actualCost, estimatedCost } = params;
        return (
          <Typography
            style={{
              color:
                actualCost > estimatedCost
                  ? "#E82C2C"
                  : actualCost < estimatedCost
                  ? "#1ABC9C"
                  : "#000",
              fontWeight: actualCost != estimatedCost ? 600 : 500
            }}
          >
            {formatCurrency(actualCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under $",
      field: "overUnder",
      width: 140,
      renderCell: (params) => {
        const { actualCost, estimatedCost } = params;
        const difference = Math.abs(actualCost - estimatedCost);
        const isNegative = actualCost < estimatedCost;
        return (
          <Typography
            style={{
              color:
                actualCost > estimatedCost
                  ? "#E82C2C"
                  : actualCost < estimatedCost
                  ? "#1ABC9C"
                  : "#000",
              fontWeight: actualCost != estimatedCost ? 600 : 500
            }}
          >
            {isNegative
              ? `-${formatCurrency(difference)}`
              : `${formatCurrency(difference)}`}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under %",
      field: "overUnderPercentage",
      width: 140,
      renderCell: (params) => {
        const { actualCost, estimatedCost } = params;
        if (!actualCost || !estimatedCost) {
          return <Typography>0%</Typography>;
        }
        return (
          <Typography>
            {Math.round((actualCost / estimatedCost) * 100)}%
          </Typography>
        );
      }
    }
  ];

  useEffect(() => {
    if (fcb && fcb.data) {
      const calculateTotals = (rows, fields) => {
        return rows.reduce((acc, row) => {
          fields.forEach((field) => {
            acc[field] = (acc[field] || 0) + row[field];
          });
          return acc;
        }, {});
      };
      setTotals(
        calculateTotals(fcb.data, [
          "originalEstimate",
          "timeSpent",
          "estimatedCost",
          "actualCost"
        ])
      );
    }
  }, [fcb]);

  const TOTAL_VALUES = [
    {
      field: "estimate",
      renderCell: () => {
        return (
          <StyledTotalValue>
            {convertToTime(totals.originalEstimate)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "timespent",
      renderCell: () => {
        const { timeSpent, originalEstimate } = totals;
        return (
          <StyledTotalValue
            color={
              originalEstimate < timeSpent
                ? "#E82C2C"
                : originalEstimate > timeSpent
                ? "#1ABC9C"
                : "#000"
            }
          >
            {convertToTime(timeSpent)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "estimateCost",
      renderCell: () => {
        if (!totals.estimatedCost) {
          return <StyledTotalValue>$0.00</StyledTotalValue>;
        }
        return (
          <StyledTotalValue>
            ${totals.estimatedCost.toFixed(2)}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "actualCost",
      renderCell: () => {
        if (!totals.actualCost) {
          return <StyledTotalValue>$0.00</StyledTotalValue>;
        }
        return (
          <StyledTotalValue>${totals.actualCost.toFixed(2)}</StyledTotalValue>
        );
      }
    },
    {
      field: "overUnder",
      renderCell: () => {
        const { actualCost, estimatedCost } = totals;
        const difference = Math.abs(actualCost - estimatedCost);
        const isNegative = actualCost < estimatedCost;
        if (!difference) {
          return <StyledTotalValue>$0.00</StyledTotalValue>;
        }
        return (
          <StyledTotalValue>
            {isNegative
              ? `-$${difference.toFixed(2)}`
              : `$${difference.toFixed(2)}`}
          </StyledTotalValue>
        );
      }
    },
    {
      field: "overUnderPercentage",
      renderCell: () => {
        const { actualCost, estimatedCost } = totals;
        if (!actualCost || !estimatedCost) {
          return <StyledTotalValue>0%</StyledTotalValue>;
        }
        return (
          <StyledTotalValue>
            {Math.round((actualCost / estimatedCost) * 100)}%
          </StyledTotalValue>
        );
      }
    }
  ];

  return (
    <StyledGrid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "1px",
        width: "calc(100vw - 340px)",
        minHeight:
          isLoading ||
          !fcb ||
          !fcb?.data ||
          (fcb?.data as Array<ObjectAny>).length == 0
            ? "400px"
            : "0px"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              Feature Cost Breakdown
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Quick Insights related to SportGravy
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          <TimeFilter
            selectedTimeRange={selectedTimeRange}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            setSelectedTimeRange={setSelectedTimeRange}
            isQaMetric={false}
          />
        </Grid>
      </Grid>

      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isLoading}>
        {(!fcb || !fcb?.data || (fcb?.data as Array<ObjectAny>).length == 0) &&
        !isLoading ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        ) : (
          <DataGridTable
            rows={fcb && (fcb.data as any)}
            columns={COLUMNS}
            pinnedColumns={[
              "key",
              "type",
              "title",
              "assignee.person.firstName"
            ]}
            totalValues={TOTAL_VALUES}
          />
        )}
      </Loader>
    </StyledGrid>
  );
};
