import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { Edit, Visibility } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getAdvertiserInvoices } from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import SendInvoiceIcon from "@assets/icons/sendInvoiceIcon.svg";
import { GridColDef } from "@mui/x-data-grid";
import {
  AdvertiserInvoiceSchema,
  useAdminAdvertiserInvoiceInvoiceIdResendPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatCurrency } from "@utils/formatCurrency";
import { differenceInDays } from "date-fns";
import { enqueueSnackbar } from "notistack";

const dateFormat = (date) => {
  const formatedDate = new Date(date);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //@ts-ignore
  return formatedDate.toLocaleDateString("en-US", options);
};

export const AdvertiserInvoice = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [loadingInvoiceId, setLoadingInvoiceId] = useState<string | null>(null);

  const [permissions, setPermissions] = useState({
    view: false,
    create: false,
    edit: false,
    send: false
  });

  const { mutate, isLoading } = useAdminAdvertiserInvoiceInvoiceIdResendPost();

  const onEdit = (invoice) =>
    navigate(`/advertiser-invoices/${invoice.invoiceId}?mode=edit`);
  const onView = (invoice) =>
    navigate(`/advertiser-invoices/${invoice.invoiceId}?mode=view`);

  const IconStyle = {
    height: "20px",
    width: "20px"
  };

  const LIST_COLUMNS: GridColDef<AdvertiserInvoiceSchema>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0", display: "flex" }}>
            {permissions?.view && (
              <IconButton onClick={() => onView(params.row)}>
                <Visibility style={IconStyle} />
              </IconButton>
            )}
            {permissions?.edit && params.row.status !== "PAID" && (
              <IconButton onClick={() => onEdit(params.row)}>
                <Edit style={IconStyle} />
              </IconButton>
            )}
            {permissions?.edit && (
              <Loader
                isLoading={
                  isLoading && params.row.invoiceId === loadingInvoiceId
                }
              >
                <IconButton
                  onClick={() => {
                    setLoadingInvoiceId(params.row.invoiceId!);
                    mutate(
                      { invoiceId: params.row.invoiceId! },
                      {
                        onSuccess: () => {
                          enqueueSnackbar("Invoice sent successfully");
                          setLoadingInvoiceId(null);
                        },
                        onError: () => {
                          enqueueSnackbar("Failed to send invoice", {
                            variant: "error"
                          });
                          setLoadingInvoiceId(null);
                        }
                      }
                    );
                  }}
                >
                  <img
                    src={SendInvoiceIcon}
                    style={{ color: "#fff", height: "20px", width: "20px" }}
                  />
                </IconButton>
              </Loader>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Business Name",
      field: "",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.advertiser?.businessName
    },
    {
      headerName: "Sponsorship Level",
      field: "sponsorshipLevel",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.advertiser?.sponsorship?.name
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row }) =>
        row.amountPreTax && row.taxAmount
          ? formatCurrency(row.amountPreTax + row.taxAmount)
          : "-"
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.status === "NOT_SENT") return "Not Sent";
        if (row.status === "PAID") return "Sent & Paid";
        if (row.status === "UNPAID") return "Sent & Unpaid";
      }
    },
    {
      headerName: "Age",
      field: "age",
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        const diffDays = differenceInDays(
          new Date(),
          new Date(row.sentAt as Date)
        );
        if (diffDays > 7 && row.status === "UNPAID") {
          return (
            <Typography fontSize="12px" color="#E82C2C">
              {diffDays} days past due
            </Typography>
          );
        }
      }
    },
    {
      headerName: "Due On",
      field: "dueOn",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => (row.sentAt ? dateFormat(row.dueAt) : "-")
    },
    {
      headerName: "Created On",
      field: "createdOn",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        row.createdAt ? dateFormat(row.createdAt) : "-"
    },
    {
      headerName: "Sent On",
      field: "sentOn",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) => (row.sentAt ? dateFormat(row.sentAt) : "-")
    },
    {
      headerName: "Paid On",
      field: "paidOn",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) => (row.paidAt ? dateFormat(row.paidAt) : "-")
    },
    {
      headerName: "Renews On",
      field: "renewsOn",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueGetter: ({ row }) => (row.renewsAt ? dateFormat(row.renewsAt) : "-")
    }
  ];

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("live-streaming.advertisers", "VIEW");
      const create = await checkPermission("live-streaming.advertisers", "ADD");
      const edit = await checkPermission("live-streaming.advertisers", "EDIT");
      const send = await checkPermission("live-streaming.advertisers", "EDIT");
      const permission = {
        create,
        edit,
        view,
        send
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "Not Sent",
        value: "NOT_SENT"
      },
      {
        label: "Sent & Paid",
        value: "PAID"
      },
      {
        label: "Sent & Unpaid",
        value: "UNPAID"
      }
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Advertiser Invoices"
          useGet={getAdvertiserInvoices}
          columns={LIST_COLUMNS}
          getRowId={(row) => row.invoiceId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          hasActionColumn={false}
        />
      </Loader>
    </>
  );
};
