import React from "react";
import {
  Avatar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";
import { LocalOfferOutlined, LocationOnOutlined } from "@mui/icons-material";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
export const MediaDetails = ({ media }: { media: ModelMedia }) => {
  const avatarUrl = media.createdBy?.person?.avatar?.baseUrl
    ? media.createdBy.person?.avatar?.baseUrl +
      media.createdBy.person?.avatar?.path
    : undefined;
  const initialsText = media.createdBy?.person
    ? `${media.createdBy.person?.firstName?.[0]}${media.createdBy.person?.lastName?.[0]}`
    : "";
  const sharedWithData = [
    ...(media.teams || []).map((t) => ({
      name: t.name,
      type: "Team",
      img: "https://via.placeholder.com/40"
    })),
    ...(media.trainingPrograms || []).map((tp) => ({
      name: tp.name,
      type: "Training Program",
      img: "https://via.placeholder.com/40"
    }))
  ];
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#F7F9FC"
      }}
    >
      {/* Left Section */}
      <Box style={{ flex: 1 }}>
        <Typography
          style={{ color: "#0F0F0F", fontWeight: 600, fontSize: "14px" }}
        >
          {new Date(media.createdAt!).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric"
          })}
        </Typography>
        {media.sport && (
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 400,
              fontSize: "14px",
              marginTop: "5px"
            }}
          >
            {media.sport.name}
          </Typography>
        )}

        <Box display="flex" alignItems="center" mt={1}>
          <LocationOnOutlined />
          <Typography
            style={{
              color: "#007AFF",
              fontWeight: 400,
              marginLeft: "10px"
            }}
          >
            {"{Address here}"}
          </Typography>
        </Box>

        {!!media.tags?.length && (
          <Box display="flex" alignItems="center" mt={1}>
            <LocalOfferOutlined />
            <Typography
              style={{
                color: "#0F0F0F",
                fontWeight: 400,
                marginLeft: "10px"
              }}
            >
              {media.tags.join(", ")}
            </Typography>
          </Box>
        )}
        {media.createdBy && (
          <Box mt={2}>
            <Typography style={{ color: "#0F0F0F", fontWeight: 400 }}>
              Created By
            </Typography>
            <Box display="flex" mt={1} alignItems="center">
              {avatarUrl ? (
                <img src={avatarUrl} width="32px" height="32px" />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#F54748",
                    color: "white",
                    fontSize: "12px",
                    borderRadius: "8px",
                    fontWeight: 700
                  }}
                >
                  {initialsText}
                </Avatar>
              )}
              <Typography
                style={{
                  color: "#007AFF",
                  fontWeight: 400,
                  marginLeft: "10px"
                }}
              >
                {formatFullName(media.createdBy.person!)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box style={{ flex: 1, marginLeft: "32px" }}>
        <Typography style={{ color: "#0F0F0F", fontWeight: 400 }}>
          Shared with
        </Typography>

        <Box
          style={{
            maxHeight: "200px", // Set the max height of the scrollable area
            overflowY: "auto" // Enable vertical scrolling for the list only
          }}
        >
          <List dense>
            {sharedWithData.map((item, index) => (
              <ListItem key={index} disableGutters style={{ padding: "4px 0" }}>
                <ListItemAvatar>
                  <Avatar
                    src={item.img}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "8px" // Rounded corners for square avatar
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography style={{ fontSize: "14px" }}>
                      {item.name}
                    </Typography>
                  }
                  secondary={
                    <Typography style={{ fontSize: "12px", color: "#6b7280" }}>
                      {item.type}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};
