import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { StepProgressLine } from "@components/StepProgressLine";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import Sentry from "@services/Sentry";
import {
  ModelPerson,
  ModelRole,
  ModelRolePermission,
  RoleAlias,
  TrainingProgramWithOrgCreateInput,
  useAdminLevelGet,
  useAdminPermissionGet,
  useAdminRoleGet,
  useAdminRoleRoleIdUserGet,
  useAdminSportGet,
  useAdminTrainingProgramPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  CoachOption,
  CoachSelectionForm
} from "@pages/teams-programs/components/CoachSelectionForm";
import {
  ManagerOption,
  ManagerSelectionForm
} from "@pages/teams-programs/components/ManagerSelectionForm";
import {
  PlayerOption,
  PlayerSelectionForm
} from "@pages/teams-programs/components/PlayerSelectionForm";
import { ProgramDetailsForm } from "./ProgramDetailsForm";
import { PermissionSelectionForm } from "@pages/teams-programs/components/PermissionSelectionForm";
import { cleanObject } from "../../../utils/cleanObject";
import { formatDate, formatTime } from "@utils/formatDate";
import { TrainingProgramTypeOptions } from "@utils/constants";

const steps = [
  "Program Details",
  "Athlete Selection",
  "Coach Selection",
  "Manager Selection",
  "Permissions"
];

export const ProgramCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const organizationId = useRecoilValue(organizationAtom);

  if (!organizationId) {
    navigate("/organizations");
  }

  const [tab, setTab] = useState("Program Details");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const activeStepNumber = steps.findIndex((step) => step === tab);
  const form = useForm({
    mode: "onBlur"
  });

  const {
    getValues,
    trigger,
    handleSubmit,
    formState: { isValid }
  } = form;
  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const [levelId, setLevelId] = useState<string | undefined>(undefined);
  const [genders, setGenders] = useState<string[]>([]);
  const [players, setPlayers] = useState<string[]>([]);
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );
  const [defaultPermissions, setDefaultPermissions] = useState<
    (ModelRole & {
      permissions: ModelRolePermission[];
    })[]
  >([]);

  const [coaches, setCoaches] = useState<string[]>([]);
  const [managers, setManagers] = useState<string[]>([]);

  const { data: permissionRequest } = useAdminPermissionGet();

  const permissionsList = useMemo(() => {
    if (permissionRequest?.data) {
      const children = permissionRequest.data.find(
        (permission) => permission.permissionId === "training-programs"
      )?.children;
      return (
        children?.filter(
          (permission) => permission.permissionId?.includes("associated")
        ) || []
      );
    }
    return [];
  }, [permissionRequest]);

  const { data: playerUserOptions, isLoading: playerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.PLAYER, {
      sportId: sportId,
      type: "TRAINING_PROGRAM",
      organizationId: organizationId,
      genders: genders,
      ...(levelId && {
        levelId: levelId
      })
    });

  const [playerOptionsList, setPlayerOptionsList] = useState<ModelPerson[]>([]);
  useEffect(() => {
    if (playerUserOptions) {
      setPlayerOptionsList(playerUserOptions.data);
    }
  }, [playerUserOptions]);

  const { data: coachUserOptions, isLoading: coachUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.COACH, {
      organizationId: organizationId
    });
  const [coachOptionsList, setCoachOptionsList] = useState<ModelPerson[]>([]);

  useEffect(() => {
    if (coachUserOptions) {
      setCoachOptionsList(coachUserOptions.data);
    }
  }, [coachUserOptions]);
  const { data: managerUserOptions, isLoading: managerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.MANAGER, {
      organizationId: organizationId
    });
  const [managerOptionsList, setManagerOptionsList] = useState<ModelPerson[]>(
    []
  );

  useEffect(() => {
    if (managerUserOptions) {
      setManagerOptionsList(managerUserOptions.data);
    }
  }, [managerUserOptions]);
  const { data: roleOptions } = useAdminRoleGet({
    pageSize: "100",
    organizationId: organizationId,
    includeChildren: true
  });

  const coachesSubRole = useMemo(() => {
    return roleOptions?.data?.roles
      ?.find((item) => item.alias === "COACH")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const managerSubRole = useMemo(() => {
    return roleOptions?.data?.roles
      ?.find((item) => item.alias === "MANAGER")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: levelOptions, isLoading: levelOptionsLoading } =
    useApiSelectOptions({
      api: useAdminLevelGet,
      dataField: "levels",
      labelField: "name",
      valueField: "levelId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const onBackClick = () => {
    setTab(steps[activeStepNumber - 1]);
    setTimeout(() => {
      trigger();
    }, 50);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    navigate("/training-programs");
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const convertDateAndTimeArray = (inputArray) => {
    const dates = [];

    inputArray.forEach((item) => {
      const { onlyTime, startDate, startTime, endDate, endTime, customRepeat } =
        item;
      if (startDate) {
        // Start a new date object when a non-empty startDate is encountered
        const currentDate = {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          timings: []
        };
        if (!onlyTime) {
          currentDate.startDate = formatDate(startDate);
        }
        if (startTime && endTime && customRepeat) {
          currentDate.timings.push({
            startTime: formatTime(startTime),
            endTime: formatTime(endTime),
            customRepeat: customRepeat
          });
        }
        dates.push(currentDate);
      } else if (dates.length > 0) {
        // If a startDate doesn't exist but there are previous dates, add timings to the most recent date object
        const currentTimings = dates[dates.length - 1].timings;
        if (startTime && endTime && customRepeat) {
          currentTimings.push({
            startTime: formatTime(startTime),
            endTime: formatTime(endTime),
            customRepeat: customRepeat
          });
        }
      }
    });

    return dates;
  };
  const onContinueClick = () => {
    setTab(steps[activeStepNumber + 1]);
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < steps.length - 1) {
      return onContinueClick;
    } else {
      return handleSubmit(onSaveClick);
    }
  };
  const { mutate: save, isLoading: isSaving } = useAdminTrainingProgramPost();
  const onSaveClick = async (formValues) => {
    try {
      const users = [
        ...playersWithValues,
        ...coachesWithValues,
        ...managerWithValues
      ];
      const cleanedUsers = users.map((user) => cleanObject(user));
      const cleanedUsersTrimmed = cleanedUsers.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ user, ...rest }) => rest
      );
      const cleanDefaultPermissions = defaultPermissions
        .map((permission) => {
          delete permission.children;
          delete permission.createdAt;
          return cleanObject(permission);
        })
        .filter(
          (role) =>
            // if parent role is selected, allow
            // but if it's a child role, only allow if it has users
            !role.parentId ||
            (role.parentId &&
              cleanedUsers.some((user) => user.roleId === role.roleId))
        );
      const data = {
        name: formValues.name,
        sportId: sportId,
        levelId: formValues.level,
        gender: formValues.gender.length > 0 ? formValues.gender : undefined,
        organizationId: organizationId,
        users: cleanedUsersTrimmed,
        defaultPermissions: cleanDefaultPermissions,
        type: formValues.type,
        dates: convertDateAndTimeArray(getValues().dateTimeComponents)
      };
      save(
        {
          data: data as TrainingProgramWithOrgCreateInput
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Training Program added successfully!", {
              variant: "success"
            });
            navigate("/training-programs");
          },
          onError: () => {
            enqueueSnackbar("Failed to add Training Program!", {
              variant: "error"
            });
          }
        }
      );
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
      Sentry.captureException(e);
    }
  };
  return (
    <Container>
      <Toolbar title="Add Training Programs" />
      <Form>
        <Loader isLoading={isSportLoading}>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <StepProgressLine
                steps={steps}
                onEditClick={(active) => {
                  setTab(steps[active]);
                  setTimeout(() => {
                    trigger();
                  }, 50);
                }}
                activeStepNumber={activeStepNumber}
              />
            </Grid>
            {tab === "Program Details" && (
              <ProgramDetailsForm
                generatedComponents={getValues().dateTimeComponents}
                disabled={false}
                isEditing={true}
                form={form}
                isLoadingOptions={isSportLoading && levelOptionsLoading}
                sportOptions={sportOptions}
                levelOptions={levelOptions}
                typeOptions={TrainingProgramTypeOptions}
                setSportsId={setSportId}
                setGenders={setGenders}
                genders={genders}
                setLevelId={setLevelId}
              />
            )}
            {tab === "Athlete Selection" && (
              <Grid xs={12} data-testid="players_tab">
                <PlayerSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={false}
                  isLoadingUsers={playerUserOptionsLoading}
                  userOptions={playerOptionsList}
                  setUserOptions={setPlayerOptionsList}
                  positionOptions={[]}
                  type="program"
                  players={players}
                  playersWithValues={playersWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  setPlayers={setPlayers}
                  roleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "PLAYER")
                      ?.roleId as string
                  }
                  parentRoleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "PARENT")
                      ?.roleId as string
                  }
                  sportId={sportId}
                />
              </Grid>
            )}
            {tab === "Coach Selection" && (
              <Grid xs={12}>
                <CoachSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={false}
                  isLoadingUsers={coachUserOptionsLoading}
                  userOptions={coachOptionsList}
                  setUserOptions={setCoachOptionsList}
                  subRoleOptions={coachesSubRole || []}
                  coaches={coaches}
                  coachesWithValues={coachesWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setCoaches={setCoaches}
                  roleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "COACH")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Manager Selection" && (
              <Grid xs={12}>
                <ManagerSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={false}
                  isLoadingUsers={managerUserOptionsLoading}
                  userOptions={managerOptionsList}
                  setUserOptions={setManagerOptionsList}
                  subRoleOptions={managerSubRole || []}
                  managers={managers}
                  managersWithValues={managerWithValues}
                  setManagersWithValues={setManagerWithValues}
                  setManagers={setManagers}
                  roleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "MANAGER")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Permissions" && (
              <Grid xs={12}>
                <PermissionSelectionForm
                  form={form}
                  defaultPermissions={defaultPermissions}
                  setDefaultPermissions={setDefaultPermissions}
                  playersWithValues={playersWithValues}
                  coachesWithValues={coachesWithValues}
                  managerWithValues={managerWithValues}
                  players={players}
                  coaches={coaches}
                  managers={managers}
                  setManagerWithValues={setManagerWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  playerRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "PLAYER")!
                  }
                  parentRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "PARENT")!
                  }
                  coachRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "COACH")!
                  }
                  coachSubRoles={coachesSubRole || []}
                  managerRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "MANAGER")!
                  }
                  managerSubRoles={managerSubRole || []}
                  permissions={permissionsList}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
      </Form>
      <Footer
        additionalBtns={[
          activeStepNumber > 0 ? (
            <Button
              variant="admin-secondary"
              type="button"
              onClick={onBackClick}
              isLoading={isSportLoading}
              key={Math.random().toString()}
            >
              Back
            </Button>
          ) : null,
          <Button
            variant="admin-secondary"
            type="button"
            onClick={onCancelClick}
            isLoading={isSportLoading}
            key={Math.random().toString()}
          >
            Cancel
          </Button>,
          <Button
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            disabled={!isValid || isSaving}
            isLoading={isSaving}
            key={Math.random().toString()}
          >
            {/* {activeStepNumber < steps.length - 1 ? "Continue" : "Save"} */}
            {"Save & Continue"}
          </Button>
        ]}
        isDisabled={!isValid}
        isLoading={isSportLoading}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
