import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Loader } from "@components/crud/Loader";
import { styled } from "@mui/material";
import { useAdminCrmOrderAgreementAgreementIdCreateSession } from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";

const StyledDiv = styled("div")`
  background-color: #fff;
  width: 75%;
  max-width: 850px;
  margin: 0 auto;
  padding: 24px;
  height: 100vh;
  @media (max-width: 476px) {
    width: 90%;
  }
`;

export const Contract = ({
  type,
  nextClick,
  setMSASigned,
  MSASigned,
  setContractSigned,
  contractSigned,
  agreementId
}: {
  type: "MSA" | "CONTRACT";
  nextClick: () => void;
  setMSASigned?: (value: boolean) => void;
  MSASigned?: boolean;
  setContractSigned?: (value: boolean) => void;
  contractSigned?: boolean;
  agreementId: string;
}) => {
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const { data: session, isLoading: isSessionLoading } =
    useAdminCrmOrderAgreementAgreementIdCreateSession(agreementId as string, {
      readOnly: false
    });

  useEffect(() => {
    if (session && session.data && session?.data?.session) {
      setSessionId(session?.data?.session?.id as string);
    }
  }, [session]);

  useEffect(() => {
    const handleMessage = (event) => {
      const eventType = event.data && event.data.type;
      const payload = event.data && event.data.payload;

      switch (eventType) {
        case "session_view.document.completed":
          if (type === "MSA" && setMSASigned) setMSASigned(false);
          if (type === "CONTRACT" && setContractSigned)
            setContractSigned(false);
          break;
        case "session_view.document.exception":
          console.log("Exception during document finalization");
          console.log(payload);
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <Container style={{ background: "#f3f4f7" }}>
      <StyledDiv>
        <Loader isLoading={isSessionLoading}>
          <iframe
            src={`https://app.pandadoc.com/s/${sessionId}/`}
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </Loader>
      </StyledDiv>
      <Footer
        saveBtnLabel="Next"
        saveBtnClick={nextClick}
        isDisabled={type === "MSA" ? MSASigned : contractSigned}
      />
    </Container>
  );
};

export default Contract;
