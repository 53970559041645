import { Box } from "@mui/system";
import { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { LoadingSpinner } from "@components/LoadingSpinner";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/"
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  min-height: 320px;
  .document {
    padding-right: 33px;
  }
`;

const StyledPage = styled(Page)`
  &.pdf-page {
    background-color: #efefef !important;
    canvas {
      width: 300px !important;
      height: 430px !important;
      border-radius: 5px;
    }
  }
`;

const PageCount = styled(Box)`
  z-index: 1;
  font-weight: 400;
  font-size: 11px;
  padding: 10px 0;
`;

const CurrentPage = styled(Box)`
  padding: 10px;
`;

const StyledPageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: auto;
  width: calc(100% + 24px);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const FeedPDFViewer = ({ src }: { src: ModelMedia }) => {
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };
  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        left: "-15px",
        width: "calc(100% + 45px)",
        backgroundColor: "#EFEFEF"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 10px"
        }}
      >
        <PageCount>
          <Grid container>
            <Grid>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px"
                }}
              >
                {src?.metadata?.documentName}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                style={{
                  marginLeft: "8px",
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "#666"
                }}
              >
                {numPages}
                {numPages !== undefined && numPages > 1 ? " Pages" : " Page"}
              </Typography>
            </Grid>
          </Grid>
        </PageCount>
        <CurrentPage>
          <Typography
            style={{ fontWeight: 400, fontSize: "10px", color: "#666" }}
          >
            Page 1
          </Typography>
        </CurrentPage>
      </div>
      <StyledBox
        style={{
          display: !numPages ? "flex" : "block",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Document
          className={"document"}
          file={src?.baseUrl && src?.path ? src?.baseUrl + src?.path : ""}
          options={options}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<LoadingSpinner />}
        >
          <StyledPageContainer
            style={{
              justifyContent: numPages && numPages < 2 ? "center" : "flex-start"
            }}
          >
            {[...Array(numPages)].map((_, index) => (
              <StyledPage
                loading={<LoadingSpinner />}
                key={index}
                className={"pdf-page"}
                scale={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                pageNumber={index + 1}
                width={300}
                height={300}
                devicePixelRatio={5}
              />
            ))}
          </StyledPageContainer>
        </Document>
      </StyledBox>
    </div>
  );
};

export default memo(FeedPDFViewer);
