import { Loader } from "@components/crud/Loader";
import { Box, Grid, styled, Typography } from "@mui/material";
import {
  useAdminRoleGet,
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { Footer } from "@components/crud/Footer";
import { FormInput } from "@components/FormInput";
import { Button } from "@components/Button";
import { Edit } from "@mui/icons-material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { hasPermission } from "@services/Casbin";

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  padding: 1.5rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
  padding-bottom: 16px;
`;

export const ProductEngineering = () => {
  const [editEnabled, setEditEnabled] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    reset,
    control,
    getValues,
    setValue,
    formState: { isDirty }
  } = useForm({
    mode: "onBlur"
  });
  const { data: settings, isLoading: isLoading } = useConfigGet();

  const { data: roles, isLoading: isRoleLoading } = useAdminRoleGet({
    type: "SYSTEM",
    includeChildren: true,
    pageSize: "100"
  });

  const EmailToOptions = useMemo(() => {
    const getOptions = (roles) => {
      return roles
        .map((role) => {
          let options = [
            {
              label: role.name,
              value: role.roleId
            }
          ];

          if (role.children) {
            options = [...options, ...getOptions(role.children)];
          }

          return options;
        })
        .flat();
    };

    if (roles && roles.data && roles.data.roles) {
      return getOptions(roles.data.roles);
    }

    return [];
  }, [roles]);

  useEffect(() => {
    if (settings) {
      const formData = {};
      settings.data.forEach((item) => {
        if (item.key) {
          const fieldName = item.key.replace("pne.", "pne_");
          let value = item.value;
          if (fieldName === "pne_chart-threshold") {
            (value as number) *= 100;
          }
          formData[fieldName] = value;
        }
      });

      reset(formData);
    }
  }, [settings]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setDisabled(!edit);
    };
    fetchPermissions();
  }, []);

  const { mutate: save, isLoading: isSaving } = useConfigPut();
  const onSave = () => {
    const data = getValues();
    data["pne_chart-threshold"] = data["pne_chart-threshold"] / 100;
    const formattedData: Array<{ key: string; value: string }> = [];
    Object.keys(data).forEach((key) => {
      const newKey = key.replace("pne_", "pne.");
      if (data[key]) formattedData.push({ key: newKey, value: data[key] });
    });
    save(
      {
        data: formattedData
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
          setEditEnabled(false);
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Grid item container direction="column" spacing="15px" padding="0 24px">
      <Loader isLoading={isLoading || isRoleLoading}>
        <StyledContainer>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <StyledTypography>
                SportsGravy Product and Engineering
              </StyledTypography>
            </Grid>
            {!editEnabled && !disabled ? (
              <Grid container item xs={6} justifyContent="flex-end">
                <Button
                  variant="admin-primary"
                  onClick={() => setEditEnabled(true)}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              </Grid>
            ) : (
              <Grid xs={6}></Grid>
            )}
            <Grid item md={6} sm={12} data-testid="UI_PASSED">
              <StyledTypography>
                QA Test case ui status = PASSED
              </StyledTypography>
              <FormMultiSelect
                control={control}
                name="pne_ui-passed-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="UI_FAILED">
              <StyledTypography>
                QA Test case ui status = FAILED
              </StyledTypography>

              <FormMultiSelect
                control={control}
                name="pne_ui-failed-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="UI_BLOCKED">
              <StyledTypography>
                QA Test case ui status = BLOCKED
              </StyledTypography>
              <FormMultiSelect
                control={control}
                name="pne_ui-blocked-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="UI_ONHOLD">
              <StyledTypography>
                QA Test case ui status = ONHOLD
              </StyledTypography>

              <FormMultiSelect
                control={control}
                name="pne_ui-hold-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="FN_PASSED">
              <StyledTypography>
                QA Test case fn status = PASSED
              </StyledTypography>
              <FormMultiSelect
                control={control}
                name="pne_fn-passed-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="FN_FAILED">
              <StyledTypography>
                QA Test case fn status = FAILED
              </StyledTypography>

              <FormMultiSelect
                control={control}
                name="pne_fn-failed-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="FN_BLOCKED">
              <StyledTypography>
                QA Test case fn status = BLOCKED
              </StyledTypography>
              <FormMultiSelect
                control={control}
                name="pne_fn-blocked-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="FN_ONHOLD">
              <StyledTypography>
                QA Test case fn status = ONHOLD
              </StyledTypography>

              <FormMultiSelect
                control={control}
                name="pne_fn-onhold-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <br />
            <Grid item md={6} sm={12} data-testid="CHART_UPDATES">
              <StyledTypography>Work Chart Updates</StyledTypography>
              <FormMultiSelect
                control={control}
                name="pne_chart-update-email"
                label="Send Email To"
                options={EmailToOptions}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item md={6} sm={12} data-testid="CHART_THRESHOLD">
              <StyledTypography>&nbsp;</StyledTypography>
              <FormInput
                control={control}
                name="pne_chart-threshold"
                label="Work Chart Threshold %"
                type="number"
                onChange={(e) => {
                  //@ts-ignore
                  setValue("pne_chart-threshold", parseInt(e.target.value));
                }}
                disabled={!editEnabled}
              />
            </Grid>
          </Grid>
        </StyledContainer>
        {editEnabled && (
          <Footer
            saveBtnClick={onSave}
            isDisabled={!isDirty || !editEnabled || isSaving}
            isLoading={isSaving}
            cancelBtnClick={() => setOpenCancelDialog(true)}
          />
        )}
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => setEditEnabled(false)}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Loader>
    </Grid>
  );
};
