import { Button, styled } from "@mui/material";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import CropOriginalRoundedIcon from "@mui/icons-material/CropOriginalRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { PDFIcon, VideoFileIcon } from "@components/Icons";
const OneLineText = styled("div")`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const EmailAttachment = ({
  attachment,
  onClick
}: {
  attachment: ModelMedia;
  onClick?: () => void;
}) => {
  const fileName = attachment.metadata?.fileName;

  const extractFileName = () => {
    const path = attachment.path!;
    const parts = path.split("/");
    return parts[parts.length - 1];
  };
  return (
    <Button
      style={{
        borderRadius: "100px",
        padding: "6px 12px",
        border: "1px solid #CBD5E1",
        display: "flex",
        alignItems: "center",
        width: "170px",
        gap: "8px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "36px",
        cursor: "pointer",
        textTransform: "none"
      }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {attachment.type === "IMAGE" ? (
        <CropOriginalRoundedIcon style={{ color: "#2B337A" }} />
      ) : attachment.type === "VIDEO" ? (
        <VideoFileIcon style={{ color: "#2B337A" }} />
      ) : attachment.mimeType === "application/pdf" ? (
        <PDFIcon style={{ color: "#2B337A" }} />
      ) : (
        <InsertDriveFileOutlinedIcon style={{ color: "#2B337A" }} />
      )}
      <OneLineText style={{ fontSize: "14px", fontWeight: 400 }}>
        {fileName || extractFileName()}
      </OneLineText>
    </Button>
  );
};
