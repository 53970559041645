import { Grid, styled, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormSwitch } from "@components/FormSwitch";
import { hasPermission } from "@services/Casbin";
import { Toolbar } from "@components/crud/Toolbar";
import { Edit } from "@mui/icons-material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";

const StyledGrid = styled(Grid)`
  padding: 0 24px;
  .toolbar {
    padding: 24px 0 0 0;
  }
`;

export const FSGOAdvertiserSettings = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const { reset, control, setValue, getValues } = form;
  const [disabled, setDisabled] = useState<boolean>(true);
  const [permission, setPermission] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { data: settings, isLoading: loading } = useConfigGet();

  const { mutate: save, isLoading: isSaving } = useConfigPut();
  const onSave = () => {
    const data = [
      {
        key: "live-stream.advertiser-approval-required",
        value: getValues("live-stream.advertiser-approval-required")
      },
      {
        key: "live-stream.advertisement-live-on-approval",
        value: getValues("live-stream.advertisement-live-on-approval")
      }
    ];
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setPermission(edit);
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (settings) {
      reset({
        "live-stream.advertiser-approval-required": settings.data.find(
          (s) => s.key === "live-stream.advertiser-approval-required"
        )?.value,
        "live-stream.advertisement-live-on-approval": settings.data.find(
          (s) => s.key === "live-stream.advertisement-live-on-approval"
        )?.value
      });
    }
  }, [settings]);

  return (
    <Loader isLoading={loading}>
      <StyledGrid item container direction="column" rowSpacing="24px">
        {permission && disabled && (
          <Toolbar
            title=""
            addBtnClick={() => setDisabled(false)}
            addBtnLabel="Edit"
            addBtnIcon={<Edit />}
          />
        )}
        {settings && (
          <Grid item container direction="column" spacing="20px">
            <Grid item marginTop="20px">
              <Typography
                style={{
                  fontSize: "12px",
                  font: "inter",
                  lineHeight: "14.52px",
                  fontWeight: 400,
                  opacity: "50%",
                  marginRight: "10px"
                }}
              >
                ADVERTISERS SETTINGS
              </Typography>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item marginTop="10px">
                <Typography
                  style={{
                    color: "#1E2941",
                    fontSize: "13px",
                    font: "inter",
                    lineHeight: "normal",
                    width: "212px"
                  }}
                >
                  Require Advertiser Approval for Advertisements
                </Typography>
              </Grid>
              <Grid item>
                <FormSwitch
                  disabled={disabled}
                  onChange={(e) => {
                    setValue(
                      `live-stream.advertiser-approval-required`,
                      e.target.checked
                    );
                  }}
                  name={`live-stream.advertiser-approval-required`}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item marginTop="10px">
                <Typography
                  style={{
                    color: "#1E2941",
                    fontSize: "13px",
                    font: "inter",
                    lineHeight: "normal",
                    width: "212px"
                  }}
                >
                  Upon advertisement approval automatically set advertisement
                  status to active
                </Typography>
              </Grid>
              <Grid item>
                <FormSwitch
                  disabled={disabled}
                  onChange={(e) => {
                    setValue(
                      `live-stream.advertisement-live-on-approval`,
                      e.target.checked
                    );
                  }}
                  name={`live-stream.advertisement-live-on-approval`}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </StyledGrid>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => setDisabled(true)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {!disabled && (
        <Footer
          saveBtnClick={onSave}
          isLoading={isSaving}
          isDisabled={isSaving}
          saveBtnLabel="Save"
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
    </Loader>
  );
};
