/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccountCallerDetails,
  CallEmailTemplateContext
} from "@templates/CallEmailTemplate";
import { useContext, useState } from "react";
import CallEndRoundedIcon from "@mui/icons-material/CallEndRounded";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import MicNoneRoundedIcon from "@mui/icons-material/MicNoneRounded";
import { FormSelect } from "@components/FormSelect";
import { ACTIVITY_OUTCOME, CRM_ACTIVITY_NEXT_ACTIONS } from "@utils/constants";
import { Container } from "@components/crud/Container";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useForm } from "react-hook-form";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { Button } from "@components/Button";
import { styled } from "@mui/material";
import { updateCRMActivity } from "@services/Network";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

const CallActionButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const CallActionButton = styled("div")`
  height: 68px;
  width: 68px;
  background-color: #e82c2c;
  padding: 18px;
  border-radius: 99px;
`;

const StyledGrid = styled(Grid)`
  button {
    width: 100%;
  }
`;

const IconStyle = {
  height: "32px",
  width: "32px",
  color: "#fff"
};

export const LiveCall = ({ account }: { account: AccountCallerDetails }) => {
  dayjs.extend(timezone);
  const {
    control,
    getValues,
    trigger,
    formState: { isValid }
  } = useForm();
  const {
    setCallInstance,
    setCallAccepted,
    disconnectCall,
    callInstance,
    setCallerDetails,
    setCallStatus,
    callStatus
  } = useContext(CallEmailTemplateContext);
  const [isMuted, setIsMuted] = useState(false);
  const [formFilled, setFormFilled] = useState(false);
  const mutation = updateCRMActivity();

  const toggleMute = () => {
    setIsMuted(!isMuted);
    callInstance?.mute(!isMuted);
  };
  return (
    <>
      {callStatus && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: callStatus == "DISCONNECTED" ? "10px" : "40px",
            marginTop: callStatus == "DISCONNECTED" ? "0px" : "24px",
            marginBottom: callStatus == "DISCONNECTED" ? "0px" : "24px"
          }}
        >
          {callStatus == "ACCEPTED" && (
            <CallActionButtonContainer onClick={toggleMute}>
              <CallActionButton
                style={{
                  backgroundColor: isMuted ? "#2B337A" : "#F3F4F7"
                }}
              >
                {isMuted ? (
                  <MicNoneRoundedIcon style={IconStyle} />
                ) : (
                  <MicOffOutlinedIcon style={{ ...IconStyle, color: "#000" }} />
                )}
              </CallActionButton>
              <span style={{ color: "#666666", fontSize: "14px" }}>
                {isMuted ? "Unmute" : "Mute"}
              </span>
            </CallActionButtonContainer>
          )}
          {callStatus != "DISCONNECTED" && (
            <CallActionButtonContainer
              onClick={() => {
                disconnectCall();
                if (formFilled) {
                  setCallAccepted(false);
                  setCallerDetails(undefined);
                  setCallStatus(undefined);
                  setCallInstance(undefined);
                }
              }}
            >
              <CallActionButton>
                <CallEndRoundedIcon style={IconStyle} />
              </CallActionButton>
              <span style={{ color: "#666666", fontSize: "14px" }}>
                Hang Up
              </span>
            </CallActionButtonContainer>
          )}
        </div>
      )}
      <Container>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <FormSelect
              control={control}
              label="Call Outcome"
              name="outcome"
              options={ACTIVITY_OUTCOME}
              required
              rules={{ required: "Call Outcome is required" }}
            />
          </Grid>
          <Grid xs={6}>
            <FormSelect
              control={control}
              options={CRM_ACTIVITY_NEXT_ACTIONS}
              name="nextAction"
              label="Next Action"
              required
              rules={{ required: "Next Action is required" }}
            />
          </Grid>
          <Grid xs={6}>
            <FormDatePicker
              control={control}
              name="nextActionDate"
              label="Date"
              required
              rules={{ required: "Next Action Date is required" }}
              onChange={() => trigger("nextActionDate")}
            />
          </Grid>
          <Grid xs={12}>
            <FormInput
              control={control}
              name="notes"
              label="Notes"
              type="text"
              rows={4}
              multiline
            />
          </Grid>
          <StyledGrid xs={12}>
            <Button
              variant="admin-primary"
              disabled={!isValid || mutation.isLoading}
              isLoading={mutation.isLoading}
              onClick={async () => {
                setFormFilled(true);
                if (account?.activityId) {
                  mutation.mutate(
                    {
                      activityId: account?.activityId,
                      data: {
                        accountId: account.accountId,
                        type: "CALL",
                        relatesTo: account?.relatesTo || "ACCOUNT",
                        status: "COMPLETED",
                        date: new Date(),
                        timezone: dayjs.tz.guess(),
                        outcome: getValues("outcome"),
                        nextAction: getValues("nextAction"),
                        nextActionDate: getValues("nextActionDate"),
                        notes: getValues("notes"),
                        externalParticipants:
                          account.contactId !== undefined
                            ? [
                                {
                                  contactId: account.contactId,
                                  phone: account.contact?.contactPhone as string
                                }
                              ]
                            : []
                      }
                    },
                    {
                      onSuccess: () => {
                        if (callStatus === "DISCONNECTED") {
                          setCallAccepted(false);
                          setCallerDetails(undefined);
                          setCallStatus(undefined);
                          setCallInstance(undefined);
                        }
                      }
                    }
                  );
                  return;
                } else {
                  if (callStatus === "DISCONNECTED") {
                    setCallAccepted(false);
                    setCallerDetails(undefined);
                    setCallStatus(undefined);
                    setCallInstance(undefined);
                  }
                }
              }}
            >
              Save
            </Button>
          </StyledGrid>
        </Grid>
      </Container>
    </>
  );
};
