import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import Grid from "@mui/system/Unstable_Grid";
import {
  useAdminAccountAccountIdKeyDataGet,
  useAdminAccountAccountIdKeyDataPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const RATINNG_OPTIONS = [
  { label: "AAA", value: "AAA" },
  { label: "AA", value: "AA" },
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" }
];

interface FormValues {
  account: string;
  address: string;
  orgSalesRating: string;
  noOfAthletes: string | undefined;
}

export const KeyDataEdit = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      account: "",
      address: "",
      orgSalesRating: "",
      noOfAthletes: undefined
    }
  });
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { data: keyData, isLoading: isLoading } =
    useAdminAccountAccountIdKeyDataGet(accountId!);

  useEffect(() => {
    if (keyData) {
      reset({
        account: keyData.data.name,
        address: keyData.data.officeAddress,
        orgSalesRating: keyData.data.orgSalesRating,
        noOfAthletes: keyData.data?.numberOfAthletes?.toString() || undefined
      });
    }
  }, [keyData]);

  const { mutate: save, isLoading: isSaving } =
    useAdminAccountAccountIdKeyDataPut();

  const saveHandler = async (formValues: FormValues) => {
    try {
      save(
        {
          accountId: accountId!,
          data: {
            orgSalesRating: formValues.orgSalesRating,
            numberOfAthletes: parseInt(formValues.noOfAthletes as string)
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Key data edited successfully!", {
              variant: "success"
            });
            navigate("/crm/key-data");
          },
          onError: () => {
            enqueueSnackbar("Failed to edit key data!", {
              variant: "error"
            });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to edit key data!", {
        variant: "error"
      });
    }
  };

  return (
    <Container>
      <Toolbar title="Edit Key Data for Accounts" />
      <Loader isLoading={isLoading}>
        <Form>
          <Loader isLoading={false}>
            <Grid container spacing={3}>
              <Grid xs={6}>
                <FormInput
                  name="account"
                  control={control}
                  label="Account"
                  required
                  type="text"
                  disabled
                />
              </Grid>
              <Grid xs={12}>
                <FormInput
                  name="address"
                  control={control}
                  label="Address"
                  required
                  type="text"
                  disabled
                />
              </Grid>
              <Grid xs={6}>
                <FormSelect
                  name="orgSalesRating"
                  control={control}
                  options={RATINNG_OPTIONS}
                  label="Org Sales Rating"
                  required
                  type="text"
                  rules={{ required: "Org Sales Rating is required" }}
                />
              </Grid>
              <Grid xs={6}>
                <FormInput
                  name="noOfAthletes"
                  control={control}
                  label="No. of Athletes"
                  required
                  type="number"
                  rules={{
                    required: "No. of Athletes is required",
                    validate: (value) =>
                      value > 0 || "No. of Athletes should be greater than 0"
                  }}
                />
              </Grid>
            </Grid>
          </Loader>
        </Form>

        <Footer
          saveBtnClick={handleSubmit(saveHandler)}
          cancelBtnClick={() => setOpenCancelDialog(true)}
          isDisabled={!isValid || isSaving}
          isLoading={isSaving}
        />
      </Loader>
      <ConfirmationDialog
        open={openCancelDialog}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={() => navigate("/crm/key-data")}
        onCancel={() => setOpenCancelDialog(false)}
      />
    </Container>
  );
};
