import { Form } from "@components/crud/Form";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { PasswordInput } from "@components/PasswordInput";
import { WarningAmber } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { getCountries } from "@services/Network";
import {
  CRM_ORDER_BANK_ACH_TYPES,
  CRM_ORDER_PAYMENT_TYPES
} from "@utils/constants";
import { UseFormReturn } from "react-hook-form";

export const PaymentDetailsForm = ({
  form,
  index,
  isFailedPayment,
  paymentType,
  setPaymentType,
  disabled
}: {
  index?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  isFailedPayment?: boolean;
  paymentType: string;
  setPaymentType?: (val: string) => void;
  disabled?: boolean;
}) => {
  const { data: countries, isLoading: countryLoading } = getCountries({
    staleTime: Infinity
  });
  const { control } = form;
  return (
    <Form>
      <Grid item container direction="column" spacing="20px">
        <Grid item container direction="row" spacing="20px">
          <Grid item xs={6}>
            <FormSelect
              control={control}
              name={`details[${index}].type`}
              label="Payment Type"
              required
              rules={{
                required: "Payment Type is required"
              }}
              disabled={disabled}
              onChange={(e) => {
                if (setPaymentType) setPaymentType(e.target.value);
              }}
              options={CRM_ORDER_PAYMENT_TYPES}
            />
          </Grid>
          {isFailedPayment && (
            <Grid
              item
              container
              direction="row"
              marginLeft="20px"
              height="50px"
              width="48%"
              alignSelf="flex-end"
              marginBottom="5px"
              style={{
                backgroundColor: "#FFEEEE",
                borderLeft: "5px solid rgba(232, 44, 44, 1)"
              }}
            >
              <Grid
                item
                style={{
                  marginTop: "-7px"
                }}
              >
                <WarningAmber htmlColor="rgba(232, 44, 44, 1)" />
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "-7px",
                  marginLeft: "10px"
                }}
              >
                Payment Failed
              </Grid>
            </Grid>
          )}
        </Grid>
        {paymentType !== "BANK_ACH" ? (
          <Grid item container direction="column" spacing="20px">
            <Grid item container direction="row" spacing="20px">
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  name={`details[${index}].number`}
                  label="Card Number"
                  control={control}
                  disabled={disabled}
                  required
                  rules={{
                    required: "Card Number is required"
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormDatePicker
                  name={`details[${index}].expirationDate`}
                  label="Expiration Date"
                  views={["year", "month"]}
                  format="MM/yyyy"
                  control={control}
                  required
                  rules={{
                    required: "Expiration Date is required"
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}>
                <PasswordInput
                  name={`details[${index}].cvv`}
                  label="CVV"
                  control={control}
                  required
                  rules={{
                    required: "CVV is required"
                  }}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="20px">
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  name={`details[${index}].cardName`}
                  label="Name on Card"
                  control={control}
                  disabled={disabled}
                  rules={{
                    required: "Name on Card is required"
                  }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <FormSelect
                  name={`details[${index}].country`}
                  label="Country"
                  control={control}
                  required
                  rules={{
                    required: "Country is required"
                  }}
                  disabled={disabled}
                  isLoading={countryLoading}
                  options={
                    countries
                      ? countries.map((c) => ({
                          label: c.name,
                          value: c.countryAltId
                        }))
                      : []
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormInput
                  type="text"
                  name={`details[${index}].zipCode`}
                  label="Zip Code"
                  control={control}
                  disabled={disabled}
                  rules={{
                    required: "Zip Code is required"
                  }}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item container direction="column" spacing="20px">
            <Grid item container direction="row" spacing="20px">
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  name={`details[${index}].number`}
                  label="Account Number"
                  control={control}
                  disabled={disabled}
                  rules={{
                    required: "Account Number is required"
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  name={`details[${index}].routingNumber`}
                  label="Routing Number"
                  control={control}
                  rules={{
                    required: "Routing Number is required"
                  }}
                  disabled={disabled}
                  required
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="20px">
              <Grid item xs={6}>
                <FormSelect
                  name={`details[${index}].accountType`}
                  label="Account Type"
                  control={control}
                  required
                  rules={{
                    required: "Account Type is required"
                  }}
                  disabled={disabled}
                  options={CRM_ORDER_BANK_ACH_TYPES}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  name={`details[${index}].accountName`}
                  label="Account Holder's Name"
                  control={control}
                  disabled={disabled}
                  required
                  rules={{
                    required: "Account Holder's Name is required"
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <FormInput
            control={control}
            label="Billing Instructions"
            name={`details[${index}].billingInstructions`}
            type="text"
            multiline
            rows={4}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
