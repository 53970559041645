import { Fragment } from "react";
import { styled } from "@mui/material";
import { useMemo } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from "@mui/lab/TimelineOppositeContent";
import formatFullName from "@utils/formatFullName";
import { Check, Close, PendingActions } from "@mui/icons-material";
import { ModelOpportunityApproval } from "@sportsgravyengineering/sg-api-react-sdk";

const dateFormatActivity = (iso) => {
  const date = new Date(iso);
  const formattedDate = date.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short"
  });

  return formattedDate;
};

const StyledTimeLineTime = styled(TimelineOppositeContent)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  padding-left: 0;
  padding-top: 15px;,
  min-width: 80px !important;
  flex: 0 !important; 
  min-width: 80px;
`;

const StyledTimelineContent = styled(TimelineContent)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  padding-left: 24px;

  padding-top: 8px;
`;

const StyledTimelineDot = styled(TimelineDot)`
  margin: 0;
  background-color: #f7f7f7;
  padding: 12px;
  border: 1px solid #e5e5e5;
  box-shadow: none;
`;

const StyledTimelineDateBox = styled(TimelineDot)`
  height: 34px;
  padding: 8px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  background-color: #f7f7f7;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid #e5e5e5;
  width: 150px;
  color: #000000;
  justify-content: center;
  margin: 0;
  position: relative;
  left: -32px;
  box-shadow: none;
`;

const StyledTimelineConnector = styled(TimelineConnector)`
  height: 48px;
  background-color: #e5e5e5;
`;

const ColumnFlexBox = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const dateFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC"
  });
};

const timeFormat = (isoString: string): string => {
  const date = new Date(isoString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });
};

const StatusTypes = [
  {
    label: "Rejected",
    value: "REJECTED",
    icon: <Close htmlColor="red" />
  },
  {
    label: "Approved",
    value: "APPROVED",
    icon: <Check htmlColor="#1ABC9C" />
  },
  {
    label: "Pending",
    value: "PENDING",
    icon: <PendingActions htmlColor="black" />
  }
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ApprovalHistoryDetails = ({ details }: { details: any }) => {
  const approvalContent = (approval: ModelOpportunityApproval) => {
    return (
      <ColumnFlexBox>
        {approval.status !== "PENDING" ? (
          <div style={{ display: "inline" }}>
            <span>{`SportsGravy ${
              approval.createdBy?.jobTitle?.name || "{{Job Title}}"
            }, `}</span>
            <span>{`${formatFullName(approval.createdBy?.person)} `}</span>
            <span>{`${
              approval.status === "APPROVED" ? "Approved" : "Rejected"
            } this Opportunity on ${dateFormatActivity(
              approval.createdAt
            )} `}</span>
          </div>
        ) : (
          <div style={{ display: "inline" }}>
            <span>{`Opportunity Owner, `}</span>
            <span>{`${formatFullName(approval.createdBy?.person)} `}</span>
            <span>{`Submitted this opportunity for approval on ${dateFormatActivity(
              approval.createdAt
            )} `}</span>
          </div>
        )}
        <div style={{ marginLeft: "10px", marginTop: "5px" }}>
          <div>
            <strong>No. of Yearly Athlete Registrations: </strong>
            <span>{`${approval.numberOfAthletes} Athletes`}</span>
          </div>
          <div style={{ marginTop: "5px" }}>
            <strong>Pricing Details: </strong>
            <span>{`$${approval.pricePerAthleteOffered} Per Athlete, Per Registration`}</span>
          </div>
          <div style={{ marginTop: "5px" }}>
            <strong>Estimated Contract Value: </strong>
            <span>{`$${approval.amount?.toFixed(2) || ""}`}</span>
          </div>
          {approval.notes && (
            <div style={{ marginTop: "5px" }}>
              <strong>Explanation: </strong>
              <span>{approval.notes || "No explanation provided"}</span>
            </div>
          )}
        </div>
      </ColumnFlexBox>
    );
  };

  const formattedApprovals = useMemo(() => {
    return details.reduce((acc, approval) => {
      const date = approval.createdAt.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(approval);
      return acc;
    }, {});
  }, [details]);
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2
        },
        padding: "24px 0 0 0"
      }}
    >
      {Object.keys(formattedApprovals).map((date, index) => (
        <Fragment key={date}>
          <TimelineItem key={index}>
            <TimelineOppositeContent
              style={{ flex: 0 }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
              <StyledTimelineDateBox>{dateFormat(date)}</StyledTimelineDateBox>
              <StyledTimelineConnector />
            </TimelineSeparator>
            <StyledTimelineContent></StyledTimelineContent>
          </TimelineItem>
          {formattedApprovals[date].map((approval, index) => (
            <TimelineItem key={index}>
              <StyledTimeLineTime>
                {timeFormat(approval.createdAt)}
              </StyledTimeLineTime>
              <TimelineSeparator>
                <StyledTimelineDot>
                  {StatusTypes.find((type) => type.value === approval.status)
                    ?.icon || null}
                </StyledTimelineDot>
                <StyledTimelineConnector />
              </TimelineSeparator>
              <StyledTimelineContent
                style={{
                  marginBottom: "24px"
                }}
              >
                {approvalContent(approval)}
              </StyledTimelineContent>
            </TimelineItem>
          ))}
        </Fragment>
      ))}
    </Timeline>
  );
};
