import React, { useState } from "react";
import { Box, Paper, Typography, TextField } from "@mui/material";
import { ModelEmailTemplate } from "@sportsgravyengineering/sg-api-react-sdk";
import { extractTextFromDelta } from "@utils/convertDeltaToText";
import { Loader } from "@components/crud/Loader";

const CustomEmailDropdown = ({
  selectedTemplate,
  setSelectedTemplate,
  templateOptions,
  isLoadingOptions,
  setSelectedTemplateId
}: {
  selectedTemplate: string;
  setSelectedTemplate: (val: string) => void;
  templateOptions: { departmentId: string; values: ModelEmailTemplate[] }[];
  isLoadingOptions: boolean;
  setSelectedTemplateId: (val: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const togglePopup = () => {
    setOpen(!open);
  };

  const handleSelectTemplate = (template: ModelEmailTemplate) => {
    setSelectedTemplate(template.subject);
    setSelectedTemplateId(template.templateId);
    setOpen(false);
  };

  return (
    <Loader isLoading={!!isLoadingOptions}>
      <Box style={{ position: "relative", width: "100%" }}>
        <TextField
          value={selectedTemplate}
          onClick={togglePopup}
          placeholder="Select an email template"
          style={{
            width: "100%",
            height: "55px",
            cursor: "pointer"
          }}
        />
        {open && (
          <Paper
            sx={{
              position: "absolute",
              top: "65px",
              width: "100%",
              maxHeight: "300px",
              overflowY: "auto",
              zIndex: 100000,
              borderRadius: "10px",
              boxShadow: "0px 1px 3px 0px #00000026",
              padding: "10px"
            }}
          >
            {templateOptions.map((templates, idx) => (
              <Box key={idx} marginBottom="10px">
                <Typography
                  variant="permissionNames"
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "15%",
                    lineHeight: "14.52px",
                    opacity: "50%",
                    marginLeft: "10px",
                    marginBottom: "15px",
                    textTransform: "uppercase"
                  }}
                >
                  {templates.values?.[0]?.department?.name}
                </Typography>
                {templates.values.map((template) => (
                  <Box
                    key={template.templateId}
                    onClick={() => handleSelectTemplate(template)}
                    sx={{
                      padding: "10px",
                      marginBottom: "5px",
                      borderRadius: 1,
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#f5f5f5"
                      }
                    }}
                  >
                    <Typography variant="body1">{template.subject}</Typography>
                    <Typography variant="body2" sx={{ color: "#999" }}>
                      {extractTextFromDelta(template.body)}
                    </Typography>
                  </Box>
                ))}
                {idx !== templateOptions.length - 1 && (
                  <Box
                    style={{
                      width: "100%",
                      backgroundColor: "#E2E2E2",
                      borderTop: "1px solid #E2E2E2",
                      height: "1px"
                    }}
                  ></Box>
                )}
              </Box>
            ))}
          </Paper>
        )}
      </Box>
    </Loader>
  );
};

export default CustomEmailDropdown;
