import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Footer } from "@components/crud/Footer";
import { PaymentDetailsForm } from "./PaymentDetailsForm";
import { createPaymentDetailForOrder } from "@services/Network";
import { enqueueSnackbar } from "notistack";
export const PaymentDetailsCreate = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });
  console.log(orderId);
  const {
    formState: { isValid },
    getValues
  } = form;

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const convertDateToExpiry = (date: Date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);

    const formattedDate = month + year;
    return formattedDate;
  };
  const mutation = createPaymentDetailForOrder();
  const onSave = async () => {
    const details = getValues().details?.[0];
    const paymentDetails = {
      cardName: details?.cardName,
      country: details?.country,
      zipCode: details?.zipCode,
      billingInstructions: details?.billingInstructions,
      number: details?.number,
      expiry: details?.expirationDate
        ? convertDateToExpiry(details?.expirationDate)
        : "",
      cvv: details?.cvv,
      type: details?.type,
      routingNumber: details?.routingNumber,
      accType: details?.accountType,
      accountName: details?.accountName
    };
    const fullName =
      paymentDetails.type === "BANK_ACH"
        ? paymentDetails.accountName
        : paymentDetails.cardName;
    const data = {
      paymentType: paymentDetails.type,
      ...(paymentDetails.type !== "BANK_ACH"
        ? {
            cardNumber: paymentDetails.number,
            expirationDate: paymentDetails.expiry,
            cvv: paymentDetails.cvv
          }
        : {
            accountNumber: paymentDetails.number,
            accountType: paymentDetails.accType,
            routingNumber: paymentDetails.routingNumber
          }),
      country: paymentDetails.country,
      zipCode: paymentDetails.zipCode,
      name: fullName,
      billingInstructions: paymentDetails.billingInstructions
    };
    mutation.mutate(
      {
        data: data,
        orderId: orderId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Payment Details Saved successfully!", {
            variant: "success"
          });
          navigate(`/crm/orders/${orderId}?tab=Payment+Details`);
        },
        onError: () => {
          enqueueSnackbar("Failed to Save Payment Details!", {
            variant: "error"
          });
        }
      }
    );
  };

  const [paymentType, setPaymentType] = useState("");
  return (
    <Container>
      <Toolbar title="Add Payment Details" />
      <Loader isLoading={false}>
        <PaymentDetailsForm
          form={form}
          index={0}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isValid}
        isLoading={mutation.isLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/crm/orders/${orderId}?tab=Payment+Details`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
