import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import {
  ModelPresetTag,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { getPresetTags } from "@services/Network";
import { IconButton, Modal, styled } from "@mui/material";
import { CloseIcon } from "@components/Icons";
import { Form } from "@components/crud/Form";
import { FormSelect } from "@components/FormSelect";
import { Footer } from "@components/crud/Footer";
import { hasPermission } from "@services/Casbin";

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 600,
  height: 60,
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

export const PresetTags = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [refreshKey] = React.useState(0);
  const [filter, setFilter] = React.useState<string>("All");
  const [openSportSelectModal, setOpenSportSelectModal] = React.useState(false);
  const [selectedSport, setSelectedSport] = React.useState(undefined);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });

  const { data: sports, isLoading: isSportsLoading } = useAdminSportGet({
    organizationId
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId as string,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("live-streaming.clip-tags", "ADD");
      const edit = await checkPermission("live-streaming.clip-tags", "EDIT");
      const view = await checkPermission("live-streaming.clip-tags", "VIEW");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const options = useMemo(() => {
    if (sports?.data)
      return sports.data.map((sport) => ({
        label: sport.name as string,
        value: sport.sportId as string
      }));
    return [];
  }, [sports]);

  const filterConfig = useMemo(() => {
    if (sports?.data) {
      return {
        field: "sportId",
        placeholderOption: { label: "All", value: "All" },
        options: [
          {
            label: "Filter by Sport",
            children: options
          }
        ]
      };
    }
  }, [sports]);

  const LIST_COLUMNS: GridColDef<ModelPresetTag>[] = [
    { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
    {
      headerName: "Sport",
      field: "sport",
      minWidth: 115,
      flex: 1,
      valueGetter: ({ row }) => row.sport?.name || "-"
    },
    {
      headerName: "Status",
      field: "isActive",
      minWidth: 115,
      flex: 1,
      valueGetter: ({ value }) => (value ? "Active" : "Inactive")
    }
  ];

  const onAdd = () => {
    navigate("/preset-tags/create");
  };

  const onEdit = (tag) => {
    navigate(`/preset-tags/${tag.presetTagId}/edit`);
  };

  const onView = (tag) => {
    navigate(`/preset-tags/${tag.presetTagId}`);
  };

  const onSortButtonClick = () => {
    if (filter !== "All") navigate(`/preset-tags/sport/${filter}`);
    else {
      setSelectedSport(undefined);
      setOpenSportSelectModal(true);
    }
  };

  return (
    <Loader isLoading={isSportsLoading || isLoadingPermissions}>
      <TableView
        title="Clip Pre-set Tags"
        useGet={getPresetTags}
        columns={LIST_COLUMNS}
        isDeleteDisabled={() => true}
        filterConfig={filterConfig}
        getRowId={(row) => row.presetTagId}
        onAdd={permissions.create ? onAdd : undefined}
        onEdit={permissions.edit ? onEdit : undefined}
        onView={permissions.view ? onView : undefined}
        refreshKey={refreshKey}
        onFilterChange={(value: string) => setFilter(value)}
        hideSortButton={false}
        onSortButtonClick={onSortButtonClick}
      />
      {openSportSelectModal && (
        <Modal
          open={openSportSelectModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MainContainer>
            <Header>
              <HeaderText>Select Sport</HeaderText>
              <IconButton
                onClick={() => {
                  setOpenSportSelectModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Header>
            <Form>
              <FormSelect
                options={[{ label: "", value: "" }, ...options]}
                name="sport"
                onChange={(e) => setSelectedSport(e.target.value)}
              />
            </Form>
            <Footer
              cancelBtnLabel="Cancel"
              saveBtnLabel="Continue"
              isDisabled={!selectedSport}
              cancelBtnClick={() => {
                setSelectedSport(undefined);
                setOpenSportSelectModal(false);
              }}
              saveBtnClick={() => {
                selectedSport && setFilter(selectedSport);
                setOpenSportSelectModal(false);
                navigate(`/preset-tags/sport/${selectedSport}`);
              }}
            />
          </MainContainer>
        </Modal>
      )}
    </Loader>
  );
};
