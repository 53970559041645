import { Backdrop, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SGLogo from "@assets/icons/SGLogo.svg";
import { useEffect } from "react";
import { AppStoreUrl, PlayStoreUrl } from "@utils/constants";

const StyledBox = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 404px;
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  text-align: center;
  .img-container {
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    border-radius: 99px;
    width: 72px;
  }
  img {
    margin-left: 5px;
    width: 60px;
    height: 60px;
  }
  @media (max-width: 465px) {
    width: 85%;
  }
  @media (max-width: 400px) {
    img {
      width: 42px;
      height: 42px;
    }
    .img-container {
      width: 50px;
    }
  }
`;

const StyledHeader = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const StyledBody = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 12px;
  color: #666666;
  @media (max-width: 400px) {
    font-size: 12px;
    margin-top: 4px;
  }
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  border-radius: 15px 0 15px 0;
  text-transform: none;
  font-wight: 600;
  font-size: 14px;
  width: 100%;
  line-height: 20px;
  @media (max-width: 300px) {
    font-size: 12px;
  }
`;

export const DownloadAppPopup = ({
  title,
  description,
  mobileUrl
}: {
  title: string;
  description: string;
  mobileUrl: string;
}) => {
  useEffect(() => {
    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventZoom, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventZoom);
    };
  }, []);

  const appStoreFallback = () => {
    const userAgent = window.navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.location.href =
        PlayStoreUrl +
        `&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Ddeep_link%253D${mobileUrl}`;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      if (window.confirm(`Open in "App Store"?`)) {
        window.location.href = AppStoreUrl;
      }
    }
  };
  const handleClick = () => {
    appStoreFallback();
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <StyledBox>
        <div className="img-container">
          <img src={SGLogo} />
        </div>
        <StyledHeader>{title}</StyledHeader>
        <StyledBody>{description}</StyledBody>
        <Box marginTop="16px">
          <StyledButton
            variant="contained"
            style={{ color: "#fff" }}
            onClick={handleClick}
          >
            Download SportsGravy app
          </StyledButton>
        </Box>
        <Box
          marginTop="16px"
          sx={{ display: "flex", gap: "8px", justifyContent: "center" }}
        >
          <Typography
            style={{
              color: "#007AFF",
              fontSize: "12px",
              fontWeight: 600,
              cursor: "pointer"
            }}
            onClick={handleClick}
          >
            Log in
          </Typography>
          <Typography
            style={{ color: "#B3B3B3", fontSize: "12px", fontWeight: 600 }}
          >
            or
          </Typography>
          <Typography
            style={{
              color: "#007AFF",
              fontSize: "12px",
              fontWeight: 600,
              cursor: "pointer"
            }}
            onClick={handleClick}
          >
            Sign up
          </Typography>
        </Box>
      </StyledBox>
    </Backdrop>
  );
};
