/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingSpinner } from "@components/LoadingSpinner";
import {
  Box,
  FormLabel,
  Grid,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import { ToolTip } from "./ToolTip";
import InfoIcon from "@assets/icons/info.svg";

const FormSelectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-height: 44px;
`;

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const LoadingContainer = styled(Box)`
  min-height: 44px;
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Select)((props) => ({
  height: 53,
  color: props.color,
  fieldset: {
    "&.MuiOutlinedInput-notchedOutline": {
      // @ts-ignore
      borderColor: "rgba(0, 0, 0, 0.26)"
    }
  }
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  //position: "absolute",
  //top: "100%",
  left: 0,
  margin: 0,
  fontFamily: "Inter var, sans-serif",
  fontSize: "12px",
  fontWeight: 500,
  height: "30px"
}));

type SelectOption = {
  label: string;
  value?: string | number;
  customStyle?: string;
  children?: SelectOption[];
  status?: string;
};

const renderMenuItem = (item: SelectOption, index, isRelease = false) => {
  const key = `${index}_${item.value}`;
  let style = {};
  if (item.children) {
    return (
      <optgroup key={key} label={item.label}>
        {item.children.map((child: any, index) =>
          renderMenuItem(child, `${index}_${key}`)
        )}
      </optgroup>
    );
  }
  if (
    (isRelease && item.status === "IN_PROGRESS") ||
    item.status === "READY_TO_RELEASE"
  ) {
    style = { color: "#3B6CF8" };
  }
  return (
    <option key={key} value={item.value} style={style}>
      {item.label}
    </option>
  );
};

interface FormSelectProps<T> extends SelectProps<T> {
  name: string;
  options: SelectOption[];
  control?: any;
  rules?: any;
  label?: string;
  required?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  optionStyle?: any;
  isReleaseSelect?: boolean;
  onChange?: (event: SelectChangeEvent<any>) => void;
  tooltip?: string;
  customOptionsComponent?: any;
}

export const FormSelect: <T>(props: FormSelectProps<T>) => JSX.Element = (
  props
) => {
  return props.control ? (
    <Controller
      name={props.name}
      control={props.control}
      rules={Object.assign(
        props.required ? { required: !!props.required } : {},
        props.rules || {}
      )}
      render={({ field, fieldState }) => (
        <FormSelectContainer data-testid={"FORM_SELECT_" + props.name}>
          {props.label && (
            <Grid
              style={{
                display: "flex"
              }}
            >
              <StyledFormLabel required={props.required}>
                <Typography display="inline" variant="formLabel">
                  {props.label}
                </Typography>
              </StyledFormLabel>
              {props.tooltip && (
                <ToolTip title={props.tooltip}>
                  <img
                    src={InfoIcon}
                    style={{ paddingLeft: "8px", height: "24px" }}
                  />
                </ToolTip>
              )}
            </Grid>
          )}
          {props.isLoading ? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          ) : (
            <StyledSelect
              native={!props.customOptionsComponent}
              error={!!fieldState.error}
              required={!!props.required}
              value={field.value || ""}
              onChange={(...args) => {
                if (props.onChange) {
                  props.onChange(args[0]);
                }
                field.onChange(...args);
              }}
              onBlur={field.onBlur}
              inputProps={{ shrink: "true" }}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300 } }
              }}
              renderValue={
                props.customOptionsComponent
                  ? (selected) => {
                      const selectedOpportunity = props.options.find(
                        (opt) => opt.value === selected
                      );
                      return selectedOpportunity?.label;
                    }
                  : undefined
              }
              placeholder={props.placeholder}
              disabled={props.disabled}
              onClose={() => {
                field.onBlur();
              }}
              sx={props.sx}
            >
              {props.options[0]?.value !== "" && (
                <option disabled value="">
                  {props.placeholder}
                </option>
              )}
              {props.customOptionsComponent
                ? props.customOptionsComponent
                : props.options.map((item, index) =>
                    renderMenuItem(
                      item,
                      index,
                      props.optionStyle ? props.optionStyle : {}
                    )
                  )}
            </StyledSelect>
          )}
          {!!fieldState.error && (
            <ErrorContainer className="helper-text">
              <Typography variant="body2">
                {fieldState.error.message}
              </Typography>
            </ErrorContainer>
          )}
        </FormSelectContainer>
      )}
    />
  ) : (
    <FormSelectContainer>
      {props.label && (
        <StyledFormLabel required={props.required}>
          <Typography display="inline" variant="formLabel">
            {props.label}
          </Typography>
        </StyledFormLabel>
      )}
      <StyledSelect
        native
        value={props.value}
        disabled={props.disabled}
        required={!!props.required}
        data-testid={"FORM_SELECT"}
        onChange={(event: SelectChangeEvent<unknown>) => {
          if (props.onChange) props.onChange(event);
        }}
        inputProps={{ shrink: "true" }}
        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        sx={props.sx}
      >
        {/* {props.options[0]?.value !== "" && (
          <option value="">{props.placeholder || props.label}</option>
        )} */}
        {props.options.map((item, index) =>
          renderMenuItem(item, index, props.isReleaseSelect)
        )}
      </StyledSelect>
    </FormSelectContainer>
  );
};
