export const cleanObject = <T>(obj: T): Partial<T> => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null || value === undefined) {
      return acc;
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value = cleanObject(value as any);
    }
    return { ...acc, [key]: value };
  }, {} as T);
};
export const removeNullValues = (obj) => {
  // Create a new object from entries that are not null
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, value]) => value && value !== null)
  );
};
