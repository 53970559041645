import { TableViewInfinite } from "@components/TableViewInfinite";
import { Loader } from "@components/crud/Loader";
import { organizationAtom, profileAtom, selectedBuildAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getBuildFeatures } from "@services/Network";
import {
  ModelBuild,
  ModelPerson,
  useAdminBuildAllGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const QABuilds = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const profileValue = useRecoilValue(profileAtom);
  const selectedBuildValue = useRecoilValue(selectedBuildAtom);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const [testers, setTesters] = useState<ModelPerson[]>([]);
  const [selectedTester, setSelectedTester] = useState<string>("");
  const [buildId, setBuildId] = useState<string>();

  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.features", "ADD");
      const edit = await checkPermission("tech.features", "EDIT");
      const del = await checkPermission("tech.features", "VIEW");
      const permission = {
        create,
        edit,
        view: del
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);

  const onEdit = (feature) => {
    navigate(`/qa-builds/${feature.featureId}/edit`);
  };

  const onView = (feature) => {
    navigate(`/qa-builds/${feature.featureId}`);
  };

  const { data: buildsData, isLoading: isBuildsLoading } =
    useAdminBuildAllGet();
  const [builds, setBuilds] = useState<ModelBuild[]>([]);

  useEffect(() => {
    if (buildsData?.data) {
      const builds = buildsData.data.builds as ModelBuild[];
      setBuilds(builds);
      const testersOfAllBuilds = [] as ModelPerson[];
      builds.map((release) => {
        release.testers?.map((tester) => {
          if (
            !testersOfAllBuilds.find(
              (person) => person.personId === tester.person?.personId
            )
          ) {
            testersOfAllBuilds.push(tester.person as ModelPerson);
          }
        });
      });
      setTesters([...testersOfAllBuilds]);
    }
  }, [buildsData]);

  const buildsConfig = useMemo(() => {
    const options =
      builds?.map((build) => ({
        label: `${build.buildNumber} - ${build.platform}`,
        value: build.buildId as string,
        status: build.buildStatus
      })) || [];

    return {
      field: "buildId",
      defaultValue:
        selectedBuildValue &&
        options.find((opt) => opt.value === selectedBuildValue)
          ? selectedBuildValue
          : options.length > 0
          ? options[0].value
          : "",
      options: options,
      label: "Build"
    };
  }, [builds]);

  const testerConfig = React.useMemo(() => {
    const options =
      testers?.map((tester: ModelPerson) => ({
        label: formatFullName(tester) as string,
        value: tester.personId as string
      })) || [];

    if (options.find((opt) => opt.value === profileValue!.personId)) {
      setSelectedTester(profileValue!.personId!);
    } else {
      setSelectedTester("");
    }
    return {
      field: "testerId",
      placeholderOption: { label: "All", value: "" },
      options: options,
      label: "Tester"
    };
  }, [testers]);

  const filterConfig = {
    field: "parentId",
    defaultValue: "",
    placeholderOption: { label: "All", value: "" },
    options: []
  };

  const LIST_COLUMNS = [
    {
      headerName: "Feature Name",
      field: "name",
      sortable: false,
      minWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => row.name
    },
    {
      headerName: "Parent Name",
      field: "parentFeature",
      sortable: false,
      minWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => (row?.parent ? row.parent.name : "")
    },
    {
      headerName: "Platform",
      field: "platform",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => row?.builds?.[0]?.build?.platform
    }
  ];

  const isEditEnabled = () => {
    return permissions.edit;
  };

  useEffect(() => {
    if (buildsData?.data?.builds) {
      const build = buildsData.data.builds.find((r) => r.buildId === buildId);
      const testers = build?.testers?.map((t) => t.person as ModelPerson) || [];
      setTesters(testers);
    }
  }, [buildId]);

  return (
    <>
      <Loader isLoading={isLoadingPermissions || isBuildsLoading}>
        <TableViewInfinite
          title="QA Builds"
          hideFilter
          useGet={getBuildFeatures}
          columns={LIST_COLUMNS}
          searchable={false}
          customFilter="FEATURE"
          getRowId={(row) => row.featureId}
          hideFooter
          filterConfig={filterConfig}
          hideLabelContainer
          hidePlatformFilter={true}
          releaseConfig={buildsConfig}
          testerConfig={testerConfig}
          testerId={selectedTester}
          setSelectedTester={setSelectedTester}
          setSelectedRelease={setBuildId}
          addBtnText="Add Build"
          defaultSort={[{ field: "name", sort: "asc" }]}
          onEdit={isEditEnabled() ? onEdit : undefined}
          onView={onView}
          qaType="BUILD"
        />
      </Loader>
    </>
  );
};
