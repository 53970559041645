import { Loader } from "@components/crud/Loader";
import { Grid, styled, Typography } from "@mui/material";
import { useAdminSettingsPut } from "@sportsgravyengineering/sg-api-react-sdk";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useAdminSettingsGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { enqueueSnackbar } from "notistack";
import { FormSelect } from "@components/FormSelect";
import { hasPermission } from "@services/Casbin";
import { Toolbar } from "@components/crud/Toolbar";
import { Edit } from "@mui/icons-material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";

const StyledGrid = styled(Grid)`
  padding: 0 24px;
  .toolbar {
    padding: 24px 0 0 0;
  }
`;

export const TeamsProgramsSettings = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [permission, setPermission] = useState(false);
  const [organizationId] = useRecoilState(organizationAtom);
  const [disabled, setDisabled] = useState(true);
  const { data: settings, isLoading: isLoading } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "org-teams-programs"
  });
  const { reset, control, getValues } = form;
  useEffect(() => {
    if (settings) {
      const settingsMap = settings.data.map((setting) => {
        return {
          settingId: setting.settingId,
          value:
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : setting.default
        };
      });
      const defaultValues = {
        selectedSettings: settingsMap
      };
      reset(defaultValues, {
        keepDefaultValues: false
      });
    }
  }, [settings]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setPermission(edit);
    };
    fetchPermissions();
  }, []);
  const { mutate: save, isLoading: isSaving } = useAdminSettingsPut();
  const onSave = () => {
    const data = getValues().selectedSettings;
    save(
      {
        data: data,
        params: {
          organizationId: organizationId!
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((o) => o.organizationId === organizationId);

  const getSettingView = (settingId) => {
    const index = settings?.data.findIndex(
      (setting) => setting.settingId === settingId
    );
    if (index !== undefined && index !== -1) {
      const setting = settings!.data[index];
      switch (setting.type) {
        case "SINGLE_SELECT":
          return (
            <Grid item container direction="row">
              <Grid item xs={6} data-testid={setting.settingId}>
                <FormSelect
                  disabled={disabled}
                  control={control}
                  name={`selectedSettings[${index}].value`}
                  options={
                    setting.options as { label: string; value: string }[]
                  }
                  label={setting.description}
                />
              </Grid>
            </Grid>
          );
      }
    }
    return <></>;
  };

  return (
    <Loader isLoading={isLoading}>
      <StyledGrid
        item
        container
        direction="column"
        spacing="24px"
        data-testid="training-program-setting"
      >
        {permission && disabled && (
          <Toolbar
            title=""
            addBtnClick={() => setDisabled(false)}
            addBtnLabel="Edit"
            addBtnIcon={<Edit />}
          />
        )}
        {settings && org?.offering?.includes("TEAM") && (
          <Grid item container direction="column" spacing="10px">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  color: "#00000",
                  fontSize: "12px",
                  font: "inter",
                  lineHeight: "14.52px",
                  letterSpacing: "10%",
                  fontWeight: 400
                }}
              >
                TEAMS
              </Typography>
            </Grid>
            {getSettingView("org-teams-programs.add-override-teams")}
          </Grid>
        )}
        {settings && org?.offering?.includes("TRAINING_PROGRAM") && (
          <Grid item container direction="column" spacing="10px">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  color: "#00000",
                  fontSize: "12px",
                  font: "inter",
                  lineHeight: "14.52px",
                  letterSpacing: "10%",
                  fontWeight: 400
                }}
              >
                TRAINING PROGRAMS
              </Typography>
            </Grid>
            {getSettingView("org-teams-programs.add-override-programs")}
          </Grid>
        )}
      </StyledGrid>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => setDisabled(true)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {!disabled && (
        <Footer
          saveBtnClick={onSave}
          isLoading={isSaving}
          isDisabled={isSaving}
          saveBtnLabel="Save"
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
    </Loader>
  );
};
