import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { GridColDef, GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { organizationAtom } from "@recoil/auth";
import {
  AdminPresetTagGetResponse,
  ModelPresetTag,
  useAdminPresetTagGet,
  useAdminPresetTagSportIdOrderPut,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS: GridColDef<ModelPresetTag>[] = [
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 115,
    flex: 1,
    valueGetter: ({ row }) => row.sport?.name || "-"
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 115,
    flex: 1,
    valueGetter: ({ value }) => (value ? "Active" : "Inactive")
  }
];
export const PresetTagSportSort = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState<ModelPresetTag[]>([]);
  const [isCancel, setIsCancel] = React.useState(false);
  const { sportId } = useParams();

  const organizationId = useRecoilValue(organizationAtom);

  const { data: sports, isLoading: isSportsLoading } = useAdminSportGet({
    organizationId
  });

  const filterConfig = useMemo(() => {
    if (sports?.data) {
      const options = sports.data.map((sport) => ({
        label: sport.name as string,
        value: sport.sportId as string
      }));
      return {
        field: "sportId",
        options: options,
        defaultValue: sportId
      };
    }
  }, [sportId]);

  const useGet = () =>
    useAdminPresetTagGet(
      { sportId, sort: true, organizationId: organizationId },
      {
        query: {
          onSuccess: (data) => {
            const tags = (
              data?.data as AdminPresetTagGetResponse
            )?.presetTags!.map((tag) => {
              return {
                ...tag,
                id: tag.presetTagId as string,
                __reorder__: tag.name as string
              };
            });
            setRows(tags);
          }
        }
      }
    );

  const orderMutation = useAdminPresetTagSportIdOrderPut();

  const onRowOrderChange = (params: GridRowOrderChangeParams) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);
    setRows(rowsClone);
  };

  const onFilterChange = (value) => {
    navigate(`/preset-tags/sport/${value}`);
  };

  const onSubmit = () => {
    if (!sportId) return;

    const tags = rows.map((row, index) => {
      return {
        presetTagId: row.presetTagId,
        order: index
      };
    });

    orderMutation.mutate(
      {
        sportId,
        data: tags
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Successfully sorted preset tags!", {
            variant: "success"
          });
          navigate("/preset-tags");
        },
        onError: () => {
          enqueueSnackbar("Something went wrong! Failed to sort preset tags.", {
            variant: "error"
          });
        }
      }
    );
  };

  const onCancel = () => {
    setIsCancel(true);
  };

  return (
    <Loader isLoading={isSportsLoading}>
      <TableView
        title="Sort Clip Pre-set Tags"
        useGet={useGet}
        columns={LIST_COLUMNS}
        rows={rows}
        filterConfig={filterConfig}
        hasActionColumn={false}
        searchable={false}
        hideFooter
        rowReordering
        isSubmitting={orderMutation.isLoading}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideSubmitFooter={false}
        onRowOrderChange={onRowOrderChange}
        onFilterChange={onFilterChange}
      />
      <ConfirmationDialog
        open={isCancel}
        title="Cancel Sorting Preset Tags?"
        body={`Are you sure you want to cancel? All changes will be lost.`}
        close={() => setIsCancel(false)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
        onConfirm={() => navigate("/preset-tags")}
        onCancel={() => setIsCancel(false)}
      />
    </Loader>
  );
};
