import {
  Autocomplete,
  Button,
  FormLabel,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { useEffect, useState } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { getLeads } from "../../../services/Network";
import { Add } from "@mui/icons-material";
import { ModelLead } from "@sportsgravyengineering/sg-api-react-sdk";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledAccountOption = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));

const LeadOption = ({
  lead,
  onClick,
  ...props
}: {
  lead: ModelLead;
  onClick: () => void;
}) => {
  const { name } = lead;
  return (
    <StyledAccountOption
      container
      direction="column"
      padding="10px 16px 10px 16px"
      {...props}
      onClick={onClick}
    >
      <Grid item>
        <Typography
          style={{
            color: "#000",
            fontSize: "13px",
            fontWeight: 500
          }}
        >
          {name}
        </Typography>
      </Grid>
    </StyledAccountOption>
  );
};

const AddLeadOption = ({ onClick }: { onClick: () => void }) => {
  return (
    <Grid
      container
      padding="10px 16px 10px 16px"
      style={{ backgroundColor: "#E5E5E5" }}
      xs={12}
      direction="column"
      data-testid="ADD_NEW_PERSON_OPTION"
    >
      <Grid item>
        <Typography
          style={{
            color: "#0F0F0F",
            fontWeight: "600",
            fontSize: "13px"
          }}
        >
          Not finding what you're looking for?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          style={{
            color: "#007AFF",
            textTransform: "capitalize",
            fontSize: "14px"
          }}
          onClick={onClick}
          startIcon={<Add />}
        >
          Add New Lead
        </Button>
      </Grid>
    </Grid>
  );
};

export const SearchAddLead = ({
  leadSelected,
  showAddLeadOption,
  required,
  label,
  disabled,
  searchValue,
  onClear
}: {
  leadSelected: (selectedLead, leadSelected, newLeadSelected) => void;
  showAddLeadOption?: boolean;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  searchValue?: string;
  onClear?: () => void;
}) => {
  const [leadSearch, setLeadSearch] = useState("");
  useEffect(() => {
    if (searchValue) setLeadSearch(searchValue);
  }, [searchValue]);
  const [addLeadMenuOpen, setAddLeadMenuOpen] = useState(false);
  const defaultLead: ModelLead = {
    name: ""
  };
  const onAddLeadSearchChange = (event) => {
    setLeadSearch(event.target.value);
    if (!event.target.value && onClear) onClear();
  };
  const { data: addLeadResults, isFetching: isLoading } = getLeads(
    {
      textSearch: leadSearch
    },
    { staleTime: Infinity, enabled: !!leadSearch?.length }
  );
  const addLeadOptions = [...addLeadResults];
  if (showAddLeadOption) addLeadOptions.push(null);

  return (
    <Grid item container direction="row" spacing="24px">
      <Grid item xs={12}>
        <StyledFormLabel required={required}>
          <Typography display="inline" variant="formLabel">
            {label || "Lead Name"}
          </Typography>
        </StyledFormLabel>
        <Autocomplete
          open={addLeadMenuOpen}
          clearOnBlur={false}
          freeSolo
          disabled={disabled}
          onOpen={() => setAddLeadMenuOpen(true)}
          onClose={() => setAddLeadMenuOpen(false)}
          // value={secondaryContact}
          getOptionLabel={(option) => (option ? option.name : "")}
          isOptionEqualToValue={(option, value) =>
            option?.leadId === value?.leadId
          }
          inputValue={leadSearch}
          renderInput={(params) => (
            <SearchInput
              data-testid="ADD_LEAD_SEARCH_PERSON_INPUT"
              {...params}
              value={leadSearch}
              onChange={onAddLeadSearchChange}
            />
          )}
          filterOptions={(options) =>
            addLeadMenuOpen && !isLoading ? options : []
          }
          renderOption={(props, option: ModelLead, state) => {
            if (
              state.index === addLeadOptions.length - 1 &&
              showAddLeadOption
            ) {
              return (
                <AddLeadOption
                  key={state.index}
                  onClick={() => {
                    leadSelected(defaultLead, false, true);
                    setAddLeadMenuOpen(false);
                  }}
                />
              );
            }
            return (
              <LeadOption
                key={option?.leadId}
                lead={option}
                onClick={() => {
                  leadSelected(
                    {
                      leadId: option?.leadId,
                      name: option?.name,
                      source: option?.source,
                      accountId: option?.accountId
                    },
                    true,
                    false
                  );
                  setLeadSearch(option!.name!);
                  setAddLeadMenuOpen(false);
                }}
              />
            );
          }}
          options={addLeadOptions || []}
          loading={isLoading}
          loadingText="Loading..."
        />
      </Grid>
    </Grid>
  );
};
