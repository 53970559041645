/* eslint-disable @typescript-eslint/no-unused-vars */
import { Loader } from "@components/crud/Loader";
import { Grid, styled, Typography } from "@mui/material";
import {
  ModelAppVersion,
  useAppVersionGet,
  useAppVersionPost,
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { FormInput } from "@components/FormInput";
import { hasPermission } from "@services/Casbin";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Edit } from "@mui/icons-material";
import { FormCheckbox } from "@components/FormCheckbox";
import { Button } from "@components/Button";

const StyledDiv = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledTypography = styled(Typography)`
  font-weight: 400;
  color: #000;
  opacity: 50%;
  font-size: 12px;
`;

export const AppVersion = () => {
  const {
    reset,
    control,
    getValues,
    formState: { isValid, isDirty }
  } = useForm({
    mode: "onBlur"
  });
  const { data: appversions, isLoading: isLoading } = useAppVersionGet();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [IOSediting, setIOSEditing] = useState<boolean>(false);
  const [ANDediting, setANDEditing] = useState<boolean>(false);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setDisabled(!edit);
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    let androidData = {};
    if (appversions?.data) {
      const andData = appversions?.data?.android;
      if (andData)
        androidData = {
          ANDbuildNumber: andData.buildNumber,
          ANDversionNumber: andData.version,
          ANDisMandatory: andData.isMandatory
        };
      const iData = appversions?.data?.ios;
      let iosData = {};
      if (iData)
        iosData = {
          IOSbuildNumber: iData.buildNumber,
          IOSversionNumber: iData.version,
          IOSisMandatory: iData.isMandatory
        };
      reset({
        ...androidData,
        ...iosData
      });
    }
  }, [appversions]);

  const { mutate: save, isLoading: isSaving } = useAppVersionPost();

  const onSave = (platform: "AND" | "IOS") => {
    const values = getValues();
    let data: ModelAppVersion = {};
    if (platform === "AND") {
      data = {
        version: values.ANDversionNumber,
        platform: "ANDROID",
        buildNumber: parseInt(values.ANDbuildNumber),
        isMandatory: values.ANDisMandatory
      };
    }
    if (platform === "IOS") {
      data = {
        version: values.IOSversionNumber,
        platform: "IOS",
        buildNumber: parseInt(values.IOSbuildNumber),
        isMandatory: values.IOSisMandatory
      };
    }
    if (data) {
      save(
        {
          data: data
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Saved Successfully!", {
              variant: "success"
            });
            if (platform === "AND") setANDEditing(false);
            if (platform === "IOS") setIOSEditing(false);
          },
          onError: () => {
            enqueueSnackbar("Failed to save!", {
              variant: "error"
            });
          }
        }
      );
    }
  };

  return (
    <Grid item container direction="column">
      <Loader isLoading={isLoading}>
        <Grid xs={12}>
          <StyledDiv>
            <StyledTypography>IOS</StyledTypography>
            {!IOSediting ? (
              <StyledDiv
                style={{
                  gap: "5px",
                  color: "#007AFF",
                  cursor: "pointer",
                  fontWeight: 600
                }}
                onClick={() => setIOSEditing(true)}
              >
                <Edit style={{ height: "16px", width: "16px" }} />
                <span>Edit</span>
              </StyledDiv>
            ) : (
              <Button
                variant="admin-primary"
                disabled={!isValid || isSaving}
                isLoading={isSaving}
                onClick={() => onSave("IOS")}
              >
                Save
              </Button>
            )}
          </StyledDiv>
          <HeaderUnderLine width="100%" />
        </Grid>
        <Grid
          item
          sx={{ marginTop: "25px" }}
          container
          columnSpacing={3}
          alignItems="center"
        >
          <Grid item xs={4}>
            <FormInput
              disabled={disabled || !IOSediting}
              control={control}
              label="Version Number"
              name="IOSversionNumber"
              type="text"
              required
              rules={{
                required: "Version Number is required"
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInput
              disabled={disabled || !IOSediting}
              control={control}
              label="Build Number"
              name="IOSbuildNumber"
              type="text"
              required
              rules={{
                required: "Build Number is required"
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormCheckbox
              disabled={disabled || !IOSediting}
              control={control}
              label="Mandatory Update"
              name="IOSisMandatory"
            />
          </Grid>
        </Grid>
        <Grid xs={12} sx={{ marginTop: "24px" }}>
          <StyledDiv>
            <StyledTypography>AND</StyledTypography>
            {!ANDediting ? (
              <StyledDiv
                style={{
                  gap: "5px",
                  color: "#007AFF",
                  cursor: "pointer",
                  fontWeight: 600
                }}
                onClick={() => setANDEditing(true)}
              >
                <Edit style={{ height: "16px", width: "16px" }} />
                <span>Edit</span>
              </StyledDiv>
            ) : (
              <Button
                variant="admin-primary"
                disabled={!isValid || isSaving}
                isLoading={isSaving}
                onClick={() => onSave("AND")}
              >
                Save
              </Button>
            )}
          </StyledDiv>
          <HeaderUnderLine width="100%" />
        </Grid>
        <Grid
          item
          sx={{ marginTop: "25px" }}
          container
          columnSpacing={3}
          alignItems="center"
        >
          <Grid item xs={4}>
            <FormInput
              disabled={disabled || !ANDediting}
              control={control}
              label="Version Number"
              name="ANDversionNumber"
              type="text"
              required
              rules={{
                required: "Version Number is required"
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInput
              disabled={disabled || !ANDediting}
              control={control}
              label="Build Number"
              name="ANDbuildNumber"
              type="text"
              required
              rules={{
                required: "Build Number is required"
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormCheckbox
              disabled={disabled || !ANDediting}
              control={control}
              label="Mandatory Update"
              name="ANDisMandatory"
            />
          </Grid>
        </Grid>
      </Loader>
    </Grid>
  );
};
