import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import {
  AdminJobTitlePostPutRequest,
  ModelDepartment,
  ModelMedia,
  mediaGet,
  useAdminDepartmentGet,
  useAdminJobTitlePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { base64ImageToFile } from "@utils/file";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { JobTitleForm } from "./jobTitleForm";

export const JobTitleCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onBlur"
  });

  const {
    register,
    getValues,
    setValue,
    formState: { isValid }
  } = form;

  const { data: departmentsData, isLoading: isLoadingDepartments } =
    useAdminDepartmentGet();
  const [isDescValid, setIsDescValid] = useState(false);
  const [departments, setDepartments] = React.useState<ModelDepartment[]>([]);

  React.useEffect(() => {
    if (departmentsData?.data) {
      setValue("isActive", true);
      setDepartments(departmentsData.data.departments!);
    }
  }, [departmentsData]);

  React.useEffect(() => {
    register("description", { required: true });
  }, [register]);

  const { mutate: save, isLoading: isSaving } = useAdminJobTitlePost();
  const [isUploadingImage, setUploadingImage] = useState(false);
  const onSave = async () => {
    const description = getValues().description;
    if (getValues().hasMedia) {
      setUploadingImage(true);
      await Promise.all(
        description?.map(async (op, idx) => {
          if (op.insert && Object.keys(op.insert).includes("image")) {
            const file = base64ImageToFile(
              op.insert.image,
              getValues().headline + "_" + idx
            );
            const filesPromises = await Promise.all(
              [file].map((file) => {
                if (file instanceof File) {
                  const promise = uploadMediaUsingPresignedUrl(file);
                  return promise;
                } else {
                  return (file as ModelMedia).mediaId!;
                }
              })
            );

            if (filesPromises) {
              const response = await mediaGet({
                mediaIds: [filesPromises[0]]
              });
              if (
                response &&
                response.data.media &&
                response.data.media.length
              ) {
                const media = response.data.media[0];
                if (media.media && media.media.baseUrl && media.media.path) {
                  let path = media.media.path;
                  if (media.media.state === "DRAFT") {
                    path = path.replace("original/", "");
                  }
                  const urlPath = media.media.baseUrl + path;
                  description[idx].insert.image = urlPath;
                }
              }
            }
          }
        })
      );

      setUploadingImage(false);
    }
    const values = {
      name: getValues().name,
      departmentId: getValues().departmentId,
      isActive: getValues().isActive,
      description: description,
      abbreviation: getValues().abbreviation,
      supportsCalls: getValues().supportsCalls,
      supportsCallCenterCalls: getValues().supportsCallCenterCalls,
      canSetDemo: getValues().canSetDemo,
      canSellAccount: getValues().canSellAccount,
      canSupportAccount: getValues().canSupportAccount,
      maxAccountsDemo:
        canSetDemo && getValues().maxAccountsDemo
          ? parseInt(getValues().maxAccountsDemo)
          : null,
      maxAccountsSupport:
        canSupportAccount && getValues().maxAccountsSupport
          ? parseInt(getValues().maxAccountsSupport)
          : null,
      maxOpenOppSell:
        canSellAccount && getValues().maxOpenOppSell
          ? parseInt(getValues().maxOpenOppSell)
          : null,
      orgRatingDemo: canSetDemo ? getValues().orgRatingDemo || [] : [],
      orgRatingSell: canSellAccount ? getValues().orgRatingSell || [] : [],
      orgRatingSupport: canSupportAccount
        ? getValues().orgRatingSupport || []
        : [],
      athletesThresholdDemo:
        canSetDemo && getValues().athletesThresholdDemo
          ? parseInt(getValues().athletesThresholdDemo)
          : null,
      athletesThresholdSell:
        canSellAccount && getValues().athletesThresholdSell
          ? parseInt(getValues().athletesThresholdSell)
          : null,
      athletesThresholdSupport:
        canSupportAccount && getValues().athletesThresholdSupport
          ? parseInt(getValues().athletesThresholdSupport)
          : null,
      canConductOnboardingDemo: getValues().canConductOnboardingDemo
    } as AdminJobTitlePostPutRequest;
    save(
      {
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Job Created successfully!", {
            variant: "success"
          });
          navigate("/job-titles");
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Job!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [canSetDemo, setCanSetDemo] = useState(false);
  const [canSellAccount, setCanSellAccount] = useState(false);
  const [canSupportAccount, setCanSupportAccount] = useState(false);
  return (
    <Container>
      <Toolbar title="Add Job" />
      <JobTitleForm
        form={form}
        departments={departments}
        isLoadingDepartments={isLoadingDepartments}
        canSetDemo={canSetDemo}
        canSellAccount={canSellAccount}
        canSupportAccount={canSupportAccount}
        setCanSellAccount={setCanSellAccount}
        setCanSetDemo={setCanSetDemo}
        setCanSupportAccount={setCanSupportAccount}
        setIsDescValid={setIsDescValid}
      />
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isValid || isSaving || isUploadingImage || !isDescValid}
        isLoading={isSaving || isUploadingImage}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/job-titles")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
