import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  ModelDemoSession,
  ModelLead,
  useAdminCrmLeadLeadIdDelete,
  useAdminCrmLeadLeadIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { Form } from "@components/crud/Form";
import { LeadDetailsForm } from "./LeadDetailsForm";
import formatFullName from "@utils/formatFullName";
import { FullName } from "@utils/types";
import { hasPermission } from "@services/Casbin";

export const LeadView = () => {
  const navigate = useNavigate();
  const { leadId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const [permissions, setPermissions] = useState({
    edit: false,
    view: false,
    delete: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("crm.leads", "DELETE");
      const edit = await checkPermission("crm.leads", "EDIT");
      const view = await checkPermission("crm.leads", "VIEW");
      if (!view) navigate("/not-found");
      const permission = {
        delete: del,
        edit,
        view
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, []);

  const { reset } = form;

  const [leadOwner, setLeadOwner] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const { data: leadData, isLoading: isLoading } = useAdminCrmLeadLeadIdGet(
    leadId!
  );

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
      details: ContactResponse;
    }[]
  >([]);

  useEffect(() => {
    if (leadData?.data) {
      const lead = leadData.data as ModelLead & {
        demoDetail: ModelDemoSession;
        referralUrl?: string;
      };
      reset({
        name: lead.name,
        source: lead.source?.type,
        status: lead.status,
        leadOwner: lead.owner?.personId,
        account: {
          name: lead.account?.name || lead.demoDetail?.organizationName || "",
          parent: lead.account?.parent?.name || "",
          category: lead.account?.category,
          type: lead.account?.type || lead.demoDetail?.organizationType,
          officeAddress:
            lead.account?.officeAddress ||
            (lead.demoDetail
              ? `${lead.demoDetail?.city}, ${lead.demoDetail?.state}, ${lead.demoDetail?.country}`
              : ""),
          email: lead.account?.email,
          website: lead.account?.website,
          sportsOffered:
            lead.account?.sports?.map((sp) => sp.sportId) ||
            (lead.demoDetail?.sport
              ? lead.demoDetail?.sport?.map((s) => s.sportId)
              : []),
          noOfAthletes:
            lead.account?.numberOfAthletes || lead.demoDetail?.noOfAthletes,
          aeOwner: lead.account?.aeOwner?.person
            ? formatFullName(lead.account?.aeOwner?.person)
            : ""
        },
        contact: {
          name:
            formatFullName(lead.contact as FullName) ||
            lead.demoDetail?.primaryContact,
          personalEmail: lead.contact?.email || lead?.demoDetail?.email,
          homeAddress: lead.contact?.location,
          phoneType: "MOB",
          phone: lead.contact?.phone || lead.demoDetail?.phone,
          whatsappNumber: lead.contact?.whatsappNumber
        },
        referralUrl: lead.referralUrl,
        interestInSG: lead.demoDetail?.message
      });
      setSelectedAccount(lead.accountId || "");
      setLeadOwner(lead.owner?.personId || "");
    }
  }, [leadData]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminCrmLeadLeadIdDelete();

  const onLeadDelete = async () => {
    try {
      await deleteAsync({ leadId: leadId as string });
      enqueueSnackbar("Lead Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/crm/leads");
    } catch (e) {
      enqueueSnackbar("Something went wrong! Unable to delete the Lead.", {
        variant: "error"
      });
      setOpenDeleteDialog(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && leadData?.data) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || "",
              isPrimary: !!c.isPrimary,
              details: c.contact as ContactResponse
            }))
          );
          if (leadData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(leadData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, leadData]);

  return (
    <Container>
      <Toolbar
        title="View Lead"
        backBtnClick={() => {
          navigate("/crm/leads");
        }}
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/crm/leads/${leadId}/edit`)
        })}
        {...(permissions.delete && {
          deleteBtnClick: () => setOpenDeleteDialog(true)
        })}
      />
      <Loader isLoading={isDeleting || isLoading}>
        <Form>
          <LeadDetailsForm
            disabled
            form={form}
            leadOwner={leadOwner}
            setLeadOwner={setLeadOwner}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
            setContacts={setContacts}
            isMQL={!leadData?.data?.accountId}
            account={leadData?.data?.account}
          />
        </Form>
      </Loader>
      <ConfirmationDialog
        title="Delete Lead?"
        body="Are you sure you want to delete this Lead?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onLeadDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
