import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import {
  ModelFeature,
  useAdminFeatureFeatureIdDelete,
  useAdminFeatureFeatureIdGet,
  useAdminFeatureGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const FeatureView = () => {
  const navigate = useNavigate();
  const { featureId } = useParams();

  const form = useForm({
    mode: "onBlur"
  });

  const { data: data, isLoading: isLoading } = useAdminFeatureFeatureIdGet(
    featureId!
  );
  const { control, reset } = form;
  const [feature, setFeature] = useState<ModelFeature>();
  const [platforms, setPlatforms] = useState<string[]>([]);
  useEffect(() => {
    if (data) {
      const feature = data.data;
      const defaultValues = {
        name: feature.name,
        parentId: feature.parentId,
        platforms: feature.platforms,
        isActive: feature.isActive,
        isSmokeTest: feature.isSmokeTest,
        iosEpicId: feature.iosEpicId,
        andEpicId: feature.andEpicId,
        webEpicId: feature.webEpicId,
        devOpsEpicId: feature.devOpsEpicId,
        apiEpicId: feature.apiEpicId
      };
      setPlatforms(feature.platforms || []);
      reset(defaultValues);
      if (data.data?.children) {
        const expandedValues = [] as {
          featureId: string;
          isExpanded: boolean;
        }[];
        data.data.children.map((child) => {
          expandedValues.push({
            featureId: child.featureId as string,
            isExpanded: true
          });
        });
      }
      setFeature(feature);
    }
  }, [data]);

  const findParentNames = (data, featureId) => {
    const result = [];

    const traverse = (features, parentNames) => {
      for (const feature of features) {
        const currentNames = [...parentNames];
        if (feature.featureId === featureId) {
          result.push(currentNames);
        } else if (feature.children && feature.children.length > 0) {
          currentNames.push({ label: feature.name, value: feature.featureId });
          traverse(feature.children, currentNames);
        }
      }
    };
    traverse(data, []);
    setParents(result[0]);
  };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [parents, setParents] = useState<{ label: string; value: string }[]>(
    []
  );
  const { data: features, isLoading: isFetchingFeatures } = useAdminFeatureGet({
    flatten: false,
    pageSize: 100
  });

  useEffect(() => {
    if (features?.data.features)
      findParentNames(features.data.features, featureId);
  }, [features]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminFeatureFeatureIdDelete();

  const onFeatureDelete = async () => {
    try {
      await deleteAsync({ featureId: featureId as string });
      enqueueSnackbar("Feature Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/features");
    } catch (e) {
      enqueueSnackbar("Something went wrong! Unable to delete the feature.", {
        variant: "error"
      });
      setOpenDeleteDialog(false);
    }
  };

  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const organizationId = useRecoilValue(organizationAtom);
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.features", "ADD");
      const edit = await checkPermission("tech.features", "EDIT");
      const del = await checkPermission("tech.features", "VIEW");
      const permission = {
        create,
        edit,
        view: del
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, [organizationId]);

  return (
    <Loader isLoading={isLoading || isFetchingFeatures || isDeleting}>
      <Container>
        <Toolbar
          title="View Feature"
          backBtnClick={() => {
            navigate("/features");
          }}
          editBtnClick={
            permissions.edit
              ? () => {
                  navigate(`/features/${featureId}/edit`);
                }
              : undefined
          }
          deleteBtnClick={
            permissions.edit
              ? () => {
                  setOpenDeleteDialog(true);
                }
              : undefined
          }
          deleteDisabled={
            feature &&
            !!(feature.children?.length || feature?.testCases?.length)
          }
        />
        <Grid
          item
          container
          direction="column"
          spacing="15px"
          paddingLeft="1.5rem"
        >
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="feature-name">
              <FormInput
                name="name"
                disabled
                control={control}
                label="Feature Name"
                required
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="feature-isActive">
              <FormCheckbox
                key="isActive"
                disabled
                control={control}
                name="isActive"
                label="Is Active"
                labelPadding="4px 8px"
                labelTypographyProps={{
                  variant: "body1"
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6} data-testid="feature-isSmokeTest">
              <FormCheckbox
                key="isSmokeTest"
                control={control}
                disabled
                name="isSmokeTest"
                label="End-To-End"
                labelPadding="4px 8px"
                labelTypographyProps={{
                  variant: "body1"
                }}
              />
            </Grid>
          </Grid>
          {parents && parents.length > 0 && (
            <Grid item marginBottom="-10px">
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  Parent Feature
                </Typography>
              </StyledFormLabel>
            </Grid>
          )}
          {parents &&
            parents.map((parent) => {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  spacing="24px"
                  key={parent.value}
                >
                  <Grid item xs={6}>
                    <FormSelect
                      disabled
                      value={parent.value}
                      options={parents}
                      name={`parents[${parent.value}]`}
                    />
                  </Grid>
                </Grid>
              );
            })}
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6} data-testid="feature-platforms">
              <FormMultiSelect
                name="platforms"
                disabled
                value="platforms"
                control={control}
                label="Platforms"
                options={FEATURE_PLATFORMS_OPTIONS}
                required
                onChange={() => {}}
                onRemove={() => {}}
              />
            </Grid>
          </Grid>

          {platforms.map((platform) => (
            <Grid
              item
              xs={6}
              width="50%"
              data-testid={`${platform}-jira-epicId`}
              key={platform}
            >
              <FormInput
                name={`${
                  platform.toLowerCase() === "dev"
                    ? "devOps"
                    : platform.toLowerCase()
                }EpicId`}
                label={`${platform} Jira Epic ID`}
                type="text"
                disabled
                control={control}
              />
            </Grid>
          ))}
        </Grid>
        <ConfirmationDialog
          title="Delete Feature?"
          body="Are you sure you want to delete this feature?"
          open={openDeleteDialog}
          close={() => setOpenDeleteDialog(false)}
          onCancel={() => setOpenDeleteDialog(false)}
          onConfirm={() => onFeatureDelete()}
          cancelBtnText="No"
          confirmBtnText="Yes"
          confirmBtnVariant="admin-error"
          icon="error"
        />
      </Container>
    </Loader>
  );
};
