import { Box, IconButton, Modal, styled } from "@mui/material";
import { CloseIcon } from "@components/Icons";
import { Header, HeaderText } from "../components/CreateMeetingModal";
import { useAdminCrmOrderAgreementAgreementIdCreateSession } from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";

export const StyledBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "hidden",
  width: "850px",
  minHeight: "90%",
  backgroundColor: "white",
  height: "554px",
  flexDirection: "column",
  zIndex: 9999,
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

export const OrderAgreementViewer = ({
  header,
  open,
  setOpen,
  agreementId
}: {
  header: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  agreementId: string;
}) => {
  const { data: data, isLoading: isLoading } =
    useAdminCrmOrderAgreementAgreementIdCreateSession(agreementId!, {
      readOnly: true
    });
  return (
    <Modal open={open}>
      <Loader isLoading={isLoading}>
        <StyledBox>
          <Header>
            <HeaderText>{header}</HeaderText>
            <IconButton
              onClick={() => {
                setOpen(false);
                //setRefreshKey(refreshKey + 1);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Header>
          <iframe
            width="100%"
            height="100%"
            src={
              data?.data
                ? `https://app.pandadoc.com/s/${data.data.session?.id}/`
                : ""
            }
          />
        </StyledBox>
      </Loader>
    </Modal>
  );
};
