/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelMedia,
  useAdminAdvertiserIdAdvertisementPost,
  useAdminAdvertiserIdGet,
  useAdminAdvertiserIdStatusPost,
  useAdminAdvertiserPost,
  useAdminSettingsGet,
  useConfigGet,
  useLookupOgPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormSelect } from "@components/FormSelect";
import {
  AD_LAYOUT_OPTIONS,
  AD_PLACEMENT_OPTIONS,
  CALL_TO_ACTION_OPTIONS
} from "@utils/constants";
import { useEffect, useState } from "react";
import { FormInput } from "@components/FormInput";
import { Backdrop, FormHelperText, Typography } from "@mui/material";
import { LivePreview } from "@components/LivePreview";
import { styled } from "@mui/material";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { Loader } from "@components/crud/Loader";
import { WEBSITE_REGEX } from "@utils/validation";
const StyledForm = styled(Form)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column !important"
  }
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "50% !important",
  [theme.breakpoints.down("xl")]: {
    width: "100% !important"
  }
}));
const StyledLivePreview = styled(Grid)(({ theme }) => ({
  width: "500px !important",
  marginLeft: "24px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "75% !important"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px",
    marginTop: "24px",
    width: "100% !important"
  }
}));
export const AdvertisementCreate = (props: { form?; logo? }) => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { advertiserId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [openAdvertiserInactiveWarning, setOpenAdvertiserInactiveWarning] =
    useState(false);
  const [adplacement, setAdPlacement] = useState("");
  const [loading, setLoading] = useState(false);
  const [adLayout, setAdLayout] = useState("");
  const [adDetails, setAdDetails] = useState({
    title: "",
    description: "",
    media: undefined,
    logo: props.logo ? props.logo : undefined,
    redirect: undefined,
    linkText: "",
    actionButton: undefined,
    businessName: props.form ? props.form.getValues("businessName") : undefined
  });
  const { data: advertiser } = useAdminAdvertiserIdGet(advertiserId!);
  const [file, setFile] = useState<File | ModelMedia | null>(null);
  const statusoptions = [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ];
  const form = props.form
    ? props.form
    : useForm({
        mode: "onBlur",
        defaultValues: {
          name: "",
          icon: "",
          advertisements: {
            status: "INACTIVE"
          },
          primaryContact: true,
          layout: ""
        }
      });
  const { data: setting, isLoading: settingLoading } = organizationId
    ? useAdminSettingsGet({
        organizationId: organizationId,
        parentId: "org-live-stream"
      })
    : useConfigGet();
  useEffect(() => {
    if (advertiser?.data.businessName)
      setAdDetails((details) => ({
        ...details,
        businessName: advertiser?.data.businessName
      }));
    if (advertiser?.data.logoId) {
      setAdDetails((details) => ({
        ...details,
        logo:
          advertiser.data.logo?.baseUrl &&
          advertiser?.data.logo?.baseUrl + advertiser?.data.logo?.path
      }));
    }
  }, [advertiser]);
  const { mutate: lookup } = useLookupOgPost();
  const lookupHandler = async () => {
    if (adDetails.redirect && adDetails.redirect !== "") {
      lookup(
        {
          data: {
            url: adDetails.redirect
          }
        },
        {
          onSuccess: (data) => {
            setMetaData(data.data);
          }
        }
      );
    }
  };
  const { mutate: changeStatus } = useAdminAdvertiserIdStatusPost();
  const changeStatusHandler = async () => {
    try {
      await changeStatus({
        advertiserId: advertiserId!,
        data: {
          status: "ACTIVE"
        }
      });
      enqueueSnackbar("Advertiser status changed successfully!", {
        variant: "success"
      });
    } catch (error) {
      enqueueSnackbar("Failed to change advertiser status!", {
        variant: "error"
      });
    }
  };
  const { mutate: save, isLoading: isSaving } = props.form
    ? useAdminAdvertiserPost()
    : useAdminAdvertiserIdAdvertisementPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      for (const key in formValues) {
        if (formValues[key] === null) {
          delete formValues[key];
        }
      }
      let values = {
        ...formValues,
        ...(organizationId && { organizationId: organizationId })
      };

      setLoading(true);
      const filesPromises = await Promise.all(
        [file].concat(props.logo).map((file) => {
          if (file instanceof File) {
            const promise = uploadMediaUsingPresignedUrl(file);
            return promise;
          }
        })
      );
      setLoading(false);
      if (filesPromises[1]) values["logoId"] = filesPromises[1];
      if (!values["secondaryContact"]) delete values["secondaryContact"];
      values["advertisements"].mediaId = filesPromises[0];
      values["advertisements"].duration =
        Number(values["advertisements"].duration) || undefined;
      values["advertisements"].isApproved =
        form.getValues("isPrimaryApprovalRequired") ||
        form.getValues("isSecondaryApprovalRequired")
          ? false
          : true;
      values["advertisements"] = [values["advertisements"]];
      if (!props.form) {
        values = values["advertisements"][0];
        values.isApproved = false;
      }

      if (
        advertiser?.data.status == "INACTIVE" &&
        values["status"] == "ACTIVE"
      ) {
        changeStatusHandler();
      }

      try {
        save(
          //@ts-ignore
          {
            //@ts-ignore
            ...(!props.form && { advertiserId: advertiserId! }),
            data: values
          },
          {
            onSuccess: () => {
              setLoading(false);
              enqueueSnackbar(
                `${
                  props.form ? "Advertiser" : "Advertisement"
                } added successfully!`,
                {
                  variant: "success"
                }
              );
              if (resetInsteadOfRoute) {
                form.reset();
                setFile(null);
                //@ts-ignore
                if (inputFileRef.current) {
                  //@ts-ignore
                  inputFileRef.current.value = "";
                }
              } else {
                if (props.form) navigate("/advertisers");
                if (!props.form) navigate(`/advertisers/${advertiserId}`);
              }
            },
            onError: () => {
              setLoading(false);
              enqueueSnackbar(
                `Failed to add ${
                  props.form ? "Advertiser" : "Advertisement"
                } !`,
                {
                  variant: "error"
                }
              );
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
      }
    };
  const onImageChange = (
    file: any,
    field: { onChange: (arg0: { target: { value: any } }) => void }
  ) => {
    if (!file) {
      setFile(null);
      setAdDetails((details) => ({
        ...details,
        media: undefined
      }));
      field.onChange({ target: { value: file } });
      return;
    }
    setFile(file);
    field.onChange({ target: { value: file } });
    setAdDetails((details) => ({
      ...details,
      media: file
    }));
  };
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const resetFile = () => {
    setFile(null);
    setAdDetails((details) => ({
      ...details,
      media: undefined
    }));
    form.setValue("file", undefined);
  };

  return (
    <Loader isLoading={settingLoading}>
      <Container data-testid="advertisement-add-form">
        <Toolbar title="Add Advertisement" />
        <StyledForm style={{ flexDirection: "row" }}>
          <StyledGrid
            data-testid="advertisement-add-form"
            container
            direction={"column"}
          >
            <Grid xs={12} data-testid="advertisement-placment">
              <FormSelect
                control={form.control}
                name="advertisements.placement"
                type="text"
                label="Ad Placement"
                options={
                  organizationId
                    ? AD_PLACEMENT_OPTIONS.filter(
                        (option) => option.value !== "FEED_POST"
                      )
                    : AD_PLACEMENT_OPTIONS
                }
                required={true}
                rules={{
                  required: "Ad Placement is required"
                }}
                onChange={(e) => {
                  setAdPlacement(e.target.value);
                  setAdLayout("");
                  form.setValue("advertisements.layout", "");
                  form.clearErrors();
                  resetFile();
                }}
              />
            </Grid>
            {adplacement !== "INSTREAM" && adplacement != "" && (
              <Grid
                xs={12}
                data-testid="advertisement-layout"
                marginTop={"25px"}
              >
                <FormSelect
                  control={form.control}
                  name="advertisements.layout"
                  label="Ad Layout"
                  required={true}
                  options={
                    AD_LAYOUT_OPTIONS.filter((option) =>
                      option.adType.includes(adplacement)
                    ) || []
                  }
                  rules={{
                    required: "Ad Layout is required"
                  }}
                  onChange={(e) => {
                    const newLayout = e.target.value;
                    //@ts-ignore
                    setMetaData(null);
                    if (
                      adLayout.includes("Video") &&
                      !newLayout.includes("Video")
                    )
                      resetFile();

                    if (
                      adLayout.includes("Photo") &&
                      !newLayout.includes("Photo")
                    )
                      resetFile();

                    setAdLayout(newLayout);
                  }}
                />
              </Grid>
            )}
            {adplacement == "INSTREAM" && (
              <Grid
                xs={12}
                data-testid="advertisement-duration"
                marginTop={"25px"}
              >
                <FormSelect
                  label="Duration"
                  control={form.control}
                  name="advertisements.duration"
                  required={true}
                  rules={{ required: "Duration is required" }}
                  options={[
                    { label: "6 seconds", value: "6" },
                    { label: "15 seconds", value: "15" },
                    { label: "30 seconds", value: "30" }
                  ]}
                />
              </Grid>
            )}
            {adplacement != "" &&
              (adLayout != "" || adplacement == "INSTREAM") &&
              adLayout != "Link" &&
              adLayout != "Text_Ad_with_Logo" &&
              adLayout != "Text_Ad_without_Logo" && (
                <Grid
                  xs={12}
                  data-testid="advertisement-media"
                  sx={{ marginTop: "25px" }}
                >
                  <FormInput
                    control={form.control}
                    name="file"
                    type="file"
                    label={
                      adplacement == "INSTREAM" || adLayout.includes("Video")
                        ? " Video"
                        : "Image"
                    }
                    required={true}
                    rules={{
                      required:
                        adplacement == "INSTREAM" || adLayout.includes("Video")
                          ? "Video is Required"
                          : "Image is Required"
                    }}
                    // @ts-ignore
                    onChange={onImageChange}
                    value={file}
                    InputProps={{
                      accept:
                        adplacement == "INSTREAM" || adLayout.includes("Video")
                          ? ".mp4"
                          : ".png, .jpeg, .jpg"
                    }}
                  />
                  <FormHelperText
                    style={{
                      color: "#B3B3B3",
                      fontSize: "13px",
                      marginLeft: "5px"
                    }}
                  >
                    Supported formats:{" "}
                    {adplacement == "INSTREAM" || adLayout.includes("Video")
                      ? "MP4"
                      : "JPG, JPEG, PNG"}
                  </FormHelperText>
                </Grid>
              )}
            {adplacement == "FEED_POST" && (
              <>
                <Grid
                  xs={12}
                  data-testid="advertisement-introCopy"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={form.control}
                    name="advertisements.description"
                    rules={{
                      maxLength: {
                        value: 150,
                        message: "Ad Intro Copy must be 150 characters or less"
                      },
                      required: false
                    }}
                    TextProps={{
                      inputProps: {
                        maxLength: 150
                      }
                    }}
                    label="Ad Intro Copy"
                    type="text"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        description: e.target.value
                      }));
                    }}
                  />
                  <FormHelperText
                    style={{
                      fontSize: "12px",
                      textAlign: "right"
                    }}
                  >
                    <Typography
                      style={{ color: "#000", display: "inline" }}
                    ></Typography>{" "}
                    Characters Remaining:{" "}
                    <Typography
                      style={{
                        fontSize: "12px",
                        display: "inline",
                        color: "#000",
                        marginLeft: "10px"
                      }}
                    >
                      {150 - adDetails.description.length}
                    </Typography>
                  </FormHelperText>
                </Grid>
              </>
            )}
            {adplacement != "" &&
              (adplacement !== "FEED_POST" || adLayout.includes("Link")) && (
                <Grid
                  xs={12}
                  data-testid="advertisement-redirect"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={form.control}
                    name="advertisements.redirectLink"
                    label="Ad Redirect Link"
                    type="text"
                    required={adLayout.includes("Link")}
                    rules={{
                      required: adLayout.includes("Link")
                        ? "Ad Redirect Link is required"
                        : false,
                      validate: (value) =>
                        value && value != "" && !WEBSITE_REGEX.test(value)
                          ? "Enter a valid link"
                          : undefined
                    }}
                    onChange={(e) => {
                      //@ts-ignore
                      if (e.target.value == "") setMetaData(null);

                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        redirect: e.target.value
                      }));
                    }}
                    onBlur={() => {
                      if (adLayout == "Link") lookupHandler();
                    }}
                  />
                </Grid>
              )}
            {adplacement == "OUTSTREAM" &&
              (adLayout == "Text_Ad_with_Logo" ||
                adLayout == "Text_Ad_without_Logo" ||
                adLayout.includes("Contextual")) && (
                <>
                  <Grid
                    xs={12}
                    data-testid="advertisement-title"
                    marginTop={"25px"}
                  >
                    <FormInput
                      control={form.control}
                      name="advertisements.title"
                      required
                      rules={{
                        required: "Ad Title is required",
                        maxLength: {
                          value: 40,
                          message: "Title must be 40 characters or less"
                        }
                      }}
                      TextProps={{
                        inputProps: {
                          maxLength: 40
                        }
                      }}
                      label="Ad Title"
                      type="text"
                      onChange={(e) => {
                        setAdDetails((details) => ({
                          ...details,
                          //@ts-ignore
                          title: capitalizeFirstLetter(e.target.value)
                        }));
                      }}
                    />
                    <FormHelperText
                      style={{
                        fontSize: "12px",
                        textAlign: "right"
                      }}
                    >
                      <Typography
                        style={{ color: "#000", display: "inline" }}
                      ></Typography>{" "}
                      Characters Remaining:{" "}
                      <Typography
                        style={{
                          fontSize: "12px",
                          display: "inline",
                          color: "#000",
                          marginLeft: "10px"
                        }}
                      >
                        {40 - adDetails.title.length}
                      </Typography>
                    </FormHelperText>
                  </Grid>
                  <Grid
                    xs={12}
                    data-testid="advertisement-description"
                    marginTop={"25px"}
                  >
                    <FormInput
                      control={form.control}
                      name="advertisements.description"
                      required
                      rules={{
                        required: "Ad Description is required",
                        maxLength: {
                          value: 100,
                          message: "Description must be 100 characters or less"
                        }
                      }}
                      TextProps={{
                        inputProps: {
                          maxLength: 100
                        }
                      }}
                      label="Ad Description"
                      type="text"
                      onChange={(e) => {
                        setAdDetails((details) => ({
                          ...details,
                          //@ts-ignore
                          description: e.target.value
                        }));
                      }}
                    />
                    <FormHelperText
                      style={{
                        fontSize: "12px",
                        textAlign: "right"
                      }}
                    >
                      <Typography
                        style={{ color: "#000", display: "inline" }}
                      ></Typography>{" "}
                      Characters Remaining:{" "}
                      <Typography
                        style={{
                          fontSize: "12px",
                          display: "inline",
                          color: "#000",
                          marginLeft: "10px"
                        }}
                      >
                        {100 - adDetails.description.length}
                      </Typography>
                    </FormHelperText>
                  </Grid>
                </>
              )}
            {adplacement == "FEED_POST" && adLayout.includes("Link") && (
              <>
                <Grid
                  xs={12}
                  data-testid="advertisement-callToActionTitle"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={form.control}
                    name="advertisements.title"
                    required
                    rules={{
                      required: "Call To Action Title is required",
                      maxLength: {
                        value: 30,
                        message: "Call To Action must be 30 characters or less"
                      }
                    }}
                    TextProps={{
                      inputProps: {
                        maxLength: 30
                      }
                    }}
                    label="Call To Action Title"
                    type="text"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        title: e.target.value
                      }));
                    }}
                  />
                  <FormHelperText
                    style={{
                      fontSize: "12px",
                      textAlign: "right"
                    }}
                  >
                    <Typography
                      style={{ color: "#000", display: "inline" }}
                    ></Typography>{" "}
                    Characters Remaining:{" "}
                    <Typography
                      style={{
                        fontSize: "12px",
                        display: "inline",
                        color: "#000",
                        marginLeft: "10px"
                      }}
                    >
                      {30 - adDetails.title.length}
                    </Typography>
                  </FormHelperText>
                </Grid>
                <Grid
                  xs={12}
                  data-testid="advertisement-linkText"
                  marginTop={"25px"}
                >
                  <FormInput
                    control={form.control}
                    name="advertisements.linkText"
                    rules={{
                      maxLength: {
                        value: 30,
                        message:
                          "Call To Action Url text must be 30 characters or less"
                      }
                    }}
                    TextProps={{
                      inputProps: {
                        maxLength: 30
                      }
                    }}
                    label="Call To Action URL Text"
                    type="text"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        linkText: e.target.value
                      }));
                    }}
                  />
                  <FormHelperText
                    style={{
                      fontSize: "12px",
                      textAlign: "right"
                    }}
                  >
                    <Typography
                      style={{ color: "#000", display: "inline" }}
                    ></Typography>{" "}
                    Characters Remaining:{" "}
                    <Typography
                      style={{
                        fontSize: "12px",
                        display: "inline",
                        color: "#000",
                        marginLeft: "10px"
                      }}
                    >
                      {30 - adDetails.linkText.length}
                    </Typography>
                  </FormHelperText>
                </Grid>
                <Grid
                  xs={12}
                  data-testid="advertisement-actionButton"
                  marginTop={"25px"}
                >
                  <FormSelect
                    control={form.control}
                    name="advertisements.actionButton"
                    required
                    rules={{
                      required: "Call To Action Button is required"
                    }}
                    options={CALL_TO_ACTION_OPTIONS}
                    label="Call To Action Button"
                    onChange={(e) => {
                      setAdDetails((details) => ({
                        ...details,
                        //@ts-ignore
                        actionButton: e.target.value
                      }));
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid
              xs={12}
              data-testid="advertisement-status"
              marginTop={"25px"}
              marginBottom={"25px"}
            >
              <FormSelect
                control={form.control}
                required
                name="advertisements.status"
                label="Status"
                options={statusoptions}
                disabled={
                  form.control._formValues.status
                    ? true
                    : organizationId
                    ? setting?.data
                        .filter(
                          //@ts-ignore
                          (s) =>
                            //@ts-ignore
                            s.settingId ===
                            "org-live-stream.advertiser-approval-required"
                        )[0]
                        //@ts-ignore
                        .organizationSettings?.filter(
                          (s) => s.organizationId === organizationId
                        ).length > 0
                      ? (setting?.data
                          .filter(
                            //@ts-ignore
                            (s) =>
                              //@ts-ignore
                              s.settingId ===
                              "org-live-stream.advertiser-approval-required"
                          )[0]
                          //@ts-ignore
                          .organizationSettings?.filter(
                            (s) => s.organizationId === organizationId
                          )[0].value as boolean)
                      : (setting?.data.filter(
                          //@ts-ignore
                          (s) =>
                            //@ts-ignore
                            s.settingId ===
                            "org-live-stream.advertiser-approval-required"
                        )[0].default as boolean)
                    : (setting &&
                        (setting.data?.find(
                          //@ts-ignore
                          (conf: ModelConfig) =>
                            conf.key ===
                            "live-stream.advertiser-approval-required"
                        )?.value as boolean)) ||
                      false
                }
                onChange={(e) => {
                  if (
                    advertiser?.data.status == "INACTIVE" &&
                    e.target.value === "ACTIVE"
                  ) {
                    setOpenAdvertiserInactiveWarning(true);
                  } else {
                    setOpenAdvertiserInactiveWarning(false);
                  }
                }}
                rules={{
                  required: "Status is required"
                }}
              />
            </Grid>
          </StyledGrid>
          <StyledLivePreview xs={12}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              Live Preview
            </Typography>
            <LivePreview
              metaData={metaData}
              layout={adLayout}
              placement={adplacement}
              adDetails={adDetails}
            />
          </StyledLivePreview>
        </StyledForm>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={form.handleSubmit(saveHandler(false))}
          isDisabled={!form.formState.isValid || isSaving || loading}
          isLoading={isSaving || loading}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => {
            if (props.form) navigate("/advertisers");
            if (!props.form) navigate(`/advertisers/${advertiserId}`);
          }}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
        <ConfirmationDialog
          open={openAdvertiserInactiveWarning}
          title="Active Advertiser Required"
          body={`To add an active advertisement for ${advertiser?.data.businessName} the advertiser status must be set to “Active”. Do you want to continue?`}
          close={() => setOpenAdvertiserInactiveWarning(false)}
          onConfirm={() => {
            form.setValue("advertisements.status", "ACTIVE");
          }}
          onCancel={() => {
            setOpenAdvertiserInactiveWarning(false);
            form.setValue("advertisements.status", "INACTIVE");
          }}
          isConfirming={false}
          confirmBtnVariant="admin-error"
          icon="error"
        />
        {loading && (
          <Backdrop
            sx={{
              overflow: "hidden",
              overflowY: "none",
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={true}
          >
            <LoadingSpinner />
          </Backdrop>
        )}
      </Container>
    </Loader>
  );
};
