import { Loader } from "@components/crud/Loader";
import { Grid, styled } from "@mui/material";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import {
  useAdminSettingsGet,
  useAdminSettingsPut,
  useLookupCountryCountryIdSubdivisionSubdivisionIdTimezoneGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { FormSelect } from "@components/FormSelect";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import { Edit } from "@mui/icons-material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";

const StyledGrid = styled(Grid)`
  padding: 0 24px;
  .toolbar {
    padding: 24px 0 0 0;
  }
`;

export const CalendarSetting = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [organizationId] = useRecoilState(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [permission, setPermission] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );

  const { data: settings, isLoading: isLoading } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "general"
  });

  const { data: timezone, isLoading: isLoadingTimeZone } =
    useLookupCountryCountryIdSubdivisionSubdivisionIdTimezoneGet(
      organization!.country!,
      organization!.province!,
      {
        city: organization!.locality
      }
    );

  const { reset, control, getValues } = form;

  useEffect(() => {
    if (settings && timezone) {
      const settingsMap = settings.data.map((setting) => {
        return {
          settingId: setting.settingId,
          value:
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : timezone.data
        };
      });
      const defaultValues = {
        selectedSettings: settingsMap
      };

      reset(defaultValues, {
        keepDefaultValues: false
      });
    }
  }, [settings, timezone]);

  const { mutate: save, isLoading: isSaving } = useAdminSettingsPut();

  const onSave = () => {
    const data = getValues().selectedSettings;
    save(
      {
        data: data,
        params: {
          organizationId: organizationId!
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
          setDisabled(true);
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setPermission(edit);
    };
    fetchPermissions();
  }, []);
  return (
    <Loader isLoading={isLoading || isLoadingTimeZone}>
      <StyledGrid item container direction="column" rowSpacing="24px">
        {permission && disabled && (
          <Toolbar
            title=""
            addBtnClick={() => setDisabled(false)}
            addBtnLabel="Edit"
            addBtnIcon={<Edit />}
          />
        )}
        {settings &&
          settings.data.map((setting, index) => {
            if (setting.type === "SINGLE_SELECT") {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  key={setting.settingId}
                  data-testid={setting.settingId}
                >
                  <Grid item xs={6}>
                    <FormSelect
                      control={control}
                      disabled={
                        setting.settingId === "general.org-local-timezone" ||
                        disabled
                      }
                      name={`selectedSettings[${index}].value`}
                      options={
                        setting.options as { label: string; value: string }[]
                      }
                      label={setting.description}
                    />
                  </Grid>
                </Grid>
              );
            }
          })}
      </StyledGrid>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => setDisabled(true)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {!disabled && (
        <Footer
          saveBtnClick={onSave}
          isLoading={isSaving}
          isDisabled={isSaving}
          saveBtnLabel="Save"
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
    </Loader>
  );
};
