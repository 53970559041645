import { Loader } from "@components/crud/Loader";
import { CloseIcon } from "@components/Icons";
import { IconButton, Modal, styled } from "@mui/material";
import { QuickAddContactForm } from "@pages/crm/components/QuickAddContactForm";
import { organizationAtom } from "@recoil/auth";
import {
  useGetAdminCrmAccountAccountId,
  useGetAdminCrmOrgAccountAccountId
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilValue } from "recoil";

export const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

export const Header = styled("div")({
  width: 1000,
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

export const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

export const QuickAddContactModal = ({
  showAddContactModal,
  setShowAddContactModal,
  selectedAccount,
  onSave,
  accountName
}: {
  showAddContactModal: boolean;
  selectedAccount: string;
  setShowAddContactModal;
  onSave;
  accountName?: string;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const { data: accountDetails, isLoading: isLoading } = accountName
    ? { data: null, isLoading: false }
    : organizationId
    ? useGetAdminCrmOrgAccountAccountId(selectedAccount)
    : useGetAdminCrmAccountAccountId(selectedAccount);
  return (
    <Modal
      open={showAddContactModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Loader isLoading={isLoading}>
        <MainContainer>
          <Header>
            <HeaderText>{`Quick Add Contact for ${
              accountName || accountDetails?.data?.name || "{Account}"
            }`}</HeaderText>
            <IconButton
              onClick={() => {
                setShowAddContactModal(false);
                //setRefreshKey(refreshKey + 1);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Header>

          <QuickAddContactForm
            onClose={() => {
              setShowAddContactModal(false);
            }}
            onSave={(resp) => {
              setShowAddContactModal(false);
              onSave(resp);
            }}
            accountId={selectedAccount}
          />
        </MainContainer>
      </Loader>
    </Modal>
  );
};
