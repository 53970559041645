import {
  Backdrop,
  Box,
  Container,
  TextField,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colors from "theme/colors";
import Grid from "@mui/system/Unstable_Grid";
import { Button } from "@components/Button";
import { enqueueSnackbar } from "notistack";
import FacebookIcon from "@assets/icons/socialFacebookIcon.svg";
import InstagramIcon from "@assets/icons/socialInstaIcon.svg";
import MailIcon from "@assets/icons/socialMailIcon.svg";
import MessageIcon from "@assets/icons/socialMessageIcon.svg";
import { useMediaGenerateShareLink } from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { Loader } from "./crud/Loader";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  maxWidth: "650px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& .footer": {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px"
  }
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

const StyledSocialIcon = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
`;

const copyToClipboard = (text: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar(`Link copied to clipboard`, {
        variant: "success"
      });
    });
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      const successful = document.execCommand("copy");
      if (successful)
        enqueueSnackbar(`Link copied to clipboard`, {
          variant: "success"
        });
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textarea);
  }
};

export const ShareModal = ({
  onClose,
  mediaIds
}: {
  onClose: () => void;
  mediaIds: string[];
}) => {
  const [url, setUrl] = useState<string>("");
  const { mutate, isLoading } = useMediaGenerateShareLink();

  useEffect(() => {
    mutate(
      {
        data: {
          mediaIds: mediaIds
        }
      },
      {
        onSuccess: (data) => {
          setUrl(data.data);
        }
      }
    );
  }, []);

  const openMailClient = () => {
    const subject = encodeURIComponent("Check out this link");
    const body = encodeURIComponent(`I found this interesting: ${url}`);
    const mailtoUrl = `mailto:test@gmail.com?subject=${subject}&body=${body}`;
    window.open(mailtoUrl, "_blank");
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  Share
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Grid container spacing={3} padding="24px" zIndex="1000">
            <Loader isLoading={isLoading}>
              <Grid xs={12} sx={{ display: "flex", gap: "20px" }}>
                <StyledSocialIcon>
                  <img src={MessageIcon} />
                  <Typography>Messages</Typography>
                </StyledSocialIcon>
                <StyledSocialIcon>
                  <img src={FacebookIcon} />
                  <Typography>Facebook</Typography>
                </StyledSocialIcon>
                <StyledSocialIcon onClick={openMailClient}>
                  <img src={MailIcon} />
                  <Typography>Mail</Typography>
                </StyledSocialIcon>
                <StyledSocialIcon>
                  <img
                    src={InstagramIcon}
                    style={{
                      width: "32px"
                    }}
                  />
                  <Typography>Instagram</Typography>
                </StyledSocialIcon>
              </Grid>
              <Grid xs={12}>
                <TextField
                  value={url}
                  variant="outlined"
                  fullWidth
                  disabled
                  sx={{
                    ".MuiInputBase-input": {
                      textOverflow: "ellipsis"
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="admin-primary"
                        onClick={() => copyToClipboard(url)}
                      >
                        Copy
                      </Button>
                    )
                  }}
                />
              </Grid>
            </Loader>
          </Grid>
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
