import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import MediaContianerWithDelete from "@components/MediaContaierWithDelete";
import {
  Box,
  Typography,
  styled,
  FormLabel,
  FormHelperText
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminAdvertiserIdGet,
  useSponsorshipLevelGet,
  useAdminAdvertiserBusinessCategoryGet,
  useAdminAdvertiserAdvertiserIdPut,
  useAdminSettingsGet,
  ModelMedia,
  useAdminAdvertiserIdStatusPost,
  useConfigGet,
  ModelConfig,
  useLookupCountryGet,
  ModelOrganizationAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import logoPickerImage from "@assets/images/iconPicker.png";
import { FormSelect } from "@components/FormSelect";
import { FormCheckbox } from "@components/FormCheckbox";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { Loader } from "@components/crud/Loader";
import {
  capitalizeEveryWord,
  capitalizeFirstCharacter
} from "@utils/capitalize";
import { EMAIL_REGEX } from "@utils/validation";
import { formatCurrency } from "@utils/formatCurrency";
import { SearchAddAccount } from "@pages/crm/components/SearchAddAccount";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
export const AdvertiserEdit = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const currentOrg = organizations.find(
    (org) => org.organizationId == organizationId
  );
  const navigate = useNavigate();
  const { advertiserId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [statusChangeToInactive, setStatusChangeToInactive] = useState(false);
  const [hasSecondaryContact, setHasSecondaryContact] = useState(false);
  const [iconFieldTouched, setIconFieldTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: advertiser, isLoading: advertiserLoading } =
    useAdminAdvertiserIdGet(advertiserId!);
  const { data: levels, isLoading: levelLoading } = useSponsorshipLevelGet({
    organizationId: organizationId!
  });
  const { data: setting, isLoading: settingLoading } = organizationId
    ? useAdminSettingsGet({
        organizationId: organizationId,
        parentId: "org-live-stream"
      })
    : useConfigGet();
  const { data: businessCategory, isLoading: businessCategoryLoading } =
    useAdminAdvertiserBusinessCategoryGet();
  const levelsOptions = useMemo(
    () =>
      levels?.data?.sponsorshipLevels.map((level) => ({
        label: `${level.name!} - ${formatCurrency(level.amount)}`,
        value: level.sponsorshipId
      })) || [],
    [levels]
  );
  const businessCategoryOptions = useMemo(
    () =>
      businessCategory?.data.map((category) => ({
        label: (category as { label: string; value: string }).label,
        value: (category as { label: string; value: string }).value
      })) || [],
    [businessCategory]
  );

  const [file, setFile] = useState<File | ModelMedia | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const statusoptions = [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ];
  const [selectedAccount, setSelectedAccount] =
    useState<ModelOrganizationAccount>();
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      icon: "",
      businessName: "",
      status: "",
      logoId: "",
      businessCategoryId: "",
      sponsorshipId: "",
      isPermissionRequired: false,
      isPrimaryApprovalRequired: false,
      isSecondaryApprovalRequired: false,
      contact: [],
      primaryContact: {
        firstName: "",
        lastName: "",
        workPhone: "",
        personalPhone: "",
        workEmail: "",
        personalEmail: ""
      },
      secondaryContact: {
        firstName: "",
        lastName: "",
        workPhone: "",
        personalPhone: "",
        workEmail: "",
        personalEmail: ""
      }
    }
  });
  useEffect(() => {
    if (advertiser?.data) {
      const defaultValues = {
        businessName: advertiser.data.businessName,
        status: advertiser.data.status,
        logoId: advertiser.data.logoId,
        businessCategoryId: advertiser.data.businessCategoryId,
        sponsorshipId: advertiser.data.sponsorshipId,
        isPrimaryApprovalRequired: advertiser.data.isPrimaryApprovalRequired,
        isSecondaryApprovalRequired:
          advertiser.data.isSecondaryApprovalRequired,
        ...(organizationId
          ? {
              contact: advertiser.data.contacts?.map((c) => {
                const accountContact = c.contact?.accounts?.find(
                  (a) => a.accountId === advertiser.data.accountId
                );
                return {
                  contactId: c.contactId!,
                  firstName: c.contact?.firstName || "",
                  lastName: c.contact?.lastName || "",
                  personalPhone: c.contact?.phone || "",
                  workPhone: accountContact?.workPhone || "",
                  personalEmail: c.contact?.email || "",
                  isApprovalRequired: c.isApprovalRequired
                };
              })
            }
          : {
              primaryContact: {
                firstName: advertiser.data.primaryContact?.firstName,
                lastName: advertiser.data.primaryContact?.lastName,
                workPhone: advertiser.data.primaryContact?.workPhone,
                personalPhone:
                  advertiser.data.primaryContact?.personalPhone || "",
                workEmail: advertiser.data.primaryContact?.workEmail,
                personalEmail:
                  advertiser.data.primaryContact?.personalEmail || "",
                contactId: advertiser.data.primaryContact?.contactId
              }
            }),

        secondaryContact: false,
        isPermissionRequired: false
      };
      if (organizationId) {
        setSelectedAccount(advertiser.data.account);
      }
      //@ts-ignore
      if (advertiser.data.secondaryContact) {
        setHasSecondaryContact(true);
        //@ts-ignore
        defaultValues["secondaryContact"] = {
          firstName: advertiser.data.secondaryContact?.firstName,
          lastName: advertiser.data.secondaryContact?.lastName,
          workPhone: advertiser.data.secondaryContact?.workPhone,
          personalPhone: advertiser.data.secondaryContact?.personalPhone || "",
          workEmail: advertiser.data.secondaryContact?.workEmail,
          personalEmail: advertiser.data.secondaryContact?.personalEmail || "",
          contactId: advertiser.data.secondaryContact?.contactId
        };
      }
      if (advertiser.data.logo) {
        setFile(advertiser.data.logo);
      }
      if (setting?.data) {
        if (organizationId) {
          const config =
            setting?.data
              .filter(
                //@ts-ignore
                (s) =>
                  //@ts-ignore
                  s.settingId === "org-live-stream.advertiser-approval-required"
              )[0]
              //@ts-ignore
              .organizationSettings?.filter(
                (s) => s.organizationId === organizationId
              ).length > 0
              ? (setting?.data
                  .filter(
                    //@ts-ignore
                    (s) =>
                      //@ts-ignore
                      s.settingId ===
                      "org-live-stream.advertiser-approval-required"
                  )[0]
                  //@ts-ignore
                  .organizationSettings?.filter(
                    (s) => s.organizationId === organizationId
                  )[0].value as boolean)
              : (setting?.data.filter(
                  //@ts-ignore
                  (s) =>
                    //@ts-ignore
                    s.settingId ===
                    "org-live-stream.advertiser-approval-required"
                  //@ts-ignore
                )[0].default as boolean);
          if (config == true) defaultValues.isPermissionRequired = config;
        } else {
          const config = setting.data?.find(
            //@ts-ignore
            (conf: ModelConfig) =>
              conf.key === "live-stream.advertiser-approval-required"
          )?.value;
          if (config == true)
            defaultValues.isPermissionRequired = config as boolean;
        }
      }
      reset(defaultValues);
    }
  }, [advertiser, setting]);

  const { mutate: changeStatus } = useAdminAdvertiserIdStatusPost();
  const changeStatusHandler = async () => {
    try {
      await changeStatus({
        advertiserId: advertiserId!,
        data: {
          status: "INACTIVE"
        }
      });
      enqueueSnackbar("Advertiser status changed successfully!", {
        variant: "success"
      });
    } catch (error) {
      enqueueSnackbar("Failed to change advertiser status!", {
        variant: "error"
      });
    }
  };
  const { mutate: save, isLoading: isSaving } =
    useAdminAdvertiserAdvertiserIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      for (const key in formValues) {
        if (formValues[key] === null) {
          delete formValues[key];
        }
      }
      const values = {
        ...formValues,
        ...(organizationId && { organizationId: organizationId })
      };

      if (!values["secondaryContact"]) delete values["secondaryContact"];
      if (file && !file.mediaId) {
        setLoading(true);
        const promise = await uploadMediaUsingPresignedUrl(file);
        values["logoId"] = promise;
      }
      if (advertiser?.data.logo && !file) {
        values["logoId"] = null;
      }
      if (!hasSecondaryContact) {
        delete values["secondaryContact"];
        delete values["secondaryContactId"];
        values["isSecondaryApprovalRequired"] = false;
      }
      if (
        (values["status"] == "INACTIVE" &&
          advertiser.data?.status == "ACTIVE") ||
        (values["status"] == "ACTIVE" && advertiser.data?.status == "INACTIVE")
      ) {
        changeStatusHandler();
      }
      try {
        save(
          {
            advertiserId: advertiserId!,
            data: !organizationId
              ? values
              : {
                  organizationId,
                  accountId: selectedAccount!.accountId!,
                  businessName: selectedAccount!.name,
                  contacts: values.contact.map((c) => ({
                    contactId: c.contactId,
                    isApprovalRequired: c.isApprovalRequired
                  })),
                  logoId: values.logoId,
                  status: values.status,
                  sponsorshipId: values.sponsorshipId,
                  businessCategoryId: values.businessCategoryId
                }
          },
          {
            onSuccess: () => {
              setLoading(false);
              enqueueSnackbar("Advertiser edited successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
                setFile(null);
                if (inputFileRef.current) {
                  inputFileRef.current.value = "";
                }
              } else {
                navigate(`/advertisers/${advertiserId}`);
              }
            },
            onError: () => {
              setLoading(false);
              enqueueSnackbar("Failed to edit advertiser!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to generate image data!", {
          variant: "error"
        });
      }
    };
  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    field.onChange(event);
    if (tempFiles && tempFiles[0]) setFile(tempFiles[0]);
  };

  const { data: countriesResponse, isFetching: isFetchingCountries } =
    useLookupCountryGet({
      query: {
        staleTime: Infinity
      }
    });

  const contactDetails = (type: "primaryContact" | "secondaryContact") => {
    return (
      <>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`${type}_firstName`}>
            <FormInput
              name={`${type}.firstName`}
              control={control}
              rules={{ required: "First Name is required" }}
              label="First Name"
              type="text"
              required
              onChange={(e) => {
                setValue(
                  //@ts-ignore
                  `${type}.firstName`,
                  capitalizeFirstCharacter(
                    (e as ChangeEvent<HTMLInputElement>)?.target.value
                  )
                );
              }}
            />
          </Grid>
          <Grid xs={6} data-testid={`${type}_lastName`}>
            <FormInput
              name={`${type}.lastName`}
              control={control}
              rules={{ required: "Last Name is required" }}
              label="Last Name"
              type="text"
              required
              onChange={(e) => {
                //@ts-ignore
                setValue(
                  `${type}.lastName`,
                  capitalizeFirstCharacter(
                    (e as ChangeEvent<HTMLInputElement>)?.target.value
                  )
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`${type}_workPhone`}>
            <FormInput
              name={`${type}.workPhone`}
              required
              control={control}
              label="Work Phone"
              rules={{
                required: "Work Phone is required",
                validate: (value) =>
                  value === getValues(`${type}.personalPhone`)
                    ? "Work Phone and Personal Phone cannot be the same"
                    : undefined
              }}
              type="tel"
              {...(organizationId && {
                country: countriesResponse?.data.find(
                  (country) => country.countryId === currentOrg?.country
                )
              })}
            />
          </Grid>
          <Grid xs={6} data-testid={`${type}_personalPhone`}>
            <FormInput
              name={`${type}.personalPhone`}
              control={control}
              label="Mobile Phone"
              type="tel"
              rules={{
                validate: (value) =>
                  value
                    ? value === getValues(`${type}.workPhone`)
                      ? "Work Phone and Personal Phone cannot be the same"
                      : undefined
                    : undefined
              }}
              {...(organizationId && {
                country: countriesResponse?.data.find(
                  (country) => country.countryId === currentOrg?.country
                )
              })}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`${type}_workEmail`}>
            <FormInput
              name={`${type}.workEmail`}
              control={control}
              rules={{
                required: "Work Email is required",
                validate: (value) =>
                  value
                    ? value === getValues(`${type}.personalEmail`)
                      ? "Work Email and Personal Email cannot be the same"
                      : !EMAIL_REGEX.test(value)
                      ? "Invalid Email"
                      : undefined
                    : undefined
              }}
              label="Work Email"
              type="text"
              required
            />
          </Grid>
          <Grid xs={6} data-testid={`${type}_personalEmail`}>
            <FormInput
              name={`${type}.personalEmail`}
              control={control}
              label="Personal Email"
              type="text"
              rules={{
                validate: (value) =>
                  value
                    ? value === getValues(`${type}.workEmail`)
                      ? "Work Email and Personal Email cannot be the same"
                      : !EMAIL_REGEX.test(value)
                      ? "Invalid Email"
                      : undefined
                    : undefined
              }}
            />
          </Grid>
          <Grid xs={12} data-testid={`${type}_hasPermission`}>
            <FormCheckbox
              name={
                type == "primaryContact"
                  ? "isPrimaryApprovalRequired"
                  : "isSecondaryApprovalRequired"
              }
              control={control}
              disabled={
                type == "primaryContact" && getValues().isPermissionRequired
              }
              label="Permission to approve and decline advertisements"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const crmContactDetails = (index: number) => {
    return (
      <>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].firstName`}>
            <FormInput
              name={`contact[${index}].firstName`}
              control={control}
              rules={{ required: "First Name is required" }}
              label="First Name"
              type="text"
              required
              disabled
            />
          </Grid>
          <Grid xs={6} data-testid={`contact[${index}].lastName`}>
            <FormInput
              name={`contact[${index}].lastName`}
              control={control}
              rules={{ required: "Last Name is required" }}
              label="Last Name"
              type="text"
              required
              disabled
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].workPhone`}>
            <FormInput
              name={`contact[${index}].workPhone`}
              required
              control={control}
              label="Work Phone"
              disabled
              type="tel"
            />
          </Grid>
          <Grid xs={6} data-testid={`contact[${index}].personalPhone`}>
            <FormInput
              name={`contact[${index}].personalPhone`}
              control={control}
              disabled
              label="Mobile Phone"
              type="tel"
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="24px">
          <Grid xs={6} data-testid={`contact[${index}].personalEmail`}>
            <FormInput
              name={`contact[${index}].personalEmail`}
              control={control}
              label="Personal Email"
              type="text"
              disabled
            />
          </Grid>
          <Grid xs={12} data-testid={`contact[${index}].hasPermission`}>
            <FormCheckbox
              name={`contact[${index}].isApprovalRequired`}
              control={control}
              disabled={index === 0}
              label="Permission to approve and decline advertisements"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Loader
      isLoading={advertiserLoading || settingLoading || isFetchingCountries}
    >
      <Container>
        <Toolbar title="Edit Advertiser" />
        <Form>
          <Grid data-testid="advertiser-edit-form" container spacing={3}>
            <Grid xs={12} md={12}>
              <Controller
                name="icon"
                control={control}
                render={({ field }) => (
                  <FormInputContainer>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Advertiser Logo"}
                      </Typography>
                    </StyledFormLabel>
                    <Grid container spacing={3} xs={12} md={12}>
                      <Grid xs={12} md={2}>
                        {file ? (
                          <MediaContianerWithDelete
                            onConfirmDelete={() => {
                              inputFileRef.current.value = "";
                              setFile(null);
                            }}
                            index={0}
                          >
                            <img
                              src={
                                file.mediaId
                                  ? file.baseUrl + file.path
                                  : URL.createObjectURL(file)
                              }
                              style={{
                                width: "96px",
                                height: "96px"
                              }}
                            />
                          </MediaContianerWithDelete>
                        ) : (
                          <img
                            src={logoPickerImage}
                            style={{
                              width: "96px",
                              height: "96px"
                            }}
                            onClick={() => {
                              setIconFieldTouched(true);
                              inputFileRef.current?.click();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <input
                      type="file"
                      onChange={(e) => onImageChange(e, field)}
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    {iconFieldTouched && !file && (
                      <FormHelperText error>
                        Advertiser Logo is required
                      </FormHelperText>
                    )}
                  </FormInputContainer>
                )}
              />
            </Grid>
            {!organizationId ? (
              <Grid xs={12} md={6} data-testid="advertiser-name">
                <FormInput
                  control={control}
                  name="businessName"
                  type="text"
                  label="Business Name"
                  required={true}
                  rules={{
                    required: "Business Name is required"
                  }}
                  onChange={(e) => {
                    setValue(
                      "businessName",
                      capitalizeEveryWord(
                        (e as ChangeEvent<HTMLInputElement>)?.target.value
                      )
                    );
                  }}
                />
              </Grid>
            ) : (
              <Grid xs={12} md={12}>
                <SearchAddAccount
                  accountSelected={() => {}}
                  disabled
                  showAddAccountOption={false}
                  searchValue={selectedAccount ? selectedAccount.name : ""}
                  label="Search and Select Organization"
                  required={true}
                  organizationId={organizationId}
                />
              </Grid>
            )}

            <Grid
              container={!!organizationId}
              xs={organizationId ? 12 : 6}
              md={organizationId ? 12 : 6}
              direction="row"
            >
              <Grid xs={12} md={6} data-testid="advertiser-category">
                <FormSelect
                  control={control}
                  name="businessCategoryId"
                  label="Business Category"
                  required={true}
                  isLoading={businessCategoryLoading}
                  options={businessCategoryOptions}
                  rules={{
                    required: "Business Category is required"
                  }}
                />
              </Grid>
            </Grid>

            <Grid xs={12} md={6} data-testid="advertiser-level">
              <FormSelect
                control={control}
                name="sponsorshipId"
                label="Sponsorship Level"
                required={true}
                options={levelsOptions}
                isLoading={levelLoading}
                rules={{
                  required: "Sponsorship Level is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="advertiser-status">
              <FormSelect
                control={control}
                required
                name="status"
                label="Status"
                options={statusoptions}
                disabled={
                  advertiser?.data.status == "INACTIVE" &&
                  advertiser.data.advertisements?.filter((ad) => ad.isApproved)
                    .length === 0
                }
                rules={{
                  required: "Status is required"
                }}
                onChange={(e) => {
                  if (
                    advertiser?.data.status == "ACTIVE" &&
                    e.target.value === "INACTIVE"
                  ) {
                    if (
                      advertiser.data.advertisements &&
                      advertiser.data.advertisements?.filter(
                        (ad) => ad.status == "ACTIVE"
                      ).length > 0
                    )
                      setStatusChangeToInactive(true);
                  } else {
                    setStatusChangeToInactive(false);
                  }
                }}
              />
            </Grid>
          </Grid>
          {organizationId ? (
            <>
              {advertiser?.data?.contacts?.map((c, idx) => (
                <>
                  <Grid
                    container
                    direction="row"
                    marginTop={idx === 0 ? "18px" : "26px"}
                  >
                    <Grid xs={9} container>
                      <Grid>
                        <FormCheckbox
                          name="contact"
                          control={control}
                          disabled
                        />
                      </Grid>
                      <Grid marginLeft={"5px"}>
                        <FormLabel
                          style={{
                            color: "#000",
                            fontWeight: 400,
                            fontSize: "12px",
                            letterSpacing: "1.2px",
                            opacity: "0.5"
                          }}
                        >
                          {" "}
                          PRIMARY CONTACT
                        </FormLabel>
                      </Grid>
                    </Grid>
                    <HeaderUnderLine />
                  </Grid>
                  <Box key={c.contactId} marginTop={"24px"}>
                    {crmContactDetails(idx)}
                  </Box>
                </>
              ))}
            </>
          ) : (
            <>
              <Grid container direction="row" marginTop={"10px"}>
                <Grid xs={9} container>
                  <Grid>
                    <FormCheckbox
                      name="primaryContact"
                      control={control}
                      disabled
                    />
                  </Grid>
                  <Grid marginLeft={"5px"}>
                    <FormLabel
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        fontSize: "12px",
                        letterSpacing: "1.2px",
                        opacity: "0.5"
                      }}
                    >
                      {" "}
                      PRIMARY CONTACT
                    </FormLabel>
                  </Grid>
                </Grid>
                <HeaderUnderLine />
              </Grid>
              <Box marginTop={"24px"}>{contactDetails("primaryContact")}</Box>
            </>
          )}
          {!organizationId && (
            <Grid container direction="row" marginTop={"29px"}>
              <Grid xs={9} container>
                <Grid>
                  <FormCheckbox
                    name="secondaryContact"
                    control={control}
                    onChange={(e) => {
                      setHasSecondaryContact(e.target.checked);
                    }}
                  />
                </Grid>
                <Grid marginLeft={"5px"}>
                  <FormLabel
                    style={{
                      color: "#000",
                      fontWeight: 400,
                      fontSize: "12px",
                      letterSpacing: "1.2px",
                      opacity: "0.5"
                    }}
                  >
                    {" "}
                    SECONDARY CONTACT
                  </FormLabel>
                </Grid>
              </Grid>
              <HeaderUnderLine />
            </Grid>
          )}
          {!organizationId && hasSecondaryContact && (
            <Box marginTop={"24px"}>{contactDetails("secondaryContact")}</Box>
          )}
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          saveAndNewBtnLabel="Save & Continue"
          isDisabled={isSaving || loading || !isValid || !isDirty || !file}
          isLoading={isSaving || loading}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => navigate("/advertisers")}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
        <ConfirmationDialog
          open={statusChangeToInactive}
          title="Change Advertiser Status"
          body={`By making making this advertise Inactive, you will be effectively inactivating all of their existing advertisements. Are you sure you want to continue?`}
          close={() => setStatusChangeToInactive(false)}
          onConfirm={() => {
            setValue("status", "INACTIVE");
          }}
          onCancel={() => {
            setStatusChangeToInactive(false);
            setValue("status", "ACTIVE");
          }}
          isConfirming={false}
          confirmBtnVariant="admin-error"
          icon="error"
        />
      </Container>
    </Loader>
  );
};
