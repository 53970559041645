import { TableView } from "@components/TableView";
import { Typography } from "@mui/material";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { organizationAtom } from "@recoil/auth";
import { getSponsorshipLevel } from "@services/Network";
import {
  ModelSponsorshipLevel,
  useAdminSponsorshipLevelOrderPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatCurrency } from "@utils/formatCurrency";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
const StyledTypography = styled(Typography)(() => ({
  fontSize: "13px"
}));

export const SponsorshipLevel = () => {
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = React.useState(0);

  const [organizationId] = useRecoilState(organizationAtom);
  const LIST_COLUMNS = [
    { headerName: "Name", field: "name", minWidth: 120, flex: 1 },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row }) => formatCurrency(row.amount)
    },
    {
      headerName: "Ad Placements",
      field: "placement",
      minWidth: 400,
      sortable: false,
      flex: 1,
      cellClassName: "multiple-line-cell",
      renderCell: (params) => (
        <>
          {!organizationId && params.row.feedPostEnabled && (
            <>
              <StyledTypography>
                Feed Post Ad - Feed/Recruiting Feed Posts
              </StyledTypography>{" "}
              <br />
            </>
          )}
          {params.row.sponsoredByEnabled && (
            <>
              <StyledTypography>
                Sponsored By Ad - Live Stream Event Feed Posts
              </StyledTypography>{" "}
              <br />
            </>
          )}
          {params.row.inStreamEnabled && (
            <>
              <StyledTypography>
                In-Stream Ad - Live Streaming Intermissions
              </StyledTypography>{" "}
              <br />
            </>
          )}
          {params.row.outStreamEnabled && (
            <>
              <StyledTypography>
                Out-Stream Ad - View Live Stream Screen
              </StyledTypography>{" "}
            </>
          )}
        </>
      )
    },
    {
      headerName: "Is Active",
      field: "isActive",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row }) => (row.isActive ? "Yes" : "No")
    }
  ];

  const [rows, setRows] = React.useState<ModelSponsorshipLevel[]>([]);
  const onAdd = () => navigate("/sponsorship-levels/create");
  const onEdit = (sponsor) =>
    navigate(`/sponsorship-levels/${sponsor.sponsorshipId}/edit`);
  const onView = (sponsor) =>
    navigate(`/sponsorship-levels/${sponsor.sponsorshipId}`);

  const orderMutation = useAdminSponsorshipLevelOrderPut();
  const onRowOrderChange = (params: GridRowOrderChangeParams) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);
    setRows([...rowsClone]);
    orderMutation.mutate(
      {
        data: rowsClone.map(
          (sponsorshipLevel) => sponsorshipLevel.sponsorshipId!
        )
      },
      {
        onSuccess: () => {
          setRefreshKey(refreshKey + 1);
        },
        onError: () => {
          enqueueSnackbar(
            "Something went wrong! Failed to sort sponsorship levels.",
            {
              variant: "error"
            }
          );
          setRefreshKey(refreshKey + 1);
        }
      }
    );
  };

  const filterConfig = {
    field: "isActive",
    placeholderOption: {
      label: "All",
      value: ""
    },
    defaultValue: true,
    options: [
      {
        label: "Active",
        value: true
      },
      {
        label: "Inactive",
        value: false
      }
    ]
  };

  return (
    <>
      <TableView
        title="Sponsorship Levels"
        useGet={getSponsorshipLevel}
        columns={LIST_COLUMNS}
        defaultSort={[{ field: "order", sort: "asc" }]}
        onAdd={rows.length < 4 ? onAdd : undefined}
        getRowHeight={() => 150}
        isDeleteDisabled={() => true}
        getRowId={(row) => row.sponsorshipId}
        onEdit={onEdit}
        onView={onView}
        rowReordering
        rows={rows}
        setRows={(data) => setRows([...data])}
        onRowOrderChange={onRowOrderChange}
        refreshKey={refreshKey}
        filterConfig={filterConfig}
        hideLabelContainer={true}
        setAddLimit={4}
      />
    </>
  );
};
