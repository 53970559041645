import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import { useState } from "react";
import { FormInput } from "./FormInput";

export const PasswordInput = ({
  name,
  control,
  rules,
  label,
  required,
  disabled,
  TextProps,
  disablePasswordView
}: {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any;
  label: string;
  required: boolean;
  disabled?: boolean;
  TextProps?: TextFieldProps;
  disablePasswordView?: boolean;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormInput
      name={name}
      control={control}
      rules={rules}
      label={label}
      required={required}
      disabled={disabled}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
              disabled={disablePasswordView}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      TextProps={TextProps}
    />
  );
};
