import Check from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { convertSecondsToDuration } from "@utils/convertsecondsToDuration";
export type GalleryItemType = {
  id: string;
  type: "IMAGE" | "VIDEO";
  date: string;
  thumbnailSrc: string;
  duration?: number;
  mediaDetails?: ModelMedia;
};

export const GalleryItem = ({
  item,
  isSelected,
  onSelect,
  clickToSelect,
  onClick
}: {
  item: GalleryItemType;
  isSelected?: boolean;
  onSelect: (id: string) => void;
  clickToSelect?: boolean;
  onClick: (id: string) => void;
}) => {
  return (
    <div
      className={`gallery-item ${isSelected ? "selected" : ""}`}
      onClick={() => {
        if (clickToSelect) onSelect(item.id);
        else onClick(item.id);
      }}
    >
      {/* Show image for images, thumbnail for videos */}
      <img src={item.thumbnailSrc} alt="Thumbnail" className="media-content" />

      {/* For video duration */}
      {item.type === "VIDEO" && (
        <span className="duration">
          {convertSecondsToDuration(item.duration)}
        </span>
      )}

      {/* Selection circle */}
      <div className={`select-circle ${isSelected ? "checked" : ""}`}>
        <IconButton
          onClick={(e) => {
            onSelect(item.id);
            e.stopPropagation();
          }}
        >
          <Check fontSize="small" htmlColor="white" />
        </IconButton>
      </div>
    </div>
  );
};
