import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, IconButton, styled } from "@mui/material";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import UndoIcon from "@assets/icons/undoIcon.svg";
import RedoIcon from "@assets/icons/redoIcon.svg";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { enqueueSnackbar } from "notistack";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";

window.Quill = Quill;
let quillObj: ReactQuill;

const StyledReactQuill = styled(ReactQuill)`
  height: 100% !important;
  .ql-toolbar {
    position: relative;
    background: #f3f4f7;
    width: fit-content;
    border: none !important;
    border-radius: 5px;
    padding: 8px;
  }
  .ql-container {
    border: none !important;
    box-shadow: none;
    border-radius: 6px;
    padding: 14px 24px;
  }
  .ql-editor {
    padding: 0px;
    height: 30vh;
    cursor: text;
  }
`;

const StyledDiv = styled("div")`
  #toolbar .secondary-toolbar .ql-color-picker .ql-picker-options {
    color: #000;
    top: -110px;
    background: #fff;
    height: 106px;
  }
  #toolbar .ql-picker .ql-picker-options {
    color: #000;
    top: -110px;
    background: #fff;
    height: 106px;
  }
  #toolbar .ql-size .ql-picker-options {
    color: #000;
    top: -170px;
    background: #fff;
    height: 170px;
  }
`;

const StyledButtonContianer = styled("span")`
  border-right: 1px solid #e5e5e5;
`;

export const EmailEditor = ({
  style,
  disabled,
  html,
  onValueChange,
  onBlur,
  onClose,
  files,
  setFiles,
  onSubmit,
  isSending
}: {
  style?;
  disabled?: boolean;
  html?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange?: (deltaOps: any[] | undefined, hasMedia: boolean) => void;
  onBlur?: () => void;
  onClose?: () => void;
  files?: { id: string; file: File | ModelMedia }[];
  setFiles?: (files: { id: string; file: File | ModelMedia }[]) => void;
  onSubmit: () => void;
  isSending?: boolean;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");

  const generateRandomString = () => {
    const array = new BigInt64Array(1);
    window.crypto.getRandomValues(array);
    return array[0].toString(36);
  };

  const handleAttachmentClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const onAddFile = (event: ChangeEvent<HTMLInputElement>) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    if (tempFiles) {
      const filesArray = Array.from(tempFiles);
      const newFiles = filesArray.map((file) => {
        return { id: generateRandomString(), file };
      });
      if (setFiles && files) setFiles([...files, ...newFiles]);
    }
    event.target.value = "";
  };

  const removeFile = (id: string) => {
    if (files && setFiles) setFiles(files.filter((file) => file.id !== id));
  };

  const onChange = (e) => {
    const imgRegex = /<img.*?>/g;
    const hasMedia = imgRegex.test(e);
    setValue(e);
    if (onValueChange)
      onValueChange(quillObj.editor?.getContents().ops, hasMedia);
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: "#toolbar"
      },
      history: {
        delay: 1000,
        maxStack: 1000
      },
      clipboard: {
        matchVisual: false
      },
      ...(disabled
        ? {}
        : {
            imageResize: {
              parchment: Quill.import("parchment"),
              modules: ["Resize", "DisplaySize", "Toolbar"]
            }
          })
    };
  }, [disabled]);

  useEffect(() => {
    if (html) {
      setValue(html);
    }
  }, [html]);

  const downloadFile = async (media) => {
    if (media instanceof File) {
      const fileName = media.name || "download";
      const downloadUrl = URL.createObjectURL(media);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
      return;
    }
    const url = media.baseUrl! + media.path;
    const fileName = media.metadata?.fileName || "download";

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName; // You can change the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error downloading the file");
    }
  };

  const attachmentsList = () => {
    return (
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "10px 0",
          maxHeight: "110px",
          overflow: "auto",
          borderTop: "1px solid #E5E5E5"
        }}
      >
        {files?.map((file) => {
          return (
            <div
              key={file.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#F3F4F7",
                padding: "10px",
                height: "37px",
                textOverflow: "ellipsis",
                borderRadius: "4px"
              }}
            >
              <div style={{ fontSize: "14px", fontWeight: 600 }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(file.file);
                  }}
                  style={{
                    color: "#007AFF",
                    cursor: "pointer",
                    textDecoration: "none"
                  }}
                >
                  {file.file instanceof File
                    ? file.file.name
                    : file.file.metadata?.fileName || file.id}
                </a>{" "}
                {file.file instanceof File && (
                  <span>
                    (
                    {file.file.size < 1024 * 1024
                      ? `${(file.file.size / 1024).toFixed(2)} KB`
                      : `${(file.file.size / (1024 * 1024)).toFixed(2)} MB`}
                    )
                  </span>
                )}
              </div>
              <IconButton
                onClick={() => removeFile(file.id)}
                disabled={disabled}
              >
                <CloseIcon
                  style={{
                    color: "#000",
                    height: "16px",
                    width: "16px",
                    cursor: "pointer"
                  }}
                />
              </IconButton>
            </div>
          );
        })}
      </div>
    );
  };

  const toolbar = () => {
    return (
      <div
        id="toolbar"
        style={{
          display: "flex",
          flexDirection: "column",
          border: "none",
          gap: "15px"
        }}
      >
        <div
          style={{
            boxShadow: "0px 4px 8px 0px #00000029",
            height: "44px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <StyledButtonContianer style={{ paddingRight: "5px" }}>
            <button
              className="ql-undo"
              style={{ paddingTop: "0" }}
              onClick={() => quillObj.getEditor().history.undo()}
            >
              <img src={UndoIcon} />
            </button>
            <button
              className="ql-redo"
              style={{ paddingTop: "0" }}
              onClick={() => quillObj.getEditor().history.redo()}
            >
              <img src={RedoIcon} />
            </button>
          </StyledButtonContianer>
          <StyledButtonContianer>
            <select className="ql-font"></select>
          </StyledButtonContianer>
          <StyledButtonContianer>
            <select className="ql-size"></select>
          </StyledButtonContianer>
          <StyledButtonContianer>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <select className="ql-color"></select>
          </StyledButtonContianer>
          <StyledButtonContianer>
            <select className="ql-align"></select>
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-indent" value="-1"></button>
            <button className="ql-indent" value="+1"></button>
          </StyledButtonContianer>
          <button className="ql-blockquote"></button>
          <button className="ql-strike"></button>
        </div>
        <div
          className="secondary-toolbar"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
            alignItems: "center"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              className="save-button"
              style={{
                background: "#2B337A",
                height: "35px",
                borderRadius: "99px",
                color: "#fff",
                marginRight: "10px",
                textTransform: "capitalize"
              }}
              disabled={isSending}
              onClick={() => {
                onSubmit();
              }}
            >
              {isSending ? <LoadingSpinner /> : <>Send</>}
            </Button>
            <button className="ql-attachment" onClick={handleAttachmentClick}>
              <AttachFileRoundedIcon />
              <input
                type="file"
                multiple
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={onAddFile}
              />
            </button>
            <select className="ql-background"></select>
            <button className="ql-link"></button>
            <button className="ql-code-block"></button>
          </div>
          <div>
            <IconButton onClick={onClose}>
              <DeleteOutlineRoundedIcon
                style={{ color: "#666", height: "22px", width: "22px" }}
              />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StyledDiv>
      <StyledReactQuill
        ref={(el) => {
          quillObj = el;
        }}
        value={value}
        readOnly={disabled}
        style={style ? style : {}}
        onChange={onChange}
        onBlur={() => {
          if (onBlur) onBlur();
        }}
        modules={modules}
      ></StyledReactQuill>
      {files && files.length > 0 && attachmentsList()}
      {toolbar()}
    </StyledDiv>
  );
};
