import { Avatar, Grid, styled } from "@mui/material";
import { Box } from "@mui/system";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import TeamIcon from "../../../src/assets/icons/teamIcon.svg";
const ProfileThumbnail = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main
}));

const StyledPersonOption = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`
}));

export const FilterTeamTpItem = ({
  teamProgram,
  ...props
}: {
  teamProgram: {
    id: string;
    name: string;
    type: "TEAM" | "TRAINING_PROGRAM";
  };
}) => {
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((org) => org.organizationId === orgId);

  let thumbnailContent;
  if (org && org.avatarId) {
    thumbnailContent = (
      <Avatar
        variant="square"
        style={{ width: "32px", height: "32px" }}
        src={org.avatar?.baseUrl + org.avatar?.path}
        alt="Team Icon"
      />
    );
  } else {
    thumbnailContent = (
      <Avatar
        variant="square"
        style={{ width: "32px", height: "32px" }}
        src={TeamIcon}
        alt="Team Icon"
      />
    );
  }
  return (
    <StyledPersonOption
      container
      {...props}
      direction="row"
      alignItems="center"
    >
      <Grid item xs={1}>
        <ProfileThumbnail>{thumbnailContent}</ProfileThumbnail>
      </Grid>
      <Grid item xs={11}>
        <span>{teamProgram?.name}</span>
        <br />
        <span style={{ color: "#64748B" }}>
          {teamProgram.type === "TEAM" ? "Team" : "Training Program"}
        </span>
      </Grid>
    </StyledPersonOption>
  );
};
