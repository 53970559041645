import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { hasPermission } from "@services/Casbin";
import { getCrmContacts, getCrmOrgContacts } from "@services/Network";
import {
  ContactResponse,
  ModelAccountContact,
  ModelContact,
  ModelPerson
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Grid, IconButton, styled, Typography } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import formatFullName from "@utils/formatFullName";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import GoogleMap from "@components/GoogleMap";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const StyledLink = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Contacts = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const { setEmailTo, setContact } = useContext(CallEmailTemplateContext);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const [addressToView, setAddressToView] = useState<string | undefined>(
    undefined
  );
  const onAdd = () => navigate("/crm/contacts/create");
  const onEdit = (contact) =>
    navigate(`/crm/contacts/${contact.contactId}/edit`);
  const onView = (contact) => navigate(`/crm/contacts/${contact.contactId}`);

  const ACCOUNTS_COLUMNS: GridColDef<ContactResponse>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: organizationId ? 100 : 250,
      flex: 0.75,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0" }}>
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Contact" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton onClick={() => onEdit(params.row)}>
                <ToolTip title="Edit Contact" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {!organizationId && (
              <>
                <IconButton
                  onClick={() => {
                    if (params.row) setContact(params.row as ModelContact);
                  }}
                >
                  <ToolTip
                    title={`Click to Call ${params.row.firstName} ${params.row.lastName}`}
                    placement="top-start"
                  >
                    <CallIcon style={IconStyle} />
                  </ToolTip>
                </IconButton>
                {!(
                  !params.row.email &&
                  !params.row.accounts?.find((acc) => acc.workEmail)
                ) && (
                  <IconButton
                    onClick={() =>
                      setEmailTo({
                        to: params.row.email,
                        name: formatFullName(
                          params.row as {
                            firstName: string;
                            middleName: string;
                            lastName: string;
                            suffix: string;
                          }
                        ),
                        accountId: undefined,
                        relatesTo: "CONTACT",
                        contactId: params.row.contactId,
                        autoSuggestOptions: [
                          ...params.row
                            .accounts!.filter((acc) => acc.workEmail)
                            .map((acc) => ({
                              label: formatFullName(params.row as ModelPerson),
                              text: acc.workEmail,
                              value: params.row.contactId!,
                              isInternal: false,
                              emailDetails: {
                                email: acc.workEmail,
                                emailInfo: `Work Email For ${acc.account?.name}`,
                                isPrimaryStar: acc.isPrimary
                              }
                            })),
                          ...(params.row.email
                            ? [
                                {
                                  label: formatFullName(
                                    params.row as ModelPerson
                                  ),
                                  text: params.row.email,
                                  value: params.row.contactId!,
                                  isInternal: false,
                                  emailDetails: {
                                    email: params.row.email,
                                    emailInfo: "Personal Email"
                                  }
                                }
                              ]
                            : [])
                        ]
                      })
                    }
                  >
                    <ToolTip
                      title={`Click to Email ${formatFullName(params.row)}`}
                      placement="top-start"
                    >
                      <EmailIcon style={IconStyle} />
                    </ToolTip>
                  </IconButton>
                )}
              </>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 175,
      renderHeader: () => <div style={{ paddingLeft: "3px" }}>Name</div>,
      flex: 1,
      valueGetter: ({ row }) => formatFullName(row)
    },
    {
      headerName: "Related Accounts",
      field: "accounts",
      minWidth: 150,
      flex: 1.5,
      sortable: false,
      renderCell: ({ row }) => (
        <Grid container direction="column" spacing="10px">
          {(row.accounts as ModelAccountContact[])?.map((acc) => (
            <Grid item key={acc.accountId}>
              {`${acc.jobTitle} ${acc.jobTitle ? "at" : ""}`}{" "}
              {acc.account?.name}
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      headerName: "Account Address",
      field: "location",
      minWidth: organizationId ? 300 : 250,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Grid container direction="column" spacing="10px" padding="10px 0">
          {row.accounts?.map((acc) => (
            <Grid item key={acc.accountId}>
              <StyledLink
                onClick={() => setAddressToView(acc.account?.officeAddress)}
              >
                {acc.account?.officeAddress}
              </StyledLink>
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      headerName: "Is Primary",
      field: "isPrimary",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Grid container direction="column" spacing="10px">
          {row.accounts?.map((acc) => (
            <Grid item key={acc.accountId}>
              {acc.isPrimary ? "Yes" : "No"}
            </Grid>
          ))}
        </Grid>
      )
    }
  ];

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.contacts", "ADD");
      const edit = await checkPermission("crm.contacts", "EDIT");
      const view = await checkPermission("crm.contacts", "VIEW");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "My Contacts",
        value: "mine"
      }
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Contacts"
          useGet={organizationId ? getCrmOrgContacts : getCrmContacts}
          columns={ACCOUNTS_COLUMNS}
          getRowId={(row) => row.contactId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
        />
        {addressToView && (
          <GoogleMap
            close={() => setAddressToView(undefined)}
            address={addressToView}
            showSearchBar={false}
            showClickedAddress={false}
          />
        )}
      </Loader>
    </>
  );
};
