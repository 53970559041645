import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Footer } from "@components/crud/Footer";
import { EmailTemplateForm } from "./emailTemplateForm";
import { uploadAdminMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import {
  ModelMedia,
  useAdminEmailTemplateTemplateIdGet,
  useAdminEmailTemplateTemplateIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";

export const EmailTemplateEdit = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const {
    reset,
    getValues,
    formState: { isDirty, isValid }
  } = form;

  console.log(templateId);
  const [departmentId, setDepartmentId] = useState<string>();
  const { data: templateData, isLoading: isLoading } =
    useAdminEmailTemplateTemplateIdGet(templateId!);
  React.useEffect(() => {
    if (templateData?.data) {
      const template = templateData.data;
      setDepartmentId(template.departmentId);
      setFiles(
        template.attachments?.map((a) => ({
          id: a.mediaId!,
          file: a
        })) || []
      );
      reset({
        name: template.name,
        isActive: template.isActive,
        departmentId: template.departmentId,
        jobTitleId: template.jobTitleId,
        subject: template.subject,
        description: template.body
      });
    }
  }, [templateData]);
  const [files, setFiles] = useState<{ id: string; file: File | ModelMedia }[]>(
    []
  );
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [isUploadingFiles, setUploadingFiles] = useState(false);
  const { mutate: save, isLoading: isSaving } =
    useAdminEmailTemplateTemplateIdPut();
  const onSave = async () => {
    const formValues = getValues();
    const data = {
      name: formValues.name,
      departmentId: formValues.departmentId,
      jobTitleId: formValues.jobTitleId,
      isActive: formValues.isActive,
      subject: formValues.subject,
      body: formValues.description,
      attachments: [] as string[]
    };
    if (files.length > 0) {
      setUploadingFiles(true);
      const filesPromises = await Promise.all(
        files.map(async (file) => {
          if (file.file instanceof File) {
            const promise = uploadAdminMediaUsingPresignedUrl(file.file);
            return promise;
          } else if (file.file?.mediaId) return file.id;
        })
      );
      setUploadingFiles(false);
      data.attachments = filesPromises as string[];
    }
    save(
      {
        templateId: templateId!,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Email Template Saved!", {
            variant: "success"
          });
          navigate("/email-templates");
        },
        onError: () => {
          enqueueSnackbar("Failed to Save Email Template!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Container>
      <Toolbar title="Edit Email Template" />
      <Loader isLoading={isLoading}>
        <EmailTemplateForm
          form={form}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          files={files}
          setFiles={setFiles}
        />
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isDirty || !isValid}
        isLoading={isUploadingFiles || isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/email-templates")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
