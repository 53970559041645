import { Typography } from "@mui/material";

export const withDataGridRenderLocation = (options) => {
  // eslint-disable-next-line react/display-name
  return ({
    row
  }: {
    row: {
      country: string;
      locality: string;
      province: string;
    };
  }) => {
    const { country, locality, province } = row;

    if (options.displayFullCountry && options.countries) {
      return (
        <>
          <Typography fontSize="12px">
            {`${locality}, ${province}`}
            <br />
            {`${
              options.countries?.find((c) => c.countryId === country)?.name ||
              country
            }`}
          </Typography>
        </>
      );
    }
    return (
      <Typography fontSize="12px">{`${locality}, ${province}, ${country}`}</Typography>
    );
  };
};

export const DataGridRenderLocation = ({
  row
}: {
  row: {
    country: string;
    locality: string;
    province: string;
  };
}) => {
  const { country, locality, province } = row;

  return (
    <Typography fontSize="12px">{`${locality}, ${province}, ${country}`}</Typography>
  );
};
