/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from "@mui/material";
import UnLinkIcon from "@assets/icons/unLink.svg";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

import React, { useContext, useEffect, useState } from "react";
import {
  AccountCallerDetails,
  CallEmailTemplateContext
} from "@templates/CallEmailTemplate";
import { FormSelect } from "@components/FormSelect";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";

const StyledOrgName = styled("div")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  text-align: left;
`;

const StyledOrgLocation = styled("div")`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666;
  width: 100%;
  text-align: left;
  margin-top: 6px;
`;

export const CallContactDetails = ({
  account,
  showTimer,
  accounts = []
}: {
  account: AccountCallerDetails;
  showTimer?: boolean;
  accounts?: {
    label: string;
    value: string | undefined;
    isPrimary: boolean;
    location: string;
  }[];
}) => {
  const { setAddNewContact, callStatus, setCallerDetails } = useContext(
    CallEmailTemplateContext
  );
  const [callTime, setCallTime] = useState(0);
  const [accountSelected, setSelectedAccount] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showTimer) {
      timer = setInterval(() => {
        setCallTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setCallTime(0);
    }
    return () => clearInterval(timer);
  }, [showTimer]);

  useEffect(() => {
    if (account.accountId) {
      setSelectedAccount(account.accountId);
    }
  }, [account]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  return (
    <>
      {account.showContactDetails !== false && (
        <>
          {account.accountName && account.location && account?.isConnected && (
            <div style={{ width: "100%" }}>
              <StyledOrgName>{account.accountName}</StyledOrgName>
              <StyledOrgLocation>{account.location}</StyledOrgLocation>
            </div>
          )}
          {(account?.accountName || account?.location) &&
            !account?.isConnected && (
              <div style={{ width: "100%" }}>
                <div
                  style={{ display: "flex", alignItems: "left", gap: "4px" }}
                >
                  <span style={{ height: "18px", width: "18px" }}>
                    <img src={UnLinkIcon} />
                  </span>
                  <StyledOrgName
                    style={{ color: "#007AFF", cursor: "pointer" }}
                    onClick={() => setAddNewContact(account)}
                  >
                    {account.accountName
                      ? account?.accountName
                      : "Unknown Account"}
                  </StyledOrgName>
                </div>
                <StyledOrgLocation>Unknown Location</StyledOrgLocation>
              </div>
            )}
          {!account.accountName &&
            !account.location &&
            accounts?.length == 0 && (
              <div style={{ width: "100%" }}>
                <StyledOrgName
                  style={{ color: "#007AFF", cursor: "pointer" }}
                  onClick={() => setAddNewContact(account)}
                >
                  Unknown Account
                </StyledOrgName>
                <StyledOrgLocation>Unknown Location</StyledOrgLocation>
              </div>
            )}
          {accounts?.length > 0 && !accountSelected && (
            <FormSelect
              options={accounts}
              name=""
              onChange={(e) => {
                const selectedAccount = accounts.find(
                  (acc) => acc.value == e.target.value
                );

                setCallerDetails({
                  ...account,
                  accountId: e.target.value,
                  accountName: selectedAccount?.label,
                  location: selectedAccount?.location,
                  isConnected: selectedAccount?.value ? true : false,
                  contact: {
                    ...account.contact,
                    isPrimary: selectedAccount?.isPrimary
                  }
                });
                setSelectedAccount(e.target.value);
              }}
            />
          )}
        </>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "left",
          gap: "10px",
          width: "100%",
          justifyContent: showTimer || callStatus ? "space-between" : "left"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "6px"
            }}
          >
            {account?.contact?.contactName && (
              <StyledOrgName style={{ textAlign: "left" }}>
                {account?.contact?.contactName}
              </StyledOrgName>
            )}
            {!account?.contact?.contactName && (
              <StyledOrgName
                style={{
                  color: "#007AFF",
                  cursor: "pointer",
                  textAlign: "left",
                  width: "fit-content"
                }}
                onClick={() => setAddNewContact(account)}
              >
                Unknown Contact
              </StyledOrgName>
            )}

            {account?.contact?.isPrimary && (
              <StarRateRoundedIcon
                style={{ height: "16px", width: "16px", color: "#E82C2C" }}
              />
            )}
          </div>
          <StyledOrgLocation style={{ textAlign: "left" }}>
            {account?.phoneType === "PERSONAL"
              ? "[P] "
              : account?.phoneType === "WORK"
              ? "[W] "
              : null}
            {formatPhoneWithCountryCode(account?.contact?.contactPhone)}
          </StyledOrgLocation>
        </div>
        {showTimer && callStatus == "ACCEPTED" && (
          <span
            style={{
              fontSize: "20px",
              fontWeight: 600
            }}
          >
            {formatTime(callTime)}
          </span>
        )}
        {!showTimer && callStatus == "CONNECTING" && (
          <span
            style={{
              fontSize: "16px",
              fontWeight: 500
            }}
          >
            Ringing...
          </span>
        )}
        {!showTimer && callStatus == "DISCONNECTED" && (
          <span
            style={{
              fontSize: "16px",
              fontWeight: 500
            }}
          >
            Call ended
          </span>
        )}
      </div>
    </>
  );
};
