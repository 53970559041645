import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Container,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import colors from "theme/colors";
import { useEffect, useState } from "react";
import { Column, DataGridTable } from "@components/DataGridTable";
import { CustomColumnGraph } from "@pages/dashboard/components/CustomColumnGraph";
import {
  adminEpicEpicIdTeamBreakdownGet,
  AdminEpicEpicIdTeamBreakdownGetResponse,
  ModelEpicIssue
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useQuery } from "@tanstack/react-query";

const StyledTableValue = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const StyledDataTable = styled("div")`
  .MuiPaper-root {
    box-shadow: none !important;
    border: 1px solid #e5e5e5;
  }
`;

const formatDate = (isoString: string): string => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric"
  });
};

const columns = [
  { label: "Backlog/Ready Commited", color: "#E5E5E5" },
  { label: "In Progress", color: "#FFC05E" },
  { label: "Done", color: "#1ABC9C", fontColor: "#fff" }
];

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  maxHeight: "calc(100vh - 64px)",
  overflowY: "auto",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));

const StyledCustomColumnGraph = styled("div")`
  .column-graph {
    margin: 8px 0 !important;
  }
  .graph {
    margin: 0px !important;
  }
`;

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const GanttChartEpicModal = ({
  onClose,
  epics
}: {
  onClose: () => void;
  epics:
    | { assigneeId: string; epics: { name: string; epicId: string }[] }
    | undefined;
}) => {
  const [selectedEpic, setSelectedEpic] = useState<string>();

  const { data: teamBreakdown, isLoading: teamBreakdownLoading } = useQuery(
    ["breakdown", selectedEpic],
    () => adminEpicEpicIdTeamBreakdownGet(selectedEpic!),
    {
      staleTime: 3600,
      cacheTime: 3600,
      refetchOnWindowFocus: true,
      enabled: !!selectedEpic
    }
  );

  useEffect(() => {
    if (epics?.epics) {
      setSelectedEpic(epics.epics[0].epicId);
    }
  }, [epics]);

  const COLUMNS: Column[] = [
    {
      field: "complete",
      headerName: "% Complete",
      width: 120,
      sortable: false,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        const value =
          (params.totalHours - params.hoursLeft) / params.totalHours;
        if (isNaN(value)) return <StyledTableValue>0.0%</StyledTableValue>;
        const donePercentage = (value * 100).toFixed(2);
        const color = donePercentage === "100.00" ? "#4CAF50" : "#000";
        return (
          <StyledTableValue style={{ color }}>
            {donePercentage}%
          </StyledTableValue>
        );
      }
    },
    {
      field: "estimate",
      headerName: "Total Estimated Hours",
      width: 150,
      sortable: false,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.totalHours && params.totalHours !== 0)
          return <StyledTableValue>-</StyledTableValue>;
        const hours = params.totalHours / 3600;
        return (
          <StyledTableValue>
            {hours.toFixed(2)} {hours > 1 ? " hrs" : " hr"}
          </StyledTableValue>
        );
      }
    },
    {
      field: "hoursLeft",
      headerName: "Hours Remaining",
      width: 150,
      sortable: false,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.hoursLeft && params.hoursLeft !== 0)
          return <StyledTableValue>-</StyledTableValue>;
        const hours = params.hoursLeft / 3600;
        return (
          <StyledTableValue>
            {hours.toFixed(2)} {hours > 1 ? " hrs" : " hr"}
          </StyledTableValue>
        );
      }
    },
    {
      field: "overUnder",
      headerName: "Over / Under",
      width: 100,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.overUnder && params.overUnder !== 0)
          return <StyledTableValue>-</StyledTableValue>;
        const hours = params.overUnder / 3600;
        return (
          <StyledTableValue
            style={{ color: hours > 1 ? "#E82C2C" : "#1ABC9C" }}
          >
            {hours.toFixed(2)} {hours > 1 ? " hrs" : " hr"}
          </StyledTableValue>
        );
      }
    },
    {
      field: "date",
      headerName: "Floating Completion Date",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        if (params.endDate && params.endDate !== "-")
          return (
            <StyledTableValue>{formatDate(params.endDate)}</StyledTableValue>
          );
        return <span>-</span>;
      }
    }
  ];

  const tableDetails = (
    rows: AdminEpicEpicIdTeamBreakdownGetResponse[],
    resourceView?: boolean
  ) => {
    const initialBreakdown = {
      backlog: 0,
      inprogress: 0,
      done: 0
    };

    const breakdown =
      rows?.[0]?.issues &&
      rows?.[0]?.issues.reduce((acc, issue) => {
        switch (issue?.issue?.status) {
          case "READY_COMMITTED":
            acc.backlog += 1;
            break;
          case "DONE":
          case "QA":
          case "REVIEW":
            acc.done += 1;
            break;
          case "IN_PROGRESS":
          case "TODAYS_PLAN":
          case "ON_HOLD":
            acc.inprogress += 1;
            break;
          default:
            break;
        }
        return acc;
      }, initialBreakdown);

    const formattedBreakdown = {
      values: [breakdown || initialBreakdown]
    };

    return (
      <div>
        <Typography
          style={{ display: "inline", fontSize: "18px", fontWeight: 600 }}
        >
          {resourceView ? "Resource View -" : "Epic View"}{" "}
        </Typography>
        {resourceView && (
          <Typography
            style={{ display: "inline", fontSize: "18px", fontWeight: 400 }}
          >
            {rows[0]?.name}
          </Typography>
        )}
        <StyledDataTable style={{ marginTop: "24px" }}>
          <DataGridTable columns={COLUMNS} rows={rows} />
        </StyledDataTable>
        <div>
          <div
            style={{
              marginTop: "24px",
              display: "flex",
              gap: "16px",
              alignItems: "center"
            }}
          >
            <span style={{ color: "#000", fontWeight: 600 }}>
              Total Jira Cards (
              {rows?.[0]?.issues ? rows?.[0]?.issues.length : 0})
            </span>
            {columns.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center"
                  }}
                >
                  <span
                    style={{
                      width: "16px",
                      height: "16px",
                      background: value.color
                    }}
                  ></span>
                  <span style={{ color: "#000" }}>{value.label}</span>
                </div>
              );
            })}
          </div>
          <div>
            <StyledCustomColumnGraph>
              <CustomColumnGraph
                columns={columns}
                values={[formattedBreakdown]}
                showLabel={false}
                showToolbar={false}
              />
            </StyledCustomColumnGraph>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 199999
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  Epics
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Box paddingBottom="24px" sx={{ minHeight: "300px" }}>
            <Grid style={{ margin: "24px 24px 0 24px" }}>
              <ButtonGroup
                variant="outlined"
                style={{ border: "rgba(215, 221, 229, 1)", overflow: "auto" }}
              >
                {epics?.epics.map((epic) => {
                  return (
                    <Button
                      key={epic.epicId}
                      variant="outlined"
                      style={
                        selectedEpic === epic.epicId
                          ? {
                              backgroundColor: "#E8EEFF",
                              color: "#2B337A",
                              fontSize: "14px",
                              fontWeight: 600,
                              textTransform: "none",
                              minWidth: "fit-content"
                            }
                          : {
                              color: "#666666",
                              fontSize: "14px",
                              fontWeight: 500,
                              textTransform: "none",
                              minWidth: "fit-content"
                            }
                      }
                      onClick={() => {
                        setSelectedEpic(epic.epicId);
                      }}
                    >
                      {epic.name}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Grid>
            <Loader isLoading={teamBreakdownLoading}>
              <Grid padding="24px">
                {tableDetails(
                  [
                    teamBreakdown?.data.find(
                      (tb) => tb.assigneeId === epics?.assigneeId
                    ) as AdminEpicEpicIdTeamBreakdownGetResponse
                  ],
                  true
                )}
              </Grid>
              {(() => {
                const values = teamBreakdown?.data.reduce((acc, curr) => {
                  acc.assigneeId = curr.assigneeId;
                  acc.name = curr.name;
                  acc.totalHours =
                    (acc.totalHours || 0) + (curr.totalHours || 0);
                  acc.hoursLeft = (acc.hoursLeft || 0) + (curr.hoursLeft || 0);
                  acc.overUnder = (acc.overUnder || 0) + (curr.overUnder || 0);
                  acc.endDate = acc.endDate
                    ? new Date(acc.endDate) > new Date(curr.endDate as Date) ||
                      curr.endDate === "-"
                      ? acc.endDate
                      : curr.endDate
                    : curr.endDate !== "-"
                    ? curr.endDate
                    : acc.endDate;
                  return acc;
                }, {});
                return (
                  <Grid padding="0 24px">
                    {tableDetails([
                      {
                        assigneeId: "1",
                        totalHours: values?.totalHours,
                        hoursLeft: values?.hoursLeft,
                        overUnder: values?.overUnder,
                        endDate: values?.endDate,
                        issues: teamBreakdown?.data.reduce(
                          (acc, curr) =>
                            acc.concat(curr?.issues as ModelEpicIssue),
                          [] as ModelEpicIssue[]
                        )
                      }
                    ])}
                  </Grid>
                );
              })()}
            </Loader>
          </Box>
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
