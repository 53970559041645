import {
  MediaPostRequestMediaType,
  adminMediaPost,
  mediaPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import axios from "axios";

const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };

    video.onerror = () => {
      reject(new Error("Failed to load video metadata"));
    };

    video.src = URL.createObjectURL(file);
  });
};

export const uploadMediaUsingPresignedUrl = async (
  file: File,
  organizationId?: string
): Promise<string> => {
  try {
    let duration = 0;

    if (file.type.startsWith("video")) {
      duration = await getVideoDuration(file);
    }
    const data = await mediaPost({
      mediaType: file.type.startsWith("image")
        ? MediaPostRequestMediaType.IMAGE
        : file.type.startsWith("video")
        ? MediaPostRequestMediaType.VIDEO
        : MediaPostRequestMediaType.DOCUMENT,
      mimeType: file.type,
      roleType: organizationId ? "ORGANIZATION" : "SYSTEM",
      roleTypeId: organizationId || "*",
      metadata: {
        fileName: file.name || "",
        ...(file.type.startsWith("video") ? { duration } : {})
      }
    });
    console.log(data);
    const formData = new FormData();
    if (
      data.data?.uploadParams &&
      data.data?.uploadParams.url &&
      data.data?.uploadParams?.fields
    ) {
      const fields = data.data?.uploadParams?.fields;
      for (const key in fields) {
        formData.append(key, fields[key]);
      }

      formData.append("file", file);
      try {
        await axios.post(data.data?.uploadParams.url, formData);

        const mediaId = data.data?.uploadParams.fields["x-amz-meta-media-id"];
        console.log("DATS:", mediaId);
        if (mediaId) return Promise.resolve(mediaId);
        else return Promise.reject(new Error("upload not successful"));
      } catch (error) {
        //enqueueSnackbar("Failed to upload!", { variant: "error" });
        console.log("ERR");
        return Promise.reject(new Error("Failed to Upload"));
      }
    }
    return Promise.reject(new Error("Upload Failed"));
  } catch (err) {
    console.log(err);
    return Promise.reject(new Error("upload not successful"));
  }
};

export const uploadAdminMediaUsingPresignedUrl = async (
  file: File
): Promise<string> => {
  try {
    const data = await adminMediaPost({
      mediaType: file.type.startsWith("image")
        ? MediaPostRequestMediaType.IMAGE
        : file.type.startsWith("video")
        ? MediaPostRequestMediaType.VIDEO
        : MediaPostRequestMediaType.DOCUMENT,
      mimeType: file.type,
      metadata: {
        fileName: file.name || ""
      }
    });
    console.log(data);
    const formData = new FormData();
    if (
      data.data?.uploadParams &&
      data.data?.uploadParams.url &&
      data.data?.uploadParams?.fields
    ) {
      const fields = data.data?.uploadParams?.fields;
      for (const key in fields) {
        formData.append(key, fields[key]);
      }

      formData.append("file", file);
      try {
        await axios.post(data.data?.uploadParams.url, formData);

        const mediaId = data.data?.uploadParams.fields["x-amz-meta-media-id"];
        console.log("DATS:", mediaId);
        if (mediaId) return Promise.resolve(mediaId);
        else return Promise.reject(new Error("upload not successful"));
      } catch (error) {
        //enqueueSnackbar("Failed to upload!", { variant: "error" });
        console.log("ERR");
        return Promise.reject(new Error("Failed to Upload"));
      }
    }
    return Promise.reject(new Error("Upload Failed"));
  } catch (err) {
    console.log(err);
    return Promise.reject(new Error("upload not successful"));
  }
};
