import {
  Autocomplete,
  Button,
  FormLabel,
  Grid,
  Typography,
  styled
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchInput } from "../../../../components/SearchInput";
import {
  getCrmContacts,
  getCrmOrgContacts
} from "../../../../services/Network";
import formatFullName from "../../../../utils/formatFullName";
import { ContactResponse } from "@sportsgravyengineering/sg-api-react-sdk";
import { Add } from "@mui/icons-material";
import colors from "theme/colors";

const StyledContactOption = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "12px 16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));

const StyledAddContactOption = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "12px 16px"
}));

const ContactOption = ({
  contact,
  onClick,
  ...props
}: {
  contact: ContactResponse | null;
  onClick: () => void;
}) => {
  const fullName = formatFullName(contact);
  const address = contact?.location;
  const optionComponents = [fullName, address];
  const optionText = optionComponents
    .filter((component) => !!component)
    .join(" | ");

  return (
    <StyledContactOption
      container
      data-testid="ADD_CONTACT_OPTION_CONTAINER"
      {...props}
      direction="row"
      alignItems="center"
      onClick={onClick}
      spacing="10px"
    >
      <Grid item xs={11}>
        {optionText}
      </Grid>
    </StyledContactOption>
  );
};

const AddContactOption = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledAddContactOption container direction="row" alignItems="center">
      <Grid
        container
        item
        xs={12}
        direction="row"
        alignItems="center"
        data-testid="ADD_NEW_CONTACT_OPTION"
      >
        <Typography>Not finding who you're looking for?</Typography>
        <Button
          style={{
            color: colors.primary.main,
            textTransform: "capitalize"
          }}
          onClick={onClick}
          startIcon={<Add />}
        >
          Add New Contact
        </Button>
      </Grid>
    </StyledAddContactOption>
  );
};

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const SearchAddContact = ({
  contactSelected,
  hideAddContactOption,
  label,
  onClear,
  searchValue,
  organizationId
}: {
  contactSelected: (
    selectedContact,
    contactSelected,
    newContactSelected
  ) => void;
  hideAddContactOption?: boolean;
  label?: string;
  onClear?: () => void;
  searchValue?: string;
  organizationId?: string;
}) => {
  const [contactSearch, setContactSearch] = useState("");
  useEffect(() => {
    if (searchValue) setContactSearch(searchValue);
  }, [searchValue]);
  const [addUserMenuOpen, setAddUserMenuOpen] = useState(false);
  const defaultContact: ContactResponse = {
    contactId: "",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    location: ""
  };
  const onAddContactSearchChange = (event) => {
    setContactSearch(event.target.value);
    if (!event.target.value && onClear) onClear();
  };
  const { data: addContactResults, isFetching: isFetchingContacts } =
    organizationId
      ? getCrmOrgContacts(
          {
            textSearch: contactSearch,
            organizationId: organizationId
          },
          { staleTime: Infinity, enabled: !!contactSearch?.length }
        )
      : getCrmContacts(
          {
            textSearch: contactSearch
          },
          { staleTime: Infinity, enabled: !!contactSearch?.length }
        );
  const addContactOptions = [...addContactResults];
  if (!hideAddContactOption) addContactOptions.push(null);

  return (
    <Grid
      // sx={{ padding: "12px 16px 12px 16px" }}
      item
      container
      direction="row"
      spacing="24px"
    >
      <Grid item xs={12}>
        <StyledFormLabel required>
          <Typography display="inline" variant="formLabel">
            {label || "Name"}
          </Typography>
        </StyledFormLabel>
        <Autocomplete
          open={addUserMenuOpen}
          clearOnBlur={false}
          freeSolo
          onOpen={() => setAddUserMenuOpen(true)}
          onClose={() => setAddUserMenuOpen(false)}
          // value={secondaryContact}
          getOptionLabel={(option) =>
            option ? `${option.firstName} ${option.lastName}` : ""
          }
          isOptionEqualToValue={(option, value) =>
            option?.contactId === value?.contactId
          }
          inputValue={contactSearch}
          renderInput={(params) => (
            <SearchInput
              data-testid="ADD_USER_SEARCH_CONTACT_INPUT"
              {...params}
              value={contactSearch}
              onChange={onAddContactSearchChange}
            />
          )}
          filterOptions={(options) =>
            addUserMenuOpen && !isFetchingContacts ? options : []
          }
          renderOption={(props, option: ContactResponse, state) => {
            if (
              !hideAddContactOption &&
              state.index === addContactOptions.length - 1
            ) {
              return (
                <AddContactOption
                  key={state.index}
                  onClick={() => {
                    contactSelected(defaultContact, false, true);
                  }}
                />
              );
            }
            return (
              <div
                data-testid={"SEARCH_OPTION_" + option.contactId}
                key={option.contactId}
                style={{
                  height: "55px"
                }}
              >
                <ContactOption
                  {...props}
                  key={option.contactId}
                  contact={option}
                  onClick={() => {
                    setContactSearch(
                      formatFullName(
                        option as {
                          firstName: string;
                          middleName: string;
                          lastName: string;
                          suffix: string;
                        }
                      )
                    );
                    contactSelected(option, true, false);
                    setAddUserMenuOpen(false);
                  }}
                />
              </div>
            );
          }}
          options={addContactOptions || []}
          loading={isFetchingContacts}
          loadingText="Loading..."
        />
      </Grid>
    </Grid>
  );
};
