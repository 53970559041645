import { Box, FormHelperText, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { TimeField } from "@mui/x-date-pickers-pro";
import { MAX_CLIP_DURATION, MIN_CLIP_DURATION } from "@utils/constants";
import React, { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import ReactPlayer from "react-player";

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const convertSecondsToMMSS = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

export const convertSecondsToHHMMSS = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(remainingSeconds).padStart(2, "0")}`;
};

export const convertSecondsToDate = (seconds: number): Date => {
  const date = new Date();
  date.setHours(Math.floor(seconds / 3600));
  date.setMinutes(Math.floor((seconds % 3600) / 60));
  date.setSeconds(seconds % 60);
  date.setMilliseconds(0);
  return date;
};

export const convertDateToSeconds = (date: Date): number => {
  if (!date) return 0;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return hours * 3600 + minutes * 60 + seconds;
};

export const ClipTimeFields = ({
  time,
  setClipData,
  playerRef
}: {
  time: {
    startTime: number;
    endTime: number;
    maxTime: number;
  };
  setClipData: (data) => void;
  playerRef: React.RefObject<ReactPlayer>;
}) => {
  const {
    control,
    trigger,
    formState: { errors },
    setValue
  } = useFormContext();
  const startTime = useWatch({ control, name: "startTime" });
  const endTime = useWatch({ control, name: "endTime" });

  const parseTime = (value: string): number => {
    const [hh, mm, ss] = value.split(":").map(Number);
    return hh * 3600 + mm * 60 + ss;
  };

  useEffect(() => {
    if (time.startTime) {
      const startTime = convertSecondsToDate(time.startTime);
      setValue("startTime", startTime);
    }
    if (time.endTime) {
      const endTime = convertSecondsToDate(time.endTime);
      setValue("endTime", endTime);
    }
    trigger("startTime");
    trigger("endTime");
  }, [time.startTime, time.endTime]);

  return (
    <>
      <Grid xs={5.75}>
        <Controller
          control={control}
          name="startTime"
          rules={{
            required: "This field is required",
            validate: {
              validTime: (value) => {
                const enteredTimeInSs = convertDateToSeconds(value);
                if (enteredTimeInSs > time.maxTime)
                  return `Time must be within ${convertSecondsToHHMMSS(
                    time.maxTime
                  )}`;
                if (endTime) {
                  const endSeconds = convertDateToSeconds(endTime);
                  if (endSeconds - enteredTimeInSs < MIN_CLIP_DURATION)
                    return `Start time must be at least ${MIN_CLIP_DURATION} seconds less than end time`;
                  if (endSeconds - enteredTimeInSs > MAX_CLIP_DURATION)
                    return `Start time must be at most ${MAX_CLIP_DURATION} seconds less than end time`;
                }
                return true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <FormInputContainer>
              <TimeField
                format="HH:mm:ss"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("endTime");
                  trigger("startTime");
                }}
                onBlur={async (e) => {
                  await trigger("endTime");
                  if (!errors.startTime) {
                    if (playerRef?.current)
                      playerRef?.current.seekTo(parseTime(e.target.value));
                    setClipData((prev) => ({
                      ...prev,
                      startTime: parseTime(e.target.value)
                    }));
                  }
                }}
              />
              {!!fieldState.error && (
                <FormHelperText error={!!fieldState.error}>
                  {fieldState.error?.message}
                </FormHelperText>
              )}
            </FormInputContainer>
          )}
        />
      </Grid>
      <Grid xs={0.5} sx={{ marginTop: "20px" }}>
        <Typography>-</Typography>
      </Grid>
      <Grid xs={5.75}>
        <Controller
          control={control}
          name="endTime"
          rules={{
            required: "This field is required",
            validate: {
              validTime: (value) => {
                const enteredTimeInSs = convertDateToSeconds(value);
                if (enteredTimeInSs > time.maxTime)
                  return `Time must be within ${convertSecondsToMMSS(
                    time.maxTime
                  )}`;
                if (startTime) {
                  const startSeconds = convertDateToSeconds(startTime);
                  if (enteredTimeInSs - startSeconds < MIN_CLIP_DURATION)
                    return `End time must be at least ${MIN_CLIP_DURATION} seconds greater than start time`;
                  if (enteredTimeInSs - startSeconds > MAX_CLIP_DURATION)
                    return `End time must be at most ${MAX_CLIP_DURATION} seconds greater than start time`;
                }
                return true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <FormInputContainer>
              <TimeField
                format="HH:mm:ss"
                value={field.value}
                onChange={(e) => {
                  console.log("onBlur");

                  field.onChange(e);
                  trigger("startTime");
                  trigger("endTime");
                }}
                onBlur={async (e) => {
                  await trigger("startTime");
                  if (!errors.endTime && !errors.startTime) {
                    setClipData((prev) => ({
                      ...prev,
                      endTime: parseTime(e.target.value),
                      startTime: convertDateToSeconds(startTime)
                    }));
                  }
                }}
              />
              {!!fieldState.error && (
                <FormHelperText error={!!fieldState.error}>
                  {fieldState.error?.message}
                </FormHelperText>
              )}
            </FormInputContainer>
          )}
        />
      </Grid>
    </>
  );
};
