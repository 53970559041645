import { AppStoreUrl, PlayStoreUrl } from "@utils/constants";
import React, { useEffect, useState } from "react";
import SGLogoLoader from "@assets/images/SGLogoLoader.gif";
import { Box, styled } from "@mui/material";
import { WhoopsyDaisy } from "./auth/WhoopsyDaisy";

const StyledSGLogoLoader = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  img {
    width: 150px;
  }
  @media (max-width: 465px) {
    img {
      width: 100px;
    }
  }
`;

export const DownloadRedirect = () => {
  const [showLoader, setShowLoader] = useState(true);

  const appStoreFallback = () => {
    const userAgent = window.navigator.userAgent;
    console.log(userAgent);
    if (/android/i.test(userAgent)) {
      window.location.href = PlayStoreUrl;
    } else if (/iPad|iPhone|iPod|Macintosh/.test(userAgent)) {
      if (window.confirm(`Open in "App Store"?`)) {
        window.location.href = AppStoreUrl;
      }
    }
  };

  useEffect(() => {
    appStoreFallback();
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return showLoader ? (
    <StyledSGLogoLoader>
      <img src={SGLogoLoader} />
    </StyledSGLogoLoader>
  ) : (
    <WhoopsyDaisy showDesc={false} title="Download Now!" />
  );
};
