import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelTwilioNumber,
  useAdminDepartmentDepartmentIdDelete,
  useAdminDepartmentDepartmentIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { DepartmentForm } from "./departmentDetailsForm";

export const DepartmentView = () => {
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { reset } = form;

  const { data: data, isLoading: isLoading } =
    useAdminDepartmentDepartmentIdGet(departmentId!);

  React.useEffect(() => {
    if (data?.data) {
      const department = data.data;
      reset({
        name: department.name,
        forwardTo:
          department.forwardToJobs && department?.forwardToJobs.length === 0
            ? "CALL_CENTER"
            : "JOBS",
        jobs:
          department.forwardToJobs &&
          department.forwardToJobs.map((job) => job.jobtitleId),
        sgNumber: (department.twilioNumber as ModelTwilioNumber)?.number,
        email: department.email
      });
    }
  }, [data]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminDepartmentDepartmentIdDelete();

  const onDepartmentDelete = async () => {
    try {
      await deleteAsync({ departmentId: departmentId as string });
      enqueueSnackbar("Department Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/departments");
    } catch (e) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete the Department.",
        {
          variant: "error"
        }
      );
      setOpenDeleteDialog(false);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Department"
        backBtnClick={() => {
          navigate("/departments");
        }}
        editBtnClick={() => {
          navigate(`/departments/${departmentId}/edit`);
        }}
        deleteBtnClick={() => {
          setOpenDeleteDialog(true);
        }}
      />
      <Loader isLoading={isLoading || isDeleting}>
        <DepartmentForm form={form} disabled />
      </Loader>
      <ConfirmationDialog
        title="Delete Department?"
        body="Are you sure you want to delete this department?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onDepartmentDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
