import { Container } from "@components/crud/Container";
import { Typography, Box } from "@mui/material";
import NoPermissionIcon from "@assets/icons/no-permission.svg";

export const NoRecords = ({
  title,
  description,
  buttonText,
  buttonClick,
  buttonIcon
}: {
  title: string;
  description?: string;
  buttonText?: string;
  buttonClick?: () => void;
  buttonIcon?: React.ReactNode;
}) => {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        gap="10px"
      >
        <img src={NoPermissionIcon} />
        <Typography
          style={{
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "30px"
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#666666"
          }}
        >
          {description}
        </Typography>
        {buttonText && buttonClick && (
          <Typography
            style={{
              fontSize: " 14px",
              fontWeight: 600,
              lineHeight: "18px",
              color: "#007aff",
              cursor: "pointer",
              display: "flex",
              alignItems: "center"
            }}
            onClick={buttonClick}
          >
            {buttonIcon}
            {buttonText}
          </Typography>
        )}
      </Box>
    </Container>
  );
};
