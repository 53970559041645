import { Loader } from "@components/crud/Loader";
import { Column, DataGridTable } from "@components/DataGridTable";
import { Grid, Typography, Divider, styled } from "@mui/material";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { AdminEpicEpicIdTeamBreakdownGetResponse } from "@sportsgravyengineering/sg-api-react-sdk";

const formatDate = (isoString: string): string => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric"
  });
};

const StyledGrid = styled(Grid)`
  .pinned-header:nth-child(1),
  .pinned-body:nth-child(1) {
    border-right: 2px solid #b3b3b3 !important;
  }
`;

const StyledLink = styled("a")`
  color: #007aff;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  &:visited {
    color: #007aff;
  }
  &:hover {
    color: #004494;
  }
  &:active {
    color: #007aff;
  }
`;

const StyledTableValue = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const TeamBreakdown = ({
  setTab,
  data,
  setResourceFilter
}: {
  setTab: (tab: "Overview" | "Team Breakdown") => void;
  data: AdminEpicEpicIdTeamBreakdownGetResponse[] | undefined;
  setResourceFilter: (value: string) => void;
}) => {
  const handleCellClick = (assigneeId: string) => {
    setResourceFilter(assigneeId);
    setTab("Overview");
  };

  const COLUMNS: Column[] = [
    {
      field: "resource",
      headerName: "Resource",
      width: 250,
      sortable: false,
      borderRight: "1px solid #B3B3B3",
      align: "left",
      onClick: (row) => {
        handleCellClick(row.assigneeId);
      },
      renderCell: (params) => {
        return <StyledLink>{params.name}</StyledLink>;
      }
    },
    {
      field: "complete",
      headerName: "% Complete",
      width: 120,
      sortable: false,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        const value =
          (params.totalHours - params.hoursLeft) / params.totalHours;
        if (isNaN(value)) return <StyledTableValue>0.0%</StyledTableValue>;
        const donePercentage = (value * 100).toFixed(2);
        const color = donePercentage === "100.00" ? "#4CAF50" : "#000";
        return (
          <StyledTableValue style={{ color }}>
            {donePercentage}%
          </StyledTableValue>
        );
      }
    },
    {
      field: "estimate",
      headerName: "Total Estimated Hours",
      width: 150,
      sortable: false,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.totalHours && params.totalHours !== 0)
          return <StyledTableValue>-</StyledTableValue>;
        const hours = params.totalHours / 3600;
        return (
          <StyledTableValue>
            {hours.toFixed(2)} {hours > 1 ? " hrs" : " hr"}
          </StyledTableValue>
        );
      }
    },
    {
      field: "hoursLeft",
      headerName: "Hours Remaining",
      width: 100,
      sortable: false,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.hoursLeft && params.hoursLeft !== 0)
          return <StyledTableValue>-</StyledTableValue>;
        const hours = params.hoursLeft / 3600;
        return (
          <StyledTableValue>
            {hours.toFixed(2)} {hours > 1 ? " hrs" : " hr"}
          </StyledTableValue>
        );
      }
    },
    {
      field: "overUnder",
      headerName: "Over / Under",
      width: 150,
      borderRight: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.overUnder && params.overUnder !== 0)
          return <StyledTableValue>-</StyledTableValue>;
        const hours = params.overUnder / 3600;
        return (
          <StyledTableValue
            style={{ color: hours > 1 ? "#E82C2C" : "#1ABC9C" }}
          >
            {hours.toFixed(2)} {hours > 1 ? " hrs" : " hr"}
          </StyledTableValue>
        );
      }
    },
    {
      field: "date",
      headerName: "Floating Completion Date",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        if (params.endDate && params.endDate !== "-")
          return (
            <StyledTableValue>{formatDate(params.endDate)}</StyledTableValue>
          );
        return <span>-</span>;
      }
    }
  ];
  return (
    <StyledGrid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "24px",
        marginLeft: "10px",
        width: "calc(100vw - 330px)"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              Epic Team Breakdown
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Quick Insights related to SportGravy
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={false}>
        {!data ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        ) : (
          <DataGridTable
            rows={data}
            columns={COLUMNS}
            pinnedColumns={["resource"]}
          />
        )}
      </Loader>
    </StyledGrid>
  );
};
