import { Container } from "@components/crud/Container";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { FormSelect } from "@components/FormSelect";
import { QATestingReleaseInsights } from "./components/QATestingReleaseInsights";
import { QATestingBuildInsights } from "./components/QATestingBuildInsights";

export const QATesting = () => {
  const [insightFilter, setInsightFilter] = useState<
    "QA_RELEASES" | "QA_BUILDS"
  >("QA_RELEASES");
  return (
    <Container>
      <Grid container spacing="15px" direction="column">
        <Grid
          item
          marginLeft="40px"
          marginTop="16px"
          style={{ maxWidth: "500px" }}
        >
          <FormSelect
            name="filter"
            value={insightFilter}
            options={[
              {
                label: "QA & Testing Insights (Releases)",
                value: "QA_RELEASES"
              },
              {
                label: "QA & Testing Insights (Builds)",
                value: "QA_BUILDS"
              }
            ]}
            onChange={(e) => setInsightFilter(e.target.value)}
          />
        </Grid>
        {insightFilter === "QA_RELEASES" && <QATestingReleaseInsights />}
        {insightFilter === "QA_BUILDS" && <QATestingBuildInsights />}
      </Grid>
    </Container>
  );
};
