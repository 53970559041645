import { FormInput } from "@components/FormInput";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import styled from "styled-components";
import { LoadingSpinner } from "./LoadingSpinner";
import { Control, UseFormTrigger } from "react-hook-form";
import React, { ChangeEvent, useEffect } from "react";

const StyledAutocomplete = styled(Autocomplete)`
  &.address-autocomplete {
    width: 100%;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autocompleteService = { current: null } as any;
const libraries = ["places"] as "places"[];

export const AddressAutocomplete = ({
  name,
  control,
  rules,
  label,
  required,
  selectSuggestion,
  disabled,
  onChange,
  trigger,
  tooltip
}: {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any;
  label: string;
  required: boolean;
  selectSuggestion: (place: google.maps.places.PlaceResult) => void;
  disabled?: boolean;
  onChange?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>;
  tooltip?: string;
}) => {
  const [address, setAddress] = React.useState<boolean>(true);

  useEffect(() => {
    if (address && trigger) {
      trigger(name);
    }
  }, [address]);
  return (
    <LoadScript
      googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
      libraries={libraries}
      loadingElement={<LoadingSpinner />}
    >
      <StyledAutocomplete
        className="address-autocomplete"
        onLoad={(autocomplete) => {
          autocompleteService.current = autocomplete;
        }}
        onPlaceChanged={() => {
          if (autocompleteService.current) {
            const place = autocompleteService.current.getPlace();
            selectSuggestion(place);
            setAddress(true);
          }
        }}
      >
        <FormInput
          name={name}
          control={control}
          rules={{
            ...rules,
            validate: (value) => {
              return !address && value
                ? "The address entered is invalid, please make a recommended selection"
                : undefined;
            }
          }}
          label={label}
          required={required}
          type="text"
          disabled={disabled}
          tooltip={tooltip}
          onChange={(e) => {
            if (onChange)
              onChange((e as ChangeEvent<HTMLInputElement>).target.value);
            setAddress(false);
          }}
        />
      </StyledAutocomplete>
    </LoadScript>
  );
};

AddressAutocomplete.defaultProps = {
  disabled: false
};
