import { TableView } from "@components/TableView";
import { getLiveStreams } from "@services/Network";
import {
  adminLiveStreamStreamIdDownload,
  useAdminTeamGet,
  useAdminTrainingProgramGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";
import { Edit, Visibility } from "@mui/icons-material";
import { DownloadIcon } from "@components/Icons";
import Sentry from "@services/Sentry";

const IconStyle = {
  height: "24px",
  width: "24px",
  color: "#666666"
};

export const LiveStream = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false
  });
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [streamIdToDownload, setStreamIdToDownload] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  const { data: team } = useAdminTeamGet({
    organizationId: organizationId!,
    pageSize: 1000
  });
  const { data: trainingProgram } = useAdminTrainingProgramGet({
    organizationId: organizationId!,
    pageSize: 1000
  });

  const organization = organizations?.find(
    (org) => org.organizationId === organizationId
  );

  const onEdit = (liveStream) => {
    navigate(`/live-stream/${liveStream.streamId}/edit`);
  };

  const onView = (liveStream) => {
    navigate(`/live-stream/${liveStream.streamId}`);
  };

  const onDownload = async (streamId: string, title: string) => {
    if (streamId) {
      try {
        setStreamIdToDownload(streamId);
        const downloadUrl = await adminLiveStreamStreamIdDownload(streamId);
        const presignedUrl = downloadUrl.data.presignedUrl;
        if (presignedUrl) {
          const a = document.createElement("a");
          a.href = presignedUrl;
          a.download = `${title}.mp4`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setStreamIdToDownload(null);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const add = await checkPermission("organization.post", "ON");
      const edit = await checkPermission("organization.post", "ON");
      setPermissions({
        add: add,
        edit: edit
      });
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const shareWithOptions = useMemo(() => {
    const orgOption = [
      {
        label: "My Organization",
        value: organizationId!
      }
    ];
    const teamOptions =
      (team?.data.teams &&
        team?.data?.teams.map((team) => ({
          label: team.name!,
          value: team.teamId
        }))) ||
      [];

    const trainingProgramOptions =
      (trainingProgram?.data.trainingPrograms &&
        trainingProgram?.data?.trainingPrograms.map((trainingProgram) => ({
          label: trainingProgram.name!,
          value: trainingProgram.programId
        }))) ||
      [];

    return [...orgOption, ...teamOptions, ...trainingProgramOptions];
  }, [team, trainingProgram]);

  const statusoptions = [
    {
      label: "Not Started",
      value: "NOT_STARTED"
    },
    {
      label: "Live",
      value: "LIVE"
    },
    {
      label: "Completed",
      value: "COMPLETED"
    },
    {
      label: "Cancelled",
      value: "CANCELLED"
    },
    {
      label: "Delayed",
      value: "DELAYED"
    },
    {
      label: "Postponed",
      value: "POSTPONED"
    },
    {
      label: "Paused",
      value: "PAUSED"
    },
    {
      label: "Unavailable",
      value: "UNAVAILABLE"
    },
    {
      label: "Shut Down",
      value: "SHUT_DOWN"
    }
  ];

  const COLUMNS = [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      maxWidth: 120,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div style={{ padding: "20px 0px", display: "flex" }}>
            <IconButton onClick={() => onView(row)}>
              <Visibility style={IconStyle} />
            </IconButton>
            {permissions.edit && row?.status === "NOT_STARTED" && (
              <IconButton onClick={() => onEdit(row)}>
                <Edit style={IconStyle} />
              </IconButton>
            )}
            {row?.status !== "NOT_STARTED" && row?.vod && (
              <Loader isLoading={streamIdToDownload === row.streamId}>
                <IconButton onClick={() => onDownload(row.streamId, row.title)}>
                  <DownloadIcon
                    sx={{
                      fill: "#fff",
                      path: {
                        stroke: "#666",
                        strokeWidth: 2
                      }
                    }}
                  />
                </IconButton>
              </Loader>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 120,
      sortable: false,
      renderCell: ({ value }) => {
        if (["PAUSED", "INTERMISSION", "LIVE"].includes(value)) {
          return (
            <Typography style={{ color: "#E82C2C", fontWeight: "600" }}>
              LIVE
            </Typography>
          );
        } else {
          return (
            <div className="MuiDataGrid-cellContent" title={`${value}`}>
              {statusoptions.find((option) => option.value === value)?.label}
            </div>
          );
        }
      }
    },
    { headerName: "Title", field: "title", minWidth: 150, flex: 1 },
    {
      headerName: "Shared With",
      field: "sharedWith",
      minWidth: 190,
      flex: 1,
      valueGetter: (params) => {
        if (params.row?.teamId || params.row?.programId) {
          if (params.row.teamId) {
            const id = params.row.teamId;
            return (
              "Team - " +
              shareWithOptions.find((option) => option.value === id)?.label
            );
          }
          if (params.row.programId) {
            const id = params.row.programId;
            return (
              "Training Program - " +
              shareWithOptions.find((option) => option.value === id)?.label
            );
          }
        }
        if (!params.row?.teamId && !params.row?.programId) {
          return "Org - " + organizationId ? organization?.name : "SportsGravy";
        }
      }
    },
    {
      headerName: "Visibility",
      field: "visibility",
      minWidth: 115,
      flex: 1,
      valueGetter: ({ row }) => {
        if (!row.isPublic) {
          return "Private";
        }
        return "Public";
      }
    },
    {
      headerName: "Sport",
      field: "sport",
      minWidth: 115,
      flex: 1,
      valueGetter: (params) => {
        return params.value.name;
      }
    },
    {
      headerName: "Schedule ",
      field: "scheduledAt",
      minWidth: 115,
      flex: 1,
      valueGetter: (params) => {
        return (
          parseISO(params.value).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric"
          }) +
          " " +
          parseISO(params.value).toLocaleTimeString("en-US", {
            minute: "2-digit",
            hour: "2-digit"
          })
        );
      }
    },
    {
      headerName: "Location",
      field: "location",
      minWidth: 115,
      flex: 1,
      valueGetter: (params) => {
        return params.row.sportLocation
          ? params.row.sportLocation.name ||
              `${params.row.sportLocation.lines.join(", ")}, ${
                params.row.sportLocation.locality
              }, ${params.row.sportLocation.province}, ${
                params.row.sportLocation.country
              }, ${params.row.sportLocation.postalCode}`
          : params.value;
      }
    }
  ];

  const filterConfig = {
    field: "interval",
    placeholderOption: {
      label: "All",
      value: ""
    },
    options: [
      {
        label: "LIVE",
        value: "LIVE"
      },
      {
        label: "Today",
        value: "TODAY"
      },
      {
        label: "Future",
        value: "FUTURE"
      },
      {
        label: "Past",
        value: "PAST"
      }
    ]
  };

  const [refreshKey] = React.useState(0);

  const onAdd = () => {
    navigate("/live-stream/create");
  };

  return (
    <Loader isLoading={isLoadingPermissions}>
      <TableView
        title="Live Streams"
        getRowId={(row) => row.streamId}
        useGet={getLiveStreams}
        columns={COLUMNS}
        filterConfig={filterConfig}
        onAdd={permissions.add ? onAdd : undefined}
        hasActionColumn={false}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
        //defaultSort={[{ sort: "asc", field: "title" }]}
        pinnedColumns={{ left: ["action", "status", "title"] }}
      />
    </Loader>
  );
};
