export const formatFullName = (
  person: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    suffix?: string;
  } | null
) => {
  if (!person) return "";
  const { firstName, lastName, middleName, suffix } = person;
  const middleInitial = middleName ? `${middleName[0]}.` : "";
  const nameComponents = [firstName, middleInitial, lastName, suffix];
  const nameString = nameComponents.filter((component) => component).join(" ");
  return nameString;
};

export default formatFullName;
