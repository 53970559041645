import React from "react";
import { Activity } from "../activities/Activity";
import { useNavigate } from "react-router-dom";

export const Activities = () => {
  const navigate = useNavigate();
  return (
    <Activity
      activities={{}}
      onClickAdd={() => {
        navigate(`/crm/activities/create`);
      }}
    />
  );
};
