/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import {
  AccountCallerDetails,
  CallEmailTemplateContext
} from "@templates/CallEmailTemplate";
import { useContext, useEffect, useMemo, useState } from "react";
import CallEndRoundedIcon from "@mui/icons-material/CallEndRounded";
import { LiveCall } from "./LiveCall";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { CallContactDetails } from "./CallContactDetails";
import {
  ModelAccount,
  useAdminContactPhoneNumberGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { createCRMActivity } from "@services/Network";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import timezone from "dayjs/plugin/timezone";

const StyledBox = styled("div")`
  position: absolute;
  bottom: 0;
  right: 32px;
  width: 450px;
  border-radius: 8px 0;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 24px 0px #00000029;
  background-color: #ffffff;
  z-index: 10000;
  max-height: 100vh;
  overflow: auto;
  border-radius: 8px 8px 0 0;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

const StyledHeader = styled("div")`
  background-color: #1abc9c;
  padding: 12px 24px;
  border-radius: 8px 8px 0 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CallActionButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const CallActionButton = styled("div")`
  height: 68px;
  width: 68px;
  background-color: #e82c2c;
  padding: 18px;
  border-radius: 99px;
`;

const IconStyle = {
  height: "32px",
  width: "32px",
  color: "#fff"
};

export const Call = ({ account }: { account: AccountCallerDetails }) => {
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const {
    setCallAccepted,
    callAccepted,
    disconnectCall,
    callStatus,
    callInstance,
    setCallerDetails
  } = useContext(CallEmailTemplateContext);
  const [expanded, setExpanded] = useState(true);
  const mutation = createCRMActivity();

  const { data: accounts, isLoading: isLoading } =
    useAdminContactPhoneNumberGet({ phoneNumber: account.phone as string });

  const accountsOptions = useMemo(() => {
    if (!accounts) return [];
    const emptyOption = {
      label: "",
      value: "",
      isPrimary: false,
      location: ""
    };

    if (accounts?.data && accounts.data.accounts) {
      const options = accounts.data.accounts.map((account) => {
        return {
          label: (account.account as ModelAccount).name as string,
          value: (account.account as ModelAccount)?.accountId || undefined,
          isPrimary: account.isPrimary as boolean,
          location: (account.account as ModelAccount).officeAddress as string
        };
      });
      return [emptyOption, ...options];
    }
    return [emptyOption];
  }, [accounts]);

  useEffect(() => {
    if (accounts?.data.contactId) {
      const contact = accounts.data;
      setCallerDetails({
        ...account,
        accountId:
          contact.accounts && contact.accounts.length == 1
            ? ((contact.accounts[0].account as ModelAccount)
                ?.accountId as string)
            : undefined,
        contactId: contact.contactId,
        phone: contact.phone,
        isConnected:
          contact.accounts && contact.accounts.length == 0 ? false : true,
        accountName: account?.accountId
          ? account.accountName
          : contact.accounts && contact.accounts.length == 1
          ? ((contact.accounts[0].account as ModelAccount)?.name as string)
          : "",
        location: account?.accountId
          ? account.location
          : contact.accounts && contact.accounts.length == 1
          ? ((contact.accounts[0].account as ModelAccount)?.name as string)
          : "",
        contact: {
          contactName: contact.firstName + " " + contact.lastName,
          contactPhone: contact.phone,
          isPrimary: account?.accountId ? account.contact?.isPrimary : false
        }
      });
    }
  }, [accounts]);
  return (
    <>
      <StyledBox>
        <StyledHeader>
          <CallIcon style={{ color: "#ffffff" }} />
          <span>
            LIVE {account.callDirection == "inbound" ? "Incoming" : "Outgoing"}{" "}
            Call
          </span>
          <div style={{ position: "relative", left: "100px" }}>
            {callAccepted &&
              (!expanded ? (
                <ExpandLessOutlinedIcon
                  onClick={() => setExpanded(true)}
                  style={{
                    height: "30px",
                    width: "30px",
                    color: "#fff",
                    cursor: "pointer"
                  }}
                />
              ) : (
                <ExpandMoreOutlinedIcon
                  onClick={() => setExpanded(false)}
                  style={{
                    height: "30px",
                    width: "30px",
                    color: "#fff",
                    cursor: "pointer"
                  }}
                />
              ))}
          </div>
        </StyledHeader>

        <div
          style={{
            display: expanded ? "flex" : "none",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "24px 40px",
            gap: "16px"
          }}
        >
          <Loader isLoading={isLoading || mutation.isLoading}>
            <CallContactDetails
              account={account}
              showTimer={callStatus == "ACCEPTED"}
              accounts={accountsOptions}
            />

            {!callAccepted && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "40px",
                  marginTop: "40px"
                }}
              >
                <CallActionButtonContainer
                  onClick={() => {
                    disconnectCall();
                    setCallAccepted(false);
                    callInstance?.reject();
                    setCallerDetails(undefined);
                  }}
                >
                  <CallActionButton>
                    <CallEndRoundedIcon style={IconStyle} />
                  </CallActionButton>
                  <span style={{ color: "#666666", fontSize: "14px" }}>
                    Decline
                  </span>
                </CallActionButtonContainer>
                <CallActionButtonContainer
                  onClick={async () => {
                    mutation.mutate(
                      {
                        data: {
                          accountId: account.accountId,
                          type: "CALL",
                          relatesTo: "ACCOUNT",
                          date: new Date(),
                          timezone: dayjs.tz.guess(),
                          direction:
                            account.callDirection === "inbound"
                              ? "inbound"
                              : "outbound",
                          status: "LIVE",
                          callId: account?.callId,
                          outcome: "CONNECTED_WITH_CONTACT",
                          internalParticipants: [user?.userId as string],
                          externalParticipants:
                            account.contactId !== undefined
                              ? [
                                  {
                                    contactId: account.contactId,
                                    phone: account.phone as string
                                  }
                                ]
                              : []
                        }
                      },
                      {
                        onSuccess: ({ data }) => {
                          setCallerDetails({
                            ...account,
                            activityId: data.activity.activityId
                          });
                          setCallAccepted(true);
                          callInstance?.accept();
                        }
                      }
                    );
                  }}
                >
                  <CallActionButton
                    style={{
                      backgroundColor: "#1ABC9C"
                    }}
                  >
                    <CallIcon style={IconStyle} />
                  </CallActionButton>
                  <span style={{ color: "#666666", fontSize: "14px" }}>
                    Accept
                  </span>
                </CallActionButtonContainer>
              </div>
            )}
            {callAccepted && <LiveCall account={account} key="live-call" />}
          </Loader>
        </div>
      </StyledBox>
    </>
  );
};
