export const getDateRangeBounds = (timeframes) => {
  const now = new Date();

  // Helper functions
  const startOfWeek = (date) => {
    const diff =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  const startOfMonth = (date) =>
    new Date(date.getFullYear(), date.getMonth(), 1);
  const startOfQuarter = (date) =>
    new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1);
  const startOfYear = (date) => new Date(date.getFullYear(), 0, 1);

  // Calculate dtStart and dtEnd for each timeframe
  const dateRanges = timeframes.map((timeframe) => {
    let dtStart, dtEnd;

    switch (timeframe) {
      case "ALL_TIME":
        dtStart = new Date(0); // Epoch start
        dtEnd = now;
        break;
      case "TODAY":
        dtStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        dtEnd = new Date(dtStart);
        dtEnd.setHours(23, 59, 59, 999);
        break;
      case "YESTERDAY":
        dtStart = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 1
        );
        dtEnd = new Date(dtStart);
        dtEnd.setHours(23, 59, 59, 999);
        break;
      case "THIS_WEEK":
        dtStart = startOfWeek(new Date(now));
        dtEnd = now;
        break;
      case "LAST_WEEK":
        dtStart = startOfWeek(new Date(now.setDate(now.getDate() - 7)));
        dtEnd = new Date(startOfWeek(new Date(now)));
        dtEnd.setDate(dtEnd.getDate() + 6);
        dtEnd.setHours(23, 59, 59, 999);
        break;
      case "THIS_MONTH":
        dtStart = startOfMonth(now);
        dtEnd = now;
        break;
      case "LAST_MONTH":
        dtStart = startOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1, 1)
        );
        dtEnd = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of last month
        dtEnd.setHours(23, 59, 59, 999);
        break;
      case "THIS_QUARTER":
        dtStart = startOfQuarter(now);
        dtEnd = now;
        break;
      case "LAST_QUARTER":
        dtStart = startOfQuarter(
          new Date(now.getFullYear(), now.getMonth() - 3, 1)
        );
        dtEnd = new Date(startOfQuarter(now) - 1); // Last day of last quarter
        dtEnd.setHours(23, 59, 59, 999);
        break;
      case "THIS_YEAR":
        dtStart = startOfYear(now);
        dtEnd = now;
        break;
      case "LAST_YEAR":
        dtStart = startOfYear(new Date(now.getFullYear() - 1, 0, 1));
        dtEnd = new Date(now.getFullYear(), 0, 0); // Last day of last year
        dtEnd.setHours(23, 59, 59, 999);
        break;
      default:
        throw new Error(`Invalid timeframe: ${timeframe}`);
    }

    return { dtStart, dtEnd };
  });

  // Find the earliest start and latest end across all ranges
  const earliestStart = new Date(
    Math.min(...dateRanges.map(({ dtStart }) => dtStart))
  );
  const latestEnd = new Date(Math.max(...dateRanges.map(({ dtEnd }) => dtEnd)));

  return {
    dtStart: earliestStart.toISOString(),
    dtEnd: latestEnd.toISOString()
  };
};
