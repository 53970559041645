import { Grid, ButtonGroup, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import React from "react";
import { SubscriptionBillingSettings } from "./SubscriptionsBillingSettings";
import { LiveStreamingSetting } from "./LiveStreamingSetting";
import { AppVersion } from "./AppVersion";

export const MobileApp = () => {
  const [organizationId] = useRecoilState(organizationAtom);

  const buttonGroups = [
    "Subscription & Billing",
    "Live Streaming",
    "App Version"
  ];
  const [activeButton, setActiveButton] = React.useState(
    "Subscription & Billing"
  );

  return (
    <Grid container direction="column" spacing="10px">
      <Grid item container direction="column" spacing="15px">
        {!organizationId && (
          <Grid
            item
            style={{
              marginLeft: "25px",
              marginTop: "16px"
            }}
          >
            <ButtonGroup
              variant="outlined"
              style={{ border: "rgba(215, 221, 229, 1)" }}
            >
              {buttonGroups.map((bg) => {
                return (
                  <Button
                    key={bg}
                    data-testid={"TAB_BUTTON_" + bg}
                    variant="outlined"
                    style={
                      activeButton === bg
                        ? {
                            backgroundColor: "#E8EEFF",
                            color: "#2B337A",
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: "none"
                          }
                        : {
                            color: "#666666",
                            fontSize: "14px",
                            fontWeight: 500,
                            textTransform: "none"
                          }
                    }
                    onClick={() => {
                      setActiveButton(bg);
                    }}
                  >
                    {bg}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="column"
        spacing={3}
        sx={{ marginTop: "1px", paddingLeft: "24px" }}
      >
        {activeButton === "Subscription & Billing" && (
          <SubscriptionBillingSettings />
        )}
        {activeButton === "Live Streaming" && <LiveStreamingSetting />}
        {activeButton === "App Version" && <AppVersion />}
      </Grid>
    </Grid>
  );
};
