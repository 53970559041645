import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Box, Typography, ToggleButton, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { DateCalendar } from "@mui/x-date-pickers-pro";
import { getCountries, getSportsByCountry } from "@services/Network";
import {
  useAdminDemoSessionPost,
  useAdminDemoSessionSlotGet,
  useLookupCountryCountryIdSubdivisionGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { isValidEmail } from "@utils/isValidEmail";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { ORGANIZATION_TYPE_OPTIONS } from "@utils/constants";
import { FormMultiSelect } from "@components/FormMultiSelect";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const StyledToggleButton = styled(ToggleButton)`
  background-color: #f3f4f6 !important;
  border: none;
  font-weight: 600 !important;
  line-height: 20px;
  border-radius: 4px !important;
  padding: 8px 16px 8px 16px;
  margin-right: 8px;
  margin-top: 24px;
  font-size: 14px;
  color: #000;

  &.MuiToggleButton-root.Mui-selected {
    background-color: #2b337a !important;
    color: #fff;
  }
  &.MuiToggleButton-root.Mui-disabled {
    border: none;
  }
  @media (max-width: 500px) {
    width: 101px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
    width: 24vw;
  }
`;

const StyledScheduledBox = styled(Box)`
  background-color: #e8eeff;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
`;

export const BookDemoSession = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [bookAppointment, setBookAppointment] = useState(false);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const isDesktop = useMediaQuery("(min-width: 500px)");
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      country: "",
      date: ""
    }
  });

  const country = watch("country");

  const { data: countryData, isLoading: isCountryDataLoading } = getCountries({
    query: {
      staleTime: Infinity,
      cacheTime: 0
    }
  });

  const { data: stateData, isLoading: isStateDataLoading } =
    useLookupCountryCountryIdSubdivisionGet(country, {
      query: {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !!country
      }
    });
  const startDate = useMemo(() => new Date().toISOString(), []);
  const endDate = useMemo(
    () =>
      new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString(),
    []
  );

  const { data: demoSlots } = useAdminDemoSessionSlotGet({
    startDate,
    endDate
  });

  const countryOptions = useMemo(() => {
    if (countryData?.length) {
      return countryData
        .map((country) => ({
          value: country.countryId as string,
          label: country.name as string
        }))
        .sort((c1, c2) => {
          if (c1.value === "US") return -1;
          if (c2.value === "US") return 1;
          return 0;
        });
    }
    return [];
  }, [countryData]);

  const selectedCountry = useMemo(() => {
    if (countryData?.length) {
      return countryData?.find((c) => c.countryId === country);
    }
    return null;
  }, [country, countryData]);

  const stateOptions = useMemo(() => {
    if (stateData?.data?.length) {
      return stateData.data.map((state) => ({
        value: state.subdivisionId as string,
        label: state.name as string
      }));
    }
    return [];
  }, [stateData]);

  const stateLabel = useMemo(() => {
    if (!stateData || !stateData.data || stateData.data.length === 0) {
      return "";
    }

    const categoryCounts = stateData.data.reduce((counts, item) => {
      const category = item.category as string;
      counts[category] = (counts[category] || 0) + 1;
      return counts;
    }, {});

    const maxCategory = Object.keys(categoryCounts).reduce((max, category) => {
      return categoryCounts[category] > categoryCounts[max] ? category : max;
    });
    return maxCategory || "";
  }, [stateData, selectedCountry]);

  const { data: sports, isFetching } = getSportsByCountry(country);

  const sportsOptions = sports?.map((sport) => ({
    label: !sport.name ? (sport.sport?.name as string) : (sport.name as string),
    value: sport.sportId as string
  }));

  const mutation = useAdminDemoSessionPost();
  const submitForm = async (formValues) => {
    formValues["noOfAthletes"] = parseInt(formValues["noOfAthletes"]);
    const date = new Date(selectedDate);
    const day = date.getDate();
    console.log(selectedTime);
    date.setUTCHours(
      parseInt(selectedTime.split(":")[0]),
      parseInt(selectedTime.split(":")[1]),
      0,
      0
    );
    date.setDate(day);
    formValues["appointmentDate"] = date.toISOString();
    mutation.mutate(
      {
        data: formValues
      },
      {
        onSuccess: () => {
          enqueueSnackbar(`Demo Session booked successfully!`, {
            variant: "success"
          });
          reset();
          setBookAppointment(false);
          window.close();
        },
        onError: () => {
          enqueueSnackbar(`Failed to book demo session.`, {
            variant: "error"
          });
        }
      }
    );
  };

  const formatDateTimeRange = (dateStr: string, hour: string) => {
    const date = new Date(dateStr);
    const [hours, minutes] = hour.split(":").map(Number);
    date.setHours(hours, minutes, 0, 0);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric"
    });
    const formattedStartTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true
    });
    const endTime = new Date(
      date.getTime() + (demoSlots?.data?.demoDuration || 60) * 60000
    );
    const formattedEndTime = endTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true
    });

    return `${formattedDate} - ${formattedStartTime} to ${formattedEndTime}`;
  };
  return (
    <>
      {!bookAppointment && (
        <FormContainer>
          <Typography
            variant="h2"
            color="text.general.primary"
            sx={{ fontWeight: 300, mb: ".5rem" }}
          >
            Sports Organization - Book Demo
          </Typography>
          <Typography variant="body1" color="text.general.secondary">
            Enter your information below to book a demo and learn how
            SportsGravy can help your sports organization.
          </Typography>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="organizationName"
                type="text"
                required
                label="Organization Name"
                control={control}
                rules={{ required: "Organization Name is required" }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormSelect
                name="organizationType"
                label="Type"
                required
                rules={{ required: "Type is required" }}
                options={ORGANIZATION_TYPE_OPTIONS}
                control={control}
              />
            </FormFieldContainer>
          </FormRow>

          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="noOfAthletes"
                label="No. of Athletes"
                type="number"
                control={control}
                required
                rules={{
                  required: "No. of Athletes is required",
                  valueAsNumber: true,
                  validate: (value) =>
                    value >= 0 || "Enter a valid no. of athletes"
                }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormSelect
                name="country"
                label="Country"
                options={countryOptions}
                control={control}
                required
                rules={{
                  required: "Country is required",
                  valueAsNumber: true
                }}
                isLoading={isCountryDataLoading}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer sx={{ mr: "1.5rem" }}>
              <FormInput
                name="city"
                label="City"
                type="text"
                control={control}
                required
                rules={{
                  required: "City is required",
                  valueAsNumber: true
                }}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <FormSelect
                name="state"
                label={capitalize(stateLabel) || "State/Province"}
                options={stateOptions}
                control={control}
                required
                rules={{
                  required: `${
                    capitalize(stateLabel) || "State/Province"
                  } is required`
                }}
                isLoading={!!country && isStateDataLoading}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormMultiSelect
                name="sportIds"
                options={sportsOptions}
                required
                label="Sports Offered"
                control={control}
                isLoading={isFetching}
                rules={{ required: "Sports Offered is required" }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="primaryContact"
                label="Primary Contact"
                type="text"
                control={control}
                required
                rules={{
                  required: "Primary Contact is required",
                  valueAsNumber: true
                }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="email"
                type="text"
                required
                label="Primary Contact Email"
                control={control}
                rules={{
                  required: "Primary Contact Email is required",
                  validate: {
                    validEmail: (email: string) =>
                      isValidEmail(email) ||
                      "Please enter a valid email address"
                  }
                }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="phone"
                label="Primary Contact Mobile Phone"
                type="tel"
                control={control}
                required
                rules={{
                  required: "Primary Contact Mobile Phone is required",
                  valueAsNumber: true
                }}
                country={selectedCountry}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="message"
                label="Why are you interested in SportsGravy?"
                type="text"
                multiline
                rows={3}
                control={control}
              />
            </FormFieldContainer>
          </FormRow>
          <ButtonContainer>
            <Button
              variant="primary"
              disabled={!isValid || !!mutation.isLoading}
              onClick={() => setBookAppointment(true)}
              isLoading={!!mutation.isLoading}
            >
              Continue
            </Button>
          </ButtonContainer>
        </FormContainer>
      )}
      {bookAppointment && (
        <FormContainer>
          <Typography
            variant="h2"
            color="text.general.primary"
            sx={{ fontWeight: 300, mb: ".5rem" }}
          >
            Select Demo Date and Time
          </Typography>
          <Typography variant="body1" color="text.general.secondary">
            Please choose a convenient date and time for your SportsGravy demo.
          </Typography>
          <Grid container sx={{ marginTop: "32px" }}>
            <Controller
              control={control}
              name="date"
              rules={{ required: "Date is required" }}
              render={({ field }) => (
                <DateCalendar
                  views={["day"]}
                  disablePast
                  sx={{
                    margin: 0,
                    height: "300px",
                    border: "1px solid #E2E8F0",
                    borderRadius: "5px"
                  }}
                  shouldDisableDate={(date: Date) => {
                    const today = new Date(new Date().setHours(0, 0, 0, 0));
                    const d = `${(date as Date).getFullYear()}-${(
                      (date as Date).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}-${(date as Date)
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`;
                    const daySlot = demoSlots?.data?.slots?.find(
                      (s) => s.date == d
                    );
                    return (
                      !daySlot ||
                      (daySlot && daySlot?.slots?.length == 0) ||
                      date.getTime() == today.getTime() ||
                      date.getDay() == 6 ||
                      date.getDay() == 0
                    );
                  }}
                  onChange={(date) => {
                    const d = `${(date as Date).getFullYear()}-${(
                      (date as Date).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}-${(date as Date)
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`;
                    setSelectedDate(d as string);
                    field.onChange(date);
                  }}
                />
              )}
            />
          </Grid>
          <Grid container md={10} xs={12}>
            {demoSlots?.data?.slots
              ?.find((s) => s.date == selectedDate)
              ?.slots?.map((slot) => (
                <Grid key={slot} xs={isDesktop ? 2 : 4}>
                  <StyledToggleButton
                    selected={selectedTime === slot}
                    value={slot}
                    onClick={() => setSelectedTime(slot)}
                  >
                    {slot}
                  </StyledToggleButton>
                </Grid>
              ))}
          </Grid>
          <FormRow>
            <Typography sx={{ fontWeight: 700, fontSize: "12px" }}>
              Time Zone:
            </Typography>{" "}
            &nbsp;
            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
              Central Standard Time (CST)
            </Typography>
          </FormRow>
          {selectedDate && selectedTime && (
            <>
              <FormRow>
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  You have scheduled your demo on:
                </Typography>
              </FormRow>
              <FormRow sx={{ marginTop: "6px" }}>
                <StyledScheduledBox>
                  <Typography
                    sx={{ color: "#2B337A", fontSize: "14px", fontWeight: 500 }}
                  >
                    {formatDateTimeRange(selectedDate, selectedTime)}
                  </Typography>
                </StyledScheduledBox>
              </FormRow>
            </>
          )}
          <ButtonContainer>
            <Button
              variant="primary"
              disabled={!isValid || !!mutation.isLoading || !selectedTime}
              type="submit"
              isLoading={!!mutation.isLoading}
              onClick={handleSubmit(submitForm)}
            >
              Book Demo
            </Button>
          </ButtonContainer>
        </FormContainer>
      )}
    </>
  );
};
