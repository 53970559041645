/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton, styled, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { useContext } from "react";
import {
  ModelAccountContact,
  ModelContact
} from "@sportsgravyengineering/sg-api-react-sdk";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import timezone from "dayjs/plugin/timezone";
import { createCRMActivity } from "@services/Network";
import { Loader } from "@components/crud/Loader";
import Grid from "@mui/system/Unstable_Grid";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

const StyledBox = styled("div")`
  position: absolute;
  bottom: 0;
  right: 32px;
  width: 450px;
  padding: 24px 24px 40px 24px;
  border-radius: 8px 0;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 24px 0px #00000029;
  background-color: #ffffff;
  z-index: 10000;
  min-height: 300px;
`;

const StyledOrgName = styled("div")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  width: 100%;
  text-align: center;
`;

const StyledOrgLocation = styled("div")`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666;
  width: 100%;
  text-align: center;
  margin-top: 6px;
  width: calc(100% - 24px);
`;

const ContactListBox = styled("div")`
  margin-top: 24px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

const StyledHeader = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

export const ContactAccountList = ({ contact }: { contact: ModelContact }) => {
  console.log(contact);
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const { setContact, connectToCall, setCallAccepted, setCallerDetails } =
    useContext(CallEmailTemplateContext);

  const mutation = createCRMActivity();

  const Contact = ({
    phone,
    account,
    phoneType
  }: {
    phone: string;
    account?: ModelAccountContact;
    phoneType: "WORK" | "PERSONAL";
  }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "10px"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div
            style={{ display: "flex", alignItems: "flex-start", gap: "6px" }}
          >
            <div
              style={{
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#000"
              }}
            >
              {formatPhoneWithCountryCode(phone)}
              {account?.isPrimary && (
                <StarRateRoundedIcon
                  style={{ height: "16px", width: "16px", color: "#E82C2C" }}
                />
              )}
            </div>
          </div>
          {phoneType === "WORK" && (
            <div
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#666"
              }}
            >
              {account?.account?.name}
            </div>
          )}
        </div>
        {contact?.phone && (
          <div
            style={{
              height: "40px",
              minWidth: "40px",
              backgroundColor: "#1ABC9C",
              borderRadius: "99px",
              padding: "8px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={async () => {
                mutation.mutate(
                  {
                    data: {
                      accountId: account?.accountId,
                      type: "CALL",
                      relatesTo: "CONTACT",
                      contactId: contact.contactId,
                      date: new Date(),
                      timezone: dayjs.tz.guess(),
                      direction: "outbound",
                      status: "LIVE",
                      outcome: "CONNECTED_WITH_CONTACT",
                      internalParticipants: [user?.userId as string],
                      externalParticipants: [
                        {
                          contactId: contact.contactId,
                          phone: contact?.phone || ""
                        }
                      ]
                    }
                  },
                  {
                    onSuccess: (data) => {
                      setCallerDetails({
                        activityId: data?.data?.activity?.activityId,
                        accountId: account?.accountId,
                        accountName: account?.account?.name,
                        relatesTo: "CONTACT",
                        contactId: contact.contactId,
                        callDirection: "outbound",
                        phoneType: phoneType,
                        location: account?.account?.officeAddress,
                        phone: contact?.phone,
                        email: contact?.email,
                        isConnected: account?.accountId ? true : false,
                        contact: {
                          contactName:
                            contact?.firstName + " " + contact?.lastName,
                          contactPhone: contact?.phone,
                          isPrimary: account?.isPrimary
                        }
                      });
                      connectToCall(
                        contact?.phone as string,
                        data?.data?.activity?.activityId
                      );
                      setContact(undefined);
                      setCallAccepted(true);
                    }
                  }
                );
              }}
            >
              <CallIcon
                style={{ height: "24px", width: "24px", color: "#fff" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <StyledBox>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}
      >
        <StyledOrgName>{`${contact.firstName} ${contact.lastName}`}</StyledOrgName>
        <IconButton
          style={{ padding: 0 }}
          onClick={() => setContact(undefined)}
        >
          <CloseRoundedIcon
            style={{ height: "24px", width: "24px", color: "#000" }}
          />
        </IconButton>
      </div>
      <StyledOrgLocation>{contact?.location}</StyledOrgLocation>
      <Loader isLoading={mutation.isLoading}>
        <ContactListBox>
          {contact.phone && (
            <>
              <Grid xs={12}>
                <StyledHeader>Personal</StyledHeader>
                <HeaderUnderLine width="100%" />
              </Grid>
              {contact?.phone && (
                <Contact
                  phone={contact?.phone}
                  phoneType="PERSONAL"
                  {...(contact.accounts?.length === 1 && {
                    account: contact.accounts[0]
                  })}
                />
              )}
            </>
          )}

          {contact?.accounts &&
            contact?.accounts.filter((c) => c.workPhone).length > 0 && (
              <>
                <Grid xs={12}>
                  <StyledHeader>Work</StyledHeader>
                  <HeaderUnderLine width="100%" />
                </Grid>
                {contact.accounts?.map(
                  (account) =>
                    account.workPhone && (
                      <Contact
                        account={account}
                        key={account.accountId}
                        phoneType="WORK"
                        phone={account?.workPhone}
                      />
                    )
                )}
              </>
            )}
        </ContactListBox>
      </Loader>
    </StyledBox>
  );
};
