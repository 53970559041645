import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { NotificationCard } from "@components/NotificationCard";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { inviteAtom, personAtom, signupTokenAtom } from "@recoil/signup";
import { basicPost } from "@services/Network";
import {
  AuthSignUpBasicRequest,
  ModelInvite,
  ModelPerson,
  SGError,
  responseCodes
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeFirstCharacter } from "@utils/capitalize";
import { isValidEmail } from "@utils/isValidEmail";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const suffixOptions = [
  { value: "", label: "" },
  { value: "Jr", label: "Jr" },
  { value: "Sr", label: "Sr" },
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
  { value: "IV", label: "IV" },
  { value: "V", label: "V" }
];

export const BasicInfo = () => {
  const navigate = useNavigate();
  const signupToken = useRecoilValue<string>(signupTokenAtom);
  const person = useRecoilValue<ModelPerson | null>(personAtom);
  const invite = useRecoilValue<ModelInvite | null>(inviteAtom);
  const [isDuplicateAccountError, setIsDuplicateAccountError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: person?.firstName || "",
      lastName: person?.lastName || "",
      middleName: person?.middleName || "",
      suffix: person?.suffix || "",
      email: person?.emailPrimary?.email || ""
    }
  });

  useEffect(() => {
    reset(
      {
        firstName: person?.firstName || "",
        lastName: person?.lastName || "",
        middleName: person?.middleName || "",
        suffix: person?.suffix || "",
        email: person?.emailPrimary?.email || ""
      },
      {
        keepDirty: true
      }
    );
  }, [personAtom]);

  const mutation = basicPost();

  const submitForm = async (formValues: AuthSignUpBasicRequest) => {
    formValues.firstName = formValues.firstName.trim();
    formValues.lastName = formValues.lastName.trim();
    formValues.middleName = formValues.middleName?.trim();
    mutation.mutate(
      {
        data: formValues,
        headers: {
          "x-sportsgravy-token": signupToken
        }
      },
      {
        onSuccess: () => {
          navigate("/sign-up/personal-info");
        },
        onError: (e: unknown) => {
          const error = e as { response: { data: SGError } };
          const errorCode = error?.response?.data?.code;
          if (errorCode === responseCodes.AUTH_SIGNUP_PERSONAL_DUPLICATE) {
            navigate("/sign-up/pending-account-warning");
          }
          if (errorCode === responseCodes.AUTH_SIGNUP_ASSOCIATED_EMAIL) {
            navigate("/sign-up/duplicate-account-associated-email", {
              state: { email: formValues.email.toLowerCase() }
            });
          }
          if (errorCode === responseCodes.AUTH_SIGNUP_DUPLICATE) {
            setIsDuplicateAccountError(true);
          }
        }
      }
    );
  };

  const errorMsg = isDuplicateAccountError ? (
    <span>
      This email is already taken. Please <Link to="/">sign in</Link> or enter a
      different email below.
    </span>
  ) : (
    "Error: Something went wrong. Try submitting again."
  );

  return (
    <FormContainer data-testid="signup-basicInfo">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Sign Up
      </Typography>
      <Typography variant="body1" color="text.general.secondary">
        Enter your Information below to begin creating your account
      </Typography>
      {mutation.isError && (
        <NotificationCard
          variant="error"
          sx={{
            mt: "1rem"
          }}
        >
          {errorMsg}
        </NotificationCard>
      )}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormRow>
          <FormFieldContainer
            sx={{ mr: "1.5rem" }}
            data-testid="signup-firstName"
          >
            <FormInput
              name="firstName"
              type="text"
              required
              label="First Name"
              control={control}
              disabled={!!invite?.inviteId}
              rules={{ required: "First Name is required" }}
              onChange={(e) => {
                setValue(
                  "firstName",
                  capitalizeFirstCharacter(
                    (e as React.ChangeEvent<HTMLInputElement>).target
                      .value as string
                  )
                );
              }}
            />
          </FormFieldContainer>
          <FormFieldContainer data-testid="signup-middleName">
            <FormInput
              name="middleName"
              type="text"
              label="Middle Name"
              disabled={!!invite?.inviteId}
              control={control}
              onChange={(e) => {
                setValue(
                  "middleName",
                  capitalizeFirstCharacter(
                    (e as React.ChangeEvent<HTMLInputElement>).target
                      .value as string
                  )
                );
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer
            sx={{ mr: "1.5rem" }}
            data-testid="signup-lastName"
          >
            <FormInput
              name="lastName"
              type="text"
              required
              label="Last Name"
              control={control}
              disabled={!!invite?.inviteId}
              rules={{ required: "Last Name is required" }}
              onChange={(e) => {
                setValue(
                  "lastName",
                  capitalizeFirstCharacter(
                    (e as React.ChangeEvent<HTMLInputElement>).target
                      .value as string
                  )
                );
              }}
            />
          </FormFieldContainer>
          <FormFieldContainer data-testid="signup-suffix">
            <FormSelect
              name="suffix"
              label="Suffix"
              disabled={!!invite?.inviteId}
              control={control}
              options={suffixOptions}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer data-testid="signup-email">
            <FormInput
              name="email"
              type="text"
              required
              label="Email"
              control={control}
              disabled={!!invite?.inviteId}
              rules={{
                required: "Email is required",
                validate: {
                  validEmail: (email: string) =>
                    isValidEmail(email) || "Please enter a valid email address"
                }
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <ButtonContainer data-testid="SIGNUP_BASIC_CONTINUE_BTN">
          <Button
            variant="primary"
            disabled={!isValid || !!mutation.isLoading}
            type="submit"
            isLoading={!!mutation.isLoading}
          >
            Continue
          </Button>
        </ButtonContainer>
      </form>
      <SignInContainer>
        <Typography variant="body1">
          Already have an account? <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
    </FormContainer>
  );
};
