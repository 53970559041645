import { Container } from "@components/crud/Container";
import { FormHelperText, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import React, { ChangeEvent, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DebitCardIcon from "@assets/icons/debitCardIcon.svg";
import CreditCardIcon from "@assets/icons/creditCardIcon.svg";
import { useForm } from "react-hook-form";
import { FormInput } from "@components/FormInput";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormSelect } from "@components/FormSelect";
import { Button } from "@components/Button";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { PasswordInput } from "@components/PasswordInput";
import { getCountries } from "@services/Network";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  CreatePaymentInputPaymentType,
  useAdminCrmOrderIdCompressedEmailPaymentDetailPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { CRM_ORDER_BANK_ACH_TYPES } from "@utils/constants";

const StyledDiv = styled("div")`
  box-shadow: 0px 0px 12px 0px #0000001f;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 75%;
  max-width: 850px;
  margin: 0 auto;
  padding: 24px;
  @media (max-width: 476px) {
    width: 90%;
  }
`;

const StyledPaymentType = styled(Grid)`
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  border: 1.5px solid #e5e5e5;
  height: 48px;
  cursor: pointer;
  @media (max-width: 576px) {
    padding: 10px 12px;
  }
`;

const StyledPaymentTypeName = styled("div")`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

const StyledButton = styled(Grid)`
  button {
    width: 100%;
  }
`;

const convertDateToExpiry = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(2);

  const formattedDate = month + year;
  return formattedDate;
};

export const PaymentDetails = ({
  onSaveClick
}: {
  onSaveClick: () => void;
}) => {
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<string>("DEBIT_CARD");
  const { orderId, compressedEmail } = useParams();
  const {
    control,
    reset,
    formState: { isValid },
    getValues
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      cvv: "",
      number: "",
      expirationDate: null,
      cardName: "",
      country: "",
      zipCode: "",
      billingInstructions: "",
      routingNumber: "",
      accountType: "",
      accountName: ""
    }
  });
  const { data: countries, isLoading: countryLoading } = getCountries({
    staleTime: Infinity
  });
  const { mutate: save, isLoading: isSaving } =
    useAdminCrmOrderIdCompressedEmailPaymentDetailPost();
  const onSave = async () => {
    const details = getValues();
    const paymentDetails = {
      cardName: details?.cardName,
      country: details?.country,
      zipCode: details?.zipCode,
      billingInstructions: details?.billingInstructions,
      number: details?.number,
      expiry: details?.expirationDate
        ? convertDateToExpiry(details?.expirationDate)
        : "",
      cvv: details?.cvv,
      type: selectedPaymentType,
      routingNumber: details?.routingNumber,
      accType: details?.accountType,
      accountName: details?.accountName
    };
    const fullName =
      selectedPaymentType === "BANK_ACH"
        ? paymentDetails.accountName
        : paymentDetails.cardName;
    const data = {
      paymentType: paymentDetails.type as CreatePaymentInputPaymentType,
      ...(selectedPaymentType !== "BANK_ACH"
        ? {
            cardNumber: paymentDetails.number,
            expirationDate: paymentDetails.expiry,
            cvv: paymentDetails.cvv
          }
        : {
            accountNumber: paymentDetails.number,
            accountType: paymentDetails.accType,
            routingNumber: paymentDetails.routingNumber
          }),
      country: paymentDetails.country,
      zipCode: paymentDetails.zipCode,
      name: fullName,
      billingInstructions: paymentDetails.billingInstructions
    };
    save(
      {
        data: data,
        orderId: orderId!,
        compressedEmail: compressedEmail!
      },
      {
        onSuccess: () => {
          onSaveClick();
        },
        onError: () => {
          enqueueSnackbar("Failed to Save Payment Details!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Container style={{ padding: "32px 0", background: "#f3f4f7" }}>
      <StyledDiv>
        <Typography>Submit your payment information</Typography>
        <Grid container sx={{ marginTop: "24px" }} spacing={3}>
          <Grid>
            <StyledPaymentType
              xs={1}
              onClick={() => {
                setSelectedPaymentType("DEBIT_CARD");
                reset();
              }}
              sx={{
                ...(selectedPaymentType === "DEBIT_CARD"
                  ? {
                      backgroundColor: "#F1F5FF",
                      border: "1.5px solid #2B337A"
                    }
                  : {})
              }}
            >
              <img src={DebitCardIcon} />
            </StyledPaymentType>
            <StyledPaymentTypeName
              sx={{
                color:
                  selectedPaymentType === "DEBIT_CARD" ? "#0F0F0F" : "#666",
                fontWeight: selectedPaymentType === "DEBIT_CARD" ? "600" : "500"
              }}
            >
              Debit Card
            </StyledPaymentTypeName>
          </Grid>
          <Grid>
            <StyledPaymentType
              xs={1}
              onClick={() => {
                setSelectedPaymentType("CREDIT_CARD");
                reset();
              }}
              sx={{
                ...(selectedPaymentType === "CREDIT_CARD"
                  ? {
                      backgroundColor: "#F1F5FF",
                      border: "1.5px solid #2B337A"
                    }
                  : {})
              }}
            >
              <img src={CreditCardIcon} />
            </StyledPaymentType>
            <StyledPaymentTypeName
              sx={{
                color:
                  selectedPaymentType === "CREDIT_CARD" ? "#0F0F0F" : "#666",
                fontWeight:
                  selectedPaymentType === "CREDIT_CARD" ? "600" : "500"
              }}
            >
              Credit Card
            </StyledPaymentTypeName>
          </Grid>

          <Grid>
            <StyledPaymentType
              xs={1}
              onClick={() => {
                setSelectedPaymentType("BANK_ACH");
                reset();
              }}
              sx={{
                ...(selectedPaymentType === "BANK_ACH"
                  ? {
                      backgroundColor: "#F1F5FF",
                      border: "1.5px solid #2B337A"
                    }
                  : {})
              }}
            >
              <AccountBalanceIcon />
            </StyledPaymentType>
            <StyledPaymentTypeName
              sx={{
                color: selectedPaymentType === "BANK_ACH" ? "#0F0F0F" : "#666",
                fontWeight: selectedPaymentType === "BANK_ACH" ? "600" : "500"
              }}
            >
              Bank (ACH)
            </StyledPaymentTypeName>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "24px" }} spacing={3}>
          {(selectedPaymentType === "CREDIT_CARD" ||
            selectedPaymentType === "DEBIT_CARD") && (
            <>
              <Grid md={6} xs={12}>
                <FormInput
                  control={control}
                  name="number"
                  label="Card Number"
                  type="text"
                  required
                  rules={{
                    required: "Card Number is required",
                    validate: (value) => {
                      value && value.length != 12
                        ? "Invalid Card Number"
                        : true;
                    }
                  }}
                  TextProps={{
                    inputProps: {
                      maxLength: 16,
                      onInput: (e) => {
                        (e as ChangeEvent<HTMLInputElement>).target.value = (
                          e as ChangeEvent<HTMLInputElement>
                        ).target.value.replace(/[^0-9]/g, "");
                      }
                    }
                  }}
                />
              </Grid>
              <Grid md={3} xs={6}>
                <FormDatePicker
                  disablePast
                  name={`expirationDate`}
                  label="Expiration Date"
                  views={["year", "month"]}
                  format="MM/yyyy"
                  control={control}
                  required
                  rules={{
                    required: "Expiration Date is required"
                  }}
                />
              </Grid>
              <Grid md={3} xs={6}>
                <PasswordInput
                  name={`cvv`}
                  label="CVV"
                  control={control}
                  required
                  rules={{
                    required: "CVV is required"
                  }}
                  TextProps={{
                    inputProps: {
                      maxLength: 3,
                      onInput: (e) => {
                        (e as ChangeEvent<HTMLInputElement>).target.value = (
                          e as ChangeEvent<HTMLInputElement>
                        ).target.value.replace(/[^0-9]/g, "");
                      }
                    }
                  }}
                />
              </Grid>
              <Grid md={6} xs={12}>
                <FormInput
                  control={control}
                  name="cardName"
                  label="Name on Card"
                  type="text"
                  rules={{
                    required: "Name on Card is required"
                  }}
                  required
                />
              </Grid>
              <Grid md={3} xs={6}>
                <FormSelect
                  label="Country"
                  name="country"
                  control={control}
                  required
                  rules={{
                    required: "Country is required"
                  }}
                  isLoading={countryLoading}
                  options={
                    countries
                      ? countries.map((c) => ({
                          label: c.name,
                          value: c.countryAltId
                        }))
                      : []
                  }
                />
              </Grid>
              <Grid md={3} xs={6}>
                <FormInput
                  control={control}
                  name="zipCode"
                  label="Zip Code"
                  type="text"
                  rules={{
                    required: "Zip Code is required"
                  }}
                  required
                />
              </Grid>
            </>
          )}
          {selectedPaymentType === "BANK_ACH" && (
            <>
              <Grid xs={12}>
                <FormSelect
                  control={control}
                  name="accountType"
                  label="Account Type"
                  options={CRM_ORDER_BANK_ACH_TYPES}
                  required
                  rules={{
                    required: "Account Type is required"
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <FormInput
                  control={control}
                  name="routingNumber"
                  label="Routing Number"
                  type="text"
                  rules={{
                    required: "Routing Number is required"
                  }}
                  required
                />
              </Grid>
              <Grid md={6} xs={12}>
                <FormInput
                  control={control}
                  name="number"
                  label="Account Number"
                  type="text"
                  rules={{
                    required: "Account Number is required"
                  }}
                  required
                />
              </Grid>
              <Grid md={6} xs={12}>
                <FormInput
                  control={control}
                  name="confirmAccNumber"
                  label="Confirm Account Number"
                  type="text"
                  rules={{
                    required: "Confirm Account Number is required",
                    validate: (value) =>
                      value === getValues().number ||
                      "Confirm Account Number does not match"
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <FormInput
                  control={control}
                  name="accountName"
                  label="Account Holder’s Name"
                  type="text"
                  required
                  rules={{
                    required: "Account Holder's Name is required"
                  }}
                />
              </Grid>
            </>
          )}
          <Grid xs={12}>
            <FormInput
              control={control}
              name="billingInstructions"
              label="Billing Instructions"
              type="text"
            />
          </Grid>
          <Grid xs={12}>
            <FormHelperText>
              By selecting Submit Payment Information, you accept the Terms of
              Service. Our Global Privacy Statement will apply to any personal
              information you provide us. You also allow SportsGravy to store
              your payment information for future transactions. Note: 3%
              surcharge for paying by credit_card.
            </FormHelperText>
          </Grid>
          <StyledButton xs={12}>
            <Button
              variant="admin-primary"
              startIcon={<LockOutlinedIcon />}
              disabled={!isValid || isSaving}
              isLoading={isSaving}
              onClick={onSave}
            >
              Submit Payment Information
            </Button>
          </StyledButton>
          <Grid
            xs={12}
            sx={{ display: "flex", justifyContent: "center", padding: 0 }}
          >
            <Typography style={{ fontSize: "12px", color: "#666" }}>
              @2024 SportsGravy. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </StyledDiv>
    </Container>
  );
};
