import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelMedia,
  useAdminEmailTemplateTemplateIdDelete,
  useAdminEmailTemplateTemplateIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { EmailTemplateForm } from "./emailTemplateForm";

export const EmailTemplateView = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { reset } = form;

  console.log(templateId);
  const [departmentId, setDepartmentId] = useState<string>();
  const { data: templateData, isLoading: isLoading } =
    useAdminEmailTemplateTemplateIdGet(templateId!);
  React.useEffect(() => {
    if (templateData?.data) {
      const template = templateData.data;
      setDepartmentId(template.departmentId);
      setFiles(
        template.attachments?.map((a) => ({
          id: a.mediaId!,
          file: a
        })) || []
      );
      reset({
        name: template.name,
        isActive: template.isActive,
        departmentId: template.departmentId,
        jobTitleId: template.jobTitleId,
        subject: template.subject,
        description: template.body
      });
    }
  }, [templateData]);
  const [files, setFiles] = useState<{ id: string; file: File | ModelMedia }[]>(
    []
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminEmailTemplateTemplateIdDelete();

  const onDepartmentDelete = async () => {
    try {
      await deleteAsync({
        templateId: templateId!
      });
      enqueueSnackbar("Email Template Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/email-templates");
    } catch (e) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete the Email Template.",
        {
          variant: "error"
        }
      );
      setOpenDeleteDialog(false);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Email Template"
        backBtnClick={() => {
          navigate("/email-templates");
        }}
        editBtnClick={() => {
          navigate(`/email-templates/${templateId}/edit`);
        }}
        deleteBtnClick={() => {
          setOpenDeleteDialog(true);
        }}
      />
      <Loader isLoading={isLoading || isDeleting}>
        <EmailTemplateForm
          form={form}
          disabled
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          files={files}
          setFiles={setFiles}
        />
      </Loader>
      <ConfirmationDialog
        title="Delete Email Template?"
        body="Are you sure you want to delete this Email Template?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onDepartmentDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
