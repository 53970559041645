/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormLabel, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import theme from "../theme/index";

const FormSwitchContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const StyledFormLabel = styled(FormLabel)(() => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}));

export const FormSwitch = (props: {
  name: string;
  control: any;
  rules?: any | undefined;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
  renderBorderStyle?: any;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  showLabels?: boolean | undefined;
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field }) => {
        return (
          <FormSwitchContainer data-testid={"FORM_SWITCH_" + props.name}>
            {props.showLabels && (
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  Off
                </Typography>
              </StyledFormLabel>
            )}
            <div>
              <Switch
                checked={field.value || false}
                value={field.value || false}
                sx={props.renderBorderStyle || {}}
                onChange={(event) => {
                  field.onChange(event);
                  if (props.onChange) {
                    props.onChange(event);
                  }
                }}
                disabled={props.disabled}
              />
            </div>
            {props.showLabels && (
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  On
                </Typography>
              </StyledFormLabel>
            )}
          </FormSwitchContainer>
        );
      }}
    />
  );
};

FormSwitch.defaultProps = {
  showLabels: true
};
