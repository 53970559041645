import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { FormHelperText } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { TwilioNumberSearchAdd } from "@pages/users/TwilioNumberSearchAdd";
import { useAdminJobTitleGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { EMAIL_REGEX } from "@utils/validation";
import React, { ChangeEvent, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
export const DepartmentForm = ({
  form,
  disabled
}: {
  form: UseFormReturn;
  disabled?: boolean;
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = form;
  const forwarsTo = watch("forwardTo");
  const { data: jobs, isLoading: isJobsLoading } = useAdminJobTitleGet({
    pageSize: 1000,
    supportsCallCenterCalls: true
  });

  const jobWithCall = useMemo(() => {
    if (jobs?.data && jobs.data.jobTitles)
      return jobs?.data.jobTitles.map((job) => ({
        label: job.name as string,
        value: job.jobtitleId as string
      }));
    return [];
  }, [jobs]);

  return (
    <Container>
      <Form>
        <Grid container spacing={3}>
          <Grid xs={6} data-testid="department-name">
            <FormInput
              disabled={disabled}
              name="name"
              onChange={(e) => {
                setValue(
                  "name",
                  capitalizeEveryWord(
                    (e as ChangeEvent<HTMLInputElement>).target.value
                  )
                );
              }}
              control={control}
              label="Name"
              required
              rules={{
                required: "Name is required"
              }}
              type="text"
            />
          </Grid>
          <Grid xs={6}>
            <FormInput
              name="email"
              label="SportsGravy Email"
              control={control}
              type="text"
              disabled={disabled}
              rules={{
                validate: (value) =>
                  value
                    ? !EMAIL_REGEX.test(value)
                      ? "Invalid Email"
                      : undefined
                    : undefined
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TwilioNumberSearchAdd
              disabled={disabled}
              control={control}
              name="sgNumber"
              province="MN"
            />
          </Grid>
          <Grid xs={6}>
            <FormSelect
              disabled={disabled}
              control={control}
              label="Forward To"
              required
              rules={{
                required: "Forward To is required"
              }}
              name="forwardTo"
              options={[
                { label: "Call Center", value: "CALL_CENTER" },
                {
                  label: "Individual Jobs",
                  value: "JOBS"
                }
              ]}
              onChange={(e) => {
                if (e.target.value === "CALL_CENTER") {
                  setValue("jobs", []);
                }
              }}
            />
          </Grid>
          {forwarsTo === "JOBS" && (
            <Grid xs={6}>
              <FormMultiSelect
                disabled={disabled}
                isLoading={isJobsLoading}
                control={control}
                name="jobs"
                label="Participating Jobs"
                required
                options={jobWithCall}
                rules={{
                  required: "Jobs is required"
                }}
              />
              <FormHelperText
                style={{
                  color: "#B3B3B3",
                  marginTop: errors.jobs ? "20px" : "5px"
                }}
              >
                Note: All users associated with the selected jobs above are
                placed in a pool to receive calls made to the department phone
                number.
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </Form>
    </Container>
  );
};
