import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormLabel, Grid, Link, Typography, styled } from "@mui/material";
import {
  ModelPersonGuardian,
  ModelRole,
  ModelUserRoleAliasBan
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import React, { useState } from "react";
import { Control } from "react-hook-form";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const RenderRolesWithAliases = ({
  orgRoles,
  control,
  isEditable,
  isNewUser,
  organizationId,
  sgBannedRoles,
  orgBannedRoles,
  disabled,
  showUnderline,
  onChangeRolesSelected,
  openRoleDesc,
  teamProgramRoles,
  setShowTPErrorModal,
  userChildren,
  userParentRoles,
  setValue
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orgRoles: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  isEditable?: boolean;
  isNewUser?: boolean;
  organizationId: string | undefined;
  sgBannedRoles?: string[] | ModelUserRoleAliasBan[] | undefined;
  orgBannedRoles?: string[] | undefined;
  disabled?: boolean;
  showUnderline?: boolean;
  onChangeRolesSelected?: (roleId, boolean) => void;
  openRoleDesc?: (roleId) => void;
  teamProgramRoles?: string[];
  setShowTPErrorModal?: (val: boolean) => void;
  userChildren?: ModelPersonGuardian[];
  userParentRoles?: ModelRole[];
  setValue?;
}) => {
  const isUnderAge = (birthedAt: string, ageOfConsent = 18): boolean => {
    const birthDate = new Date(birthedAt);
    const today = new Date();

    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the current date is before the child's birthday this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      return age < ageOfConsent;
    }
    return age < ageOfConsent;
  };

  const checkGuardiansAndRoles = (): number => {
    let matchingChildrenCount = 0;
    if (userChildren) {
      for (const obj of userChildren) {
        const { person } = obj;
        if (person?.birthedAt && isUnderAge(String(person.birthedAt))) {
          if (person?.guardians?.length === 1) {
            const hasMatchingRole = person.user?.roles?.some(
              (role) => role.organizationId === organizationId
            );
            if (hasMatchingRole) {
              matchingChildrenCount += 1;
            }
          }
        }
      }
    }

    return matchingChildrenCount;
  };

  const [parentToRemove, setParentToRemove] = useState<
    { roleId: string; message: string } | undefined
  >();

  const renderRoles = (rolesArr, isBannedAlias: boolean = false) => {
    const roleStyle =
      isEditable || showUnderline
        ? {
            color: "#3B6CF8",
            fontWeight: 400,
            textDecoration: "underline"
          }
        : {
            color: "#1E293B"
          };

    return (
      <Grid
        item
        container
        direction="row"
        spacing="10px"
        data-testid="roleRow"
        style={{ marginLeft: "-26px" }}
      >
        {rolesArr.map((role) => {
          let shouldDisableParent =
            (organizationId && role.role?.alias === "PARENT") || false;
          if (shouldDisableParent) {
            shouldDisableParent = !userParentRoles?.find(
              (r) => r.roleId === role.value
            );
          }
          let shouldDisableAthlete =
            (organizationId && role.role?.alias === "PLAYER") || false;
          if (shouldDisableAthlete) {
            const formValue =
              control._formValues[role.value.replace(".", "_") + "_SELECTED"];
            shouldDisableAthlete = !formValue;
          }
          if (organizationId || isEditable) {
            return (
              <Grid
                item
                container
                direction="row"
                key={role.value}
                data-testid={"ROLE_VALUE_" + role.value}
              >
                {/* <div
                  style={{
                    display: "flex",
                    marginLeft: "3px",
                    marginTop: "10px"
                  }}
                > */}
                <Grid item>
                  <FormCheckbox
                    // key={role.value}
                    control={control}
                    name={role.value.replace(".", "_") + "_SELECTED"}
                    label={role.label || role.role.name}
                    disabled={
                      disabled ||
                      isBannedAlias ||
                      shouldDisableParent ||
                      shouldDisableAthlete
                    }
                    labelPadding="4px 8px"
                    onClickLabel={() => openRoleDesc!(role.value)}
                    notUnCheckable={
                      role.role.alias !== "PARENT" &&
                      teamProgramRoles?.includes(role.value)
                    }
                    onChange={(e) => {
                      if (
                        !e.target.checked &&
                        role.role.alias !== "PARENT" &&
                        teamProgramRoles?.includes(role.value) &&
                        setShowTPErrorModal
                      ) {
                        setShowTPErrorModal(true);
                        return;
                      }
                      if (!e.target.checked && role.role?.alias === "PARENT") {
                        const childrenAssociated = checkGuardiansAndRoles();
                        if (childrenAssociated > 0) {
                          setParentToRemove({
                            roleId: role.value,
                            message: `This Parent/Guardian is the legal Parent/Guardian of
                             ${childrenAssociated} ${
                               childrenAssociated > 1 ? "children" : "child"
                             } 
                             under the legal age requirement who ${
                               childrenAssociated > 1 ? "do" : "does"
                             } not have another Legal Parent/Guardian who is actively associated within your Organization.
                            Removing this user from their Parent/Guardian Role will remove their children under the legal age requirement from your Organization, 
                            and they will be disassociated from all Teams and Training Programs.`
                          });
                          return;
                        }
                      }
                      onChangeRolesSelected!(role.value, e.target.checked);
                    }}
                    labelTypographyProps={{
                      variant: "body1",
                      style: roleStyle
                    }}
                  />
                </Grid>
                {role.children?.length ? (
                  role.children.map((subRoles) => {
                    let shouldDisableParent =
                      (organizationId && subRoles.role?.alias === "PARENT") ||
                      false;
                    if (shouldDisableParent) {
                      shouldDisableParent = !userParentRoles?.find(
                        (r) => r.roleId === subRoles.roleId
                      );
                    }
                    let shouldDisableAthlete =
                      (organizationId && subRoles.role?.alias === "PLAYER") ||
                      false;
                    if (shouldDisableAthlete) {
                      const formValue =
                        control._formValues[
                          subRoles.roleId.replace(".", "_") + "_SELECTED"
                        ];
                      shouldDisableAthlete = !formValue;
                    }
                    return (
                      <Grid
                        item
                        key={subRoles.roleId}
                        data-testid={"ROLE_VALUE_" + subRoles.roleId}
                      >
                        <FormCheckbox
                          control={control}
                          name={subRoles.roleId.replace(".", "_") + "_SELECTED"}
                          label={subRoles.displayText || subRoles.name}
                          labelPadding="4px 8px"
                          onClickLabel={() => openRoleDesc!(subRoles.roleId)}
                          disabled={
                            disabled ||
                            isBannedAlias ||
                            shouldDisableAthlete ||
                            shouldDisableParent
                          }
                          notUnCheckable={
                            subRoles.role?.alias !== "PARENT" &&
                            teamProgramRoles?.includes(role.value)
                          }
                          onChange={(e) => {
                            if (
                              !e.target.checked &&
                              subRoles.role?.alias !== "PARENT" &&
                              teamProgramRoles?.includes(role.value) &&
                              setShowTPErrorModal
                            ) {
                              setShowTPErrorModal(true);
                              return;
                            }
                            if (
                              !e.target.checked &&
                              subRoles.role?.alias === "PARENT"
                            ) {
                              const childrenAssociated =
                                checkGuardiansAndRoles();
                              if (childrenAssociated > 0) {
                                setParentToRemove({
                                  roleId: subRoles.roleId,
                                  message: `This Parent/Guardian is the legal Parent/Guardian of
                                   ${childrenAssociated} ${
                                     childrenAssociated > 1
                                       ? "children"
                                       : "child"
                                   } 
                                   under the legal age requirement who ${
                                     childrenAssociated > 1 ? "do" : "does"
                                   } not have another Legal Parent/Guardian who is actively associated within your Organization.
                                  Removing this user from their Parent/Guardian Role will remove their children under the legal age requirement from your Organization, 
                                  and they will be disassociated from all Teams and Training Programs.`
                                });
                                return;
                              }
                            }
                            onChangeRolesSelected!(
                              subRoles.roleId,
                              e.target.checked
                            );
                          }}
                          labelTypographyProps={{
                            variant: "body1",
                            style: roleStyle
                          }}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <></>
                )}
                {/* </div> */}
              </Grid>
            );
          } else {
            return (
              <Grid
                item
                key={role.role.roleId}
                data-testid={"ROLE_VALUE_" + role.role.roleId}
              >
                <FormCheckbox
                  // key={role.role.roleId}
                  control={control}
                  disabled={!isEditable}
                  name={role.role.roleId.replace(".", "_") + "_SELECTED"}
                  label={role.role.name}
                  labelPadding="4px 8px"
                  labelTypographyProps={{
                    variant: "body1"
                  }}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    );
  };

  const rolesAliasesMap = orgRoles.reduce((map, role) => {
    const alias = role.role.alias;
    if (!map[alias]) {
      map[alias] = [];
    }
    map[alias].push(role);
    return map;
  }, {});
  return (
    <Grid item container direction="column" spacing="10px">
      {Object.keys(rolesAliasesMap).map((alias) => {
        const bannedAlias =
          isEditable || !isNewUser
            ? organizationId
              ? orgBannedRoles?.indexOf(alias) !== -1 ||
                (sgBannedRoles && sgBannedRoles?.indexOf(alias) !== -1)
              : sgBannedRoles && sgBannedRoles?.indexOf(alias) !== -1
            : false;
        return (
          alias !== "SPORTSFAN" && (
            <Grid
              item
              container
              direction="column"
              spacing="10px"
              key={alias}
              data-testid={"ROLE_ALIAS_" + alias}
            >
              <Grid item>
                {isEditable || isNewUser ? (
                  <StyledFormLabel required>
                    <Typography display="inline" variant="formLabel">
                      For {" " + capitalize(alias)}
                      {(alias === "PARENT" || alias === "PLAYER") &&
                        " (Assign this role through "}
                      {(alias === "PARENT" || alias === "PLAYER") && (
                        <>
                          <Link
                            href="/import-players"
                            style={{
                              color: "#3B6CF8",
                              fontWeight: "normal",
                              textDecoration: "underline"
                            }}
                          >
                            Upload Athletes/Players
                          </Link>
                        </>
                      )}
                      {(alias === "PARENT" || alias === "PLAYER") && " screen)"}
                    </Typography>
                  </StyledFormLabel>
                ) : (
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    data-testid={"ROLE_ALIAS_" + alias}
                  >
                    For {" " + capitalize(alias)}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                direction="row"
                spacing="15px"
                style={{ marginLeft: "-1px" }}
              >
                {renderRoles(rolesAliasesMap[alias], bannedAlias)}
              </Grid>
            </Grid>
          )
        );
      })}
      <ConfirmationDialog
        open={!!parentToRemove}
        title="Warning"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        body={
          parentToRemove?.message ||
          "This Parent Has Children Associated to them"
        }
        onConfirm={() => {
          if (parentToRemove && onChangeRolesSelected)
            onChangeRolesSelected(parentToRemove?.roleId, false);
          setParentToRemove(undefined);
        }}
        onCancel={() => {
          if (setValue && parentToRemove)
            setValue(
              parentToRemove.roleId.replace(".", "_") + "_SELECTED",
              true
            );
          setParentToRemove(undefined);
        }}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Grid>
  );
};
