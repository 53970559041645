import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import {
  getCrmAccounts,
  getCrmContacts,
  getCrmOrgAccounts,
  getCrmOrgContacts,
  getCRMUsers
} from "../../../services/Network";
import { Control, Controller } from "react-hook-form";
import { Add, Star } from "@mui/icons-material";
import formatFullName from "@utils/formatFullName";
import {
  ContactResponse,
  ModelAccount,
  ModelOrganizationAccountContact
} from "@sportsgravyengineering/sg-api-react-sdk";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const AccountOption = ({
  person,
  onClick,
  disabled,
  ...props
}: {
  person;
  disabled?: boolean;
  onClick: () => void;
}) => {
  return (
    <Grid
      container
      direction="column"
      padding="10px 16px 10px 16px"
      {...props}
      onClick={onClick}
    >
      <Grid item container direction="row" spacing="5px">
        <Grid item>
          <Typography
            style={{
              color: disabled ? "rgba(0, 0, 0, 0.38)" : "#0F0F0F",
              fontSize: "13px",
              fontWeight: 500
            }}
          >
            {person?.label}
          </Typography>
        </Grid>
        {person?.emailDetails?.isPrimaryStar && (
          <Grid item style={{ marginTop: "1px" }}>
            <Star
              htmlColor="#E82C2C"
              style={{
                width: "15px",
                height: "15px"
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Typography
          style={{
            color: "#666666",
            fontSize: "13px",
            fontWeight: 400
          }}
        >
          {person?.emailDetails?.email || person?.text}
        </Typography>
      </Grid>
      {person?.emailDetails?.emailInfo && (
        <Grid item>
          <Typography
            style={{
              color: "#666666",
              fontSize: "11px",
              fontWeight: 400
            }}
          >
            {person?.emailDetails?.emailInfo}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const AddAccountOption = ({ onClick }: { onClick: () => void }) => {
  return (
    <Grid
      container
      padding="10px 16px 10px 16px"
      style={{ backgroundColor: "#E5E5E5" }}
      xs={12}
      direction="column"
      data-testid="ADD_NEW_PERSON_OPTION"
    >
      <Grid item>
        <Typography
          style={{
            color: "#0F0F0F",
            fontWeight: "600",
            fontSize: "13px"
          }}
        >
          Not finding who you're looking for?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          style={{
            color: "#007AFF",
            textTransform: "capitalize",
            fontSize: "14px"
          }}
          onClick={onClick}
          startIcon={<Add />}
        >
          Add New Contact
        </Button>
      </Grid>
    </Grid>
  );
};
export type participant = {
  label: string;
  text: string | undefined;
  value: string;
  isInternal?: boolean;
  phone?: string;
  emailDetails?: {
    email?: string;
    emailInfo?: string;
    isPrimaryStar?: boolean;
    isWork?: boolean;
  };
};
export const SearchPartipants = ({
  required,
  label,
  control,
  showAddOption,
  name,
  disabled,
  selectedOption,
  labelField,
  addNewlick,
  tagField,
  type,
  onChange,
  organizationId
}: {
  required: boolean;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  showAddOption: boolean;
  name: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOption?: participant;
  labelField?: "address" | "email";
  tagField?: "name";
  type: string;
  onChange: (options: participant[]) => void;
  addNewlick?: () => void;
  organizationId?: string;
}) => {
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const [accountSearch, setAccountSearch] = useState("");
  const { data: searchResults, isFetching: isFetchingUsers } =
    type === "CALL" || type === "MEETING" || type === "EMAIL"
      ? getCRMUsers(
          {
            textSearch: accountSearch,
            ...(organizationId && {
              organizationId: organizationId
            })
          },
          {
            staleTime: Infinity,
            enabled:
              !!accountSearch?.length && (type === "CALL" || type === "MEETING")
          }
        )
      : {
          data: [],
          isFetching: false
        };

  const { data: searchResultsContacts, isFetching: isFetchingContacts } =
    organizationId
      ? getCrmOrgContacts(
          {
            textSearch: accountSearch,
            organizationId: organizationId
          },
          { staleTime: Infinity, enabled: !!accountSearch?.length }
        )
      : getCrmContacts(
          {
            textSearch: accountSearch
          },
          { staleTime: Infinity, enabled: !!accountSearch?.length }
        );
  const { data: searchResultsAccounts, isFetching: isFetchingAccounts } =
    type === "EMAIL"
      ? organizationId
        ? getCrmOrgAccounts(
            {
              textSearch: accountSearch,
              organizationId: organizationId
            },
            { staleTime: Infinity, enabled: !!accountSearch?.length }
          )
        : getCrmAccounts(
            {
              textSearch: accountSearch
            },
            { staleTime: Infinity, enabled: !!accountSearch?.length }
          )
      : { data: null, isFetching: false };
  const options = useMemo(() => {
    let res: participant[] = [];
    if (searchResults) {
      res = searchResults
        .filter((r) => !res.find((r1) => r1.value === r.userId))
        .map((person) => ({
          label: `${person.firstName} ${person.lastName}`,
          text:
            labelField == "address"
              ? person.addressPrimary?.lines?.[0]
              : person.emailPrimary?.email || person.email,
          value: person.userId as string,
          ...((type === "MEETING" || type === "EMAIL") && {
            emailDetails: {
              email: person.emailPrimary?.email || person.email,
              emailInfo: "SportsGravy Email"
            }
          }),
          isInternal: true
        }));
    }
    if (searchResultsContacts) {
      if (type !== "EMAIL" && type !== "MEETING") {
        searchResultsContacts.map((contact) => {
          if (!res.find((r) => r.value === contact.contactId)) {
            res.push({
              label: formatFullName(
                contact as {
                  firstName: string;
                  middleName: string;
                  lastName: string;
                  suffix: string;
                }
              ),
              value: contact.contactId as string,
              text: labelField == "address" ? contact.location : contact.email,
              isInternal: false,
              phone: contact.phone
            });
          }
        });
      } else {
        searchResultsContacts.map(
          (c: ModelOrganizationAccountContact | ContactResponse) => {
            const contactAccounts = c.accounts;
            contactAccounts.map(
              (ca: {
                workEmail?: string;
                account: ModelAccount;
                isPrimary: boolean;
              }) => {
                if (ca.workEmail && !res.find((r) => r.text === ca.workEmail))
                  res.push({
                    label: formatFullName(
                      c as {
                        firstName: string;
                        middleName: string;
                        lastName: string;
                        suffix: string;
                      }
                    ),
                    value: c.contactId as string,
                    emailDetails: {
                      email: ca.workEmail,
                      emailInfo: `Work Email for ${ca.account?.name || ""}`,
                      isPrimaryStar: ca.isPrimary,
                      isWork: true
                    },
                    text: ca.workEmail,
                    isInternal: false
                  });
              }
            );
            if (c.email && !res.find((r) => r.text === c.email)) {
              res.push({
                label: formatFullName(
                  c as {
                    firstName: string;
                    middleName: string;
                    lastName: string;
                    suffix: string;
                  }
                ),
                value: c.contactId as string,
                emailDetails: {
                  email: c.email,
                  emailInfo: "Personal Email",
                  isWork: false
                },
                text: c.email,
                isInternal: false
              });
            }
          }
        );
      }
    }
    if (searchResultsAccounts) {
      searchResultsAccounts.map((acc) => {
        if (acc.email && !res.find((r) => r.text === acc.email)) {
          res.push({
            label: acc.name,
            value: acc.accountId as string,
            text: acc.email,
            isInternal: false
          });
        }
      });
    }
    if (showAddOption)
      res.push({
        label: "Additional Label",
        text: "Additional Text",
        value: "*"
      });
    if (selectedOption) res.push(selectedOption);
    return res;
  }, [
    searchResults,
    accountSearch,
    searchResultsContacts,
    searchResultsAccounts
  ]);

  const handleAddAccountClick = () => {
    if (addNewlick) addNewlick();
    const inputElement = autocompleteRef.current?.querySelector("input");
    if (inputElement) {
      inputElement.blur();
    }
  };
  return (
    <Grid item container direction="row" spacing="24px" key={type}>
      <Grid item xs={12}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? `${label} is required` : false
          }}
          render={({ field }) => (
            <Box ref={autocompleteRef}>
              <StyledFormLabel required={required}>
                <Typography display="inline" variant="formLabel">
                  {label}
                </Typography>
              </StyledFormLabel>
              <Grid
                spacing={3}
                sx={{ padding: "0px 0px 12px 0px", marginTop: "4px" }}
              >
                <Autocomplete
                  disablePortal
                  value={field.value}
                  filterOptions={(options) => {
                    return !isFetchingUsers &&
                      !isFetchingContacts &&
                      !isFetchingAccounts
                      ? options.filter(
                          (o) =>
                            o.value === selectedOption?.value ||
                            (type === "EMAIL" || type === "MEETING"
                              ? !field?.value.some((v) => v.text === o.text)
                              : !field?.value.some((v) => v.value === o.value))
                        )
                      : [];
                  }}
                  freeSolo
                  disableClearable={true}
                  multiple
                  disabled={disabled}
                  options={options}
                  getOptionLabel={(option) => {
                    if (labelField == "email") {
                      return `${option.label} - ${option.text}`;
                    }
                    return option.label;
                  }}
                  renderOption={(props, option, state) => {
                    if (option.value === "*" && showAddOption && addNewlick) {
                      return (
                        <AddAccountOption
                          key={state.index}
                          onClick={handleAddAccountClick}
                        />
                      );
                    }
                    if (option.value === selectedOption?.value) return null;
                    return (
                      <li
                        {...props}
                        style={{
                          borderBottom: "1px solid #E5E5E5",
                          ...(type === "EMAIL" &&
                            !option.text && {
                              cursor: "not-allowed",
                              pointerEvents: "none"
                            })
                        }}
                      >
                        <AccountOption
                          person={option}
                          onClick={() => {}}
                          disabled={type === "EMAIL" && !option.text}
                        />
                      </li>
                    );
                  }}
                  filterSelectedOptions
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={
                          type === "EMAIL" || type === "MEETING"
                            ? `${option.label} ${
                                option.text ? "(" + option.text + ")" : ""
                              }`
                            : tagField == "name"
                            ? option.label
                            : labelField == "email"
                            ? `${option.label} - ${option.text}`
                            : option.label
                        }
                        {...getTagProps({ index })}
                        disabled={
                          option.value === selectedOption?.value || disabled
                        }
                        key={index}
                      />
                    ))
                  }
                  onInputChange={(event, newInputValue) => {
                    setAccountSearch(newInputValue);
                  }}
                  loading={
                    isFetchingUsers || isFetchingContacts || isFetchingAccounts
                  }
                  loadingText="Loading..."
                  onChange={(event, newValue) => {
                    if (selectedOption) {
                      field.onChange([
                        ...[selectedOption],
                        ...newValue.filter(
                          (option) => selectedOption?.value !== option.value
                        )
                      ]);
                      onChange([
                        ...[selectedOption],
                        ...newValue.filter(
                          (option) => selectedOption?.value !== option.value
                        )
                      ]);
                    } else {
                      field.onChange(newValue);
                      onChange(newValue);
                    }
                  }}
                />
              </Grid>
            </Box>
          )}
        />
      </Grid>
    </Grid>
  );
};
