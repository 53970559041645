import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Form } from "@components/crud/Form";
import { LeadDetailsForm } from "./LeadDetailsForm";
import { Footer } from "@components/crud/Footer";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  ModelDemoSession,
  ModelLead,
  useAdminCrmLeadLeadIdGet,
  useAdminCrmLeadLeadIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import formatFullName from "@utils/formatFullName";
import { FullName } from "@utils/types";
import { hasPermission } from "@services/Casbin";

export const LeadEdit = () => {
  const navigate = useNavigate();
  const { leadId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("crm.leads", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
      details: ContactResponse;
    }[]
  >([]);

  const {
    reset,
    getValues,
    formState: { isDirty, isValid }
  } = form;

  const [leadOwner, setLeadOwner] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const { data: leadData, isLoading: isLoading } = useAdminCrmLeadLeadIdGet(
    leadId!
  );

  useEffect(() => {
    if (leadData?.data) {
      const lead = leadData.data as ModelLead & {
        demoDetail: ModelDemoSession;
        referralUrl?: string;
      };
      reset({
        name: lead.name,
        source: lead.source?.type,
        status: lead.status,
        leadOwner: lead.owner?.personId,
        account: {
          name: lead.account?.name || "",
          parent: lead.account?.parent?.name || "",
          category: lead.account?.category,
          type: lead.account?.type || lead.demoDetail?.organizationType,
          officeAddress:
            lead.account?.officeAddress ||
            (lead.demoDetail
              ? `${lead.demoDetail.city}, ${lead.demoDetail.state}, ${lead.demoDetail.country}`
              : ""),
          email: lead.account?.email,
          website: lead.account?.website,
          sportsOffered:
            lead.account?.sports?.map((sp) => sp.sportId) ||
            (lead.demoDetail?.sport
              ? lead.demoDetail?.sport?.map((s) => s.sportId)
              : []),
          noOfAthletes:
            lead.account?.numberOfAthletes || lead.demoDetail?.noOfAthletes,
          aeOwner: lead.account?.aeOwner?.person
            ? formatFullName(lead.account?.aeOwner?.person)
            : ""
        },
        contact: {
          name:
            formatFullName(lead.contact as FullName) ||
            lead.demoDetail?.primaryContact,
          personalEmail: lead.contact?.email || lead?.demoDetail?.email,
          homeAddress: lead.contact?.location,
          phoneType: "MOB",
          phone: lead.contact?.phone || lead.demoDetail?.phone,
          whatsappNumber: lead.contact?.whatsappNumber
        },
        referralUrl: lead.referralUrl,
        interestInSG: lead.demoDetail?.message
      });
      setSelectedAccount(lead.accountId || "");
      setLeadOwner(lead.owner?.personId || "");
    }
  }, [leadData]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { mutate: save, isLoading: isSaving } = useAdminCrmLeadLeadIdPut();
  const onSave = () => {
    const formvalues = getValues();
    const data = {
      name: formvalues.name,
      ownerId: leadOwner,
      source: {
        sourceId: leadData?.data?.sourceId,
        name: "",
        type: formvalues.source
      },
      status: formvalues.status,
      ...(leadData?.data?.accountId
        ? {
            accountId: selectedAccount,
            contactId: selectedContact
          }
        : {
            account: {
              name: formvalues.account?.name || "",
              ...(formvalues.account?.parent && {
                parent: formvalues.account?.parent
              }),
              category: formvalues.account?.category,
              type: formvalues.account?.type,
              officeAddress: formvalues.account?.officeAddress,
              email: formvalues.account?.email,
              website: formvalues.account?.website || undefined,
              sportsOffered: formvalues.account?.sportsOffered,
              aeOwnerId: formvalues.account?.aeOwner,
              noOfAthletes: parseInt(formvalues.account?.noOfAthletes)
            },
            contact: {
              name: formvalues.contact?.name,
              email: formvalues.contact?.personalEmail,
              homeAddress: formvalues.contact?.homeAddress,
              phone: formvalues.contact?.phone,
              whatsappNumber: formvalues.contact?.whatsappNumber
            }
          }),
      accountId: selectedAccount || undefined,
      contactId: selectedContact || undefined
    };
    save(
      {
        leadId: leadId!,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Lead Edited successfully!", {
            variant: "success"
          });
          if (!isLeadQualifed) navigate("/crm/leads");
          else navigate(`/crm/opportunities/create?leadId=${leadId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to Edit Lead!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && leadData?.data) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );

          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || "",
              details: c.contact as ContactResponse
            }))
          );
          if (leadData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(leadData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, leadData]);

  const [isLeadQualifed, setIsLeadQualified] = useState(false);
  return (
    <Container>
      <Toolbar title="Edit Lead" />
      <Loader isLoading={isLoading}>
        <Form>
          <LeadDetailsForm
            form={form}
            leadOwner={leadOwner}
            setLeadOwner={setLeadOwner}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
            isMQL={!leadData?.data?.accountId}
            mode="EDIT"
            setContacts={setContacts}
            setQualifiedLead={(val) => setIsLeadQualified(val)}
          />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        saveBtnLabel={isLeadQualifed ? "Save & Add Opportunity" : "Save"}
        isDisabled={!isDirty || !isValid || isSaving}
        isLoading={isLoading || isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/leads")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
